import React, { useContext, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { EditInfo, Message } from "../../context/appContextData";
import { Requirement } from "../../apicaller/domain/response/operation";
import { RequirementRegistRequest } from "../../apicaller/domain/request/operation";
import { deleteRequirement, postRequirement, useRequirement } from "../../apicaller/repository/operation";
import { ErrorMessages, SuccessMessages } from "../../consts/messages";
import { Requirement as RequirementLabel } from "../../consts/label.ts";

import torqueWrench from "../../icons/torque-wrench.png";
import glue from "../../icons/glue.png";
import grease from "../../icons/grease.png";
import oil from "../../icons/oil.png";
import silicone from "../../icons/silicone.png";
import antiRust from "../../icons/anti-rust.png";
import torqueWrenchClassA from "../../icons/torque-wrench-classA.png";
import torqueWrenchClassB from "../../icons/torque-wrench-classB.png";
import RequirementChip from "./RequirementChip";
import DialogRequirement from "./DialogRequirement";

interface Props {
  operationId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outterDiv: {
      height: "100%",
      overflowY: "auto",
      overflowX: "hidden",
      scrollSnapType: "y mandatory",
    },
    innerDiv: {
      flexGrow: 1,
      padding: "0 10px 0 10px",
      display: "flex",
      flexdirection: "row",
      flexWrap: "wrap",
      scrollSnapType: "y mandatory",
    },
  })
);

const RequirementChipGroup = (props: Props) => {
  const styles = useStyles();
  const EditContext = useContext(EditInfo);
  const [requirementList, getRequirementList, mutateRequirementList, requirementError] = useRequirement();
  const snackBarMessage = useContext(Message);
  const requirementItems = [
    { index: 1, name: RequirementLabel.TORQUE, image: torqueWrench, avatarSourceId: 1, text: RequirementLabel.TORQUE, avatarSource: torqueWrench },
    { index: 2, name: RequirementLabel.GLUE, image: glue, avatarSourceId: 2, text: RequirementLabel.GLUE, avatarSource: glue },
    { index: 3, name: RequirementLabel.GREASE, image: grease, avatarSourceId: 3, text: RequirementLabel.GREASE, avatarSource: grease },
    { index: 4, name: RequirementLabel.OIL, image: oil, avatarSourceId: 4, text: RequirementLabel.OIL, avatarSource: oil },
    { index: 5, name: RequirementLabel.SLIDING_AGENT, image: silicone, avatarSourceId: 5, text: RequirementLabel.SLIDING_AGENT, avatarSource: silicone },
    { index: 6, name: RequirementLabel.ANTI_RUST, image: antiRust, avatarSourceId: 6, text: RequirementLabel.ANTI_RUST, avatarSource: antiRust },
    { index: 7, name: RequirementLabel.CLASS_A, image: torqueWrenchClassA, avatarSourceId: 7, text: RequirementLabel.CLASS_A, avatarSource: torqueWrenchClassA },
    { index: 8, name: RequirementLabel.CLASS_B, image: torqueWrenchClassB, avatarSourceId: 8, text: RequirementLabel.CLASS_B, avatarSource: torqueWrenchClassB },
  ];
  const [dialogRequirementList, setDialogRequirementList] = useState(requirementItems);

  useEffect(() => {
    if (!requirementList) return;
    if (!requirementList.requirement) return;

    setRequirementList();
  }, [requirementList]);

  useEffect(() => {
    getRequirementList(props.operationId);
  }, [props.operationId]);

  // SWR error
  useEffect(() => {
    if (requirementError) snackBarMessage.setMessage(ErrorMessages.GET_REQUIREMENT, "error");
  }, [requirementError]);

  // add chip
  const handleChipAdd = (requirement_id: number) => {
    const reqData = new RequirementRegistRequest(Number(props.operationId), requirement_id);
    postRequirement(reqData).then(
      (data) => {
        mutateRequirementList();
        snackBarMessage.setMessage(SuccessMessages.INSERT_REQUIREMENT, "success");
      },
      (error) => {
        snackBarMessage.setMessage(ErrorMessages.INSERT_REQUIREMENT, "error");
      }
    );
  };

  // delete chip
  const handleChipDelete = (requirementNecessityId: number) => {
    deleteRequirement(String(requirementNecessityId)).then(
      (data) => {
        mutateRequirementList();
        snackBarMessage.setMessage(SuccessMessages.DELETE_REQUIREMENT, "success");
      },
      (error) => {
        snackBarMessage.setMessage(ErrorMessages.DELETE_REQUIREMENT, "error");
      }
    );
  };

  const setDialogData = () => {
    const list = setRequirementList();

    let tempList = list;
    for (let i = 0; i < requirementItems.length; i = i + 1) {
      let exist = tempList.find((data) => data.requirement_id == requirementItems[i].index) ?? 0;
      if (exist !== 0) {
        requirementItems.splice(i, 1);
        i = i - 1;
      }
    }
    setDialogRequirementList(requirementItems);
  };

  const setRequirementList = () => {
    if (!requirementList) return [];

    const list: Requirement[] = [];
    if (!requirementList.requirement) return [];
    requirementList.requirement.map((a) => {
      list.push(a);
    });

    return list;
  };

  return (
    <div className={styles.outterDiv}>
      <div className={styles.innerDiv}>
        {setRequirementList().map((requirement) => {
          return <RequirementChip requirementData={requirement} handleDelete={handleChipDelete} requirementItems={requirementItems} />;
        })}
        {EditContext.editMode && <DialogRequirement AddHandle={handleChipAdd} getAddItems={setDialogData} requirementItems={dialogRequirementList} />}
      </div>
    </div>
  );
};

export default RequirementChipGroup;
