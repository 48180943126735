import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { WorkStandardListDisplayRow, WorkStandardListDisplayRows } from "./display/operationList";
import React, { useContext, useMemo, useState } from "react";
import { DialogMessages, SuccessMessages } from "../../consts/messages";
import { Message, WindowSize } from "../../context/appContextData";
import Paper from "@material-ui/core/Paper";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { IconButton } from "@material-ui/core";
import { deleteWorkStandard } from "../../apicaller/repository/workstandard";
import { SelectedConditions, SelectedOperationInfo } from "../../types/workStandard";
import EditIcon from "@material-ui/icons/Edit";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import { revision } from "../../consts/firstRevision";
import { MoveDialog } from "./MoveDialog";
import { ListOption } from "../Common/ListBox";
import { ChangeNameDialog } from "./ChangeNameDialog";
import { WorkFlowStatusNamesLabel, WorkStandardListPageLabel } from "../../consts/label";

interface Column {
  id: "no" | "operationName" | "status" | "editionNum" | "managedNum" | "createUser" | "date" | "editIcon" | "changeName" | "move" | "delete";
  label: string;
  minWidth?: number;
  align: "left" | "right" | "center";
}

interface Props {
  displayRows: WorkStandardListDisplayRows;
  mutateOperationList: () => void;
  showOptionColumns: boolean;
  handleOpenWorkStandard: (selectedConditions: SelectedConditions, workStandardInfo: SelectedOperationInfo) => void;
  module: { id: number; name: string };
  teamId: number;
}

export class OperationForDisplay {
  operation_list: string[] = [];
}

export default function OperationTable(props: Props) {
  const snackBarMessage = useContext(Message);
  const [isOpenChangeNameDialog, setIsOpenChangeNameDialog] = useState(false);
  const [isOpenMoveDialog, setIsOpenMoveDialog] = useState(false);
  const [selectChangeNameWorkStandard, setSelectChangeNameWorkStandard] = useState<{ id: number; name: string }>({ id: 0, name: "" });
  const [selectMoveWorkStandard, setSelectMoveWorkStandard] = useState<number>(0);
  const windowSizeContext = useContext(WindowSize);

  const [columns, setColumns] = useState<Column[]>([
    {
      id: "no",
      label: WorkStandardListPageLabel.NUMBER,
      minWidth: 50,
      align: "right",
    },
    {
      id: "operationName",
      label: WorkStandardListPageLabel.WORK_STANDARD_NAME,
      minWidth: 200,
      align: "left",
    },
    {
      id: "status",
      label: WorkStandardListPageLabel.STATUS,
      minWidth: 100,
      align: "left",
    },
    {
      id: "editionNum",
      label: WorkStandardListPageLabel.REVISION_NUMBER,
      minWidth: 50,
      align: "right",
    },
    {
      id: "managedNum",
      label: WorkStandardListPageLabel.MANAGED_NUMBER,
      minWidth: 50,
      align: "right",
    },
    {
      id: "createUser",
      label: WorkStandardListPageLabel.CREATOR,
      minWidth: 100,
      align: "left",
    },
    {
      id: "date",
      label: WorkStandardListPageLabel.DATE,
      minWidth: 50,
      align: "left",
    },
    {
      id: "changeName",
      label: WorkStandardListPageLabel.CHANGE_NAME,
      minWidth: 50,
      align: "center",
    },
    {
      id: "move",
      label: WorkStandardListPageLabel.MOVE,
      minWidth: 50,
      align: "center",
    },
    {
      id: "delete",
      label: WorkStandardListPageLabel.DELETE,
      minWidth: 50,
      align: "center",
    },
  ]);

  const moduleListOption: ListOption = useMemo(() => {
    return { key: props.module.id, value: props.module.name };
  }, [props.module]);

  const handleShowHome = (row: WorkStandardListDisplayRow) => {
    props.handleOpenWorkStandard(
      {
        factory: { key: row.factory_id, value: row.factory_name },
        plant: { key: row.plant_id, value: row.plant_name },
        module: { key: row.line_id, value: row.line_name },
        unit: { key: row.unit_id, value: row.unit_name },
        team: { key: row.team_id, value: row.team_name },
        model: { key: row.model_id, value: row.model_name },
      },
      {
        operation_id: row.operation_id,
        managed_number: row.managed_number,
        operation_name: row.operation_name,
      }
    );
  };

  const openChangeNameDialog = (workStandardId: number, workStandardName: string) => {
    setSelectChangeNameWorkStandard({ id: workStandardId, name: workStandardName });
    setIsOpenChangeNameDialog(true);
  };

  const handleCloseChangeNameDialog = () => {
    props.mutateOperationList();
    setIsOpenChangeNameDialog(false);
  };

  const openMoveDialog = (workStandardId: number) => {
    setSelectMoveWorkStandard(workStandardId);
    setIsOpenMoveDialog(true);
  };

  const handleCloseMoveDialog = () => {
    props.mutateOperationList();
    setIsOpenMoveDialog(false);
  };

  const handleDelete = (workStandardId: number) => {
    if (window.confirm(DialogMessages.COMMON_DELETE)) {
      deleteWorkStandard(String(workStandardId))
        .then(() => {
          props.mutateOperationList();
          snackBarMessage.setMessage(SuccessMessages.DELETE_WORK_STANDARD, "success");
        })
        .catch((error) => {
          console.log(error);
          const message = error.substring(error.indexOf("msg=") + 5, error.length - 1);
          snackBarMessage.setMessage(message, "error");
        });
    }
  };

  return (
    <>
      <ChangeNameDialog
        open={isOpenChangeNameDialog}
        workStandardId={selectChangeNameWorkStandard.id}
        workStandardName={selectChangeNameWorkStandard.name}
        onClose={handleCloseChangeNameDialog}
      />
      <MoveDialog
        open={isOpenMoveDialog}
        workStandardId={selectMoveWorkStandard}
        module={moduleListOption}
        teamId={props.teamId}
        onClose={handleCloseMoveDialog}
      />
      <TableContainer
        component={Paper}
        style={{
          height: windowSizeContext.height - 210,
          minHeight: "100px",
          overflow: "auto",
        }}
      >
        <Table stickyHeader aria-label="sticky table" style={{ minWidth: "800px" }}>
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                if (column.id === "changeName" && !props.showOptionColumns) {
                  return <></>;
                }
                if (column.id === "delete" && !props.showOptionColumns) {
                  return <></>;
                }
                if (column.id === "move" && !props.showOptionColumns) {
                  return <></>;
                }

                return (
                  <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth, whiteSpace: "nowrap" }}>
                    {column.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.displayRows.rows.map((row, rowIndex) => (
              <TableRow key={row.operation_id} hover={true} style={{ cursor: "pointer" }} onClick={() => handleShowHome(row)}>
                <TableCell align="right">{rowIndex + 1} </TableCell>
                <TableCell align="left">{row.operation_name}</TableCell>
                <TableCell align="left">{row.status} </TableCell>
                <TableCell align="right">{row.revision_number} </TableCell>
                <TableCell align="right">{row.managed_number} </TableCell>
                <TableCell align="left">{row.user_name + " (" + row.login_id + ")"}</TableCell>
                <TableCell align="left">{row.created_at}</TableCell>
                {props.showOptionColumns && (
                  <TableCell align="center">
                    {row.status === WorkFlowStatusNamesLabel.EDITING && row.revision_number === revision.firstRevision && (
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          openChangeNameDialog(row.operation_id, row.operation_name);
                          e.stopPropagation();
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                  </TableCell>
                )}
                {props.showOptionColumns && (
                  <TableCell align="center">
                    {row.status === WorkFlowStatusNamesLabel.EDITING && row.revision_number === revision.firstRevision && (
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          openMoveDialog(row.operation_id);
                          e.stopPropagation();
                        }}
                      >
                        <SwapHorizIcon />
                      </IconButton>
                    )}
                  </TableCell>
                )}
                {props.showOptionColumns && (
                  <TableCell align="center">
                    {row.status === WorkFlowStatusNamesLabel.EDITING && (
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          handleDelete(row.operation_id);
                          e.stopPropagation();
                        }}
                      >
                        <RemoveCircleOutlineIcon color="error" />
                      </IconButton>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
