import React, { useContext, useState } from "react";
import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { Container, Draggable, DropResult } from "react-smooth-dnd";
import { makeStyles, createStyles, useTheme } from "@material-ui/core/styles";
import { EditInfo, Message, WindowSize } from "../../context/appContextData";
import { ProcessRows } from "./display/operationManagementBook";
import ProcessManagementBookDialog, { ProcessManagementBookDialogAction } from "./ProcessManagementBookDialog";
import { DialogMessages, ErrorMessages, SuccessMessages, TooltipMessages, WarningMessages } from "../../consts/messages";
import { deleteProcess, postProcess, putProcessName } from "../../apicaller/repository/managementbook";
import { ProcessNameUpdateRequest, ProcessRegistRequest } from "../../apicaller/domain/request/managementbook";
import { ManagementBookProcessListTabLabel } from "../../consts/label";

interface Props {
  processDisplayRows: ProcessRows;
  managementBookId: number;
  handleRefleshProcess: any;
  handleSwapProcess: (dropResult: DropResult) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    processHeader: {
      overflow:"visible",
      borderBottomRightRadius: "0px",
      borderBottomLeftRadius: "0px",
    },
    processContainer: {
      height: "100%",
      flexGrow: 1,
      borderTopRightRadius: "0px",
      borderTopLeftRadius: "0px",
    },
    headerRow: {
      display: "flex",
    },
    headerOrderCell: {
      display: "flex",
      width: "10%",
      alignItems: "center",
      whiteSpace: "nowrap",
    },
    headerNameCell: {
      paddingLeft: "5%",
      display: "flex",
      width: "70%",
      alignItems: "center",
      whiteSpace: "nowrap",
    },
    headerButtonCell: {
      display: "flex",
      width: "20%",
      alignItems: "center",
    },
    tableRow: {
      height: "50px",
      display: "flex",
    },
    bodyOrderCell: {
      display: "flex",
      width: "10%",
      alignItems: "center",
    },
    bodyNameCell: {
      display: "flex",
      width: "70%",
      alignItems: "center",
    },
    bodyButtonCell: {
      display: "flex",
      width: "20%",
      alignItems: "center",
      justifyContent: "right",
    },
  })
);

const ProcessManagementBookTable = (props: Props) => {
  const theme = useTheme();
  const styles = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [inputText, setInputText] = useState("");
  const [targetProcessId, setTargetProcessId] = useState(0);
  const EditContext = React.useContext(EditInfo);
  const snackBarMessage = useContext(Message);
  const windowSizeContext = useContext(WindowSize);

  // 追加ボタン押下時
  const handleClickAdd = () => {
    setIsAdd(true);
    setOpenDialog(true);
  };

  // 編集ボタン押下時
  const handleClickEdit = (id: number, name: string) => {
    setIsAdd(false);
    setTargetProcessId(id);
    setInputText(name);
    setOpenDialog(true);
  };

  const handleDelete = (id: number) => {
    if (window.confirm(DialogMessages.DELETE_OBJECT)) {
      deleteProcess(String(id))
        .then(() => {
          props.handleRefleshProcess();
          snackBarMessage.setMessage(SuccessMessages.DELETE_PROCESS, "success");
        })
        .catch((error: any) => {
          console.log(error);
          snackBarMessage.setMessage(ErrorMessages.DELETE_PROCESS, "error");
        });
    }
    return;
  };

  // ダイアログ入力項目変更時
  const handleChangeText = (text: string) => {
    setInputText(text);
  };

  // ダイアログボタン押下時
  const handleDialogAction = (name: string, action: ProcessManagementBookDialogAction) => {
    // キャンセルボタン押下時処理
    if (action === ProcessManagementBookDialogAction.cancel) {
      setTargetProcessId(0);
      setInputText("");
      setOpenDialog(false);
      return;
    }

    //文字の空チェック
    if (name === "") {
      snackBarMessage.setMessage(WarningMessages.DATAMANAGEMENT_INPUT_LENGTH, "warning");
      return;
    }

    // 工程登録API処理
    if (action === ProcessManagementBookDialogAction.create) {
      postProcess(new ProcessRegistRequest(name, props.managementBookId)).then(
        () => {
          props.handleRefleshProcess();
          setTargetProcessId(0);
          setInputText("");
          setOpenDialog(false);
          snackBarMessage.setMessage(SuccessMessages.INSERT_PROCESS, "success");
        },
        (error: any) => {
          console.log(error);
          snackBarMessage.setMessage(ErrorMessages.INSERT_PROCESS, "error");
        }
      );
      return;
    }
    // 工程名変更API処理
    if (action === ProcessManagementBookDialogAction.edit) {
      putProcessName(new ProcessNameUpdateRequest(targetProcessId, name)).then(
        () => {
          props.handleRefleshProcess();
          setTargetProcessId(0);
          setInputText("");
          setOpenDialog(false);
          snackBarMessage.setMessage(SuccessMessages.UPDATE_PROCESS, "success");
        },
        (error: any) => {
          console.log(error);
          snackBarMessage.setMessage(ErrorMessages.UPDATE_PROCESS, "error");
        }
      );
      return;
    }
  };

  return (
    <>
      <ProcessManagementBookDialog
        open={openDialog}
        isAdd={isAdd}
        inputText={inputText}
        handleChangeText={handleChangeText}
        handleDialogAction={handleDialogAction}
      />
      <TableContainer component={Paper} className={styles.processHeader}>
        <Table stickyHeader>
          <TableHead>
            <TableRow className={styles.headerRow}>
              <TableCell className={styles.headerOrderCell} align={"right"}>
                {ManagementBookProcessListTabLabel.PROCESS_ORDER}
              </TableCell>
              <TableCell className={styles.headerNameCell} align={"left"}>
                {ManagementBookProcessListTabLabel.PROCESS_NAME}
              </TableCell>
              <TableCell className={styles.headerButtonCell} align="right">
                {EditContext.editMode && (
                  <div>
                    <IconButton size="small" onClick={handleClickAdd}>
                      <AddCircleIcon />
                    </IconButton>
                  </div>
                )}
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <TableContainer component={Paper} className={styles.processContainer}>
        <Table stickyHeader>
          <TableBody>
            <Container dragHandleSelector=".dragHandleSelector" lockAxis="y" onDrop={props.handleSwapProcess}>
              {props.processDisplayRows.processRows.map((row, index) => {
                return (
                  <Draggable key={index}>
                    <TableRow className={styles.tableRow} role="checkbox" tabIndex={-1} key={row.process_id}>
                      <TableCell className={styles.bodyOrderCell} align={"right"}>
                        {index + 1}
                      </TableCell>
                      <TableCell className={styles.bodyNameCell} align={"left"}>
                        {row.process_name}
                      </TableCell>
                      <TableCell className={styles.bodyButtonCell}>
                        {EditContext.editMode && (
                          <div>
                            <IconButton size="small" onClick={() => handleClickEdit(row.process_id, row.process_name)}>
                              <CreateIcon />
                            </IconButton>
                            <Tooltip title={row.deletable ? "" : TooltipMessages.UNDELETABLE_PROCESS}>
                              <span>
                                <IconButton size="small" onClick={() => handleDelete(row.process_id)} disabled={!row.deletable}>
                                  <DeleteIcon />
                                </IconButton>
                              </span>
                            </Tooltip>
                            <IconButton className="dragHandleSelector" size="small">
                              <DragHandleIcon />
                            </IconButton>
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  </Draggable>
                );
              })}
            </Container>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ProcessManagementBookTable;
