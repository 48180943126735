import { useState } from "react";
import useSWR from "swr";
import { get, put } from "../apiCaller";
import { ApiMethod, ApiName, createEndPoint } from "../createEndPoint";
import {
  OperationNameUpdateRequest,
} from "../domain/request/operationlist";
import { PublishedOperationList } from "../domain/response/operationlist";

//---PublishedOperation---
export const usePublishedOperation = (): [PublishedOperationList, (team_id: string, model_id: string) => void, () => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get);
  const getData = (team_id: string, model_id: string) => {
    setEndPoint(createEndPoint({ name: ApiName.OperationPublishList, method: ApiMethod.Get }, [team_id, model_id]));
  };
  const resetData = () => {
    setEndPoint(null);
  };
  const mutateData = () => {
    mutate(endPoint);
  };

  return [data, getData, resetData, mutateData, error];
};

// Update OperationName
export const putOperationName = (body: OperationNameUpdateRequest) => {
  const bodyJson = JSON.stringify(body);
  return put(createEndPoint({ name: ApiName.OperationName, method: ApiMethod.Put }), bodyJson);
};
