export class CommonResponse {
  code: number = 0;
  message: string = "";
}

export class CommonErrors {
  errors: Errors = { status_code: 0, message: "" };
}

export class Errors {
  status_code: number = 0;
  message: string = "";
}
