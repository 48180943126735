export class FactoryListResponse {
  factory: Factory[] = [];
}

export class Factory {
  factory_id: number = 0;
  factory_name: string = "";
  author_id: number = 0;
  created_at: string = "";
  updated_at: string = "";
}

export class PlantListResponse {
  plant: Plant[] = [];
}

export class Plant {
  plant_id: number = 0;
  factory_id: number = 0;
  plant_name: string = "";
  author_id: number = 0;
  created_at: string = "";
  updated_at: string = "";
}

//クラスのプロパティ名はBEに合わせて作る
//そうしないとデータ取得する時一回変換しなければいけない
export class LineListResponse {
  line: Line[] = [];
}

export class Line {
  line_id: number = 0;
  plant_id: number = 0;
  line_name: string = "";
  author_id: number = 0;
  created_at: string = "";
  updated_at: string = "";
}

export class SectionListResponse {
  module_list: Section[] = [];
  unit_list: Section[] = [];
  team_list: Section[] = [];
}
export class Section {
  section_name: string = "";
  section_id: number = 0;
  role: string = "";
}

export class UnitListResponse {
  unit: UnitList[] = [];
}

export class UnitList {
  unit_id: number = 0;
  line_id: number = 0;
  unit_name: string = "";
  login_id: string = "";
  user_name: string = "";
  created_at: string = "";
  updated_at: string = "";
}

export class TeamListResponse {
  team: TeamList[] = [];
}

export class TeamList {
  team_id: number = 0;
  unit_id: number = 0;
  team_name: string = "";
  login_id: string = "";
  user_name: string = "";
  created_at: string = "";
  updated_at: string = "";
}

export class ModelListResponse {
  model: ModelList[] = [];
}

export class ModelList {
  model_id: number = 0;
  line_id: number = 0;
  model_name: string = "";
  login_id: string = "";
  user_name: string = "";
  created_at: string = "";
  updated_at: string = "";
}

export class VolumeListResponse {
  volume: VolumeList[] = [];
}

export class VolumeList {
  volume_id: number = 0;
  model_id: number = 0;
  volume: number = 0;
  login_id: string = "";
  user_name: string = "";
  created_at: string = "";
  updated_at: string = "";
}

export class ProductionCapacityListOfPublishedManagementBookResponse {
  production_capacity_and_management_book_id_list: ProductionCapacityAndManagementBookIdList[];
}

export class ProductionCapacityAndManagementBookIdList {
  management_book_id: number = 0;
  production_capacity: number = 0;
  production_capacity_id: number = 0;
}

export class UserAllDataResponse {
  user: UserAllData[] = [];
}

export class UserAllData {
  user_id: number = 0;
  module_role: string;
  unit_role: string;
  team_role: string;
  login_id: string = "";
  user_name: string = "";
  authority: number = 0;
  enabled: number = 0;
  created_at: string = "";
  updated_at: string = "";
}

export class UserAllRoleRespose {
  module_role: [];
  unit_role: [];
  team_role: [];
}

export class UnitProductionCapacityListResponse {
  unit: UnitProductionCapacity[] = [];
}

export class UnitProductionCapacity {
  unit_id: number = 0;
  unit_name: string = "";
  current_production_capacity_id: number = 0;
  production_capacity: ProductionCapacityItem[] = [];
}

export class ProductionCapacityItem {
  production_capacity_id: number = 0;
  production_capacity: number = 0;
}

export class ProductionCapacityHistoryListResponse {
  history: ProductionCapacityHistory[] = [];
}

export class ProductionCapacityHistory {
  created_at: string = "";
  login_id: string = "";
  user_name: string = "";
  description: string = "";
}
