import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import {
  Button,
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useContext, useEffect, useMemo } from "react";
import { Message } from "../../context/appContextData";
import { useUserHistory } from "../../apicaller/repository/datamanagament";
import { authorityMap } from "../../consts/authority";
import { userHistoryActionMap } from "../../consts/userHistoryAction";
import { formatDatetime } from "../../function/formatDatetime";
import { ErrorMessages } from "../../consts/messages";
import { DialogButtonLabel, UserListPageLabel } from "../../consts/label";

export interface Props {
  open: boolean;
  userId: number;
  onClose: () => void;
}

export const UserHistoryDialog = (props: Props) => {
  const snackBarMessage = useContext(Message);
  const [userHistory, getUserHistory, resetUserHistory, mutateUserHistory, userHistoryError] = useUserHistory();

  useEffect(() => {
    if (props.userId === 0) return;
    getUserHistory(props.userId);
  }, [props.userId]);

  useEffect(() => {
    mutateUserHistory();
  }, [props.open]);

  useEffect(() => {
    if (userHistoryError && props.userId !== 0) {
      console.error(userHistoryError);
      snackBarMessage.setMessage(ErrorMessages.GET_USER_HISTORY, "error");
    }
  }, [userHistoryError]);

  const userHistoryDisplay = useMemo(() => {
    return userHistory?.histories.map((history) => {
      let authority = "-";
      if (!history.module_id) {
        authority = authorityMap.get(history.authority) ?? "-";
      }

      return {
        action: userHistoryActionMap.get(history.action) ?? "-",
        login_id: history.login_id || "-",
        user_name: history.user_name || "-",
        authority: authority,
        module_name: history.module_name || "-",
        unit_name: history.unit_name || "-",
        team_name: history.team_name || "-",
        created_at: formatDatetime(history.created_at),
      };
    });
  }, [userHistory]);

  return (
    <Dialog fullWidth={false} maxWidth={"xl"} open={props.open} onClose={props.onClose}>
      <DialogTitle>{UserListPageLabel.USER_HISTORY_DIALOG_TITLE}</DialogTitle>
      <DialogContent>
        <TableContainer
          style={{
            maxHeight: "60vh",
            overflow: "auto",
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell style={{ whiteSpace: "nowrap" }}>{UserListPageLabel.USER_HISTORY_DIALOG_MANIPULATION}</TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>{UserListPageLabel.LOGIN_ID}</TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>{UserListPageLabel.USER_NAME}</TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>{UserListPageLabel.AUTHORITY}</TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>{UserListPageLabel.MODULE}</TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>{UserListPageLabel.UNIT}</TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>{UserListPageLabel.TEAM}</TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>{UserListPageLabel.USER_HISTORY_DIALOG_DATE_TIME}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userHistoryDisplay?.map((history) => {
                return (
                  <TableRow>
                    <TableCell>{history.action}</TableCell>
                    <TableCell>{history.login_id}</TableCell>
                    <TableCell>{history.user_name}</TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }}>{history.authority}</TableCell>
                    <TableCell>{history.module_name}</TableCell>
                    <TableCell>{history.unit_name}</TableCell>
                    <TableCell>{history.team_name}</TableCell>
                    <TableCell>{history.created_at}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color={"secondary"}>
          {DialogButtonLabel.CLOSE}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
