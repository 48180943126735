import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Grid, Typography, useTheme } from "@material-ui/core";
import { ErrorMessages, SuccessMessages } from "../../consts/messages";
import { FactoryDisplayListBox, ModuleDisplayListBox, PlantDisplayListBox, UnitProductionCapacityDisplayRows } from "../Common/display/display";
import { putProductionCapacityCurrently, useFactory, useLine, usePlant, useUnitProductionCapacity } from "../../apicaller/repository/datamanagament";
import { InnerAreaSize, Message } from "../../context/appContextData";
import {
  FactoryListResponse,
  LineListResponse,
  PlantListResponse,
  UnitProductionCapacityListResponse,
} from "../../apicaller/domain/response/datamanagement";
import { sortByAsc } from "../../function/sort";
import ListBox, { ListOption } from "../Common/ListBox";
import UnitProductionCapacityTable from "./UnitProductionCapacityTable";
import { ProductionCapacityRequest } from "../../apicaller/domain/request/datamanagement";
import { useGetElementProperty } from "../../hooks/getElementProperty";
import { useUserBelonging } from "../../apicaller/repository/authentication";
import { DataManagementType, Label } from "../../consts/label";

interface Props {
  tabContentHeight: number;
}

export default function ProductionCapacityTab(props: Props) {
  const theme = useTheme();
  const snackBarMessage = useContext(Message);
  const listBoxesRef = useRef(null);
  const labelRef = useRef(null);

  const [userBelonging, getUserBelonging, resetUserBelonging, mutateUserBelonging, userBelongingError] = useUserBelonging();
  const [factoryList, getFactoryList, resetFactoryList, mutateFactoryList, factoryError] = useFactory();
  const [plantList, getPlantList, resetPlantList, mutatePlantList, plantError] = usePlant();
  const [moduleList, getModuleList, resetModuleList, mutateModuleList, moduleError] = useLine();
  const [
    unitProductionCapacityList,
    getUnitProductionCapacityList,
    resetUnitProductionCapacityList,
    mutateUnitProductionCapacityList,
    unitProductionCapacityError,
  ] = useUnitProductionCapacity();

  const [selectedFactoryItem, setSelectedFactoryItem] = useState<ListOption>(null);
  const [selectedPlantItem, setSelectedPlantItem] = useState<ListOption>(null);
  const [selectedLineItem, setSelectedLineItem] = useState<ListOption>(null);

  const listBoxesHeight = useGetElementProperty(listBoxesRef).getElementProperty("height");
  const labelHeight = useGetElementProperty(labelRef).getElementProperty("height");

  const tableHeight = useMemo(() => {
    return props.tabContentHeight - (listBoxesHeight + labelHeight + theme.spacing(1));
  }, [props.tabContentHeight, listBoxesHeight, labelHeight]);

  //#region 選択イベント系
  // 拠点のリストボックスが変更されたとき
  const factoryChangeHandler = (values: ListOption) => {
    setSelectedFactoryItem(null);
    setSelectedPlantItem(null);
    setSelectedLineItem(null);

    resetPlantList();
    resetModuleList();
    resetUnitProductionCapacityList();

    if (values != null) {
      setSelectedFactoryItem(values);
      getPlantList(String(values.key));
    }
  };

  // 工場のリストボックスが変更されたとき
  const plantChangeHandler = (values: ListOption) => {
    setSelectedPlantItem(null);
    setSelectedLineItem(null);

    resetModuleList();
    resetUnitProductionCapacityList();

    if (values != null) {
      setSelectedPlantItem(values);
      getModuleList(values.key);
    }
  };

  // モジュールのリストボックスが変更されたとき
  const moduleChangeHandler = (values: ListOption) => {
    setSelectedLineItem(null);

    resetUnitProductionCapacityList();

    if (values != null) {
      setSelectedLineItem(values);
      getUnitProductionCapacityList(String(values.key));
    }
  };

  // 生産台数のリストボックスが変更されたとき
  const unitProductionCapacityChangeHandler = (values: ListOption) => {
    const body = new ProductionCapacityRequest(values.key);
    putProductionCapacityCurrently(body).then(
      () => {
        mutateUnitProductionCapacityList();
        snackBarMessage.setMessage(SuccessMessages.UPDATE_UNIT_PRODUCTION_CAPACITY, "success");
      },
      (error: any) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.UPDATE_UNIT_PRODUCTION_CAPACITY, "error");
      }
    );
  };
  //#endregion

  useEffect(() => {
    getUserBelonging();
    getFactoryList();
  }, []);

  // 拠点の初期選択項目を設定
  useEffect(() => {
    if (!factoryList) return;
    if (!userBelonging) return;
    if (!userBelonging.factory_id) return;
    if (userBelonging.factory_id === 0) return;
    factoryList.factory.forEach((row) => {
      if (row.factory_id === userBelonging.factory_id) {
        setSelectedFactoryItem({ key: row.factory_id, value: row.factory_name });
      }
    });
  }, [userBelonging, factoryList]);

  // 拠点の選択項目が変更されたときに工場リストを取得
  useEffect(() => {
    if (!selectedFactoryItem) return;
    if (!selectedFactoryItem.key) return;
    if (selectedFactoryItem.key === 0) return;
    getPlantList(String(selectedFactoryItem.key));
  }, [selectedFactoryItem]);

  // 工場の初期選択項目を設定
  useEffect(() => {
    if (!plantList) return;
    if (!plantList.plant) return;
    if (plantList.plant.length === 0) return;
    plantList.plant.forEach((row) => {
      if (row.plant_id === userBelonging.plant_id) {
        setSelectedPlantItem({ key: row.plant_id, value: row.plant_name });
      }
    });
  }, [plantList]);

  // 工場の選択項目が変更されたときにモジュールリストを取得
  useEffect(() => {
    if (!selectedPlantItem) return;
    if (!selectedPlantItem.key) return;
    if (selectedPlantItem.key === 0) return;
    getModuleList(selectedPlantItem.key);
  }, [selectedPlantItem]);

  // モジュールの初期選択項目を設定
  useEffect(() => {
    if (!moduleList) return;
    if (!moduleList.line) return;
    if (moduleList.line.length === 0) return;
    moduleList.line.forEach((row) => {
      if (row.line_id === userBelonging.module_id) {
        setSelectedLineItem({ key: row.line_id, value: row.line_name });
      }
    });
  }, [moduleList]);

  // モジュールの選択項目が変更されたときにユニットリストを取得
  useEffect(() => {
    if (!selectedLineItem) return;
    if (!selectedLineItem.key) return;
    if (selectedLineItem.key === 0) return;
    getUnitProductionCapacityList(String(selectedLineItem.key));
  }, [selectedLineItem]);

  // SWR error
  useEffect(() => {
    if (factoryError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_FACTORY, "error");
    }
    if (plantError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_PLANT, "error");
    }
    if (moduleError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_MODULE, "error");
    }
    if (unitProductionCapacityError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_UNIT_PRODUCTION_CAPACITY, "error");
    }
  }, [factoryError, plantError, moduleError, unitProductionCapacityError]);

  // sort for display factory listbox
  let factoryDisplayListBox = new FactoryDisplayListBox();
  if (factoryList && factoryList.factory) {
    let sorted = new FactoryListResponse();
    sorted.factory = sortByAsc(factoryList.factory, "factory_id");
    if (sorted.factory !== undefined) factoryDisplayListBox.setData(sorted);
  }

  // sort for display plant listbox
  let plantDisplayListBox = new PlantDisplayListBox();
  if (plantList && plantList.plant) {
    let sorted = new PlantListResponse();
    sorted.plant = sortByAsc(plantList.plant, "plant_id");
    if (sorted.plant !== undefined) plantDisplayListBox.setData(sorted);
  }

  // sort for display line listbox
  let moduleDisplayListBox = new ModuleDisplayListBox();
  if (moduleList && moduleList.line) {
    let sorted = new LineListResponse();
    sorted.line = sortByAsc(moduleList.line, "line_id");
    if (sorted.line !== undefined) moduleDisplayListBox.setData(sorted);
  }

  // sort for display unit production capacity rows
  let unitProductionCapacityDisplayRows = new UnitProductionCapacityDisplayRows();
  if (unitProductionCapacityList && unitProductionCapacityList.unit) {
    let sorted = new UnitProductionCapacityListResponse();
    sorted.unit = sortByAsc(unitProductionCapacityList.unit, "unit_id");
    if (sorted.unit !== undefined) unitProductionCapacityDisplayRows.setData(sorted);
  }

  return (
    <Grid container spacing={1} style={{ padding: theme.spacing(0, 1) }}>
      {/* 1行目 */}
      <Grid item xs={12} ref={listBoxesRef}>
        <Grid container>
          <Grid item xs={3}>
            {/* 拠点 */}
            <ListBox disable label={Label.FACTORY} options={factoryDisplayListBox.options} value={selectedFactoryItem} onChange={factoryChangeHandler} />
          </Grid>
          <Grid item xs={3}>
            {/* 工場 */}
            <ListBox disable label={Label.PLANT} options={plantDisplayListBox.options} value={selectedPlantItem} onChange={plantChangeHandler} />
          </Grid>
          <Grid item xs={3}>
            {/* モジュール */}
            <ListBox label={Label.MODULE} options={moduleDisplayListBox.options} value={selectedLineItem} onChange={moduleChangeHandler} />
          </Grid>
        </Grid>
      </Grid>

      {/* 2行目 */}
      <Grid item xs={12}>
        {/* ユニット生産台数 */}
        <Typography ref={labelRef} className={"typography-label"}>
          {DataManagementType.ProductionCapacity}
        </Typography>
        <UnitProductionCapacityTable
          contentRows={unitProductionCapacityDisplayRows}
          unitProductionCapacityChangeHandler={unitProductionCapacityChangeHandler}
          height={tableHeight}
        />
      </Grid>
    </Grid>
  );
}
