export class DeployTarget {
    static readonly LOCAL = "LOCAL";
    static readonly IAC_DEV = "IAC_DEV";
    static readonly IAC_PRD = "IAC_PRD";
    static readonly FLEX_PRD = "FLEX_PRD";
}

export class DeployTargetWarningMessage {
    static readonly LOCAL = "※ローカル環境です";
    static readonly IAC_DEV = "※テスト環境です";
    static readonly IAC_PRD = "※テスト環境です";
    static readonly FLEX_PRD = "";
}  