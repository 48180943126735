import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Grid, Typography, useTheme } from "@material-ui/core";
import DataManagementTable from "./DataManagementTable";
import DataManagementDialog, { DataManagementDialogAction, DataManagementDialogTarget } from "./DataManagementDialog";
import { isLengthCorrect } from "../../function/validationCheck";
import { ErrorMessages, SuccessMessages, WarningMessages } from "../../consts/messages";
import {
  FactoryDisplayListBox,
  ModuleDisplayListBox,
  ModelDisplayRows,
  PlantDisplayListBox,
  TeamDisplayRows,
  UnitDisplayRows,
  VolumeDisplayRows,
} from "../Common/display/display";
import {
  postModel,
  postTeam,
  postUnit,
  postVolume,
  putModel,
  putTeam,
  putUnit,
  useFactory,
  useLine,
  useModel,
  usePlant,
  useTeam,
  useUnit,
  useVolume,
} from "../../apicaller/repository/datamanagament";
import {
  DataManagement,
  ModelRegistRequest,
  ModelUpdateRequest,
  TeamRegistRequest,
  TeamUpdateRequest,
  UnitRegistRequest,
  UnitUpdateRequest,
  VolumeRegistRequest,
} from "../../apicaller/domain/request/datamanagement";
import { Message } from "../../context/appContextData";
import {
  FactoryListResponse,
  LineListResponse,
  ModelListResponse,
  PlantListResponse,
  TeamListResponse,
  UnitListResponse,
  VolumeListResponse,
} from "../../apicaller/domain/response/datamanagement";
import { sortByAsc } from "../../function/sort";
import ListBox, { ListOption } from "../Common/ListBox";
import { useGetElementProperty } from "../../hooks/getElementProperty";
import { useUserBelonging } from "../../apicaller/repository/authentication";
import { DataManagementAreaTabLabel, DataManagementType, Label } from "../../consts/label";

interface Props {
  tabContentHeight: number;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      // AppBar表示分の余白
      marginTop: 30,
      flexGrow: 1,
    },
    button: {
      fontSize: 24,
      width: 140,
      height: 50,
      position: "absolute",
      right: 40,
      bottom: 20,
    },
  })
);

export default function AreaTable(props: Props) {
  const theme = useTheme();
  const classes = useStyles();

  const snackBarMessage = useContext(Message);

  const listBoxesRef = useRef(null);
  const labelRef = useRef(null);

  const [userBelonging, getUserBelonging, resetUserBelonging, mutateUserBelonging, userBelongingError] = useUserBelonging();
  const [factoryList, getFactoryList, resetFactoryList, mutateFactoryList, factoryError] = useFactory();
  const [plantList, getPlantList, resetPlantList, mutatePlantList, plantError] = usePlant();
  const [lineList, getLineList, resetLineList, mutateLineList, lineError] = useLine();
  const [modelList, getModelList, resetModelList, mutateModelList, modelError] = useModel();
  const [unitList, getUnitList, resetUnitList, mutateUnitList, unitError] = useUnit();
  const [teamList, getTeamList, resetTeamList, mutateTeamList, teamError] = useTeam();
  const [productionCapacityList, getProductionCapacityList, resetProductionCapacityList, mutateProductionCapacityList, productionCapacityError] =
    useVolume();

  const [selectedFactoryItem, setSelectedFactoryItem] = useState<ListOption>(null);
  const [selectedPlantItem, setSelectedPlantItem] = useState<ListOption>(null);
  const [selectedLineItem, setSelectedLineItem] = useState<ListOption>(null);
  const [selectedModelId, setSelectedModelId] = useState<number | null>(null);
  const [selectedUnitId, setSelectedUnitId] = useState<number | null>(null);
  const [selectedTeamId, setSelectedTeamId] = useState<number | null>(null);
  const [selectedProductionCapacityId, setSelectedProductionCapacityId] = useState<number | null>(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [target, setTarget] = useState<DataManagementDialogTarget>({
    id: 0,
    isAdd: false,
    name: "",
    type: "",
    createdAt: "",
    updatedAt: "",
  });

  const listBoxesHeight = useGetElementProperty(listBoxesRef).getElementProperty("height");
  const labelHeight = useGetElementProperty(labelRef).getElementProperty("height");

  const tableHeight = useMemo(() => {
    return (props.tabContentHeight - (listBoxesHeight + labelHeight * 2 + theme.spacing(1) * 2)) / 2;
  }, [props.tabContentHeight, listBoxesHeight, labelHeight]);

  const changeTitleHandler = (e: React.FormEvent<HTMLInputElement>) => {
    target.name = e.currentTarget.value;
    setTarget({ ...target });
  };

  //POSTする前に飛ばすROWの設置
  const createPostRequest = (target: DataManagementDialogTarget): DataManagement | undefined => {
    switch (target.type) {
      case DataManagementType.Model: {
        return new ModelRegistRequest(target.name, selectedPlantItem.key!);
      }
      case DataManagementType.Unit: {
        return new UnitRegistRequest(target.name, selectedLineItem.key!);
      }
      case DataManagementType.Team: {
        return new TeamRegistRequest(target.name, selectedUnitId!, selectedLineItem.key!);
      }
      case DataManagementType.ProductionCapacity: {
        return new VolumeRegistRequest(Number(target.name), selectedUnitId!);
      }
    }
    return undefined;
  };

  // PUTする前に飛ばすROWの設置
  const createPutRequest = (target: DataManagementDialogTarget): DataManagement | undefined => {
    switch (target.type) {
      case DataManagementType.Model: {
        return new ModelUpdateRequest(selectedModelId!, target.name);
      }
      case DataManagementType.Unit: {
        return new UnitUpdateRequest(selectedUnitId!, target.name);
      }
      case DataManagementType.Team: {
        return new TeamUpdateRequest(selectedTeamId!, target.name);
      }
      default: {
        return undefined;
      }
    }
  };

  //#region 選択イベント系
  //拠点listbox変わる時
  const factoryChangeHandler = (values: ListOption) => {
    setSelectedFactoryItem(null);
    setSelectedPlantItem(null);
    setSelectedLineItem(null);
    setSelectedModelId(null);
    setSelectedUnitId(null);
    setSelectedTeamId(null);
    setSelectedProductionCapacityId(null);

    resetPlantList();
    resetLineList();
    resetModelList();
    resetUnitList();
    resetTeamList();
    resetProductionCapacityList();

    if (values != null) {
      setSelectedFactoryItem(values);
      getPlantList(String(values.key));
    }
  };

  //工場listbox変わる時
  const plantChangeHandler = (values: ListOption) => {
    setSelectedPlantItem(null);
    setSelectedLineItem(null);
    setSelectedModelId(null);
    setSelectedUnitId(null);
    setSelectedTeamId(null);
    setSelectedProductionCapacityId(null);

    resetLineList();
    resetModelList();
    resetUnitList();
    resetTeamList();
    resetProductionCapacityList();

    if (values != null) {
      setSelectedPlantItem(values);
      getLineList(values.key);
      getModelList(String(values.key));
    }
  };

  // ラインlistbox変わる時
  const lineChangeHandler = (values: ListOption) => {
    setSelectedLineItem(null);
    setSelectedUnitId(null);
    setSelectedTeamId(null);
    setSelectedProductionCapacityId(null);

    resetUnitList();
    resetTeamList();
    resetProductionCapacityList();

    if (values != null) {
      setSelectedLineItem(values);
      getUnitList(String(values.key));
    }
  };

  const modelSelectHandler = (id: number, _: string) => {
    setSelectedModelId(id);
  };

  const unitSelectHandler = (id: number, _: string) => {
    setSelectedTeamId(null);
    setSelectedUnitId(id);
    getTeamList(String(id));
    getProductionCapacityList(String(id));
  };

  const teamSelectHandler = (id: number, _: string) => {
    setSelectedTeamId(id);
  };

  const productionCapacitySelectHandler = (id: number, _: string) => {
    setSelectedProductionCapacityId(id);
  };
  //#endregion

  //編集時(追加ボタンとペンボタン両方)
  const editHandler = (id: number, isAdd: boolean, title: string, areaType: string, createdAt: string, updatedAt: string) => {
    setTarget({
      ...{
        id: id,
        isAdd: isAdd,
        name: title,
        type: areaType,
        createdAt: createdAt,
        updatedAt: updatedAt,
      },
    });
    setIsEditDialogOpen(true);
  };

  //Dialog側操作ボタンを押す時
  const dialogActionsHandler = (title: string, action: DataManagementDialogAction) => {
    if (action === DataManagementDialogAction.cancel) {
      setIsEditDialogOpen(false);
      return;
    }

    switch (target.type) {
      case DataManagementType.ProductionCapacity: {
        if (!isLengthCorrect(title, 1, 5)) {
          snackBarMessage.setMessage(WarningMessages.DATAMANAGEMENT_PRODUCTION_CAPACITY_INPUT_LENGTH, "warning");
          return;
        }
        break;
      }
      default: {
        if (!isLengthCorrect(title, 1, 50)) {
          snackBarMessage.setMessage(WarningMessages.DATAMANAGEMENT_INPUT_LENGTH, "warning");
          return;
        }
        break;
      }
    }

    target.name = title;
    setTarget({ ...target });

    if (action === DataManagementDialogAction.create) {
      postData(target);
    }
    if (action === DataManagementDialogAction.update) {
      putData(target);
    }
  };

  //追加
  const postData = (target: DataManagementDialogTarget) => {
    let body = createPostRequest(target);
    if (body === undefined) {
      return;
    }

    switch (target.type) {
      case DataManagementType.Model:
        postModel(body).then(
          () => {
            setIsEditDialogOpen(false);
            mutateModelList();
            snackBarMessage.setMessage(SuccessMessages.INSERT_MODEL, "success");
          },
          (error: any) => {
            console.log(error);
            snackBarMessage.setMessage(ErrorMessages.INSERT_MODEL, "error");
          }
        );
        break;
      case DataManagementType.Unit:
        postUnit(body).then(
          () => {
            setIsEditDialogOpen(false);
            mutateUnitList();
            snackBarMessage.setMessage(SuccessMessages.INSERT_UNIT, "success");
          },
          (error: any) => {
            console.log(error);
            snackBarMessage.setMessage(ErrorMessages.INSERT_UNIT, "error");
          }
        );
        break;
      case DataManagementType.Team:
        postTeam(body).then(
          () => {
            setIsEditDialogOpen(false);
            mutateTeamList();
            snackBarMessage.setMessage(SuccessMessages.INSERT_TEAM, "success");
          },
          (error: any) => {
            console.log(error);
            snackBarMessage.setMessage(ErrorMessages.INSERT_TEAM, "error");
          }
        );
        break;
      case DataManagementType.ProductionCapacity:
        postVolume(body).then(
          () => {
            setIsEditDialogOpen(false);
            mutateProductionCapacityList();
            snackBarMessage.setMessage(SuccessMessages.INSERT_VOLUME, "success");
          },
          (error: any) => {
            console.log(error);
            snackBarMessage.setMessage(ErrorMessages.INSERT_VOLUME, "error");
          }
        );
        break;

      default:
        break;
    }
  };

  // Name変更時
  const putData = (target: DataManagementDialogTarget) => {
    let row = createPutRequest(target);
    if (row === undefined) {
      return;
    }

    switch (target.type) {
      case DataManagementType.Model:
        putModel(row).then(
          () => {
            setIsEditDialogOpen(false);
            mutateModelList();
            snackBarMessage.setMessage(SuccessMessages.UPDATE_MODEL, "success");
          },
          (error: any) => {
            console.log(error);
            snackBarMessage.setMessage(ErrorMessages.UPDATE_MODEL, "error");
          }
        );
        break;
      case DataManagementType.Unit:
        putUnit(row).then(
          () => {
            setIsEditDialogOpen(false);
            mutateUnitList();
            snackBarMessage.setMessage(SuccessMessages.UPDATE_UNIT, "success");
          },
          (error: any) => {
            console.log(error);
            snackBarMessage.setMessage(ErrorMessages.UPDATE_UNIT, "error");
          }
        );
        break;
      case DataManagementType.Team:
        putTeam(row).then(
          () => {
            setIsEditDialogOpen(false);
            mutateTeamList();
            snackBarMessage.setMessage(SuccessMessages.UPDATE_TEAM, "success");
          },
          (error: any) => {
            console.log(error);
            snackBarMessage.setMessage(ErrorMessages.UPDATE_TEAM, "error");
          }
        );
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    getFactoryList();
    getUserBelonging();
  }, []);

  // 拠点の初期選択項目を設定
  useEffect(() => {
    if (!factoryList) return;
    if (!userBelonging) return;
    if (!userBelonging.factory_id) return;
    if (userBelonging.factory_id === 0) return;
    factoryList.factory.forEach((row) => {
      if (row.factory_id === userBelonging.factory_id) {
        setSelectedFactoryItem({ key: row.factory_id, value: row.factory_name });
      }
    });
  }, [userBelonging, factoryList]);

  // 拠点の選択項目が変更されたときに工場リストを取得
  useEffect(() => {
    if (!selectedFactoryItem) return;
    if (!selectedFactoryItem.key) return;
    if (selectedFactoryItem.key === 0) return;
    getPlantList(String(selectedFactoryItem.key));
  }, [selectedFactoryItem]);

  // 工場の初期選択項目を設定
  useEffect(() => {
    if (!plantList) return;
    if (!plantList.plant) return;
    if (plantList.plant.length === 0) return;
    plantList.plant.forEach((row) => {
      if (row.plant_id === userBelonging.plant_id) {
        setSelectedPlantItem({ key: row.plant_id, value: row.plant_name });
      }
    });
  }, [plantList]);

  // 工場の選択項目が変更されたときにモジュールリストを取得
  useEffect(() => {
    if (!selectedPlantItem) return;
    if (!selectedPlantItem.key) return;
    if (selectedPlantItem.key === 0) return;
    getLineList(selectedPlantItem.key);
    getModelList(String(selectedPlantItem.key));
  }, [selectedPlantItem]);

  // モジュールの初期選択項目を設定
  useEffect(() => {
    if (!lineList) return;
    if (!lineList.line) return;
    if (lineList.line.length === 0) return;
    lineList.line.forEach((row) => {
      if (row.line_id === userBelonging.module_id) {
        setSelectedLineItem({ key: row.line_id, value: row.line_name });
      }
    });
  }, [lineList]);

  // モジュールの選択項目が変更されたときにユニットリストを取得
  useEffect(() => {
    if (!selectedLineItem) return;
    if (!selectedLineItem.key) return;
    if (selectedLineItem.key === 0) return;
    getUnitList(String(selectedLineItem.key));
  }, [selectedLineItem]);

  // SWR error
  useEffect(() => {
    if (factoryError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_FACTORY, "error");
    }
    if (plantError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_PLANT, "error");
    }
    if (lineError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_MODULE, "error");
    }
    if (modelError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_MODEL, "error");
    }
    if (unitError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_UNIT, "error");
    }
    if (teamError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_TEAM, "error");
    }
    if (productionCapacityError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_VOLUME, "error");
    }
  }, [factoryError, plantError, lineError, modelError, unitError, teamError, productionCapacityError]);

  // sort for display factory listbox
  let factoryDisplayListBox = new FactoryDisplayListBox();
  if (factoryList && factoryList.factory) {
    let sorted = new FactoryListResponse();
    sorted.factory = sortByAsc(factoryList.factory, "factory_id");
    if (sorted.factory !== undefined) factoryDisplayListBox.setData(sorted);
  }

  // sort for display plant listbox
  let plantDisplayListBox = new PlantDisplayListBox();
  if (plantList && plantList.plant) {
    let sorted = new PlantListResponse();
    sorted.plant = sortByAsc(plantList.plant, "plant_id");
    if (sorted.plant !== undefined) plantDisplayListBox.setData(sorted);
  }

  // sort for display line listbox
  let lineDisplayListBox = new ModuleDisplayListBox();
  if (lineList && lineList.line) {
    let sorted = new LineListResponse();
    sorted.line = sortByAsc(lineList.line, "line_id");
    if (sorted.line !== undefined) lineDisplayListBox.setData(sorted);
  }

  // sort for display model
  let modelDisplayRows = new ModelDisplayRows();
  if (modelList && modelList.model) {
    let sorted = new ModelListResponse();
    sorted.model = sortByAsc(modelList.model, "model_id");
    if (sorted.model !== undefined) modelDisplayRows.setData(sorted);
  }

  // sort for display unit
  let unitDisplayRows = new UnitDisplayRows();
  if (unitList) {
    if (unitList.unit !== undefined) {
      let sorted = new UnitListResponse();
      sorted.unit = sortByAsc(unitList.unit, "unit_id");
      if (sorted.unit !== undefined) unitDisplayRows.setData(sorted);
    }
  }

  // sort for display team
  let teamDisplayRows = new TeamDisplayRows();
  if (teamList) {
    if (teamList.team !== undefined) {
      let sorted = new TeamListResponse();
      sorted.team = sortByAsc(teamList.team, "team_id");
      if (sorted.team !== undefined) teamDisplayRows.setData(sorted);
    }
  }

  // sort for display production capacity
  let productionCapacityDisplayRows = new VolumeDisplayRows();
  if (productionCapacityList && productionCapacityList.volume) {
    let sorted = new VolumeListResponse();
    sorted.volume = sortByAsc(productionCapacityList.volume, "volume_id");
    if (sorted.volume !== undefined) productionCapacityDisplayRows.setData(sorted);
  }

  return (
    <>
      <DataManagementDialog
        open={isEditDialogOpen}
        target={target}
        dialogActionsHandler={dialogActionsHandler}
        changeTitleHandler={changeTitleHandler}
      ></DataManagementDialog>
      <Grid container spacing={1} style={{ padding: theme.spacing(0, 1) }}>
        {/* 1行目 */}
        <Grid item xs={12} ref={listBoxesRef}>
          <Grid container>
            <Grid item xs={3}>
              {/* 拠点 */}
              <ListBox disable label={Label.FACTORY} options={factoryDisplayListBox.options} value={selectedFactoryItem} onChange={factoryChangeHandler} />
            </Grid>
            <Grid item xs={3}>
              {/* 工場 */}
              <ListBox disable label={Label.PLANT} options={plantDisplayListBox.options} value={selectedPlantItem} onChange={plantChangeHandler} />
            </Grid>
            <Grid item xs={3}>
              {/* モジュール(旧ライン) */}
              <ListBox label={Label.MODULE} options={lineDisplayListBox.options} value={selectedLineItem} onChange={lineChangeHandler} />
            </Grid>
          </Grid>
        </Grid>

        {/* 2行目 */}
        <Grid item xs={4}>
          {/* 機種 */}
          <Typography className={"typography-label"} ref={labelRef}>
            {DataManagementAreaTabLabel.MODEL}
          </Typography>
          <DataManagementTable
            headers={[DataManagementAreaTabLabel.NUMBER, DataManagementAreaTabLabel.MODEL_NAME]}
            contentRows={modelDisplayRows}
            height={tableHeight}
            cellPadding={16}
            tableType={DataManagementType.Model}
            selectedId={selectedModelId}
            handleOnRowClick={modelSelectHandler}
            handleEditRow={editHandler}
            canAddRow={selectedPlantItem !== null}
            canEditRow
          />
        </Grid>
        <Grid item xs={4}>
          {/* ユニット */}
          <Typography className={"typography-label"}>{DataManagementAreaTabLabel.UNIT}</Typography>
          <DataManagementTable
            headers={[DataManagementAreaTabLabel.NUMBER, DataManagementAreaTabLabel.UNIT_NAME]}
            contentRows={unitDisplayRows}
            height={tableHeight}
            cellPadding={16}
            tableType={DataManagementType.Unit}
            selectedId={selectedUnitId}
            handleOnRowClick={unitSelectHandler}
            handleEditRow={editHandler}
            canAddRow={selectedLineItem !== null}
            canEditRow
          />
        </Grid>
        <Grid item xs={4}>
          {/* チーム */}
          <Typography className={"typography-label"}>{DataManagementAreaTabLabel.TEAM}</Typography>
          <DataManagementTable
            headers={[DataManagementAreaTabLabel.NUMBER, DataManagementAreaTabLabel.TEAM_NAME]}
            contentRows={teamDisplayRows}
            height={tableHeight}
            cellPadding={16}
            tableType={DataManagementType.Team}
            selectedId={selectedTeamId}
            handleOnRowClick={teamSelectHandler}
            handleEditRow={editHandler}
            canAddRow={selectedUnitId !== null}
            canEditRow
          />
        </Grid>

        {/* 3行目 */}
        <Grid item xs={4} />
        <Grid item xs={4}>
          {/* 生産台数 */}
          <Typography className={"typography-label"}>{DataManagementAreaTabLabel.UNIT_PRODUCTION_CAPACITY}</Typography>
          <DataManagementTable
            headers={[DataManagementAreaTabLabel.NUMBER, DataManagementAreaTabLabel.PRODUCTION_CAPACITY]}
            contentRows={productionCapacityDisplayRows}
            height={tableHeight}
            cellPadding={16}
            tableType={DataManagementType.ProductionCapacity}
            selectedId={selectedProductionCapacityId}
            handleOnRowClick={productionCapacitySelectHandler}
            handleEditRow={editHandler}
            canAddRow={selectedUnitId !== null}
          />
        </Grid>
      </Grid>
    </>
  );
}
