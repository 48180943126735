import React, { useContext, useMemo, useRef } from "react";
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@material-ui/core";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { EditInfo, LoginInfo, WindowSize } from "../../context/appContextData";
import { ManagementBookHistoryListResponse, ManagementBookItem } from "../../apicaller/domain/response/managementbook";
import { ProductionCapacityHistoryListResponse } from "../../apicaller/domain/response/datamanagement";
import { formatDatetime } from "../../function/formatDatetime";
import { sortByDesc } from "../../function/sort";
import { Authority } from "../../consts/authority";
import { purple } from "@material-ui/core/colors";
import { useGetElementProperty } from "../../hooks/getElementProperty";
import { ManagementBookManagementTabLabel } from "../../consts/label";

const useStyles = makeStyles(() =>
  createStyles({
    typography: {
      fontWeight: "bold",
    },
    historyConteiner: {
      height: "100%",
      overflow: "auto",
    },
    reviseHistoryConteiner: {
      height: "100%",
      overflow: "auto",
    },
    statusConteiner: {
      height: "15%",
      marginTop: "2.5%",
    },
    signConteiner: {
      height: "15%",
      marginTop: "2.5%",
    },
    blankStyle: {},
    tableRow: {
      cursor: "pointer",
    },
    currentRevisionTableRow: {
      backgroundColor: purple[50],
      "&&:hover": {
        backgroundColor: purple[50],
      },
      "& > .MuiTableCell-root": {
        fontWeight: "bold",
      },
    },
    cell50par: {
      width: "50%",
      whiteSpace: "nowrap",
    },
    cell40par: {
      width: "40%",
      whiteSpace: "nowrap",
    },
    cell30par: {
      width: "30%",
      whiteSpace: "nowrap",
    },
    cell20par: {
      width: "20%",
      whiteSpace: "nowrap",
    },
    cell10par: {
      width: "10%",
      whiteSpace: "nowrap",
    },
    headerCell20par: {
      width: "20%",
      whiteSpace: "nowrap",
      borderRight: 1,
      borderRightStyle: "solid",
      borderRightColor: "#E0E0E0",
      backgroundColor: "#FAFAFA",
      fontWeight: "bold",
    },
    bodyHistoryDiv: {
      width: "200px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      verticalAlign: "middle",
      textAlign: "left",
    },
  })
);

interface Props {
  contentHeight: number;
  managementBook: ManagementBookItem;
  productionCapacityHistoryList: ProductionCapacityHistoryListResponse;
  managementBookHistoryList: ManagementBookHistoryListResponse;
  handleClickHistoryRow: (management_book_id: number) => void;
}

const InfomationManagementBookTab = (props: Props) => {
  const styles = useStyles();
  const theme = useTheme();
  const EditContext = useContext(EditInfo);
  const LoginContext = useContext(LoginInfo);

  const sortedManagementBookHistoryList = useMemo(() => {
    if (props.managementBookHistoryList.history == null) {
      return [];
    }
    return sortByDesc(props.managementBookHistoryList.history, "revision_number");
  }, [props.managementBookHistoryList.history]);

  return (
    <Grid container spacing={1} style={{ height: props.contentHeight, padding: theme.spacing(0, 1) }}>
      <Grid item xs={6} direction="column" style={{ display: "flex", height: "100%" }}>
        <Typography className={styles.typography} variant="h6" noWrap>
          {ManagementBookManagementTabLabel.CURRENT_PRODUCTION_CAPACITY_TABLE_TITLE}
        </Typography>
        <TableContainer component={Paper} className={styles.historyConteiner} style={{ flexGrow: 1 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell className={styles.cell20par} align={"left"}>
                  {ManagementBookManagementTabLabel.DATE_TIME}
                </TableCell>
                <TableCell className={styles.cell40par} align={"left"}>
                  {ManagementBookManagementTabLabel.CHARGE}
                </TableCell>
                <TableCell className={styles.cell40par} align={"left"}>
                  {ManagementBookManagementTabLabel.CURRENT_HISTORY}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.productionCapacityHistoryList.history?.map((row) => {
                return (
                  <TableRow>
                    <TableCell className={styles.cell20par} align={"left"}>
                      {formatDatetime(row.created_at)}
                    </TableCell>
                    <TableCell className={styles.cell40par} align={"left"}>
                      {row.user_name + " (" + row.login_id + ")"}
                    </TableCell>
                    <TableCell className={styles.cell40par} align={"left"}>
                      {row.description}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={6} direction="column" style={{ display: "flex", height: "100%" }}>
        <Typography className={styles.typography} variant="h6" noWrap>
          {ManagementBookManagementTabLabel.REVISE_HISTORY_TABLE_TITLE}
        </Typography>
        <TableContainer component={Paper} className={styles.reviseHistoryConteiner} style={{ flexGrow: 1 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell className={styles.cell10par} align={"right"}>
                  {ManagementBookManagementTabLabel.REVISION_NUMBER}
                </TableCell>
                <TableCell className={styles.cell20par} align={"left"}>
                  {ManagementBookManagementTabLabel.DATE_TIME}
                </TableCell>
                <TableCell className={styles.cell20par} align={"left"}>
                  {ManagementBookManagementTabLabel.CHARGE_PERSON}
                </TableCell>
                <TableCell className={styles.cell20par} align={"left"}>
                  {ManagementBookManagementTabLabel.APPROVER}
                </TableCell>
                <TableCell className={styles.cell30par} align={"left"}>
                  {ManagementBookManagementTabLabel.REVISE_CONTENT}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedManagementBookHistoryList?.map((row) => {
                let style = styles.tableRow;
                if (EditContext.editMode || LoginContext.loginUser.authority === Authority.GENERAL) {
                  // ポインターを矢印のままにする
                  style = styles.blankStyle;
                } else if (props.managementBook.management_book_id === row.management_book_id) {
                  // 強調表示する
                  style = styles.currentRevisionTableRow;
                }
                return (
                  <TableRow className={style} hover onClick={() => props.handleClickHistoryRow(row.management_book_id)}>
                    <TableCell className={styles.cell10par} align={"right"}>
                      {row.revision_number}
                    </TableCell>
                    <TableCell className={styles.cell20par} align={"left"}>
                      {formatDatetime(row.created_at)}
                    </TableCell>
                    <TableCell className={styles.cell20par} align={"left"}>
                      {row.editor_name + " (" + row.editor_login_id + ")"}
                    </TableCell>
                    <TableCell className={styles.cell20par} align={"left"}>
                      {row.approver_name !== "" && row.approver_name + " (" + row.approver_login_id + ")"}
                    </TableCell>
                    <TableCell className={styles.cell30par} align={"left"}>
                      <Tooltip placement={"bottom-start"} title={row.detail}>
                        <div className={styles.bodyHistoryDiv}>{row.detail}</div>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default InfomationManagementBookTab;
