/* eslint-disable no-use-before-define */
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

interface Props {
  label: string;
  options: string[];
  onChange?: any;
  defaultSelect?: string;
  value?: string;
  disableClearable?: boolean;
  disable?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: 5,
    },
  }),
);

export default function ComboBox(props: Props) {
  const styles = useStyles();

  const onChange = (event: any, item: any) => {
    if (props.onChange !== undefined) {
      props.onChange(item);
    }
  };

  return (
    <div className={styles.root}>
      <Autocomplete
        disabled={props.disable ? true : false}
        disableClearable={props.disableClearable ? true : false}
        noOptionsText={"no data found"}
        options={props.options}
        getOptionLabel={(option) => option}
        defaultValue={props.defaultSelect}
        value={props.value}
        renderInput={(params) => (
          <TextField {...params} placeholder={props.label} variant="standard" />
        )}
        size="small"
        onChange={onChange}
      />
    </div>
  );
}
