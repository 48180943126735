export class WorkStandardIdRequest {
  readonly work_standard_id: number;

  constructor(work_standard_id: number) {
    this.work_standard_id = work_standard_id;
  }
}

export class PostDuplicateWorkStandardRequest {
  readonly source_work_standard_id: number;
  readonly work_standard_name: string;
  readonly team_id: number;
  readonly model_id: number;

  constructor(source_work_standard_id: number, work_standard_name: string, team_id: number, model_id: number) {
    this.source_work_standard_id = source_work_standard_id;
    this.work_standard_name = work_standard_name;
    this.team_id = team_id;
    this.model_id = model_id;
  }
}

export class PostHistoryDetailRequest {
  readonly work_standard_id: number;
  readonly target: string;
  readonly detail: string;

  constructor(work_standard_id: number, target: string, detail: string) {
    this.work_standard_id = work_standard_id;
    this.target = target;
    this.detail = detail;
  }
}

export class PutHistoryDetailRequest {
  readonly work_standard_history_detail_id: number;
  readonly target: string;
  readonly detail: string;

  constructor(work_standard_history_detail_id: number, target: string, detail: string) {
    this.work_standard_history_detail_id = work_standard_history_detail_id;
    this.target = target;
    this.detail = detail;
  }
}
