import React, { Component } from "react";

import ImageMapEditor from "../components/imagemap/ImageMapEditor";

class ImageEditorContainer extends Component<any> {
  render() {
    return (
      <div>
        <ImageMapEditor illustrationS3Key={this.props.illustrationS3Key} modelId={this.props.modelId} workStandardName={this.props.workStandardName} revisionNumber={this.props.revisionNumber}
                        closeCanvas={this.props.closeCanvas} />
      </div>
    );
  }
}

export default ImageEditorContainer;
