import React, { useContext, useEffect, useState } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import {
  DialogContent,
  DialogActions,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { ModuleRole, SignUpUserInfo, TeamRole, UnitRole } from "./display/userList";
import { Message } from "../../context/appContextData";
import { ErrorMessages, SuccessMessages, WarningMessages } from "../../consts/messages";
import { UserInfoLimits } from "../../consts/inputLimits";
import { isOnlyHalfAlphabetAndNumber } from "../../function/validationCheck";
import ComboBox from "../Common/ComboBox";
import { useLine, useTeam, useUnit } from "../../apicaller/repository/datamanagament";
import { Button } from "@material-ui/core";
import { UserSignUp } from "../../apicaller/domain/request/authentication";
import { postSignUp, useUserBelonging } from "../../apicaller/repository/authentication";
import { Authority } from "../../consts/authority";
import { cloneDeep } from "lodash";
import SaveIcon from "@material-ui/icons/Save";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { AuthorityLabel, DialogButtonLabel, UserListPageLabel } from "../../consts/label";
import { Role } from "../../consts/role";

const useStyles = makeStyles({
  title: {
    textAlign: "center",
  },
  tableHeader: {
    backgroundColor: "whitesmoke",
  },
  roleTable: {
    overflowY: "auto",
    maxHeight: "400px",
  },
  textField: {
    width: "100%",
  },
  typography: {
    textAlign: "center",
  },
  cursor_pointer: {
    cursor: "finger",
  },
});

export enum DialogAction {
  update,
  delete,
  cancel,
}

export interface Props {
  open: boolean;
  handleDialogAction: (action: DialogAction) => void;
}

const defaultSignUpUserInfo: SignUpUserInfo = {
  loginId: "",
  name: "",
  authority: 0,
  password: "",
  moduleRole: [{ module_id: 0, role: Role.ASSOCIATE }],
  unitRole: [{ unit_id: 0, role: Role.ASSOCIATE }],
  teamRole: [{ team_id: 0, role: Role.ASSOCIATE }],
};

export default function UserSignUpDialog(props: Props) {
  const styles = useStyles();
  const theme = useTheme();
  const snackBarMessage = useContext(Message);

  const [userBelonging, getUserBelonging, resetUserBelonging, mutateUserBelonging, userBelongingError] = useUserBelonging();
  const [signUpUserInfo, setSignUpUserInfo] = useState<SignUpUserInfo>(cloneDeep(defaultSignUpUserInfo));
  const [moduleList, getModuleList, resetModuleList, mutateModuleList, moduleError] = useLine();
  const [unitList, getUnitList, resetUnitList, mutateUnitList, unitError] = useUnit();
  const [teamList, getTeamList, resetTeamList, mutateTeamList, teamError] = useTeam();

  const [showPassword, setShowPassword] = useState(false);
  const [headerModuleRole, setHeaderModuleRole] = useState<string[]>([UserListPageLabel.MODULE, UserListPageLabel.ROLE]);
  const [headerUnitRole, setHeaderUnitRole] = useState<string[]>([UserListPageLabel.UNIT, UserListPageLabel.ROLE]);
  const [headerTeamRole, setHeaderTeamRole] = useState<string[]>([UserListPageLabel.TEAM, UserListPageLabel.ROLE]);

  useEffect(() => {
    getUserBelonging();
  }, []);

  useEffect(() => {
    if (!userBelonging) return;
    getModuleList(userBelonging.plant_id);
  }, [userBelonging]);

  useEffect(() => {
    const modules: string = signUpUserInfo.moduleRole.map((row) => String(row.module_id)).join(",");
    const units: string = signUpUserInfo.unitRole.map((row) => String(row.unit_id)).join(",");

    getUnitList(modules);
    getTeamList(units);
  }, [signUpUserInfo]);

  useEffect(() => {
    if (!unitList) return;
    const filterdRoles: UnitRole[] = signUpUserInfo.unitRole.filter((unitRole) => unitList.unit.some((unit) => unit.unit_id === unitRole.unit_id));
    filterdRoles.push({ unit_id: 0, role: "ASSCOCIATE" });
    setSignUpUserInfo({ ...signUpUserInfo, unitRole: filterdRoles });
  }, [unitList]);

  useEffect(() => {
    if (!teamList) return;
    const filterdRoles: TeamRole[] = signUpUserInfo.teamRole.filter((teamRole) => teamList.team.some((team) => team.team_id === teamRole.team_id));
    filterdRoles.push({ team_id: 0, role: "ASSCOCIATE" });
    setSignUpUserInfo({ ...signUpUserInfo, teamRole: filterdRoles });
  }, [teamList]);

  useEffect(() => {
    if (moduleError) {
      snackBarMessage.setMessage(ErrorMessages.GET_MODULE, "error");
    }
    if (unitError) {
      snackBarMessage.setMessage(ErrorMessages.GET_UNIT, "error");
    }
    if (teamError) {
      snackBarMessage.setMessage(ErrorMessages.GET_TEAM, "error");
    }
    if (userBelongingError) {
      snackBarMessage.setMessage(ErrorMessages.GET_USER_BELOGING, "error");
    }
  }, [userBelongingError, moduleError, unitError, teamError]);

  const convAuthority = (value: number | undefined): string => {
    if (value === Authority.GENERAL) {
      return AuthorityLabel.GENERAL;
    } else if (value === Authority.EDITOR) {
      return AuthorityLabel.EDITOR;
    } else if (value === Authority.ADMIN) {
      return AuthorityLabel.ADMIN;
    } else {
      return "";
    }
  };

  //#region ---handle user info change---
  // テキストフィールドでログインIDを変更する時
  const handleChangeLoginId = (value: string) => {
    setSignUpUserInfo({ ...signUpUserInfo, loginId: value });
  };

  // テキストフィールドでユーザー名を変更する時
  const handleChangeUserName = (value: string) => {
    setSignUpUserInfo({ ...signUpUserInfo, name: value });
  };

  // テキストフィールドでパスワードを変更する時
  const handleChangePassword = (value: string) => {
    setSignUpUserInfo({ ...signUpUserInfo, password: value });
  };

  // コンボボックスで権限を入力された場合
  const handleChangeAuthority = (value: string) => {
    let authority;
    if (value === "一般") {
      authority = 0;
    } else if (value === "編集者") {
      authority = 1;
    } else if (value === "管理者") {
      authority = 2;
    }
    setSignUpUserInfo({ ...signUpUserInfo, authority: authority });
  };

  const handleAddModule = () => {
    let module_role: ModuleRole[] = signUpUserInfo.moduleRole;
    if (module_role.find((v) => v.module_id === 0)) return;
    module_role.push({ module_id: 0, role: "ASSCOCIATE" });
    setSignUpUserInfo({ ...signUpUserInfo, moduleRole: module_role });
  };

  const handleChangeModule = (index: number, id: number) => {
    signUpUserInfo.moduleRole[index] = { module_id: id, role: signUpUserInfo.moduleRole[index].role };
    setSignUpUserInfo({ ...signUpUserInfo });
  };

  const handleChangeModuleRole = (index: number, role: "ASSCOCIATE" | "MODULE_MANAGER") => {
    signUpUserInfo.moduleRole[index] = { module_id: signUpUserInfo.moduleRole[index].module_id, role: role };
    setSignUpUserInfo({ ...signUpUserInfo });
  };

  const handleDeleteModule = (index: number) => {
    signUpUserInfo.moduleRole.splice(index, 1);
    setSignUpUserInfo({ ...signUpUserInfo });
  };

  const handleAddUnit = () => {
    let unit_role: UnitRole[] = signUpUserInfo.unitRole;
    if (unit_role.find((v) => v.unit_id === 0)) return;
    unit_role.push({ unit_id: 0, role: "ASSCOCIATE" });
    setSignUpUserInfo({ ...signUpUserInfo, unitRole: unit_role });
  };

  const handleChangeUnit = (index: number, id: number) => {
    signUpUserInfo.unitRole[index] = { unit_id: id, role: signUpUserInfo.unitRole[index].role };
    setSignUpUserInfo({ ...signUpUserInfo });
  };

  const handleChangeUnitRole = (index: number, role: "ASSCOCIATE" | "UNIT_LEADER") => {
    signUpUserInfo.unitRole[index] = { unit_id: signUpUserInfo.unitRole[index].unit_id, role: role };
    setSignUpUserInfo({ ...signUpUserInfo });
  };

  const handleDeleteUnit = (index: number) => {
    signUpUserInfo.unitRole.splice(index, 1);
    setSignUpUserInfo({ ...signUpUserInfo });
  };

  const handleAddTeam = () => {
    let team_role: TeamRole[] = signUpUserInfo.teamRole;
    if (team_role.find((v) => v.team_id === 0)) return;
    team_role.push({ team_id: 0, role: "ASSCOCIATE" });
    setSignUpUserInfo({ ...signUpUserInfo, teamRole: team_role });
  };

  const handleChangeTeam = (index: number, id: number) => {
    signUpUserInfo.teamRole[index] = { team_id: id, role: signUpUserInfo.teamRole[index].role };
    setSignUpUserInfo({ ...signUpUserInfo });
  };

  const handleChangeTeamRole = (index: number, role: "ASSCOCIATE" | "TEAM_LEADER") => {
    signUpUserInfo.teamRole[index] = { team_id: signUpUserInfo.teamRole[index].team_id, role: role };
    setSignUpUserInfo({ ...signUpUserInfo });
  };

  const handleDeleteTeam = (index: number) => {
    signUpUserInfo.teamRole.splice(index, 1);
    setSignUpUserInfo({ ...signUpUserInfo });
  };

  const handleSave = () => {
    let req = new UserSignUp(
      signUpUserInfo.loginId,
      signUpUserInfo.password,
      signUpUserInfo.authority,
      signUpUserInfo.name,
      signUpUserInfo.moduleRole.filter((e) => e.module_id !== 0),
      signUpUserInfo.unitRole.filter((e) => e.unit_id !== 0),
      signUpUserInfo.teamRole.filter((e) => e.team_id !== 0)
    );

    if (req.login_id.length < 8) {
      snackBarMessage.setMessage(WarningMessages.LOGIN_ID_INPUT_LENGTH, "warning");
      return;
    }
    if (req.password.length < 8) {
      snackBarMessage.setMessage(WarningMessages.LOGIN_PW_INPUT_LENGTH, "warning");
      return;
    }
    if (req.user_name.length < 1) {
      snackBarMessage.setMessage(WarningMessages.LOGIN_NAME_INPUT_LENGTH, "warning");
      return;
    }
    if (req.module_role.length < 1) {
      snackBarMessage.setMessage(WarningMessages.SIGNUP_EMPTY_MODULE, "warning");
      return;
    }

    postSignUp(req)
      .then(() => {
        setSignUpUserInfo(cloneDeep(defaultSignUpUserInfo));
        props.handleDialogAction(DialogAction.cancel);
        snackBarMessage.setMessage(SuccessMessages.POST_SIGN_UP, "success");
      })
      .catch((err) => {
        console.log(err);
        snackBarMessage.setMessage(ErrorMessages.POST_SIGN_UP, "error");
      });
  };

  const viewModule = () => {
    return (
      <Table>
        <TableHead className={styles.tableHeader}>
          {headerModuleRole.map((h) => {
            return (
              <TableCell>
                <div>{h}</div>
              </TableCell>
            );
          })}
        </TableHead>
        <TableBody>
          {signUpUserInfo.moduleRole.map((row, index) => {
            let module_name = moduleList !== undefined ? moduleList.line.find((v) => v.line_id === row.module_id)?.line_name : "";
            return (
              <TableRow>
                <TableCell>
                  <ComboBox
                    key={row.module_id}
                    label={UserListPageLabel.MODULE}
                    options={moduleList !== undefined ? moduleList.line.map((l) => l.line_name) : []}
                    value={module_name}
                    onChange={(e: string) => {
                      if (e === null) {
                        handleDeleteModule(index);
                        return;
                      }
                      let id = moduleList?.line.find((f) => f.line_name === e)?.line_id;
                      if (!id) return;
                      handleChangeModule(index, id);
                      handleAddModule();
                    }}
                  />
                </TableCell>
                <TableCell>
                  <ComboBox
                    key={row.module_id}
                    disableClearable
                    label={UserListPageLabel.ROLE}
                    options={[Role.ASSOCIATE, Role.MODULE_MANAGER]}
                    value={row.role}
                    onChange={(e: string) => {
                      handleChangeModuleRole(index, e);
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  const viewUnit = () => {
    return (
      <Table>
        <TableHead className={styles.tableHeader}>
          {headerUnitRole.map((h) => {
            return (
              <TableCell>
                <div>{h}</div>
              </TableCell>
            );
          })}
        </TableHead>
        <TableBody>
          {signUpUserInfo.unitRole.map((row, index) => {
            let unit_name = unitList !== undefined ? unitList.unit.find((v) => v.unit_id === row.unit_id)?.unit_name : "";
            return (
              <TableRow>
                <TableCell>
                  <ComboBox
                    key={row.unit_id}
                    label={UserListPageLabel.UNIT}
                    options={unitList !== undefined ? unitList.unit.map((l) => l.unit_name) : []}
                    value={unit_name}
                    onChange={(e: string) => {
                      if (e === null) {
                        handleDeleteUnit(index);
                        return;
                      }
                      let id = unitList.unit.find((f) => f.unit_name === e)?.unit_id;
                      if (!id) return;
                      handleChangeUnit(index, id);
                      handleAddUnit();
                    }}
                  />
                </TableCell>
                <TableCell>
                  <ComboBox
                    key={row.unit_id}
                    disableClearable
                    label={UserListPageLabel.ROLE}
                    options={[Role.ASSOCIATE, Role.UNIT_LEADER]}
                    value={row.role}
                    onChange={(e: string) => {
                      handleChangeUnitRole(index, e);
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  const viewTeam = () => {
    return (
      <Table>
        <TableHead className={styles.tableHeader}>
          {headerTeamRole.map((h) => {
            return (
              <TableCell>
                <div>{h}</div>
              </TableCell>
            );
          })}
        </TableHead>
        <TableBody>
          {signUpUserInfo.teamRole.map((row, index) => {
            let team_name = teamList !== undefined ? teamList.team.find((v) => v.team_id === row.team_id)?.team_name : "";
            return (
              <TableRow>
                <TableCell>
                  <ComboBox
                    key={row.team_id}
                    label={UserListPageLabel.TEAM}
                    options={teamList !== undefined ? teamList.team.map((l) => l.team_name) : []}
                    value={team_name}
                    onChange={(e: string) => {
                      if (e === null) {
                        handleDeleteTeam(index);
                        return;
                      }
                      let id = teamList.team.find((f) => f.team_name === e)?.team_id;
                      if (!id) return;
                      handleChangeTeam(index, id);
                      handleAddTeam();
                    }}
                  />
                </TableCell>
                <TableCell>
                  <ComboBox
                    key={row.team_id}
                    disableClearable
                    label={UserListPageLabel.ROLE}
                    options={[Role.ASSOCIATE, Role.TEAM_LEADER]}
                    value={row.role}
                    onChange={(e: string) => {
                      handleChangeTeamRole(index, e);
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  return (
    <Dialog
      maxWidth={"lg"}
      fullWidth
      aria-labelledby="user-dialog"
      open={props.open}
      onClose={() => {
        props.handleDialogAction(DialogAction.cancel);
      }}
    >
      <DialogTitle className={styles.title} id="user-dialog">
        {UserListPageLabel.USER_SIGN_UP_DIALOG_TITLE}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={4} style={{ borderRight: "1px solid" }}>
            <Typography className={styles.typography} variant={"h6"}>
              {UserListPageLabel.BASIC_INFO_TITLE}
            </Typography>
            <TextField
              className={styles.textField}
              margin="normal"
              label={UserListPageLabel.USER_SIGN_UP_DIALOG_ID}
              value={signUpUserInfo?.loginId}
              inputProps={{ maxLength: UserInfoLimits.ID_MAX_LENGTH }}
              onChange={(e) => {
                if (!isOnlyHalfAlphabetAndNumber(e.currentTarget.value)) {
                  snackBarMessage.setMessage(WarningMessages.NOT_ONLY_HALF_ALPHABET_AND_NUMBER, "warning");
                  return;
                }
                handleChangeLoginId(e.currentTarget.value);
              }}
            />
            <TextField
              className={styles.textField}
              id="outlined-password-input"
              margin="normal"
              label={UserListPageLabel.USER_SIGN_UP_DIALOG_PASSWORD}
              type={showPassword ? "text" : "password"}
              autoComplete='new-password'
              value={signUpUserInfo?.password}
              inputProps={{ maxLength: UserInfoLimits.PW_MAX_LENGTH }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton className={styles.cursor_pointer} onClick={() => { setShowPassword(!showPassword); }}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                if (!isOnlyHalfAlphabetAndNumber(e.currentTarget.value)) {
                  snackBarMessage.setMessage(WarningMessages.NOT_ONLY_HALF_ALPHABET_AND_NUMBER, "warning");
                  return;
                }
                handleChangePassword(e.currentTarget.value);
              }}
            />
            <TextField
              className={styles.textField}
              margin="normal"
              label={UserListPageLabel.USER_NAME}
              value={signUpUserInfo?.name}
              inputProps={{ maxLength: UserInfoLimits.NAME_MAX_LENGTH }}
              onChange={(e) => handleChangeUserName(e.currentTarget.value)}
            />
            <div style={{ width: "100%", marginTop: theme.spacing(4) }}>
              <ComboBox
                disableClearable
                label={UserListPageLabel.AUTHORITY}
                options={[AuthorityLabel.GENERAL, AuthorityLabel.EDITOR, AuthorityLabel.ADMIN]}
                value={convAuthority(signUpUserInfo?.authority)}
                onChange={(e: string) => {
                  handleChangeAuthority(e);
                }}
              />
            </div>
          </Grid>
          <Grid item xs={8} style={{ borderLeft: "1px solid" }}>
            <Typography className={styles.typography} variant={"h6"}>
              {UserListPageLabel.AFFILIATION_INFO_TITLE}
            </Typography>
            <div className={styles.roleTable}>
              <TableContainer>{viewModule()}</TableContainer>
              <TableContainer>{viewUnit()}</TableContainer>
              <TableContainer>{viewTeam()}</TableContainer>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => {
            props.handleDialogAction(DialogAction.cancel);
          }}
        >
          {DialogButtonLabel.CLOSE}
        </Button>
        <Button
          color="secondary"
          variant="contained"
          startIcon={<SaveIcon />}
          onClick={() => {
            handleSave();
          }}
        >
          {UserListPageLabel.SAVE}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
