import { Badge, Button, Menu, Tooltip } from "antd";
import i18n from "i18next";
import debounce from "lodash/debounce";
import React, { Component } from "react";
import "../../libs/fontawesome-5.2.0/css/all.css";
import "../../styles/index.less";
import { code } from "../canvas/constants";
import Container from "../common/Container";
import SandBox from "../sandbox/SandBox";
import descriptors from "./Descriptors.json";
import ImageMapFooterToolbar from "./ImageMapFooterToolbar";
import ImageMapHeaderToolbar from "./ImageMapHeaderToolbar";
import ImageMapItems from "./ImageMapItems";
import ImageMapTitle from "./ImageMapTitle";
import Canvas from "../canvas/Canvas";
import Icon from "../icon/Icon";
import { string } from "prop-types";
import { getSignedUrlForGet, getSignedUrlForPut } from "../../../apicaller/repository/operation";
import { putAws } from "../../../apicaller/repository/fileupload";
import { DialogMessages } from "../../../consts/messages";
import Handler from "../../components/canvas/handlers/Handler";

import "antd/lib/switch/style/css";
import "antd/lib/col/style/css";
import "antd/lib/tag/style/css";
import "antd/lib/form/style/css";
import "antd/lib/select/style/css";
import "antd/lib/slider/style/css";
import { WorkStandardWorkTabLabel } from "../../../consts/label";

const propertiesToInclude = [
  "id",
  "name",
  "locked",
  "file",
  "src",
  "link",
  "tooltip",
  "animation",
  "layout",
  "workareaWidth",
  "workareaHeight",
  "videoLoadType",
  "autoplay",
  "shadow",
  "muted",
  "loop",
  "code",
  "icon",
  "userProperty",
  "trigger",
  "configuration",
  "superType",
  "points",
  "svg",
  "loadType",
];

const defaultOption = {
  fill: "rgba(0, 0, 0, 1)",
  stroke: "rgba(255, 255, 255, 0)",
  strokeUniform: true,
  resource: {},
  link: {
    enabled: false,
    type: "resource",
    state: "new",
    dashboard: {},
  },
  tooltip: {
    enabled: true,
    type: "resource",
    template: "<div>{{message.name}}</div>",
  },
  animation: {
    type: "none",
    loop: true,
    autoplay: true,
    duration: 1000,
  },
  userProperty: {},
  trigger: {
    enabled: false,
    type: "alarm",
    script: "return message.value > 0;",
    effect: "style",
  },
};

class ImageMapEditor extends Component {
  state = {
    selectedItem: null,
    zoomRatio: 1,
    preview: false,
    loading: false,
    progress: 0,
    animations: [],
    styles: [],
    dataSources: [],
    editing: false,
    descriptors: descriptors,
    objects: undefined,
    illustrationS3Key: string,
  };

  componentDidMount() {
    this.showLoading(true);
    this.setState({
      selectedItem: null,
    });

    getSignedUrlForGet(this.props.illustrationS3Key).then(
      (url) => {
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => new File([blob], "hoge.json"))
          .then((file) => {
            // fileはFileオブジェクト
            this.handlers.onImport(file);
          });
      },
      (error) => {
        console.log("error : getSignedUrl");
      },
    );
    this.shortcutHandlers.esc();
  }

  canvasHandlers = {
    onAdd: (target) => {
      const { editing } = this.state;
      this.forceUpdate();
      if (!editing) {
        this.changeEditing(true);
      }
      if (target.type === "activeSelection") {
        this.canvasHandlers.onSelect(null);
        return;
      }
      this.canvasRef.handler.select(target);
    },
    onSelect: (target) => {
      const { selectedItem } = this.state;
      if (target && target.id && target.id !== "workarea" && target.type !== "activeSelection") {
        if (selectedItem && target.id === selectedItem.id) {
          return;
        }
        this.canvasRef.handler.getObjects().forEach((obj) => {
          if (obj) {
            this.canvasRef.handler.animationHandler.resetAnimation(obj, true);
          }
        });
        this.setState({
          selectedItem: target,
        });
        return;
      }
      this.canvasRef.handler.getObjects().forEach((obj) => {
        if (obj) {
          this.canvasRef.handler.animationHandler.resetAnimation(obj, true);
        }
      });
      this.setState({
        selectedItem: null,
      });
    },
    onRemove: () => {
      const { editing } = this.state;
      if (!editing) {
        this.changeEditing(true);
      }
      this.canvasHandlers.onSelect(null);
    },
    onModified: debounce(() => {
      const { editing } = this.state;
      this.forceUpdate();
      if (!editing) {
        this.changeEditing(true);
      }
    }, 300),
    onZoom: (zoom) => {
      this.setState({
        zoomRatio: zoom,
      });
    },
    onChange: (selectedItem, changedValues, allValues) => {
      const { editing } = this.state;
      if (!editing) {
        this.changeEditing(true);
      }
      const changedKey = Object.keys(changedValues)[0];
      const changedValue = changedValues[changedKey];
      if (allValues.workarea) {
        this.canvasHandlers.onChangeWokarea(changedKey, changedValue, allValues.workarea);
        return;
      }
      if (changedKey === "width" || changedKey === "height") {
        this.canvasRef.handler.scaleToResize(allValues.width, allValues.height);
        return;
      }
      if (changedKey === "angle") {
        this.canvasRef.handler.rotate(allValues.angle);
        return;
      }
      if (changedKey === "locked") {
        this.canvasRef.handler.setObject({
          lockMovementX: changedValue,
          lockMovementY: changedValue,
          hasControls: !changedValue,
          hoverCursor: changedValue ? "pointer" : "move",
          editable: !changedValue,
          locked: changedValue,
        });
        return;
      }
      if (changedKey === "file" || changedKey === "src" || changedKey === "code") {
        if (selectedItem.type === "image") {
          this.canvasRef.handler.setImageById(selectedItem.id, changedValue);
        } else if (selectedItem.superType === "element") {
          this.canvasRef.handler.elementHandler.setById(selectedItem.id, changedValue);
        }
        return;
      }
      if (changedKey === "link") {
        const link = Object.assign({}, defaultOption.link, allValues.link);
        this.canvasRef.handler.set(changedKey, link);
        return;
      }
      if (changedKey === "tooltip") {
        const tooltip = Object.assign({}, defaultOption.tooltip, allValues.tooltip);
        this.canvasRef.handler.set(changedKey, tooltip);
        return;
      }
      if (changedKey === "animation") {
        const animation = Object.assign({}, defaultOption.animation, allValues.animation);
        this.canvasRef.handler.set(changedKey, animation);
        return;
      }
      if (changedKey === "icon") {
        const { unicode, styles } = changedValue[Object.keys(changedValue)[0]];
        const uni = parseInt(unicode, 16);
        if (styles[0] === "brands") {
          this.canvasRef.handler.set("fontFamily", "Font Awesome 5 Brands");
        } else if (styles[0] === "regular") {
          this.canvasRef.handler.set("fontFamily", "Font Awesome 5 Regular");
        } else {
          this.canvasRef.handler.set("fontFamily", "Font Awesome 5 Free");
        }
        this.canvasRef.handler.set("text", String.fromCodePoint(uni));
        this.canvasRef.handler.set("icon", changedValue);
        return;
      }
      if (changedKey === "shadow") {
        if (allValues.shadow.enabled) {
          if ("blur" in allValues.shadow) {
            this.canvasRef.handler.setShadow(allValues.shadow);
          } else {
            this.canvasRef.handler.setShadow({
              enabled: true,
              blur: 15,
              offsetX: 10,
              offsetY: 10,
            });
          }
        } else {
          this.canvasRef.handler.setShadow(null);
        }
        return;
      }
      if (changedKey === "fontWeight") {
        this.canvasRef.handler.set(changedKey, changedValue ? "bold" : "normal");
        return;
      }
      if (changedKey === "fontStyle") {
        this.canvasRef.handler.set(changedKey, changedValue ? "italic" : "normal");
        return;
      }
      if (changedKey === "textAlign") {
        this.canvasRef.handler.set(changedKey, Object.keys(changedValue)[0]);
        return;
      }
      if (changedKey === "trigger") {
        const trigger = Object.assign({}, defaultOption.trigger, allValues.trigger);
        this.canvasRef.handler.set(changedKey, trigger);
        return;
      }
      if (changedKey === "filters") {
        const filterKey = Object.keys(changedValue)[0];
        const filterValue = allValues.filters[filterKey];
        if (filterKey === "gamma") {
          const rgb = [filterValue.r, filterValue.g, filterValue.b];
          this.canvasRef.handler.imageHandler.applyFilterByType(filterKey, changedValue[filterKey].enabled, {
            gamma: rgb,
          });
          return;
        }
        if (filterKey === "brightness") {
          this.canvasRef.handler.imageHandler.applyFilterByType(filterKey, changedValue[filterKey].enabled, {
            brightness: filterValue.brightness,
          });
          return;
        }
        if (filterKey === "contrast") {
          this.canvasRef.handler.imageHandler.applyFilterByType(filterKey, changedValue[filterKey].enabled, {
            contrast: filterValue.contrast,
          });
          return;
        }
        if (filterKey === "saturation") {
          this.canvasRef.handler.imageHandler.applyFilterByType(filterKey, changedValue[filterKey].enabled, {
            saturation: filterValue.saturation,
          });
          return;
        }
        if (filterKey === "hue") {
          this.canvasRef.handler.imageHandler.applyFilterByType(filterKey, changedValue[filterKey].enabled, {
            rotation: filterValue.rotation,
          });
          return;
        }
        if (filterKey === "noise") {
          this.canvasRef.handler.imageHandler.applyFilterByType(filterKey, changedValue[filterKey].enabled, {
            noise: filterValue.noise,
          });
          return;
        }
        if (filterKey === "pixelate") {
          this.canvasRef.handler.imageHandler.applyFilterByType(filterKey, changedValue[filterKey].enabled, {
            blocksize: filterValue.blocksize,
          });
          return;
        }
        if (filterKey === "blur") {
          this.canvasRef.handler.imageHandler.applyFilterByType(filterKey, changedValue[filterKey].enabled, {
            value: filterValue.value,
          });
          return;
        }
        this.canvasRef.handler.imageHandler.applyFilterByType(filterKey, changedValue[filterKey]);
        return;
      }
      if (changedKey === "chartOption") {
        try {
          const sandbox = new SandBox();
          const compiled = sandbox.compile(changedValue);
          const { animations, styles } = this.state;
          const chartOption = compiled(3, animations, styles, selectedItem.userProperty);
          selectedItem.setChartOptionStr(changedValue);
          this.canvasRef.handler.elementHandler.setById(selectedItem.id, chartOption);
        } catch (error) {
          console.error(error);
        }
        return;
      }
      this.canvasRef.handler.set(changedKey, changedValue);
    },
    onChangeWokarea: (changedKey, changedValue, allValues) => {
      if (changedKey === "layout") {
        this.canvasRef.handler.workareaHandler.setLayout(changedValue);
        return;
      }
      if (changedKey === "file" || changedKey === "src") {
        this.canvasRef.handler.workareaHandler.setImage(changedValue);
        return;
      }
      if (changedKey === "width" || changedKey === "height") {
        this.canvasRef.handler.originScaleToResize(this.canvasRef.handler.workarea, allValues.width, allValues.height);
        this.canvasRef.canvas.centerObject(this.canvasRef.handler.workarea);
        return;
      }
      this.canvasRef.handler.workarea.set(changedKey, changedValue);
      this.canvasRef.canvas.requestRenderAll();
    },
    onTooltip: (ref, target) => {
      const value = Math.random() * 10 + 1;
      return (
        <div>
          <div>
            <div>
              <Button>{target.id}</Button>
            </div>
            <Badge count={value} />
          </div>
        </div>
      );
    },
    onClick: (canvas, target) => {
      const { link } = target;
      if (link.state === "current") {
        document.location.href = link.url;
        return;
      }
      window.open(link.url);
    },
    onContext: (ref, event, target) => {
      if ((target && target.id === "workarea") || !target) {
        const { layerX: left, layerY: top } = event;
        return null;
      }
      if (target.type === "activeSelection") {
        return (
          <Menu>
            <Menu.Item
              onClick={() => {
                this.canvasRef.handler.duplicate();
              }}
            >
              {WorkStandardWorkTabLabel.COPY}
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                this.canvasRef.handler.remove();
              }}
            >
              {WorkStandardWorkTabLabel.DELETE}
            </Menu.Item>
          </Menu>
        );
      }
      if (target.type === "group") {
        return (
          <Menu>
            <Menu.Item
              onClick={() => {
                this.canvasRef.handler.duplicate();
              }}
            >
              {WorkStandardWorkTabLabel.COPY}
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                this.canvasRef.handler.remove();
              }}
            >
              {WorkStandardWorkTabLabel.DELETE}
            </Menu.Item>
          </Menu>
        );
      }
      return (
        <Menu>
          <Menu.Item
            onClick={() => {
              this.canvasRef.handler.duplicateById(target.id);
            }}
          >
            {WorkStandardWorkTabLabel.COPY}
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              this.canvasRef.handler.removeById(target.id);
            }}
          >
            {WorkStandardWorkTabLabel.DELETE}
          </Menu.Item>
        </Menu>
      );
    },
    onTransaction: (transaction) => {
      this.forceUpdate();
    },
  };

  handlers = {
    onChangePreview: (checked) => {
      let data;
      if (this.canvasRef) {
        data = this.canvasRef.handler.exportJSON().filter((obj) => {
          if (!obj.id) {
            return false;
          }
          return true;
        });
      }
      this.setState({
        preview: typeof checked === "object" ? false : checked,
        objects: data,
      });
    },
    onProgress: (progress) => {
      this.setState({
        progress,
      });
    },
    onImport: (files) => {
      if (files) {
        this.showLoading(true);
        setTimeout(() => {
          const reader = new FileReader();
          reader.onprogress = (e) => {
            if (e.lengthComputable) {
              const progress = parseInt((e.loaded / e.total) * 100, 10);
              this.handlers.onProgress(progress);
            }
          };
          reader.onload = async (e) => {
            const { objects, animations, styles, dataSources } = JSON.parse(e.target.result);
            this.setState({
              animations,
              styles,
              dataSources,
            });
            if (objects) {
              this.canvasRef.handler.clear(true);
              const data = objects.filter((obj) => {
                if (!obj.id) {
                  return false;
                }
                return true;
              });
              const newData = await Promise.all(
                data.map(async (object) => {
                  if (object.type === "image" || object.type === "video") {
                    // キャンバスの白い部分を除く
                    if (object.id === "workarea") return object;
                    // 表示用の署名付きURLをセットする
                    object.src = await getSignedUrlForGet(object.name);
                  }
                  return object;
                }),
              );
              this.canvasRef.handler.importJSON(newData);
            }
          };
          reader.onloadend = () => {
            this.showLoading(false);
          };
          reader.onerror = () => {
            this.showLoading(false);
          };
          // reader.readAsText(files[0]);
          reader.readAsText(files);
        }, 500);
      }
    },
    onUpload: () => {
      const inputEl = document.createElement("input");
      inputEl.accept = ".json";
      inputEl.type = "file";
      inputEl.hidden = true;
      inputEl.onchange = (e) => {
        this.handlers.onImport(e.target.files);
      };
      document.body.appendChild(inputEl); // required for firefox
      inputEl.click();
      inputEl.remove();
    },
    onSaveJSON: async () => {
      const objects = this.canvasRef.handler
        .exportJSON()
        .filter((obj) => {
          if (!obj.id) {
            return false;
          }
          return true;
        })
        .map((object) => {
          if (object.type === "image" || object.type === "video") {
            // キャンバスの白い部分を除く
            if (object.id === "workarea") return object;
            // srcに空文字をいれる（表示用の署名付きURLをリセットするため）
            object.src = "";
          }
          return object;
        });
      const { animations, styles, dataSources } = this.state;
      const exportDatas = {
        objects,
        animations,
        styles,
        dataSources,
      };
      const data = JSON.stringify(exportDatas, null, "\t");
      const file = new File([data], "hoge.json");
      const url = await getSignedUrlForPut(this.props.illustrationS3Key);
      putAws(url, file);
      this.changeEditing(false);
      window.alert("保存しました。");
    },
    onDownload: () => {
      this.showLoading(true);
      const objects = this.canvasRef.handler.exportJSON().filter((obj) => {
        if (!obj.id) {
          return false;
        }
        return true;
      });
      const { animations, styles, dataSources } = this.state;
      const exportDatas = {
        objects,
        animations,
        styles,
        dataSources,
      };
      const anchorEl = document.createElement("a");
      anchorEl.href = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(exportDatas, null, "\t"))}`;
      anchorEl.download = `${this.canvasRef.handler.workarea.name || "sample"}.json`;
      document.body.appendChild(anchorEl); // required for firefox
      anchorEl.click();
      anchorEl.remove();
      this.showLoading(false);
    },
    onDownloadFiles: () => {
      const s3Keys = this.canvasRef.handler
        .exportJSON()
        .filter((obj) => {
          if (!obj.id || obj.id === "workarea") {
            return false;
          }
          if (obj.type !== "image") {
            return false;
          }
          return true;
        })
        .map((obj) => obj.name);

      if (s3Keys.length === 0) {
        window.alert(DialogMessages.NO_DOWNLOADABLE_IMAGES);
        return;
      }
      this.downloadFiles(s3Keys);
    },
    onChangeAnimations: (animations) => {
      if (!this.state.editing) {
        this.changeEditing(true);
      }
      this.setState({
        animations,
      });
    },
    onChangeStyles: (styles) => {
      if (!this.state.editing) {
        this.changeEditing(true);
      }
      this.setState({
        styles,
      });
    },
    onChangeDataSources: (dataSources) => {
      if (!this.state.editing) {
        this.changeEditing(true);
      }
      this.setState({
        dataSources,
      });
    },
    onSaveImage: () => {
      this.canvasRef.handler.saveCanvasImage();
    },
  };

  shortcutHandlers = {
    esc: () => {
      document.addEventListener("keydown", (e) => {
        if (e.code === code.ESCAPE) {
          this.handlers.onChangePreview(false);
        }
      });
    },
  };

  transformList = () => {
    return Object.values(this.state.descriptors).reduce((prev, curr) => prev.concat(curr), []);
  };

  showLoading = (loading) => {
    this.setState({
      loading,
    });
  };

  changeEditing = (editing) => {
    this.setState({
      editing,
    });
  };

  getFormattedDate = (date, format) => {
    const symbol = {
      M: date.getMonth() + 1,
      d: date.getDate(),
      h: date.getHours(),
      m: date.getMinutes(),
      s: date.getSeconds(),
    };

    const formatted = format.replace(/(M+|d+|h+|m+|s+)/g, (v) => ((v.length > 1 ? "0" : "") + symbol[v.slice(-1)]).slice(-2));

    return formatted.replace(/(y+)/g, (v) => date.getFullYear().toString().slice(-v.length));
  };

  downloadFiles = (s3Keys) => {
    const anchorEl = document.createElement("a");
    const now = new Date();
    const formattedDate = this.getFormattedDate(now, "yyyyMMddhhmmss");
    s3Keys.forEach((s3Key) => {
      getSignedUrlForGet(s3Key)
        .then((url) => fetch(url))
        .then((response) => response.blob())
        .then((blob) => {
          anchorEl.href = URL.createObjectURL(blob);

          // [作標タイトル]_版数[版数]_[現在日時]_[S3キー].[拡張子]
          const name =
            this.props.workStandardName + "_版数" + this.props.revisionNumber + "_" + formattedDate + "_" + s3Key.slice(s3Key.lastIndexOf("/") + 1);

          anchorEl.download = name;
          anchorEl.click();
          URL.revokeObjectURL(anchorEl.href);
        });
    });
    anchorEl.remove();
  };

  render() {
    const { preview, selectedItem, zoomRatio, loading, progress, animations, styles, dataSources, editing, descriptors, objects } = this.state;
    const { onAdd, onRemove, onSelect, onModified, onChange, onZoom, onTooltip, onClick, onContext, onTransaction } = this.canvasHandlers;
    const { onChangePreview, onDownload, onUpload, onChangeAnimations, onChangeStyles, onChangeDataSources, onSaveJSON, onDownloadFiles } =
      this.handlers;
    Handler.canvasRef = this.canvasRef;
    Handler.setLoad = this.setState();
    const action = (
      <React.Fragment>
        {
          // 振る舞い残す系なのでコメントで残す
        }
        {/* <CommonButton
          className="rde-action-btn"
          shape="circle"
          icon="file-download"
          disabled={!editing}
          tooltipTitle={i18n.t("action.download")}
          onClick={onDownload}
          tooltipPlacement="bottomRight"
        /> */}
        {editing ? (
          <>
            {
              // 振る舞い残す系なのでコメントで残す
            }
            {/* <CommonButton
              className="rde-action-btn"
              shape="circle"
              icon="file-upload"
              tooltipTitle={i18n.t("action.upload")}
              tooltipPlacement="bottomRight"
            /> */}
            <Tooltip title={i18n.t("action.save")} placement={"bottom"}>
              <Button
                className="rde-action-btn"
                style={{
                  color: "RoyalBlue",
                }}
                shape="circle"
                size="large"
                onClick={onSaveJSON}
              >
                <Icon name="save" prefix="fas" size={1.5} />
              </Button>
            </Tooltip>
          </>
        ) : (
          <>
            {
              // 振る舞い残す系なのでコメントで残す
            }
            {/* <CommonButton
              className="rde-action-btn"
              shape="circle"
              icon="file-upload"
              tooltipTitle={i18n.t("action.upload")}
              tooltipPlacement="bottomRight"
              onClick={onUpload}
            /> */}
            <Button
              disabled
              className="rde-action-btn"
              style={{
                color: "LightSteelBlue",
              }}
              shape="circle"
              size="large"
            >
              <Icon name="save" prefix="fas" size={1.5} />
            </Button>
          </>
        )}
        {
          // 振る舞い残す系なのでコメントで残す
        }
        {/* <CommonButton
          className="rde-action-btn"
          shape="circle"
          icon="image"
          tooltipTitle={i18n.t("action.image-save")}
          onClick={onSaveImage}
          tooltipPlacement="bottomRight"
        /> */}
        <Tooltip title={i18n.t("action.close")} placement={"bottom"}>
          <Button
            className="rde-action-btn"
            style={{
              color: "tomato",
              paddingRight: "5px",
            }}
            shape="circle"
            size="large"
            onClick={() => this.props.closeCanvas(editing)}
          >
            <Icon name="window-close" prefix="fas" size={1.5} />
          </Button>
        </Tooltip>
      </React.Fragment>
    );
    const titleContent = (
      <React.Fragment>
        <span>{WorkStandardWorkTabLabel.CANVAS_TITLE}</span>
      </React.Fragment>
    );
    const title = <ImageMapTitle title={titleContent} action={action} />;
    const content = (
      <div className="rde-editor">
        <ImageMapItems
          ref={(c) => {
            this.itemsRef = c;
          }}
          canvasRef={this.canvasRef}
          descriptors={descriptors}
          modelId={this.props.modelId}
          illustrationS3Key={this.props.illustrationS3Key}
        />

        <div className="rde-editor-canvas-container">
          <div className="rde-editor-header-toolbar">
            <ImageMapHeaderToolbar canvasRef={this.canvasRef} selectedItem={selectedItem} onSelect={onSelect} onDownloadFiles={onDownloadFiles} />
          </div>
          <div
            ref={(c) => {
              this.container = c;
            }}
            className="rde-editor-canvas"
          >
            <Canvas
              ref={(c) => {
                this.canvasRef = c;
              }}
              className="rde-canvas"
              minZoom={30}
              maxZoom={500}
              objectOption={defaultOption}
              propertiesToInclude={propertiesToInclude}
              onModified={onModified}
              onAdd={onAdd}
              onRemove={onRemove}
              onSelect={onSelect}
              onZoom={onZoom}
              onTooltip={onTooltip}
              onClick={onClick}
              onContext={onContext}
              onTransaction={onTransaction}
              keyEvent={{
                transaction: true,
              }}
            />
          </div>
          <div className="rde-editor-footer-toolbar">
            <ImageMapFooterToolbar canvasRef={this.canvasRef} preview={preview} onChangePreview={onChangePreview} zoomRatio={zoomRatio} />
          </div>
        </div>
      </div>
    );
    return <Container title={title} content={content} loading={loading} />;
  }
}

export default ImageMapEditor;
