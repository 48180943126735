import { Divider, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import { WorkStandardManagementTabLabel } from "../../consts/label";

interface Props {
  managedNumber: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textDiv: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "calc(100% - 40px)",
    },
    text: {
      textAlign: "center",
      fontSize: 20,
    },
  })
);

export default function ManagedNumTable(props: Props) {
  const styles = useStyles();

  return (
    <>
      <Typography className={"typography-head"}>{WorkStandardManagementTabLabel.MANAGED_NUMBER}</Typography>
      <Divider />
      <div className={styles.textDiv}>
        <Typography className={styles.text}>{props.managedNumber}</Typography>
      </div>
    </>
  );
}
