export class ManagementBookRegistRequest {
  model_id: number;
  team_id: number;
  production_capacity_id: number;

  constructor(model_id: number, team_id: number, production_capacity_id: number) {
    this.model_id = model_id;
    this.team_id = team_id;
    this.production_capacity_id = production_capacity_id;
  }
}

export class DuplicateRequest {
  source_management_book_id: number;
  production_capacity_id: number;

  constructor(source_management_book_id: number, production_capacity_id: number) {
    this.source_management_book_id = source_management_book_id;
    this.production_capacity_id = production_capacity_id;
  }
}

export class ProcessRegistRequest {
  process_name: string;
  management_book_id: number;

  constructor(process_name: string, management_book_id: number) {
    this.process_name = process_name;
    this.management_book_id = management_book_id;
  }
}

export class ProcessNameUpdateRequest {
  process_id: number;
  process_name: string;

  constructor(id: number, name: string) {
    this.process_id = id;
    this.process_name = name;
  }
}

export class ProcessOrderUpdateRequest {
  process_order_list: ProcessOrder[] = [];
}

export class ProcessOrder {
  process_id: number = 0;
  process_order: number = 0;

  constructor(process_id: number, process_order: number) {
    this.process_id = process_id;
    this.process_order = process_order;
  }
}

export class OperationInProcessRequest {
  process_id: number = 0;
  work_standard_id: number = 0;

  constructor(process_id: number, work_standard_id: number) {
    this.process_id = process_id;
    this.work_standard_id = work_standard_id;
  }
}

export class OperationInProcessOrderUpdateRequest {
  operation_order: OperationInProcessOrder[] = [];
}

export class OperationInProcessOrder {
  operation_in_process_id: number = 0;
  operation_order: number = 0;

  constructor(operation_in_process_id: number, operation_order: number) {
    this.operation_in_process_id = operation_in_process_id;
    this.operation_order = operation_order;
  }
}
