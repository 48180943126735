import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Tooltip } from "@material-ui/core";

import { OperationSignatureDisplayList } from "./display/home";
import { OperationSignatureList } from "../../apicaller/domain/response/operation";
import { WorkStandardManagementTabLabel } from "../../consts/label";

interface Props {
  operationSignatureList?: OperationSignatureList;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      overflow: "auto",
      textAlign: "center",
      height: "100%",
    },
    rowDiv: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  })
);

export default function OperatorSignatureTable(props: Props) {
  const classes = useStyles();
  const [operationSignatureDisplayList, setOperationSignatureDisplayList] = useState<OperationSignatureDisplayList>(
    new OperationSignatureDisplayList()
  );

  // change in SWR data
  useEffect(() => {
    if (!props.operationSignatureList) return;
    if (!props.operationSignatureList.operator_signature_list) return;
    setOperationSignatureDisplayList(operationSignatureDisplayList.setData(props.operationSignatureList).sortByDesc());
  }, [props.operationSignatureList]);

  return (
    <TableContainer className={classes.container}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell align="left">{WorkStandardManagementTabLabel.CHECKED_AT}</TableCell>
            <TableCell align="left">{WorkStandardManagementTabLabel.CHECKED_PERSON}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {operationSignatureDisplayList.operationSignatures.map((row, rowindex) => (
            <TableRow key={rowindex}>
              <TableCell align="left">
                <div className={classes.rowDiv}>{row.created_at}</div>
              </TableCell>
              <TableCell align="left">
                <Tooltip title={row.user_name + " (" + row.login_id + ")"}>
                  <div className={classes.rowDiv}>{row.user_name + " (" + row.login_id + ")"}</div>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
