import React from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { DropResult } from "react-smooth-dnd";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { OperationInProcessDisplayRows } from "./display/operationManagementBook";
import { ManagementBookWorkStandardListTabLabel } from "../../consts/label";

interface Props {
  workStandardDisplayRows: OperationInProcessDisplayRows;
  handleSwapProcess: (dropResult: DropResult) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    unlinkedWorkStandardContainer: {
      height: "100%",
      flexGrow: 1,
    },
    headerRow: {
      display: "flex",
    },
    headerIndexCell: {
      width: "10%",
      whiteSpace: "nowrap",
    },
    headerManagedNoCell: {
      width: "20%",
      whiteSpace: "nowrap",
    },
    headerNameCell: {
      width: "70%",
      whiteSpace: "nowrap",
    },
    bodyRow: {
      height: "50px",
      display: "flex",
    },
    indexCell: {
      width: "10%",
    },
    managedNoCell: {
      width: "20%",
    },
    nameCell: {
      width: "70%",
    },
  })
);

const UnlinkedWorkStandardTable = (props: Props) => {
  const styles = useStyles();

  return (
    <TableContainer
      className={styles.unlinkedWorkStandardContainer}
      component={Paper}
    >
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell className={styles.headerIndexCell} align={"right"}>{ManagementBookWorkStandardListTabLabel.NUMBER}</TableCell>
            <TableCell className={styles.headerManagedNoCell} align={"right"}>{ManagementBookWorkStandardListTabLabel.MANAGED_NUMBER}</TableCell>
            <TableCell className={styles.headerNameCell} align={"left"}>{ManagementBookWorkStandardListTabLabel.WORK_STANDARD_NAME_SHORT}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {props.workStandardDisplayRows?.unlinkedWorkStandardRows?.map((row, index) => {
            return (
              <TableRow tabIndex={-1} key={row.operation_id}>
                <TableCell className={styles.indexCell} align={"right"}>{index + 1}</TableCell>
                <TableCell className={styles.managedNoCell} align={"right"}>{row.managed_number}</TableCell>
                <TableCell className={styles.nameCell} align={"left"}>{row.operation_name}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UnlinkedWorkStandardTable;
