import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("UTC");

export const formatDatetime = (datetime: string) =>
  dayjs(datetime).tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm:ss");

export const formatDate = (datetime: string) =>
  dayjs(datetime).tz("Asia/Tokyo").format("YYYY-MM-DD");
