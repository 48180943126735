import i18n from "i18next";

export const userHistoryActionMap = new Map<
  | "CREATE_USER"
  | "CHANGE_USER"
  | "DELETE_USER"
  | "BECOME_ASSOCIATE"
  | "BECOME_TEAM_LEADER"
  | "BECOME_UNIT_LEADER"
  | "BECOME_MODULE_MANAGER"
  | "DELETE_ROLE",
  string
>([
  ["CREATE_USER", "ユーザーが登録されました"],
  ["CHANGE_USER", "ユーザー情報が変更されました"],
  ["DELETE_USER", "ユーザーが削除されました"],
  ["BECOME_ASSOCIATE", "アソシエイトになりました"],
  ["BECOME_TEAM_LEADER", "チームリーダーになりました"],
  ["BECOME_UNIT_LEADER", "ユニットリーダーになりました"],
  ["BECOME_MODULE_MANAGER", "モジュールマネージャーになりました"],
  ["DELETE_ROLE", "ロールが削除されました"],
]);

const updateUserHistoryActionMap = () => {
  userHistoryActionMap.set("CREATE_USER", i18n.t("user-history-action-map.create-user"));
  userHistoryActionMap.set("CHANGE_USER", i18n.t("user-history-action-map.change-user"));
  userHistoryActionMap.set("DELETE_USER", i18n.t("user-history-action-map.delete-user"));
  userHistoryActionMap.set("BECOME_ASSOCIATE", i18n.t("user-history-action-map.become-associate"));
  userHistoryActionMap.set("BECOME_TEAM_LEADER", i18n.t("user-history-action-map.become-team-leader"));
  userHistoryActionMap.set("BECOME_UNIT_LEADER", i18n.t("user-history-action-map.become-unit-leader"));
  userHistoryActionMap.set("BECOME_MODULE_MANAGER", i18n.t("user-history-action-map.become-module-manager"));
  userHistoryActionMap.set("DELETE_ROLE", i18n.t("user-history-action-map.delete-role"));
};

if (i18n.isInitialized) {
  updateUserHistoryActionMap();
}

i18n.on("languageChanged", () => {
  updateUserHistoryActionMap();
});

i18n.on("loaded", () => {
  updateUserHistoryActionMap();
});
