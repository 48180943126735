import React from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";

interface Loading {
  title: string;
  setOpen: Function;
  showLoading: Function;
  hideLoading: Function;
}

const loading: Loading = {
  title: "",
  setOpen: (_: boolean) => {
  },
  showLoading: () => {
    loading.setOpen(true);
  },
  hideLoading: () => {
    loading.setOpen(false);
  },
};

export const Loading2 = () => {
  const [open, setOpen] = React.useState<boolean>(false);
  loading.setOpen = setOpen;

  return (
    <Backdrop
      style={{
        zIndex: 2000,
        color: "#fff",
      }}
      open={open}
    >
      {loading.title}
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export const showLoading = (title: string) => {
  loading.title = title;
  loading.showLoading();
};

export const hideLoading = () => {
  loading.hideLoading();
};
