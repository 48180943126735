// 確認・承認依頼
export class ApplicationRequest {
  operation_id: number = 0;
}

// 確認
export class ConfirmerRequest {
  operation_id: number;

  constructor(id: number) {
    this.operation_id = id;
  }
}

// 承認
export class ApproverRequest {
  operation_id: number;

  constructor(id: number) {
    this.operation_id = id;
  }
}

// 改訂承認
export class RevisingApproveRequest {
  operation_id: number;

  constructor(id: number) {
    this.operation_id = id;
  }
}

// 引き戻し
export class RevertRequest {
  operation_id: number;

  constructor(id: number) {
    this.operation_id = id;
  }
}

// 修正
export class ModifyRequest {
  operation_id: number;

  constructor(id: number) {
    this.operation_id = id;
  }
}

// 初版公開
export class PublishRequest {
  operation_id: number;

  constructor(id: number) {
    this.operation_id = id;
  }
}

// 改訂
export class ReviseRequest {
  operation_id: number;

  constructor(id: number) {
    this.operation_id = id;
  }
}

// 改訂中の承認依頼
export class RevisingApplicationRequest {
  operation_id: number;
  title: string;
  detail: string;

  constructor(id: number, title: string, detail: string) {
    this.operation_id = id;
    this.title = title;
    this.detail = detail;
  }
}

// 改訂フローの引き戻し
export class CancelUnderRevisionRequest {
  operation_id: number;

  constructor(id: number) {
    this.operation_id = id;
  }
}

// 改訂フローの修正
export class ModifyUnderRevisionRequest {
  operation_id: number;

  constructor(id: number) {
    this.operation_id = id;
  }
}

// 改訂中公開
export class PublishUnderRevisionRequest {
  readonly operation_id: number;

  constructor(id: number) {
    this.operation_id = id;
  }
}

// 作業者サイン
export class OperatorSignRequest {
  operation_id: number;

  constructor(id: number) {
    this.operation_id = id;
  }
}

// 管理台帳ID
export class ManagementBookIdRequest {
  management_book_id: number = 0;

  constructor(id: number) {
    this.management_book_id = id;
  }
}

// 管理台帳の承認依頼
export class ManagementBookApplicationRequest {
  management_book_id: number = 0;
  detail: string = "";

  constructor(id: number, detail: string) {
    this.management_book_id = id;
    this.detail = detail;
  }
}
