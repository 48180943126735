import React, { useContext } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { postLogout } from "../../apicaller/repository/authentication";
import { Message } from "../../context/appContextData";
import { ErrorMessages } from "../../consts/messages";
import { putEditingOffAll as putWsEditingOffAll } from "../../apicaller/repository/workstandard";
import { putEditingOffAll as putMbEditingOffAll } from "../../apicaller/repository/managementbook";
import { LoginPageLabel } from "../../consts/label";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      flexDirection: "column",
    },
    component: {
      margin: 10,
    },
    button: {
      margin: 10,
      color: "white",
      backgroundColor: "indianred",
    },
    icon: {
      color: "royalblue",
      cursor: "pointer",
    },
    text: {
      color: "royalblue",
      cursor: "pointer",
    },
  })
);

const Logout = () => {
  const styles = useStyles();
  const snackBarMessage = useContext(Message);

  const handleLogout = () => {
    putWsEditingOffAll()
      .then(putMbEditingOffAll)
      .then(postLogout)
      .then(() => {
        window.location.reload();
      })
      .catch((error: any) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.POST_LOGOUT, "error");
      });
  };

  return (
    <div>
      <Grid className={styles.grid}>
        <Typography className={styles.component} variant="h2">
          {LoginPageLabel.LOGOUT_TITLE}
        </Typography>
        <Button className={styles.button} variant="contained" size="large" onClick={handleLogout}>
          {LoginPageLabel.LOGOUT_BUTTON}
        </Button>
      </Grid>
    </div>
  );
};

export default Logout;
