import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ContentTree from "../components/Instructions/ContentTree";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: 50,
    },
  })
);

const Instructions = () => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <ContentTree></ContentTree>
    </div>
  );
};
export default Instructions;
