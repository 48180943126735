import React, { ForwardedRef, forwardRef, MutableRefObject } from "react";
import Grid from "@material-ui/core/Grid";
import { Chip, Theme, Typography, createStyles, makeStyles } from "@material-ui/core";
import { SelectedConditions, SelectedOperationInfo } from "../../types/workStandard";
import { AlwaysEnglishAreaLabel } from "../../consts/label";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    outerDiv: {
      display: "flex",
      flexFlow: "row wrap",
    },
    title: {
      fontSize: 16,
      margin: 5,
      verticalAlign: "middle",
    },
    titleOperation: {
      fontSize: 24,
      margin: 5,
      fontWeight: "bold",
      verticalAlign: "middle",
    },
    chip: {
      fontSize: 16,
      margin: 5,
    },
  })
);

type Props = {
  selectedConditions: SelectedConditions;
  selectedWorkStandardInfo: SelectedOperationInfo;
};

export const WorkStandardInfo = forwardRef((props: Props, ref: ForwardedRef<HTMLDivElement>) => {
  const styles = useStyles();

  return (
    <Grid container ref={ref} className={styles.root}>
      <Grid item sm={12}>
        <div className={styles.outerDiv}>
          <span>
            <Chip label={AlwaysEnglishAreaLabel.MODULE} variant={"outlined"} color={"secondary"} className={styles.chip} />
            <Typography display="inline" className={styles.title}>
              {props.selectedConditions.module.value}
            </Typography>
          </span>
          <span>
            <Chip label={AlwaysEnglishAreaLabel.UNIT} variant={"outlined"} color={"secondary"} className={styles.chip} />
            <Typography display="inline" className={styles.title}>
              {props.selectedConditions.unit.value}
            </Typography>
          </span>
          <span>
            <Chip label={AlwaysEnglishAreaLabel.TEAM} variant={"outlined"} color={"secondary"} className={styles.chip} />
            <Typography display="inline" className={styles.title}>
              {props.selectedConditions.team.value}
            </Typography>
          </span>
          <span>
            <Chip label={AlwaysEnglishAreaLabel.MODEL} variant={"outlined"} color={"secondary"} className={styles.chip} />
            <Typography display="inline" className={styles.title}>
              {props.selectedConditions.model.value}
            </Typography>
          </span>
          <span>
            <Chip label={AlwaysEnglishAreaLabel.WORK_STANDARD} variant={"outlined"} color={"secondary"} className={styles.chip} />
            <Typography display="inline" className={styles.titleOperation}>
              {props.selectedWorkStandardInfo.operation_name}
            </Typography>
          </span>
        </div>
      </Grid>
    </Grid>
  );
});
