import React, { useContext, useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import UserListTable from "../components/UserList/UserListTable";
import SelectArea from "../components/Common/SelectArea";
import { Button, Grid, Switch, Typography, useTheme } from "@material-ui/core";
import { EditInfo, LoginInfo } from "../context/appContextData";
import { DialogMessages } from "../consts/messages";
import { Redirect } from "react-router-dom";
import { useFactory, useLine, usePlant, useTeam, useUnit } from "../apicaller/repository/datamanagament";
import { FactoryDisplayComboBox, PlantDisplayComboBox } from "../components/Common/display/display";
import {
  FactoryListResponse,
  LineListResponse,
  PlantListResponse,
  TeamListResponse,
  UnitListResponse,
} from "../apicaller/domain/response/datamanagement";
import { sortByAsc } from "../function/sort";
import { LineDisplayComboBox, TeamDisplayComboBox, UnitDisplayComboBox } from "../components/OperationList/display/operationList";
import { SelectedUserInfo } from "../components/UserList/display/userList";
import { Authority } from "../consts/authority";
import { useUserBelonging } from "../apicaller/repository/authentication";
import { AuthorityLabel, EditModeLabel, UserListPageLabel } from "../consts/label";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      // AppBar表示分の余白
      marginTop: 50,
    },
    toolbar: {
      flexGrow: 1,
      minHeight: "36px",
      paddingInline: 0,
      width: "100%",
    },
    toolbarLeftSide: {
      flexGrow: 1,
      display: "flex",
    },
    table: {
      width: "100%",
      padding: "10px",
    },
  })
);
const DEFAULT_SELECT_OBJ = { id: 0, name: "" };

const UserList = () => {
  const styles = useStyles();
  const theme = useTheme();
  const EditContext = useContext(EditInfo);
  const LoginContext = useContext(LoginInfo);

  const [userBelonging, getUserBelonging, resetUserBelonging, mutateUserBelonging, userBelongingError] = useUserBelonging();
  const [factoryList, getFactoryList, resetFactoryList, mutateFactoryList, factoryError] = useFactory();
  const [plantList, getPlantList, resetPlantList, mutatePlantList, plantError] = usePlant();
  const [lineList, getLineList, resetModuleList, mutateLineList, lineError] = useLine();
  const [unitList, getUnitList, resetUnitList, mutateUnitList, unitError] = useUnit();
  const [teamList, getTeamList, resetTeamList, mutateTeamList, teamError] = useTeam();

  const [openDialog, setOpenDialog] = useState(false);
  const [openSignUpDialog, setOpenSignUpDialog] = useState(false);
  const [displayRoleList, setDisplayRoleList] = useState({ module_role: [], unit_role: [], team_role: [] });
  const [selectUser, setSelectUser] = useState<SelectedUserInfo>(new SelectedUserInfo());
  const [userPW, setUserPW] = useState("");
  const [authority, setAuthority] = useState(AuthorityLabel.GENERAL);

  const [selectFactoryItem, setSelectFactoryItem] = useState(DEFAULT_SELECT_OBJ);
  const [selectPlantItem, setSelectPlantItem] = useState(DEFAULT_SELECT_OBJ);
  const [selectLineItem, setSelectLineItem] = useState(DEFAULT_SELECT_OBJ);
  const [selectUnitItem, setSelectUnitItem] = useState(DEFAULT_SELECT_OBJ);
  const [selectTeamItem, setSelectTeamItem] = useState(DEFAULT_SELECT_OBJ);

  const handleEditModeChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    let result: boolean = false;
    if (checked) {
      result = window.confirm(DialogMessages.EDIT_MODE_ON);
    } else {
      result = window.confirm(DialogMessages.EDIT_MODE_OFF);
    }
    if (result) {
      EditContext.invert();
    }
  };

  // 拠点ComboBoxの変更時の処理
  const factoryChangeHandler = (value: string) => {
    setSelectFactoryItem({ ...DEFAULT_SELECT_OBJ });
    setSelectPlantItem({ ...DEFAULT_SELECT_OBJ });
    setSelectLineItem({ ...DEFAULT_SELECT_OBJ });
    setSelectUnitItem({ ...DEFAULT_SELECT_OBJ });
    setSelectTeamItem({ ...DEFAULT_SELECT_OBJ });

    resetPlantList();
    resetModuleList();
    resetUnitList();
    resetTeamList();
    if (value !== null) {
      const factory_id = factoryList.factory[factoryList.factory.findIndex((e: { factory_name: string }) => e.factory_name === value)].factory_id;
      setSelectFactoryItem({ ...{ id: factory_id, name: value } });
    }
  };

  // 工場ComboBoxの変更時の処理
  const plantChangeHandler = (value: string) => {
    setSelectPlantItem({ ...DEFAULT_SELECT_OBJ });
    setSelectLineItem({ ...DEFAULT_SELECT_OBJ });
    setSelectUnitItem({ ...DEFAULT_SELECT_OBJ });
    setSelectTeamItem({ ...DEFAULT_SELECT_OBJ });

    resetModuleList();
    resetUnitList();
    resetTeamList();

    if (value !== null) {
      const plant_id = plantList.plant[plantList.plant.findIndex((e: { plant_name: string }) => e.plant_name === value)].plant_id;
      setSelectPlantItem({ ...{ id: plant_id, name: value } });
    }
  };

  //モジュールComboBoxの変更時の処理
  const moduleChangeHandler = (value: string) => {
    setSelectTeamItem({ ...DEFAULT_SELECT_OBJ });
    setSelectUnitItem({ ...DEFAULT_SELECT_OBJ });
    setSelectLineItem({ ...DEFAULT_SELECT_OBJ });

    resetUnitList();
    resetTeamList();

    if (value !== null) {
      if (!lineList) return;
      const line_id = lineList.line[lineList.line.findIndex((e: { line_name: string }) => e.line_name === value)].line_id;
      setSelectLineItem({ ...{ id: line_id, name: value } });
    }
  };

  //ユニットComboBoxの変更時の処理
  const unitChangeHandler = (value: string) => {
    setSelectTeamItem({ ...DEFAULT_SELECT_OBJ });
    setSelectUnitItem({ ...DEFAULT_SELECT_OBJ });

    resetTeamList();

    if (value !== null) {
      const unit_id = unitList.unit[unitList.unit.findIndex((e: { unit_name: string }) => e.unit_name === value)].unit_id;
      setSelectUnitItem({ ...{ id: unit_id, name: value } });
    }
  };

  //チームComboBoxの変更時の処理
  const teamChangeHandler = (value: string) => {
    setSelectTeamItem({ ...DEFAULT_SELECT_OBJ });
    if (value !== null) {
      const team_id = teamList.team[teamList.team.findIndex((e: { team_name: string }) => e.team_name === value)].team_id;
      setSelectTeamItem({ ...{ id: team_id, name: value } });
    }
    return;
  };

  //拠点リストのソート
  let factoryDisplayComboBox = new FactoryDisplayComboBox();
  if (factoryList) {
    if (factoryList.factory !== undefined) {
      let sorted = new FactoryListResponse();
      sorted.factory = sortByAsc(factoryList.factory, "factory_id");
      if (sorted.factory !== undefined) factoryDisplayComboBox.setData(sorted);
    }
  }

  //工場リストのソート
  let plantDisplayComboBox = new PlantDisplayComboBox();
  if (plantList) {
    if (plantList.plant !== undefined) {
      let sorted = new PlantListResponse();
      sorted.plant = sortByAsc(plantList.plant, "plant_id");
      if (sorted.plant !== undefined) plantDisplayComboBox.setData(sorted);
    }
  }

  //モジュールリストのソート
  let lineDisplayComboBox = new LineDisplayComboBox();
  if (lineList) {
    if (lineList.line !== undefined) {
      let sorted = new LineListResponse();
      sorted.line = sortByAsc(lineList.line, "line_id");
      if (sorted.line !== undefined) lineDisplayComboBox.setData(sorted);
    }
  }

  //ユニットリストのソート
  let unitDisplayComboBox = new UnitDisplayComboBox();
  if (unitList) {
    if (unitList.unit !== undefined) {
      let sorted = new UnitListResponse();
      sorted.unit = sortByAsc(unitList.unit, "unit_id");
      if (sorted.unit !== undefined) unitDisplayComboBox.setData(sorted);
    }
  }

  //チームリストのソート
  let teamDisplayComboBox = new TeamDisplayComboBox();
  if (teamList) {
    if (teamList.team !== undefined) {
      let sorted = new TeamListResponse();
      sorted.team = sortByAsc(teamList.team, "team_id");
      if (sorted.team !== undefined) teamDisplayComboBox.setData(sorted);
    }
  }

  const handleAddNewUser = () => {
    setSelectUser(new SelectedUserInfo());
    setDisplayRoleList({ module_role: [], unit_role: [], team_role: [] });
    setUserPW("");
    setAuthority(AuthorityLabel.GENERAL);
    setOpenSignUpDialog(true);
  };

  useEffect(() => {
    getUserBelonging();
    getFactoryList();
  }, []);


  // 拠点の初期選択項目を設定
  useEffect(() => {
    if (!factoryList) return;
    if (!userBelonging) return;
    if (!userBelonging.factory_id) return;
    if (userBelonging.factory_id === 0) return;
    factoryList.factory.forEach((row) => {
      if (row.factory_id === userBelonging.factory_id) {
        setSelectFactoryItem({ id: row.factory_id, name: row.factory_name });
      }
    });
  }, [userBelonging, factoryList]);

  // 拠点の選択項目が変更されたときに工場リストを取得
  useEffect(() => {
    if (!selectFactoryItem) return;
    if (!selectFactoryItem.id) return;
    if (selectFactoryItem.id === 0) return;
    getPlantList(String(selectFactoryItem.id));
  }, [selectFactoryItem]);

  // 工場の初期選択項目を設定
  useEffect(() => {
    if (!plantList) return;
    if (!plantList.plant) return;
    if (plantList.plant.length === 0) return;
    plantList.plant.forEach((row) => {
      if (row.plant_id === userBelonging.plant_id) {
        setSelectPlantItem({ id: row.plant_id, name: row.plant_name });
      }
    });
  }, [plantList]);

  // 工場の選択項目が変更されたときにモジュールリストを取得
  useEffect(() => {
    if (!selectPlantItem) return;
    if (!selectPlantItem.id) return;
    if (selectPlantItem.id === 0) return;
    getLineList(selectPlantItem.id);
  }, [selectPlantItem]);

  // モジュールの初期選択項目を設定
  useEffect(() => {
    if (!lineList) return;
    if (!lineList.line) return;
    if (lineList.line.length === 0) return;
    lineList.line.forEach((row) => {
      if (row.line_id === userBelonging.module_id) {
        setSelectLineItem({ id: row.line_id, name: row.line_name });
      }
    });
  }, [lineList]);

  // モジュールの選択項目が変更されたときにユニットリストを取得
  useEffect(() => {
    if (!selectLineItem) return;
    if (!selectLineItem.id) return;
    if (selectLineItem.id === 0) return;
    getUnitList(String(selectLineItem.id));
  }, [selectLineItem]);

  // ユニットの初期選択項目を設定
  useEffect(() => {
    if (!unitList) return;
    if (!unitList.unit) return;
    if (unitList.unit.length === 0) return;
    unitList.unit.forEach((row) => {
      if (row.unit_id === userBelonging.unit_id) {
        setSelectUnitItem({ id: row.unit_id, name: row.unit_name });
      }
    });
  }, [unitList]);

  // ユニットの選択項目が変更されたときにチームリストを取得
  useEffect(() => {
    if (!selectUnitItem) return;
    if (!selectUnitItem.id) return;
    if (selectUnitItem.id === 0) return;
    getTeamList(String(selectUnitItem.id));
  }, [selectUnitItem]);

  // チームの初期選択項目を設定
  useEffect(() => {
    if (!teamList) return;
    if (!teamList.team) return;
    if (teamList.team.length === 0) return;
    teamList.team.forEach((row) => {
      if (row.team_id === userBelonging.team_id) {
        setSelectTeamItem({ id: row.team_id, name: row.team_name });
      }
    });
  }, [teamList]);

  //権限
  if (LoginContext.loginUser.authority === 0) {
    return <Redirect to="/login" />;
  }

  return (
    <Grid container className={styles.container}>
      <Grid item sm={9}>
        <SelectArea
          factoryDisplayComboBox={factoryDisplayComboBox}
          plantDisplayComboBox={plantDisplayComboBox}
          lineDisplayComboBox={lineDisplayComboBox}
          unitDisplayComboBox={unitDisplayComboBox}
          teamDisplayComboBox={teamDisplayComboBox}
          selectedFactoryItem={selectFactoryItem.name}
          selectedPlantItem={selectPlantItem.name}
          selectedLineItem={selectLineItem.name}
          selectedUnitItem={selectUnitItem.name}
          selectedTeamItem={selectTeamItem.name}
          handleSelectFactory={factoryChangeHandler}
          handleSelectPlant={plantChangeHandler}
          handleSelectModule={moduleChangeHandler}
          handleSelectUnit={unitChangeHandler}
          handleSelectTeam={teamChangeHandler}
        />
      </Grid>
      <Grid item sm />
      {EditContext.editMode && (
        <Grid item>
          <Button
            style={{ marginRight: theme.spacing(2) }}
            variant={"contained"}
            color={"secondary"}
            onClick={() => {
              handleAddNewUser();
            }}
          >
            {UserListPageLabel.CREATE_NEW_USER}
          </Button>
        </Grid>
      )}
      {LoginContext.loginUser.authority === Authority.ADMIN && (
        <Grid item sm={1} style={{ display: "flex", alignItems: "center" }}>
          <Typography className={"edit-label"}>{EditModeLabel.EDIT}</Typography>
          <Switch checked={EditContext.editMode} onChange={handleEditModeChange} />
        </Grid>
      )}
      <Grid item sm={12}>
        <UserListTable
          factory_id={selectFactoryItem.id}
          plant_id={selectPlantItem.id}
          module_id={selectLineItem.id}
          unit_id={selectUnitItem.id}
          team_id={selectTeamItem.id}
          open_dialog={openDialog}
          set_open_dialog={setOpenDialog}
          display_role_list={displayRoleList}
          set_display_role_list={setDisplayRoleList}
          select_user={selectUser}
          set_select_user={setSelectUser}
          usr_pass={userPW}
          set_usr_pass={setUserPW}
          authority={authority}
          set_authority={setAuthority}
          openSignUpDialog={openSignUpDialog}
          setOpenSignUpDialog={setOpenSignUpDialog}
          showEditButtons={EditContext.editMode}
        />
      </Grid>
    </Grid>
  );
};

export default UserList;
