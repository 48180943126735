import React, { useContext } from "react";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { EditInfo } from "../../context/appContextData";
import { Requirement } from "../../apicaller/domain/response/operation";

interface Props {
  requirementData: Requirement;
  handleDelete: any;
  requirementItems: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      scrollSnapAlign: "center",
      flexdirection: "row",
      padding: 5,
      scrollSnapAlign: "center"
    },
    chip: {
      fontSize: 18,
      borderRadius: 5,
      height: "100%",
      minHeight: 35,
      minWidth: 100,
      "& .MuiChip-label": {
        // wordWrap: "break-word",
        overflowWrap: "anywhere",
        whiteSpace: "normal",
        textOverflow: "clip",
        width: "100%",
      },
    },
  })
);

export const RequirementChip = (props: Props) => {
  const styles = useStyles();
  const EditContext = useContext(EditInfo);

  const getRequirementName = (index: number) => {
    const findItem = props.requirementItems.find((element) => element.index == index) ?? 0;
    if (findItem === 0) return "";
    return findItem.name;
  };

  const getRequirementImage = (index: number) => {
    const findItem = props.requirementItems.find((element) => element.index == index) ?? 0;
    if (findItem === 0) return "";
    return findItem.image;
  };

  return (
    <div className={styles.root}>
      {EditContext.editMode ? (
        <Chip
          label={getRequirementName(props.requirementData.requirement_id)}
          avatar={<Avatar alt="image" src={getRequirementImage(props.requirementData.requirement_id)} />}
          onDelete={() => props.handleDelete(props.requirementData.requirement_necessity_id)}
        />
      ) : (
        <Chip
          className={styles.chip}
          label={getRequirementName(props.requirementData.requirement_id)}
          avatar={<Avatar alt="image" src={getRequirementImage(props.requirementData.requirement_id)} />}
        />
      )}
    </div>
  );
};

export default RequirementChip;
