export interface DataManagement {}

export class UnitRegistRequest implements DataManagement {
  unit_name: string;
  line_id: number;

  constructor(unit_name: string, line_id: number) {
    this.unit_name = unit_name;
    this.line_id = line_id;
  }
}

export class UnitUpdateRequest implements DataManagement {
  unit_id: number;
  unit_name: string;

  constructor(id: number, name: string) {
    this.unit_id = id;
    this.unit_name = name;
  }
}

export class TeamRegistRequest implements DataManagement {
  team_name: string;
  unit_id: number;
  line_id: number;

  constructor(team_name: string, unit_id: number, line_id: number) {
    this.team_name = team_name;
    this.line_id = line_id;
    this.unit_id = unit_id;
  }
}

export class TeamUpdateRequest implements DataManagement {
  team_id: number;
  team_name: string;

  constructor(id: number, name: string) {
    this.team_id = id;
    this.team_name = name;
  }
}

export class ModelRegistRequest implements DataManagement {
  model_name: string;
  plant_id: number;

  constructor(model_name: string, plant_id: number) {
    this.model_name = model_name;
    this.plant_id = plant_id;
  }
}

export class ModelUpdateRequest implements DataManagement {
  model_id: number;
  model_name: string;

  constructor(id: number, name: string) {
    this.model_id = id;
    this.model_name = name;
  }
}

export class VolumeRegistRequest implements DataManagement {
  volume: number;
  unit_id: number;

  constructor(volume: number, unit_id: number) {
    this.volume = volume;
    this.unit_id = unit_id;
  }
}

export class ProductionCapacityRequest implements DataManagement {
  production_capacity_id: number;

  constructor(id: number) {
    this.production_capacity_id = id;
  }
}

export class UserDeleteRequest implements DataManagement {
  user_id: number;

  constructor(id: number) {
    this.user_id = id;
  }
}

export class UserRoleUpdateRequest {
  section_name: string = "";
  section_id: number = 0;
  user_id: number = 0;
  role: string = "";

  constructor(section_name: string, section_id: number, user_id: number, role: string) {
    this.section_name = section_name;
    this.section_id = section_id;
    this.user_id = user_id;
    this.role = role;
  }
}
