import React, { useContext, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Tooltip } from "@material-ui/core";
import { ConfirmerDisplay } from "./display/home";
import { ConfirmerList } from "../../apicaller/domain/response/operation";
import { EditInfo } from "../../context/appContextData";
import { WorkStandardManagementTabLabel } from "../../consts/label";

interface Props {
  confirmerList: ConfirmerList;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      overflow: "auto",
      textAlign: "center",
      height: "100%",
    },
    nameRowDiv: {
      width: "100%",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      verticalAlign: "middle",
      textAlign: "center",
      margin: "0 auto",
    },
  })
);

export default function ConfirmerTable(props: Props) {
  const classes = useStyles();
  const EditContext = useContext(EditInfo);
  const confirmerDisplay = new ConfirmerDisplay();

  if (props.confirmerList?.confirmer) {
    confirmerDisplay.setData(props.confirmerList).sortData();
  }

  return (
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center">{WorkStandardManagementTabLabel.CONFIRM}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {confirmerDisplay.confirmer.map((row, rowindex) => (
              <TableRow key={rowindex}>
                <TableCell align="center" colSpan={EditContext.editMode ? 2 : 1}>
                  <Tooltip title={row}>
                    <div className={classes.nameRowDiv}>{row}</div>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  );
}
