export class WorkFlowStatus {
  static readonly EDITING = "EDITING";
  static readonly WAITING_CONFIRM = "WAITING_CONFIRM";
  static readonly WAITING_APPROVE = "WAITING_APPROVE";
  static readonly REJECTED = "REJECTED";
  static readonly APPROVED = "APPROVED";
  static readonly PUBLISHED = "PUBLISHED";
  static readonly ARCHIVED = "ARCHIVED";
}

export const CreateNew = "新規作成";
