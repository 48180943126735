import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Paper, TableHead, Toolbar } from "@material-ui/core";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Container, Draggable, DropResult } from "react-smooth-dnd";
import OperationmanagementBookDialog, { OperationManagementBookDialogAction, OperationManagementBookDialogTarget, } from "./OperationManagementBookDialog";
import { EditInfo, Message } from "../../context/appContextData";
import { OperationDisplay, OperationInProcessDisplay, OperationInProcessDisplayRows, PublishedOperationDisplay, } from "./display/operationManagementBook";
import { usePublishedOperation } from "../../apicaller/repository/operationlist";
import { ErrorMessages, WarningMessages } from "../../consts/messages";
import { ManagementBookWorkStandardListTabLabel } from "../../consts/label";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
      padding: "0",
    },
  },
  managementBookContainer: {
    height: "100%",
    flexGrow: 1,
  },
  headerRow: {
    display: "flex",
  },
  headerOrderCell: {
    display: "flex",
    width: "10%",
    alignItems: "center",
    whiteSpace: "nowrap",
  },
  headerManagementNumCell: {
    display: "flex",
    width: "10%",
    alignItems: "center",
    whiteSpace: "nowrap",
  },
  headerNameCell: {
    display: "flex",
    width: "60%",
    alignItems: "center",
    whiteSpace: "nowrap",
  },
  headerButtonCell: {
    display: "flex",
    alignItems: "center",
    width: "20%",
  },
  bodyRow: {
    height: "50px",
    display: "flex",
  },
  bodyOrderCell: {
    display: "flex",
    width: "10%",
    alignItems: "center",
  },
  bodyManagementNumCell: {
    display: "flex",
    width: "10%",
    alignItems: "center",
  },
  bodyNameCell: {
    display: "flex",
    width: "60%",
    alignItems: "center",
  },
  bodyButtonCell: {
    display: "flex",
    width: "20%",
  },
});

interface RowProps {
  row: OperationInProcessDisplay;
  handleClickAdd: any;
  handleDragSwap: any;
  handleClickDelete: (operation_in_process_id: number) => void;
  processRowIndex: number;
  handleShowHome: any;
}

function Row(props: RowProps) {
  const [open, setOpen] = useState(true);
  const styles = useRowStyles();
  const EditContext = useContext(EditInfo);

  const moveToOperationPageHandler = (operationRow: OperationDisplay) => {
    if (!EditContext.editMode) {
      props.handleShowHome(
        operationRow.operation_id,
        operationRow.operation_name,
        operationRow.operation_in_process_id,
        operationRow.managed_number,
        props.row.process_id
      );
    }
  };

  return (
    <>
      <TableRow className={styles.root} hover style={{ cursor: "pointer" }} onClick={() => setOpen(!open)}>
        <TableCell>
          <Toolbar style={{ minHeight: "50px" }}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            <Typography>{props.row.process_name}</Typography>
          </Toolbar>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow className={styles.headerRow}>
                    <TableCell className={styles.headerOrderCell} align={"right"}>
                      {ManagementBookWorkStandardListTabLabel.WORK_STANDARD_ORDER}
                    </TableCell>
                    <TableCell className={styles.headerManagementNumCell} align={"right"}>
                      {ManagementBookWorkStandardListTabLabel.MANAGED_NUMBER}
                    </TableCell>
                    <TableCell className={styles.headerNameCell} align={"left"}>
                      {ManagementBookWorkStandardListTabLabel.WORK_STANDARD_NAME_SHORT}
                    </TableCell>
                    <TableCell className={styles.headerButtonCell} align="right">
                      {EditContext.editMode && (
                        <div>
                          <IconButton size="small" onClick={() => props.handleClickAdd(props.row.process_id)}>
                            <AddCircleIcon />
                          </IconButton>
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <Container dragHandleSelector=".dragHandleSelector" lockAxis="y" onDrop={(e) => props.handleDragSwap(props.processRowIndex, e)}>
                    {/* 作業標準 */}
                    {props.row.operation_list.map((operationRow, operationRowIndex) => (
                      <Draggable key={operationRowIndex}>
                        <TableRow
                          className={styles.bodyRow}
                          key={operationRow.operation_id}
                          hover={EditContext.editMode ? false : true}
                          style={EditContext.editMode ? { cursor: "auto" } : { cursor: "pointer" }}
                          onClick={() => moveToOperationPageHandler(operationRow)}
                        >
                          {/* ORDER */}
                          <TableCell className={styles.bodyOrderCell} align={"right"}>
                            {operationRow.operation_order}
                          </TableCell>
                          {/* 管理番号 */}
                          <TableCell className={styles.bodyManagementNumCell} align={"right"}>
                            {operationRow.managed_number}
                          </TableCell>
                          {/* 名前 */}
                          <TableCell className={styles.bodyNameCell} align={"left"}>
                            {operationRow.operation_name}
                          </TableCell>

                          {/* ボタン */}
                          <TableCell className={styles.bodyButtonCell} align={"right"}>
                            {EditContext.editMode && (
                              <>
                                <IconButton className="dragHandleSelector" size="small">
                                  <DragHandleIcon />
                                </IconButton>
                                <IconButton onClick={() => props.handleClickDelete(operationRow.operation_in_process_id)} size="small">
                                  <RemoveCircleIcon />
                                </IconButton>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      </Draggable>
                    ))}
                  </Container>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

interface Props {
  processContents: OperationInProcessDisplayRows;
  handleAddedOperationInProcess: any;
  handleOperationInProcessUpdate: any;
  handleOperationInProcessDelete: (operation_in_process_id: string) => void;
  selectModelId: number;
  selectTeamId: number;
  handleShowHome: any;
}

export default function OperationManagementBookTable(props: Props) {
  const styles = useRowStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const snackBarMessage = useContext(Message);
  const [target, setTarget] = useState<OperationManagementBookDialogTarget>({
    process: "",
    team_id: "",
    model_id: "",
    process_order: 0,
    process_name: "",
    selectableOperation: new PublishedOperationDisplay(),
  });
  const [publishedOperationData, getPublishedOperation, resetPublishedOperation, mutatePublishedOperationList, publishedOperationError] =
    usePublishedOperation();
  const [publishedOperationDisplay, setPublishedOperationDisplay] = useState<PublishedOperationDisplay>(new PublishedOperationDisplay());

  const handleDragSwap = (processIndex: number, dropResult: DropResult) => {
    props.handleOperationInProcessUpdate(processIndex, dropResult);
  };

  const handleClickAdd = (process_id: number) => {
    getPublishedOperation(String(props.selectTeamId), String(props.selectModelId));

    const tmp_process = props.processContents.processRows?.find((row) => row.process_id === process_id);
    if (tmp_process === undefined) return;

    setTarget({
      ...target,
      process: String(process_id),
      team_id: String(props.selectTeamId),
      model_id: String(props.selectModelId),
      process_order: tmp_process.operation_list.length + 1,
      process_name: tmp_process.process_name,
    });
  };

  const handleClickDelete = (operation_in_process_id: number) => {
    props.handleOperationInProcessDelete(String(operation_in_process_id));
  };

  const handleDialogAction = (_: string, action: OperationManagementBookDialogAction) => {
    if (action === OperationManagementBookDialogAction.create) {
      props.handleAddedOperationInProcess();
      setOpenDialog(false);
    }
    if (action === OperationManagementBookDialogAction.cancel) {
      setOpenDialog(false);
    }
    resetPublishedOperation();
  };

  useEffect(() => {
    if (!publishedOperationData) return;
    if (!publishedOperationData.operation_list) return;
    if (publishedOperationData.operation_list.length === 0) {
      // そもそも登録できる作業標準が１つもない場合、警告メッセージを表示してダイアログを開かない
      snackBarMessage.setMessage(WarningMessages.OPERATION_BOOK_NO_PUBLISHED, "warning");
      resetPublishedOperation();
      return;
    }

    setPublishedOperationDisplay(publishedOperationDisplay.setData(publishedOperationData, props.processContents));
    if (publishedOperationDisplay.managed_number.length === 0) {
      // 全て登録済みなので登録できる作業標準が１つもない場合、警告メッセージを表示してダイアログを開かない
      snackBarMessage.setMessage(WarningMessages.OPERATION_BOOK_ALL_REGISTERED, "warning");
      resetPublishedOperation();
      return;
    }

    // 登録(選択)可能な作業標準のみをセットし、登録用ダイアログをオープン
    setTarget({
      ...target,
      selectableOperation: publishedOperationDisplay,
    });
    setOpenDialog(true);
  }, [publishedOperationData]);

  // SWR error
  useEffect(() => {
    if (publishedOperationError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_PUBLISHED, "error");
    }
  }, [publishedOperationError]);

  return (
    <>
      <OperationmanagementBookDialog open={openDialog} target={target} handleDialogAction={handleDialogAction} />
      <TableContainer className={styles.managementBookContainer} component={Paper}>
        <Table>
          <TableBody>
            {props.processContents.processRows.map((row, processRowIndex) => (
              <Row
                key={row.process_id}
                row={row}
                processRowIndex={processRowIndex}
                handleClickAdd={handleClickAdd}
                handleDragSwap={handleDragSwap}
                handleClickDelete={handleClickDelete}
                handleShowHome={props.handleShowHome}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
