import React, { useContext } from "react";
import ComboBox from "./ComboBox";
import Grid from "@material-ui/core/Grid";
import { ModelDisplayComboBox, VolumeDisplayComboBox } from "./display/display";
import { LoginInfo } from "../../context/appContextData";
import { Authority } from "../../consts/authority";
import { Label } from "../../consts/label";

interface Props {
  modelDisplayComboBox: ModelDisplayComboBox;
  volumeDisplayComboBox: VolumeDisplayComboBox;
  selectedModelItem: string;
  selectedVolumeItem: string;
  handleSelectModel: (value: string) => void;
  handleSelectVolume: (value: string) => void;
}

export default function SelectModel(props: Props) {
  const LoginContext = useContext(LoginInfo);

  return (
    <Grid container spacing={0}>
      <Grid item sm={2}>
        <ComboBox
          disable={!props.modelDisplayComboBox.text.length}
          label={Label.MODEL}
          options={props.modelDisplayComboBox.text}
          value={props.selectedModelItem}
          onChange={(value: string) => props.handleSelectModel(value)}
        />
      </Grid>
      <Grid item sm={2}>
        <ComboBox
          disable={!props.volumeDisplayComboBox.text.length || LoginContext.loginUser.authority === Authority.GENERAL}
          label={Label.PRODUCTION_CAPACITY_SHORT}
          options={props.volumeDisplayComboBox.text}
          value={props.selectedVolumeItem}
          onChange={(value: string | null) => props.handleSelectVolume(value)}
        />
      </Grid>
    </Grid>
  );
}
