import React, { useContext, useEffect, useState } from "react";
import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { EditInfo, Message } from "../../context/appContextData";
import { deleteHistoryDetail, useHistoryDetail } from "../../apicaller/repository/workstandard";
import { DialogMessages, ErrorMessages, SuccessMessages } from "../../consts/messages";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { WorkStandardHistoryDetailDisplay, WorkStandardHistoryDetailListDisplay } from "../Home/display/home";
import HistoryDetailDialog from "../Home/HistoryDetailDialog";
import { WorkStandardHistoryDetailTabLabel } from "../../consts/label";

const useStyles = makeStyles(() =>
  createStyles({
    historyContainer: {
      height: "100%",
      overflow: "auto",
    },
    headerRow: {
      height: "10%",
    },
    bodyRow: {
      height: "10%",
    },
    cell50par: {
      width: "50%",
    },
    cell20par: {
      width: "20%",
    },
    cell15par: {
      width: "15%",
    },
    cell10par: {
      width: "10%",
    },
    cell5par: {
      width: "5%",
    },
  })
);

interface Props {
  contentHeight: number;
  workStandardId: number;
  workStandardRevision: number;
}

export const HistoryDetailTab = (props: Props) => {
  const styles = useStyles();
  const theme = useTheme();
  const snackBarMessage = useContext(Message);
  const EditContext = useContext(EditInfo);
  const [openDialog, setOpenDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [workStandardHistoryDetailListDisplay, setWorkStandardHistoryDetailListDisplay] = useState(new WorkStandardHistoryDetailListDisplay());
  const [workStandardHistoryDetailDisplay, setWorkStandardHistoryDetailDisplay] = useState(new WorkStandardHistoryDetailDisplay());
  const [historyDetail, getHistoryDetail, resetHistoryDetail, mutateHistoryDetail, historyDetailError] = useHistoryDetail();

  const handleDialogClose = () => {
    setWorkStandardHistoryDetailDisplay(new WorkStandardHistoryDetailDisplay());
    setOpenDialog(false);
    setIsEdit(false);
    mutateHistoryDetail();
  };

  const handleDelete = (workStandardHistoryDetailID: number) => {
    let result: boolean = window.confirm(DialogMessages.DELETE_WORK_STANDARD_HISTORY_DETAIL);
    if (result) {
      deleteHistoryDetail(String(workStandardHistoryDetailID)).then(
        () => {
          mutateHistoryDetail();
          snackBarMessage.setMessage(SuccessMessages.DELETE_HISTORY_DETAIL, "success");
        },
        (error) => {
          console.log(error);
          snackBarMessage.setMessage(ErrorMessages.DELETE_HISTORY_DETAIL, "error");
        }
      );
    }
  };

  useEffect(() => {
    getHistoryDetail(String(props.workStandardId));
  }, []);

  useEffect(() => {
    getHistoryDetail(String(props.workStandardId));
  }, [props.workStandardId]);

  useEffect(() => {
    if (!historyDetail || !historyDetail.work_standard_history_detail) return;
    setWorkStandardHistoryDetailListDisplay(workStandardHistoryDetailListDisplay?.setData(historyDetail).sortByAsc());
  }, [historyDetail]);

  useEffect(() => {
    if (historyDetailError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_HISTORY_DETAIL, "error");
    }
  }, [historyDetailError]);

  return (
    <>
      <HistoryDetailDialog
        workStandardId={props.workStandardId}
        workStandardHistoryDetail={workStandardHistoryDetailDisplay}
        open={openDialog}
        isEdit={isEdit}
        handleDialogClose={handleDialogClose}
      />
      <Grid container spacing={1} style={{ padding: theme.spacing(0, 1) }}>
        <Grid item xs={12} style={{ height: props.contentHeight }}>
          {/* 作業標準の改訂詳細履歴Table */}
          <TableContainer component={Paper} className={styles.historyContainer}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow className={styles.headerRow}>
                  <TableCell className={styles.cell5par} style={{ whiteSpace: "nowrap" }}>
                    {WorkStandardHistoryDetailTabLabel.HISTORY_DETAIL_TABLE_REVISE}
                  </TableCell>
                  <TableCell className={styles.cell10par} style={{ whiteSpace: "nowrap" }}>
                    {WorkStandardHistoryDetailTabLabel.TARGET}
                  </TableCell>
                  <TableCell className={styles.cell50par} style={{ whiteSpace: "nowrap" }}>
                    {WorkStandardHistoryDetailTabLabel.DETAIL}
                  </TableCell>
                  <TableCell className={styles.cell15par} style={{ whiteSpace: "nowrap" }}>
                    {WorkStandardHistoryDetailTabLabel.HISTORY_DETAIL_TABLE_UPDATED_AT}
                  </TableCell>
                  <TableCell className={styles.cell20par} style={{ whiteSpace: "nowrap" }}>
                    {WorkStandardHistoryDetailTabLabel.CHARGE_PERSON}
                  </TableCell>
                  {EditContext.editMode && props.workStandardRevision !== 1 && (
                    <>
                      <TableCell />
                      <TableCell align="right" style={{ whiteSpace: "nowrap" }}>
                        <IconButton
                          size="small"
                          onClick={() => {
                            setOpenDialog(true);
                          }}
                        >
                          <AddCircleIcon />
                        </IconButton>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {workStandardHistoryDetailListDisplay?.workStandardHistoryDetailList.map((row: WorkStandardHistoryDetailDisplay) => {
                  return (
                    <TableRow className={styles.bodyRow}>
                      <TableCell className={styles.cell5par}>{"Δ" + String(row.deltaNumber)}</TableCell>
                      <TableCell className={styles.cell10par}>{row.target}</TableCell>
                      <TableCell className={styles.cell50par} style={{ whiteSpace: "pre-wrap" }}>
                        {row.detail}
                      </TableCell>
                      <TableCell className={styles.cell15par}>{row.updatedAt}</TableCell>
                      <TableCell className={styles.cell20par}>{row.user}</TableCell>
                      {EditContext.editMode && (
                        <>
                          <TableCell align="center">
                            {props.workStandardRevision === row.deltaNumber + 1 && (
                              <>
                                <IconButton
                                  size="small"
                                  onClick={(e) => {
                                    setWorkStandardHistoryDetailDisplay(row);
                                    setOpenDialog(true);
                                    setIsEdit(true);
                                  }}
                                >
                                  <CreateIcon />
                                </IconButton>
                              </>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {props.workStandardRevision === row.deltaNumber + 1 && (
                              <>
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    handleDelete(row.id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </>
                            )}
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};
