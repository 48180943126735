import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import { DialogButtonLabel, WorkStandardPageLabel, WorkFlowButtonLabel } from "../../consts/label";
import { WorkStandardRejectMessageLimits } from "../../consts/inputLimits";

export interface Props {
  open: boolean;
  isInput?: boolean;
  rejectMessage:
    | {
        message: string;
        login_id: string;
        user_name: string;
      }
    | undefined;
  onChange?: (rejectMessage: string) => void;
  onClose: () => void;
  onReject?: () => void;
}

export const RejectMessageDialog = (props: Props) => {
  return (
    <Dialog open={props.open} fullWidth maxWidth={"sm"}>
      <DialogTitle>{WorkStandardPageLabel.REJECT_MESSAGE}</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          margin="normal"
          multiline
          label={WorkStandardPageLabel.REJECT_MESSAGE}
          value={props.rejectMessage?.message ?? ""}
          inputProps={{ maxLength: WorkStandardRejectMessageLimits.INPUT_MAX_LENGTH }}
          InputProps={{
            readOnly: !props.isInput,
          }}
          onChange={(e) => {
            props.onChange!(e.target.value);
          }}
        />
        <TextField
          disabled={props.isInput}
          fullWidth
          margin="normal"
          label={WorkStandardPageLabel.REJECTOR}
          value={`${props.rejectMessage?.user_name ?? ""} (${props.rejectMessage?.login_id ?? ""})`}
          InputProps={{
            readOnly: true,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={props.onClose}>
          {DialogButtonLabel.CLOSE}
        </Button>
        {props.isInput && (
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              props.onReject!();
            }}
          >
            {WorkFlowButtonLabel.REJECT}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
