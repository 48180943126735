import React, { useContext, useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import { WorkStandardNameLimits } from "../../consts/inputLimits";
import { putChangeWorkStandardName } from "../../apicaller/repository/operation";
import { ErrorMessages, SuccessMessages, WarningMessages } from "../../consts/messages";
import { Message } from "../../context/appContextData";
import { isLengthCorrect } from "../../function/validationCheck";
import { DialogButtonLabel, WorkStandardListPageLabel } from "../../consts/label";

export interface Props {
  open: boolean;
  workStandardId: number;
  workStandardName: string;
  onClose: () => void;
}

export const ChangeNameDialog = (props: Props) => {
  const snackBarMessage = useContext(Message);
  const [name, setName] = useState<string>(props.workStandardName);

  useEffect(() => {
    if (!props.open) return;
    setName(props.workStandardName);
  }, [props.workStandardName, props.open]);

  const handleClose = () => {
    props.onClose();
  };

  const handleChange = () => {
    if (!isLengthCorrect(name, WorkStandardNameLimits.INPUT_MIN_LENGTH, WorkStandardNameLimits.INPUT_MAX_LENGTH)) {
      snackBarMessage.setMessage(WarningMessages.CREATENEW_NAME_INPUT_LENGTH, "warning");
      return;
    }

    putChangeWorkStandardName({ operation_id: props.workStandardId, operation_name: name })
      .then(() => {
        snackBarMessage.setMessage(SuccessMessages.CHANGE_WORK_STANDARD_NAME, "success");
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        snackBarMessage.setMessage(ErrorMessages.CHANGE_WORK_STANDARD_NAME, "error");
      });
  };

  return (
    <Dialog fullWidth maxWidth={"sm"} open={props.open}>
      <DialogTitle>{WorkStandardListPageLabel.CHANGE_NAME_DIALOG_TITLE}</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          variant={"outlined"}
          label={WorkStandardListPageLabel.WORK_STANDARD_NAME}
          value={name}
          inputProps={{ maxLength: WorkStandardNameLimits.INPUT_MAX_LENGTH }}
          onChange={(e) => {
            setName(e.currentTarget.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button color={"secondary"} onClick={handleClose}>
          {DialogButtonLabel.CLOSE}
        </Button>
        <Button variant="contained" color={"secondary"} onClick={handleChange}>
          {WorkStandardListPageLabel.CHANGE_NAME_BUTTON_LABEL}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
