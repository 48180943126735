import { Card } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useContext } from "react";
import { Redirect } from "react-router-dom";
import CreateNewOperation from "../components/CreateNew/CreateNewOperation";
import { LoginInfo } from "../context/appContextData";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      // AppBar表示分の余白
      marginTop: 50,
      flexGrow: 1,
      height: 900,
    },
  })
);

export const OPERATION_NAME_MIN_LENGTH = 1;
export const OPERATION_NAME_MAX_LENGTH = 200;

const CreateNew = () => {
  const styles = useStyles();
  const LoginContext = useContext(LoginInfo);

  //権限
  if (LoginContext.loginUser.authority === 0) {
    return <Redirect to="/login" />;
  }

  return (
    <Card className={styles.container}>
      <CreateNewOperation />
    </Card>
  );
};

export default CreateNew;
