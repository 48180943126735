import { cloneDeep } from "lodash";
import {
  UserAllDataResponse,
  UserAllData,
  LineListResponse,
  Line,
  TeamList,
  TeamListResponse,
  UnitList,
  UnitListResponse,
} from "../../../apicaller/domain/response/datamanagement";
import { Role } from "../../../consts/role";

export interface UserInfoDisplayComboBox {
  text: string[];
}

export class LineDisplayComboBox implements UserInfoDisplayComboBox {
  text: string[] = [];

  public setData(data: LineListResponse) {
    this.text = data.line.map((e: Line) => e.line_name);
  }
}

export class UnitDisplayComboBox implements UserInfoDisplayComboBox {
  text: string[] = [];

  public setData(data: UnitListResponse) {
    this.text = data.unit.map((e: UnitList) => e.unit_name);
  }
}

export class TeamDisplayComboBox implements UserInfoDisplayComboBox {
  text: string[] = [];

  public setData(data: TeamListResponse) {
    this.text = data.team.map((e: TeamList) => e.team_name);
  }
}

export class UserAllDataDisplayRow {
  user_id: number = 0;
  id: string = "";
  name: string = "";
  authority: number = 0;
  enable: number = 0;
  module_role: string = "ASSOCIATE";
  unit_role: string = "ASSOCIATE";
  team_role: string = "ASSOCIATE";
}

export class UserAllDataDisplayRows {
  rows: UserAllDataDisplayRow[] = [];
  public setData = (data: UserAllDataResponse) => {
    this.rows = data.user.map((e: UserAllData) => {
      return {
        user_id: e.user_id,
        id: e.login_id,
        name: e.user_name,
        authority: e.authority,
        enable: e.enabled,
        module_role: e.module_role,
        unit_role: e.unit_role,
        team_role: e.team_role,
      };
    });
  };
}

export class SelectedUserInfo {
  user_id: number = 0;
  id: string = "";
  name: string = "";
  authority: number = 0;

  setData(user_id: number, id: string, name: string, authority: number) {
    this.user_id = user_id;
    this.id = id;
    this.name = name;
    this.authority = authority;

    return cloneDeep(this);
  }
}

export class SelectedUserAuthority {
  user_id: number = 0;
  id: string = "";
  name: string = "";
  authority: string = "";

  setData(user_id: number, id: string, name: string, authority: string) {
    this.user_id = user_id;
    this.id = id;
    this.name = name;
    this.authority = authority;

    return cloneDeep(this);
  }
}

export type SignUpUserInfo = {
  loginId: string;
  name: string;
  authority: number;
  password: string;
  moduleRole: ModuleRole[];
  unitRole: UnitRole[];
  teamRole: TeamRole[];
}

export type ModuleRole = {
  module_id: number;
  role: "ASSCOCIATE" | "MODULE_MANAGER";
};
export type UnitRole = {
  unit_id: number;
  role: "ASSCOCIATE" | "UNIT_LEADER";
};
export type TeamRole = {
  team_id: number;
  role: "ASSCOCIATE" | "TEAM_LEADER";
};
