import { Backdrop, CircularProgress } from '@material-ui/core'
import React from 'react'

interface Props {
    open: boolean,
    title: string,
}

const Loading = (props: Props) => {
  return (
    <Backdrop
    style={{
      zIndex: 2000,
      color: "#fff",
    }}
    open={props.open}
  >
    {props.title}
    <CircularProgress color="inherit" />
  </Backdrop>
  )
}

export default Loading