import { Card, Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import HistoryTable from "../Home/HistoryTable";
import CreaterTable from "../Home/CreaterTable";
import ManagedNumTable from "../Home/ManagedNumTable";
import ApproverTable from "../Home/ApproverTable";
import ConfirmerTable from "../Home/ConfirmerTable";
import { useApprover, useConfirmer, useHistory, useOperation, useOperationSignature } from "../../apicaller/repository/operation";
import OperatorSignatureTable from "../Home/OperatorSignatureTable";
import { SelectedConditions, SelectedOperationInfo } from "../../types/workStandard";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    managementNo: {
      height: "calc(50% - " + theme.spacing(1) / 2 + "px)",
      marginBottom: theme.spacing(1),
    },
    creater: {
      height: "calc(50% - " + theme.spacing(1) / 2 + "px)",
    },
  })
);

type Props = {
  contentHeight: number;
  selectedConditions: SelectedConditions;
  selectedWorkStandardInfo: SelectedOperationInfo;
  onClickWorkStandardHistory: (workStandardId: number) => void;
};

export const ManagementTab = (props: Props) => {
  const styles = useStyles();
  const theme = useTheme();

  const [workStandard, getWorkStandard, mutateWorkStandard, workStandardError] = useOperation();
  const [confirmerList, getConfirmerList, resetConfirmerList, mutateConfirmerList, confirmerListError] = useConfirmer();
  const [approverList, getApproverList, resetApproverList, mutateApproverList, approverListError] = useApprover();
  const [historyList, getHistoryList, resetHistoryList, mutateHistoryList, historyListError] = useHistory();
  const [operationSignatureList, getOperationSignatureList, resetOperationSignatureList, mutateOperationSignatureList, operationSignatureListError] =
    useOperationSignature();

  useEffect(() => {
    if (!props.selectedWorkStandardInfo?.operation_id) return;
    getWorkStandard(String(props.selectedWorkStandardInfo.operation_id));
    // eslint警告無視
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedWorkStandardInfo]);

  useEffect(() => {
    if (!workStandard?.operation_id) return;

    getHistoryList(String(workStandard.operation_id));
    getConfirmerList(String(workStandard.operation_id));
    getApproverList(String(workStandard.operation_id));
    getOperationSignatureList(String(workStandard.operation_id));
    mutateConfirmerList();
    mutateApproverList();
    mutateHistoryList();
    mutateOperationSignatureList();

    // eslint警告無視
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workStandard]);

  return (
    <Grid container spacing={1} style={{ padding: theme.spacing(0, 1) }}>
      <Grid item xs={8}>
        <Grid container spacing={1}>
          <Grid item xs={4} style={{ height: props.contentHeight * 0.4 }}>
            <Card className={styles.managementNo}>
              <ManagedNumTable managedNumber={workStandard?.managed_number} />
            </Card>
            <Card className={styles.creater}>
              <CreaterTable userName={workStandard?.user_name} loginId={workStandard?.login_id} />
            </Card>
          </Grid>
          <Grid item xs={4} style={{ height: props.contentHeight * 0.4 }}>
            <Card style={{ height: "100%" }}>
              <ApproverTable approverList={approverList} />
            </Card>
          </Grid>
          <Grid item xs={4} style={{ height: props.contentHeight * 0.4 }}>
            <Card style={{ height: "100%" }}>
              <ConfirmerTable confirmerList={confirmerList} />
            </Card>
          </Grid>
          <Grid item xs={12} style={{ height: props.contentHeight * 0.6 }}>
            <Card style={{ height: "100%" }}>
              <HistoryTable
                operationId={workStandard?.operation_id}
                historyList={historyList}
                mutateHistoryList={mutateHistoryList}
                onClickWorkStandardHistory={props.onClickWorkStandardHistory}
              />
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4} style={{ height: props.contentHeight }}>
        <Card style={{ height: "100%" }}>
          <OperatorSignatureTable operationSignatureList={operationSignatureList} />
        </Card>
      </Grid>
    </Grid>
  );
};
