import React, { useEffect, useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { OperationHashMasterList } from "../../apicaller/domain/response/operation";
import { OperationHashItemChipGroup } from "./display/home";
import { WorkStandardWorkTabLabel } from "../../consts/label";
import { hashMap } from "../../consts/hash";

export type HashDialogContent = {
  text: string;
  hashMasterID: number;
};

export interface Props {
  open: boolean;
  onClose: () => void;
  AddHandle: any;
  isProcessing: boolean;
  setIsProcessing: any;
  HashDialogContents: OperationHashMasterList;
  HashRegisteredList: OperationHashItemChipGroup;
}

export default function HashChipDialog(props: Props) {
  const { onClose, open, isProcessing, setIsProcessing } = props;

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (avatarSourceId: number) => {
    onClose();
    props.AddHandle(avatarSourceId);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-hash-dialog-title" open={open}>
      <DialogTitle id="simple-hash-dialog-title">{WorkStandardWorkTabLabel.SELECT_ADD_HASH}</DialogTitle>
      <List>
        {props.HashDialogContents.operation_hash &&
          props.HashDialogContents.operation_hash.map((HashDialogContent) => {
            var registered: boolean = false;
            for (var i = 0; i < props.HashRegisteredList.operation_hash_item_chip_group.length; i++) {
              if (
                props.HashRegisteredList.operation_hash_item_chip_group[i].operation_hash_master_id === HashDialogContent.operation_hash_master_id
              ) {
                registered = true;
              }
            }
            return (
              <ListItem
                button
                onClick={() => {
                  setIsProcessing(true);
                  handleListItemClick(HashDialogContent.operation_hash_master_id);
                }}
                key={HashDialogContent.content}
                disabled={isProcessing ? true : registered}
              >
                <ListItemText primary={hashMap.get(HashDialogContent.content)} />
              </ListItem>
            );
          })}
      </List>
    </Dialog>
  );
}
