import i18n from "i18next";

export const WorkStandardHistoryDetailTarget = {
  ILLUSTRATION: "図解",
  OPERATION_CARD: "作業詳細項目",
  PARTS_TABLE: "部品表",
  SAFETY_EQUIPMENT: "安全保護具",
  REQUIREMENT_NECESSITY: "管理項目",
  SAFETY_POINT: "安全ポイント",
};

const updateWorkStandardHistoryDetailTarget = () => {
  WorkStandardHistoryDetailTarget.ILLUSTRATION = i18n.t("work-standard-history-detail-tab-label.illustration-label");
  WorkStandardHistoryDetailTarget.OPERATION_CARD = i18n.t("work-standard-history-detail-tab-label.work-detail-label");
  WorkStandardHistoryDetailTarget.PARTS_TABLE = i18n.t("work-standard-history-detail-tab-label.parts-label");
  WorkStandardHistoryDetailTarget.SAFETY_EQUIPMENT = i18n.t("work-standard-history-detail-tab-label.safety-equipment-label");
  WorkStandardHistoryDetailTarget.REQUIREMENT_NECESSITY = i18n.t("work-standard-history-detail-tab-label.requirement-label");
  WorkStandardHistoryDetailTarget.SAFETY_POINT = i18n.t("work-standard-history-detail-tab-label.safety-point-label");
};

export const WorkStandardHistoryDetailTargetList = [
  { key: 1, value: "図解" },
  { key: 2, value: "作業詳細項目" },
  { key: 3, value: "部品表" },
  { key: 4, value: "安全保護具" },
  { key: 5, value: "管理項目" },
  { key: 6, value: "安全ポイント" },
];

const updateWorkStandardHistoryDetailTargetList = () => {
  WorkStandardHistoryDetailTargetList[0].value = i18n.t("work-standard-history-detail-tab-label.illustration-label");
  WorkStandardHistoryDetailTargetList[1].value = i18n.t("work-standard-history-detail-tab-label.work-detail-label");
  WorkStandardHistoryDetailTargetList[2].value = i18n.t("work-standard-history-detail-tab-label.parts-label");
  WorkStandardHistoryDetailTargetList[3].value = i18n.t("work-standard-history-detail-tab-label.safety-equipment-label");
  WorkStandardHistoryDetailTargetList[4].value = i18n.t("work-standard-history-detail-tab-label.requirement-label");
  WorkStandardHistoryDetailTargetList[5].value = i18n.t("work-standard-history-detail-tab-label.safety-point-label");
};

if (i18n.isInitialized) {
  updateWorkStandardHistoryDetailTargetList();
  updateWorkStandardHistoryDetailTarget();
}
i18n.on("languageChanged", () => {
  updateWorkStandardHistoryDetailTargetList();
  updateWorkStandardHistoryDetailTarget();
});
i18n.on("loaded", () => {
  updateWorkStandardHistoryDetailTargetList();
  updateWorkStandardHistoryDetailTarget();
});
