import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import ListBox, { ListOption } from "../Common/ListBox";
import { useTeam, useUnit } from "../../apicaller/repository/datamanagament";
import { ErrorMessages, SuccessMessages, WarningMessages } from "../../consts/messages";
import { Message } from "../../context/appContextData";
import { postMoveWorkStandard } from "../../apicaller/repository/workstandard";
import { DialogButtonLabel, Label, WorkStandardListPageLabel } from "../../consts/label";

export interface Props {
  open: boolean;
  workStandardId: number;
  module: ListOption;
  teamId: number;
  onClose: () => void;
}

const DEFAULT_SELECT_OBJ = { key: 0, value: "" };

export const MoveDialog = (props: Props) => {
  const [unitList, getUnitList, resetUnitList, mutateUnitList, unitError] = useUnit();
  const [teamList, getTeamList, resetTeamList, mutateTeamList, teamError] = useTeam();
  const [selectUnitItem, setSelectUnitItem] = useState(DEFAULT_SELECT_OBJ);
  const [selectTeamItem, setSelectTeamItem] = useState(DEFAULT_SELECT_OBJ);
  const snackBarMessage = useContext(Message);

  useEffect(() => {
    if (unitError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_UNIT, "error");
    }
    if (teamError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_TEAM, "error");
    }
  }, [unitError, teamError]);

  useEffect(() => {
    if (!props.module) return;
    if (!props.module.key) return;
    if (props.module.key === 0) return;
    getUnitList(String(props.module.key));
  }, [props.module, props.open]);

  useEffect(() => {
    resetTeamList();
    setSelectTeamItem(DEFAULT_SELECT_OBJ);

    if (!selectUnitItem) return;
    if (!selectUnitItem.key) return;
    if (selectUnitItem.key === 0) return;
    getTeamList(String(selectUnitItem.key));
  }, [selectUnitItem]);

  const displayUnitList: ListOption[] = useMemo(() => {
    if (!unitList) return [];
    if (!unitList.unit) return [];
    return unitList.unit.map((unit) => {
      return {
        key: unit.unit_id,
        value: unit.unit_name,
      };
    });
  }, [unitList]);

  const displayTeamList: ListOption[] = useMemo(() => {
    if (!teamList) return [];
    if (!teamList.team) return [];
    return teamList.team
      .filter((team) => team.team_id !== props.teamId)
      .map((team) => {
        return {
          key: team.team_id,
          value: team.team_name,
        };
      });
  }, [teamList]);

  const handleClose = () => {
    props.onClose();
    resetUnitList();
    resetTeamList();
    setSelectUnitItem(DEFAULT_SELECT_OBJ);
    setSelectTeamItem(DEFAULT_SELECT_OBJ);
  };

  const handleMove = () => {
    if (!selectUnitItem || selectUnitItem.key === 0) {
      snackBarMessage.setMessage(WarningMessages.SELECTED_UNIT, "warning");
      return;
    }
    if (!selectTeamItem || selectTeamItem.key === 0) {
      snackBarMessage.setMessage(WarningMessages.SELECTED_TEAM, "warning");
      return;
    }
    postMoveWorkStandard({ work_standard_id: props.workStandardId, team_id: selectTeamItem.key })
      .then(() => {
        snackBarMessage.setMessage(SuccessMessages.MOVE_WORK_STANDARD, "success");
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        snackBarMessage.setMessage(ErrorMessages.MOVE_WORK_STANDARD, "error");
      });
  };

  return (
    <Dialog fullWidth maxWidth={"sm"} open={props.open}>
      <DialogTitle>{WorkStandardListPageLabel.MOVE_DIALOG_TITLE}</DialogTitle>
      <DialogContent>
        <Typography>{WorkStandardListPageLabel.MOVE_TO}</Typography>
        <ListBox disable label={Label.MODULE} options={[props.module]} value={props.module} onChange={() => {}} />
        <ListBox label={Label.UNIT} options={displayUnitList} value={selectUnitItem} onChange={(e) => setSelectUnitItem(e)} />
        <ListBox label={Label.TEAM} options={displayTeamList} value={selectTeamItem} onChange={(e) => setSelectTeamItem(e)} />
      </DialogContent>
      <DialogActions>
        <Button color={"secondary"} onClick={handleClose}>
          {DialogButtonLabel.CLOSE}
        </Button>
        <Button variant="contained" color={"secondary"} onClick={handleMove}>
          {WorkStandardListPageLabel.MOVE_BUTTON_LABEL}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
