import { createStyles, makeStyles, Portal, Snackbar, Theme } from "@material-ui/core";
import { useContext } from "react";
import MuiAlert from "@material-ui/lab/Alert";
import { Message } from "../../context/appContextData";
import { ErrorMessages } from "../../consts/messages";
import React from "react";

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export type SnackMessage = {
  message: string;
  type: AlertType;
};

export type AlertType = "error" | "warning" | "info" | "success";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    snackBar: {
      height: "auto",
      lineHeight: "28px",
    },
    alert: {
      height: "100%",
      whiteSpace: "pre-line",
    },
  })
);

export function SnackbarMessage() {
  const classes = useStyles();
  const snackBarMessage = useContext(Message);

  return (
    <div className={classes.root}>
      <Portal>
        <Snackbar
          open={snackBarMessage.showAlert}
          onClose={snackBarMessage.closeMessage}
          className={classes.snackBar}
          autoHideDuration={
            snackBarMessage.message.type === "info" || snackBarMessage.message.type === "success" || snackBarMessage.message.type === "warning"
              ? 3000
              : null
          }
        >
          <Alert className={classes.alert} onClose={snackBarMessage.closeMessage} severity={snackBarMessage.message.type}>
            {snackBarMessage.message.message}
          </Alert>
        </Snackbar>
      </Portal>
    </div>
  );
}
