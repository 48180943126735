import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

interface Props {
  label: string;
  options: ListOption[];
  onChange: (value: ListOption) => void;
  value?: ListOption;
  disable?: boolean;
  disableClearable?: boolean;
}

export interface ListOption {
  key: number;
  value: string;
}

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: 5,
    },
  }),
);

export default function ListBox(props: Props) {
  const styles = useStyles();

  const onChange = (_: any, item: any) => {
    if (props.onChange !== undefined) {
      props.onChange(item);
    }
  };

  return (
    <div className={styles.root}>
      <Autocomplete
        disabled={props.disable}
        disableClearable={!!props.disableClearable}
        options={props.options}
        getOptionLabel={(option) => option.value}
        value={props.value}
        renderInput={(params) => <TextField {...params} placeholder={props.label} variant="standard" />}
        size="small"
        onChange={onChange}
      />
    </div>
  );
}
