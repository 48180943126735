import React, { ForwardedRef, forwardRef, useContext } from "react";
import clsx from "clsx";
import { createStyles, makeStyles, useTheme, Theme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListAltIcon from "@material-ui/icons/ListAlt";
import FiberNewIcon from "@material-ui/icons/FiberNew";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PeopleIcon from "@material-ui/icons/People";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Popover from "@material-ui/core/Popover";
import LoginUserCard from "../components/Appbar/LoginUserCard";
import { SnackbarMessage } from "../components/Common/SnackbarMessage";
import { EditInfo, LoginInfo, MiniDrawerInfo } from "../context/appContextData";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import { NavLink } from "react-router-dom";
import { Button } from "@material-ui/core";
import UserFeedbackCard from "../components/Appbar/UserFeedbackCard";
import { MenuBook } from "@material-ui/icons";
import { DeployTarget, DeployTargetWarningMessage } from "../consts/deployTarget";
import { purple } from "@material-ui/core/colors";
import { SideBarLabel } from "../consts/label";

const deployTarget = process.env.REACT_APP_DEPLOY_TARGET;
const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    icon: {
      marginRight: 5,
    },
    title: {
      flexGrow: 1,
      color: "white",
    },
    menuButton: {
      marginRight: 10,
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7) + 1,
      },
    },
    content: {
      marginLeft: 57,
    },
    typography: {
      padding: theme.spacing(1),
    },
    itemText: {
      fontSize: 14,
    },
    navLink: {
      textDecoration: "none",
      width: "100%",
      display: "block",
    },
    selectedNavLink: {
      backgroundColor: purple[100],
      textDecoration: "none",
      width: "100%",
      display: "block",
    },
    listItem: {
      "& > .MuiTouchRipple-root": {
        color: "white",
      },
      color: "black",
    }
  })
);

interface Props {
  pageComponents: any[];
  pageTitle: string;
}

export const MiniDrawer = forwardRef((props: Props, ref: ForwardedRef<HTMLDivElement>) => {
  const styles = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [userFeedbackAnchor, setUserFeedbackAnchor] = React.useState<HTMLButtonElement | null>(null);
  const popperOpen = Boolean(anchorEl);
  const userFeedbackPopperOpen = Boolean(userFeedbackAnchor);
  const EditContext = useContext(EditInfo);
  const LoginContext = useContext(LoginInfo);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handlePopperOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handlePopperClose = () => {
    setAnchorEl(null);
  };

  const openUserFeedbackPopperHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    setUserFeedbackAnchor(e.currentTarget);
  };

  const closeUserFeedbackPopperHandler = () => {
    setUserFeedbackAnchor(null);
  };

  const handlePageChange = () => {
    //編集モードの場合は編集モードをやめる
    if (EditContext.editMode) {
      EditContext.invert();
    }
  };

  const handleWarningMessage = () => {
    if (deployTarget === DeployTarget.LOCAL) {
      return DeployTargetWarningMessage.LOCAL;
    } else if (deployTarget === DeployTarget.IAC_DEV) {
      return DeployTargetWarningMessage.IAC_DEV;
    } else if (deployTarget === DeployTarget.IAC_PRD) {
      return DeployTargetWarningMessage.IAC_PRD;
    } else if (deployTarget === DeployTarget.FLEX_PRD) {
      return DeployTargetWarningMessage.FLEX_PRD;
    }
  };

  return (
    <>
      <CssBaseline />
      <AppBar
        color="primary"
        ref={ref}
        position="fixed"
        className={clsx(styles.appBar, {
          [styles.appBarShift]: open,
        })}
      >
        {/* 共通用エラーメッセージ */}
        <SnackbarMessage />
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(styles.menuButton, {
              [styles.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <DeviceHubIcon className={styles.icon} />
          <Typography className={styles.title} variant="h6" noWrap>
            {props.pageTitle + "　" + handleWarningMessage()}
          </Typography>
          {/* レイアウトを維持するためにコンポーネントごとにloginUserチェック */}
          {LoginContext.loginUser && (
            <div>
              <IconButton color="inherit" onClick={handlePopperOpen}>
                <AccountCircle />
              </IconButton>
              <Popover
                open={popperOpen}
                anchorEl={anchorEl}
                onClose={handlePopperClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <LoginUserCard />
              </Popover>
            </div>
          )}
          {/* レイアウトを維持するためにコンポーネントごとにloginUserチェック */}
          {LoginContext.loginUser && (
            <Typography className={styles.typography} noWrap>
              {LoginContext.loginUser.login_id}
            </Typography>
          )}
          {/* レイアウトを維持するためにコンポーネントごとにloginUserチェック */}
          {LoginContext.loginUser && (
            <Typography className={styles.typography} noWrap>
              {LoginContext.loginUser.user_name}
            </Typography>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(styles.drawer, {
          [styles.drawerOpen]: open,
          [styles.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [styles.drawerOpen]: open,
            [styles.drawerClose]: !open,
          }),
        }}
        open={open}
        onClose={handleDrawerClose}
      >
        <div>
          <Toolbar variant="dense">
            <IconButton color="inherit" onClick={handleDrawerClose} size="small">
              {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </Toolbar>
        </div>
        <div onClick={handleDrawerClose} onKeyDown={handleDrawerClose}>
          {LoginContext.loginUser && (
            // リストまでコマンドアウトしないとボタンの一番上に変な空白が出る
            <List>
              {/* <NavLink exact to="/" className={(isActive) => isActive ? styles.selectedNavLink : styles.navLink}>
              <ListItem className={styles.listItem} button onClick={handlePageChange}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <Typography className={styles.itemText}>作業標準</Typography>
              </ListItem>
            </NavLink> */}

              <NavLink
                exact
                to="/operationManagementBook"
                className={(isActive) => isActive ? styles.selectedNavLink : styles.navLink}
              >
                <ListItem className={styles.listItem} button onClick={handlePageChange}>
                  <ListItemIcon>
                    <ListAltIcon />
                  </ListItemIcon>
                  <Typography className={styles.itemText}>{SideBarLabel.MANAGEMENT_BOOK}</Typography>
                </ListItem>
              </NavLink>

              {/* 下記はユーザーフィードバック不要機能のため、サイドバーに表示しない */}
              {/* 改廃リスト、工程訓練リスト、作業確認リスト */}
              {/* <NavLink exact to="/deltaList" className={(isActive) => isActive ? styles.selectedNavLink : styles.navLink}>
              <ListItem className={styles.listItem} button onClick={handlePageChange} >
                <ListItemIcon>
                  <ViewListIcon />
                </ListItemIcon>
                <Typography className={styles.itemText}>改廃リスト</Typography>
              </ListItem>
            </NavLink>

            <NavLink exact to="/training" className={(isActive) => isActive ? styles.selectedNavLink : styles.navLink}>
              <ListItem className={styles.listItem} button onClick={handlePageChange} >
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <Typography className={styles.itemText}>工程訓練リスト</Typography>
              </ListItem>
            </NavLink>

            <NavLink exact to="/confirmer" className={(isActive) => isActive ? styles.selectedNavLink : styles.navLink}>
              <ListItem className={styles.listItem} button onClick={handlePageChange} >
                <ListItemIcon>
                  <HowToRegIcon />
                </ListItemIcon>
                <Typography className={styles.itemText}>作業確認リスト</Typography>
              </ListItem>
            </NavLink> */}
            </List>
          )}

          <Divider />

          {LoginContext.loginUser && LoginContext.loginUser.authority > 0 && (
            <List>
              <NavLink exact to="/createNew" className={(isActive) => isActive ? styles.selectedNavLink : styles.navLink}>
                <ListItem className={styles.listItem} button onClick={handlePageChange}>
                  <ListItemIcon>
                    <FiberNewIcon color={"inherit"} />
                  </ListItemIcon>
                  <Typography className={styles.itemText}>{SideBarLabel.CREATE_NEW}</Typography>
                </ListItem>
              </NavLink>

              <NavLink exact to="/operationList" className={(isActive) => isActive ? styles.selectedNavLink : styles.navLink}>
                <ListItem className={styles.listItem} button onClick={handlePageChange}>
                  <ListItemIcon>
                    <FindInPageIcon />
                  </ListItemIcon>
                  <Typography className={styles.itemText}>{SideBarLabel.OPERATION_LIST}</Typography>
                </ListItem>
              </NavLink>

              {/* ファイル管理はユーザーフィードバック不要機能のため、サイドバーに表示しない */}
              {/* <NavLink exact to="/fileUpload" className={(isActive) => isActive ? styles.selectedNavLink : styles.navLink}>
                <ListItem className={styles.listItem} button onClick={handlePageChange} >
                  <ListItemIcon>
                    <FileIcon />
                  </ListItemIcon>
                  <Typography className={styles.itemText}>ファイル管理</Typography>
                </ListItem>
              </NavLink> */}

              <NavLink exact to="/dataManagement" className={(isActive) => isActive ? styles.selectedNavLink : styles.navLink}>
                <ListItem className={styles.listItem} button onClick={handlePageChange}>
                  <ListItemIcon>
                    <DirectionsCarIcon />
                  </ListItemIcon>
                  <Typography className={styles.itemText}>{SideBarLabel.DATA_MANAGEMENT}</Typography>
                </ListItem>
              </NavLink>

              <NavLink exact to="/userList" className={(isActive) => isActive ? styles.selectedNavLink : styles.navLink}>
                <ListItem className={styles.listItem} button onClick={handlePageChange}>
                  <ListItemIcon>
                    <PeopleIcon />
                  </ListItemIcon>
                  <Typography className={styles.itemText}>{SideBarLabel.USER_LIST}</Typography>
                </ListItem>
              </NavLink>

              <Divider />
            </List>
          )}

          <NavLink exact to="/login" className={(isActive) => isActive ? styles.selectedNavLink : styles.navLink}>
            <ListItem className={styles.listItem} button onClick={handlePageChange}>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <Typography className={styles.itemText}>{SideBarLabel.LOGIN}</Typography>
            </ListItem>
          </NavLink>

          {LoginContext.loginUser && (
            <NavLink exact to="/instructions" className={(isActive) => isActive ? styles.selectedNavLink : styles.navLink}>
              <ListItem className={styles.listItem} button onClick={handlePageChange}>
                <ListItemIcon>
                  <MenuBook />
                </ListItemIcon>
                <Typography className={styles.itemText}>{SideBarLabel.INSTRUCTIONS}</Typography>
              </ListItem>
            </NavLink>
          )}
        </div>
      </Drawer>
      <main className={styles.content}>
        <MiniDrawerInfo.Provider value={{ open: open, handleDrawerClose: handleDrawerClose }}>
          {props.pageComponents.map((compo) => {
            return compo;
          })}
        </MiniDrawerInfo.Provider>
      </main>
    </>
  );
});
