import React, { useContext } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { EditInfo } from "../../context/appContextData";
import { Tooltip } from "@material-ui/core";
import { UnitProductionCapacityDisplayRows } from "../Common/display/display";
import Paper from "@material-ui/core/Paper";
import ListBox, { ListOption } from "../Common/ListBox";
import { DataManagementProductionCapacityTabLabel } from "../../consts/label";

interface Props {
  contentRows: UnitProductionCapacityDisplayRows;
  unitProductionCapacityChangeHandler: any;
  height: number;
}

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    container: {
      overflow: "auto",
      textAlign: "center",
    },
    nameRowDiv: {
      width: "150px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      verticalAlign: "middle",
      textAlign: "center",
      margin: "0 auto",
    },
  })
);

export default function UnitProductionCapacityTable(props: Props) {
  const styles = useStyles();
  const EditContext = useContext(EditInfo);

  const unitProductionCapacityChangeHandler = (selected: ListOption, current: ListOption) => {
    if (selected.key === current.key) {
      return;
    }
    props.unitProductionCapacityChangeHandler(selected);
  };

  return (
    <TableContainer component={Paper} className={styles.container} style={{ height: props.height }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell key={1} align="center">
              {DataManagementProductionCapacityTabLabel.NUMBER}
            </TableCell>
            <TableCell key={2} align="center">
              {DataManagementProductionCapacityTabLabel.UNIT}
            </TableCell>
            <TableCell key={3} align="center">
              {DataManagementProductionCapacityTabLabel.CURRENT_PRODUCTION_CAPACITY}
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {props.contentRows.row.map((row, rowindex) => {
            return (
              <TableRow key={rowindex}>
                <TableCell align="center">{rowindex + 1}</TableCell>

                <TableCell align="center">
                  <Tooltip title={row.unitName}>
                    <div className={styles.nameRowDiv}>{row.unitName}</div>
                  </Tooltip>
                </TableCell>

                <TableCell align="center">
                  {EditContext.editMode ? (
                    <ListBox
                      label={""}
                      options={row.productionCapacityList}
                      value={
                        row.currentProductionCapacity.key === 0
                          ? { key: 0, value: DataManagementProductionCapacityTabLabel.NO_CURRENT_PRODUCTION_CAPACITY }
                          : row.currentProductionCapacity
                      }
                      onChange={(e) => unitProductionCapacityChangeHandler(e, row.currentProductionCapacity)}
                    />
                  ) : (
                    <>{row.currentProductionCapacity.key === 0 ? DataManagementProductionCapacityTabLabel.NO_CURRENT_PRODUCTION_CAPACITY : row.currentProductionCapacity.value}</>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
