import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

interface Props {
  open: boolean;
  title: string;
  description: string;
  okButtonLabel?: string;
  cancelButtonLabel?: string;
  onOk: () => void;
  onCancel: () => void;
}

export const OkCancelDialog = ({ open, title, description, okButtonLabel = "OK", cancelButtonLabel = "キャンセル", onOk, onCancel }: Props) => {
  return (
    <Dialog open={open} onClose={onCancel} aria-labelledby="ok-cancel-dialog-title" aria-describedby="ok-cancel-dialog-description">
      <DialogTitle id="ok-cancel-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="ok-cancel-dialog-description">{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} autoFocus>
          {cancelButtonLabel}
        </Button>
        <Button onClick={onOk} color={"secondary"}>
          {okButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
