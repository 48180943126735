import React, { useContext } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Tooltip } from "@material-ui/core";
import { EditInfo, LoginInfo } from "../../context/appContextData";
import { OperationHistoryDisplayGroup } from "./display/home";
import { DialogMessages } from "../../consts/messages";
import { HistoryList } from "../../apicaller/domain/response/operation";
import { sortByDesc } from "../../function/sort";
import { revision } from "../../consts/firstRevision";
import { purple } from "@material-ui/core/colors";
import { WorkStandardManagementTabLabel } from "../../consts/label";

interface Props {
  operationId: number;
  historyList: HistoryList;
  mutateHistoryList: () => void;
  onClickWorkStandardHistory: (workStandardId: number) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      overflow: "auto",
      textAlign: "center",
      height: "100%",
    },
    blankStyle: {},
    tableRow: {
      "&&:hover": {
        backgroundColor: purple[50],
      },
      cursor: "pointer",
    },
    currentRevisionTableRow: {
      backgroundColor: purple[50],
      "& > .MuiTableCell-root": {
        fontWeight: "bold",
      },
    },
    // header style
    headerRevisionCell: {
      width: "5%",
    },
    headerDateCell: {
      width: "20%",
    },
    headerTitleCell: {
      width: "20%",
    },
    headerDetailCell: {
      width: "25%",
    },
    headerApproverCell: {
      width: "15%",
    },
    headerAuthorCell: {
      width: "15%",
    },
    // body style
    bodyRevisionCell: {
      width: "5%",
    },
    bodyDateCell: {
      width: "20%",
    },
    bodyTitleCell: {
      width: "20%",
    },
    bodyDetailCell: {
      width: "25%",
    },
    bodyApproverCell: {
      width: "15%",
    },
    bodyAuthorCell: {
      width: "15%",
    },
    cellDiv: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  })
);

export default function HistoryTable(props: Props) {
  const styles = useStyles();
  const EditContext = useContext(EditInfo);
  const LoginContext = useContext(LoginInfo);

  // 履歴テーブルの行をクリックした時の遷移処理
  const showPageHandler = (operationId: string) => {
    // 編集モードがオンの場合は遷移しない
    if (EditContext.editMode) return;

    // 現在と同じ作業標準の場合は遷移しない
    if (operationId === String(props.operationId)) return;

    // ログインユーザーの権限をチェックし、一般権限の場合はメッセージを表示してページ遷移しない。
    if (LoginContext.loginUser.authority === 0) {
      window.alert(DialogMessages.NOT_ALLOWED_SHOW_OLD_OPERATION);
      return;
    }

    // クリックしたページを表示する。
    props.onClickWorkStandardHistory(Number(operationId));
  };

  // change in SWR data(sort for display history)
  const historyDisplay = new OperationHistoryDisplayGroup();
  if (props.historyList?.operation_history_list) {
    let sorted = new HistoryList();
    sorted.operation_history_list = sortByDesc(props.historyList.operation_history_list, "created_at");
    historyDisplay.setData(sorted);
  }

  return (
    <TableContainer className={styles.container}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell align={"right"} className={styles.headerRevisionCell}>
              {WorkStandardManagementTabLabel.REVISION}
            </TableCell>
            <TableCell align={"left"} className={styles.headerDateCell}>
              {WorkStandardManagementTabLabel.CREATED_AT}
            </TableCell>
            <TableCell align={"left"} className={styles.headerTitleCell}>
              {WorkStandardManagementTabLabel.OVERVIEW}
            </TableCell>
            <TableCell align={"left"} className={styles.headerDetailCell}>
              {WorkStandardManagementTabLabel.DETAIL}
            </TableCell>
            <TableCell align={"left"} className={styles.headerApproverCell}>
              {WorkStandardManagementTabLabel.APPROVER}
            </TableCell>
            <TableCell align={"left"} className={styles.headerAuthorCell}>
              {WorkStandardManagementTabLabel.CREATOR}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {historyDisplay.operationHistorys.map((historyData, index) => {
            let style = styles.tableRow;
            if (EditContext.editMode) {
              style = styles.blankStyle;
            } else if (props.operationId === Number(historyData.operation_id)) {
              style = styles.currentRevisionTableRow;
            }
            return (
              <TableRow className={style} key={index} onClick={() => showPageHandler(historyData.operation_id)}>
                <TableCell align={"right"} className={styles.bodyRevisionCell}>
                  <div className={styles.cellDiv}>{historyData.revision_number}</div>
                </TableCell>
                <TableCell align={"left"} className={styles.bodyDateCell}>
                  <div className={styles.cellDiv}>{historyData.date}</div>
                </TableCell>
                <TableCell align={"left"} className={styles.bodyTitleCell}>
                  <Tooltip title={historyData.title} placement={"bottom-start"}>
                    <div className={styles.cellDiv}>{historyData.title}</div>
                  </Tooltip>
                </TableCell>
                <TableCell align={"left"} className={styles.bodyDetailCell}>
                  {historyData.revision_number === "1" ? (
                    <div className={styles.cellDiv}>{"-"}</div>
                  ) : (
                    <Tooltip title={historyData.detail} placement={"bottom-start"}>
                      <div className={styles.cellDiv}>{historyData.detail}</div>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell align={"left"} className={styles.bodyApproverCell}>
                  {historyData.revision_number === String(revision.firstRevision) ? (
                    <div className={styles.cellDiv}>{"-"}</div>
                  ) : (
                    <Tooltip title={historyData.approver_name + " (" + historyData.approver_login_id + ")"} placement={"bottom-start"}>
                      <div className={styles.cellDiv}>
                        {historyData.approver_name === "" ? "" : historyData.approver_name + " (" + historyData.approver_login_id + ")"}
                      </div>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell align={"left"} className={styles.bodyAuthorCell}>
                  <Tooltip title={historyData.author_name + " (" + historyData.author_login_id + ")"} placement={"bottom-start"}>
                    <div className={styles.cellDiv}>
                      {historyData.author_name === "" ? "" : historyData.author_name + " (" + historyData.author_login_id + ")"}
                    </div>
                  </Tooltip>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
