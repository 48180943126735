import React from "react";
import { createStyles, makeStyles, TextField, Theme } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { isOnlyHalfNumber } from "../../function/validationCheck";
import { DATA_MANAGEMENT_TITLE_MAX_LENGTH } from "../../pages/DataManagement";
import { LoginInfo, Message } from "../../context/appContextData";
import { WarningMessages } from "../../consts/messages";
import { useContext } from "react";
import { grey } from "@material-ui/core/colors";
import { DataManagementPageLabel, DataManagementType, DialogButtonLabel } from "../../consts/label";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    submit: {
      backgroundColor: theme.palette.secondary.main,
      color: "white",
      textAlign: "center",
      padding: 7,
    },
    cancel: {
      backgroundColor: grey[300],
      textAlign: "center",
      padding: 7,
    },
  })
);

export enum DataManagementDialogAction {
  create,
  update,
  cancel,
}

export type DataManagementDialogTarget = {
  id: number;
  isAdd: boolean;
  name: string;
  type: string;
  createdAt: string;
  updatedAt: string;
};

export interface Props {
  open: boolean;
  target: DataManagementDialogTarget;
  dialogActionsHandler: (title: string, action: DataManagementDialogAction) => void;
  changeTitleHandler: any;
}

export default function DataManagementDialog(props: Props) {
  const styles = useStyles();
  const LoginContext = useContext(LoginInfo);
  const snackBarMessage = useContext(Message);

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={props.open}>
      {/* ダイアログタイトル */}
      <DialogTitle style={{ textAlign: "center" }} id="simple-dialog-title">
        {props.target.type}
      </DialogTitle>
      <List>
        {/* 名前表示 */}
        <ListItem>
          <TextField
            size="small"
            value={props.target.name}
            label={props.target.type}
            onChange={(e) => {
              if (props.target.type === DataManagementType.ProductionCapacity) {
                if (!isOnlyHalfNumber(e.currentTarget.value)) {
                  snackBarMessage.setMessage(WarningMessages.NOT_ONLY_HALF_NUMBER, "warning");
                  return;
                }

                if (e.currentTarget.value.length > 5) {
                  snackBarMessage.setMessage(WarningMessages.DATAMANAGEMENT_VOLUME_MAX_COUNT, "warning");
                  return;
                }
              }

              props.changeTitleHandler(e);
            }}
            inputProps={{ maxLength: DATA_MANAGEMENT_TITLE_MAX_LENGTH }}
          />
        </ListItem>
        {/* 登録者 */}
        <ListItem>
          <TextField disabled size="small" defaultValue={LoginContext.loginUser.user_name} label={DataManagementPageLabel.REGISTER} />
        </ListItem>
        {/* 登録日 */}
        {!props.target.isAdd && (
          <ListItem>
            <TextField
              disabled
              size="small"
              defaultValue={props.target.createdAt.substring(0, 10) + " " + props.target.createdAt.substring(11, 19)}
              label={DataManagementPageLabel.CREATE_DATE_TIME}
            />
          </ListItem>
        )}
        {/* 最終更新日 */}
        {!props.target.isAdd && (
          <ListItem>
            <TextField
              disabled
              size="small"
              defaultValue={props.target.updatedAt.substring(0, 10) + " " + props.target.updatedAt.substring(11, 19)}
              label={DataManagementPageLabel.UPDATE_DATE_TIME}
            />
          </ListItem>
        )}
        {/* 登録ボタン */}
        <ListItem
          button
          onClick={() => {
            if (props.target.isAdd) {
              props.dialogActionsHandler(props.target.name, DataManagementDialogAction.create);
            } else {
              props.dialogActionsHandler(props.target.name, DataManagementDialogAction.update);
            }
          }}
        >
          <ListItemText className={styles.submit} primary={DialogButtonLabel.REGIST} />
        </ListItem>
        {/* キャンセルボタン */}
        <ListItem
          button
          onClick={() => {
            props.dialogActionsHandler(props.target.name, DataManagementDialogAction.cancel);
          }}
        >
          <ListItemText className={styles.cancel} primary={DialogButtonLabel.CANCEL} />
        </ListItem>
      </List>
    </Dialog>
  );
}
