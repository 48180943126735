import React, { useContext, useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CreateIcon from "@material-ui/icons/Create";
import { LoginInfo } from "../../context/appContextData";
import { IconButton } from "@material-ui/core";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import HistoryIcon from "@material-ui/icons/History";
import { DialogAction, UserSettingDialog } from "./UserSettingDialog";
import { putUserDelete, useAllUser, useSectionList, useUserRole } from "../../apicaller/repository/datamanagament";
import { putUserAttachMaster } from "../../apicaller/repository/authentication";
import { UserAttachMasterUpdateRequest } from "../../apicaller/domain/request/authentication";
import { SelectedUserInfo, UserAllDataDisplayRows } from "./display/userList";
import PersonIcon from "@material-ui/icons/Person";
import ReportIcon from "@material-ui/icons/Report";
import { Message, WindowSize } from "../../context/appContextData";
import { DialogMessages, ErrorMessages, SuccessMessages, WarningMessages } from "../../consts/messages";
import { sortByAsc, sortByDesc } from "../../function/sort";
import { UserAllDataResponse } from "../../apicaller/domain/response/datamanagement";
import { isLengthCorrect } from "../../function/validationCheck";
import { UserInfoLimits } from "../../consts/inputLimits";
import Paper from "@material-ui/core/Paper";
import { UserDeleteRequest } from "../../apicaller/domain/request/datamanagement";
import UserSignUpDialog from "./UserSignUpDialog";
import { UserHistoryDialog } from "./UserHistoryDialog";
import { Authority } from "../../consts/authority";
import { AuthorityLabel, UserListPageLabel } from "../../consts/label";

interface Props {
  factory_id: number;
  plant_id: number;
  module_id: number;
  unit_id: number;
  team_id: number;
  open_dialog: boolean;
  set_open_dialog: any;
  display_role_list: any;
  set_display_role_list: any;
  select_user: any;
  set_select_user: any;
  usr_pass: string;
  set_usr_pass: any;
  authority: string;
  set_authority: any;
  openSignUpDialog: boolean;
  setOpenSignUpDialog: any;
  showEditButtons: boolean;
}

type headerText = {
  headerName: string;
  headerWidth: string;
};

// 権限
export const AUTHORITY_VALUE = {
  General: 0,
  Editor: 1,
  Manager: 2,
} as const;





const UserListTable = (props: Props) => {
  const LoginContext = useContext(LoginInfo);
  const [userList, getUserList, mutateUserList, userError] = useAllUser();
  const [useUserRoleList, getUserRoleList, mutateUserRoleList, userRoleError] = useUserRole();
  const [sectionList, getSectionList, resetSectionList, mutateSectionList, sectionError] = useSectionList();

  const [openHistoryDialog, setOpenHistoryDialog] = useState<boolean>(false);
  const [historyUserId, setHistoryUserId] = useState<number>(0);
  const [requestAuthority, setRequestAuthority] = useState<string>("");
  const [headerUser, setHeaderUser] = useState<headerText[]>([
    {
      headerName: UserListPageLabel.LOGIN_ID,
      headerWidth: "20%",
    },
    {
      headerName: UserListPageLabel.USER_NAME,
      headerWidth: "20%",
    },
    {
      headerName: UserListPageLabel.AUTHORITY,
      headerWidth: "10%",
    },
    {
      headerName: UserListPageLabel.MODULE_ROLE,
      headerWidth: "10%",
    },
    {
      headerName: UserListPageLabel.UNIT_ROLE,
      headerWidth: "10%",
    },
    {
      headerName: UserListPageLabel.TEAM_ROLE,
      headerWidth: "10%",
    },
    {
      headerName: UserListPageLabel.SUSPENSION,
      headerWidth: "5%",
    },
    {
      headerName: UserListPageLabel.HISTORY,
      headerWidth: "5%",
    },
    {
      headerName: UserListPageLabel.EDIT,
      headerWidth: "5%",
    },
    {
      headerName: UserListPageLabel.DELETE,
      headerWidth: "5%",
    },
  ]);

  const [AUTHORITY, setAUTHORITY] = useState([
    { id: AUTHORITY_VALUE.General, name: AuthorityLabel.GENERAL },
    { id: AUTHORITY_VALUE.Editor, name: AuthorityLabel.EDITOR },
    { id: AUTHORITY_VALUE.Manager, name: AuthorityLabel.ADMIN },
  ]);

  const snackBarMessage = useContext(Message);
  const windowSizeContext = useContext(WindowSize);

  // #region ---ユーザー名称、ID、所属情報変更Dialog---
  // ユーザー情報変更(鉛筆)ボタン押下時
  const handleUserEdit = (target: SelectedUserInfo) => {
    props.set_select_user(target);
    getUserRoleList(target.user_id);
    if (target.authority === 0) {
      props.set_authority(AuthorityLabel.GENERAL);
    } else if (target.authority === 1) {
      props.set_authority(AuthorityLabel.EDITOR);
    } else if (target.authority === 2) {
      props.set_authority(AuthorityLabel.ADMIN);
    }
    props.set_open_dialog(true);
  };

  // ユーザー情報(TextField,ComboBox)変更時
  const editSelectUser = (target: SelectedUserInfo) => {
    props.set_select_user(target);
  };

  // ユーザー情報(TextField,ComboBox)変更時
  const editSelectRoles = (target: any) => {
    props.set_display_role_list(target);
  };

  // ユーザー名称、ID、所属情報変更Dialogでのアクション(押下ボタン)に応じた処理
  const DialogActionHandle = (action: DialogAction) => {
    // 「登録」
    if (action === DialogAction.update) {
      putUser(props.select_user);
    }
    // 「削除」
    else if (action === DialogAction.delete) {
    }
    // 「キャンセル」
    else if (action === DialogAction.cancel) {
      props.set_open_dialog(false);
    }
  };

  // ユーザー名称、ID、所属情報変更時
  const putUser = (target: SelectedUserInfo) => {
    let row = new UserAttachMasterUpdateRequest(target);
    if (!isLengthCorrect(row.login_id, UserInfoLimits.ID_MIN_LENGTH, UserInfoLimits.ID_MAX_LENGTH)) {
      snackBarMessage.setMessage(WarningMessages.LOGIN_ID_INPUT_LENGTH, "warning");
      return;
    }
    if (!isLengthCorrect(row.user_name, UserInfoLimits.NAME_MIN_LENGTH, UserInfoLimits.NAME_MAX_LENGTH)) {
      snackBarMessage.setMessage(WarningMessages.LOGIN_NAME_INPUT_LENGTH, "warning");
      return;
    }

    putUserAttachMaster(row).then(
      () => {
        LoginContext.getLoginUser();
        mutateUserList();
        snackBarMessage.setMessage(SuccessMessages.UPDATE_ATTACH, "success");
      },
      (error: any) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.UPDATE_ATTACH, "error");
      }
    );
  };

  //ユーザー削除ボタンを押されたときの処理
  const userDeleteHandeler = (user_id: number) => {
    let result = window.confirm(DialogMessages.DELETE_USER);
    if (!result) {
      return;
    }

    const body = new UserDeleteRequest(user_id);

    putUserDelete(body).then(
      () => {
        snackBarMessage.setMessage(SuccessMessages.DELETE_USER, "success");
        mutateUserList();
      },
      (error: any) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.DELETE_USER, "error");
      }
    );
  };
  // #endregion

  // #region ユーザー登録
  const SignUpDialogActionHandle = (action: DialogAction) => {
    // 「登録」
    if (action === DialogAction.update) {
    }
    // 「削除」
    else if (action === DialogAction.delete) {
    }
    // 「キャンセル」
    else if (action === DialogAction.cancel) {
      props.setOpenSignUpDialog(false);
    }
    mutateUserList();
  };
  // #endregion

  // 権限変換(権限値を画面表示用文字列に変換)
  function GetAuthText(authNum: number) {
    var authText: string = "";

    for (let i = 0; i < AUTHORITY.length; i++) {
      if (authNum === AUTHORITY[i].id) {
        authText = AUTHORITY[i].name;
        break;
      }
    }

    return authText;
  }

  useEffect(() => {
    getUserList(props.factory_id, props.plant_id, props.module_id, props.unit_id, props.team_id);

    if (userList) {
      if (userList.user !== undefined) {
        let sorted = new UserAllDataResponse();
        sorted.user = sortByAsc(userList.user, "login_id");
        if (sorted.user !== undefined) userListDisplayRows.setData(sorted);
      }
    }
  }, [props.factory_id, props.plant_id, props.module_id, props.unit_id, props.team_id]);

  // ユーザーカード変更時の画面最新化
  useEffect(() => {
    mutateUserList();
  }, [LoginContext.loginUser]);

  // ユーザーカード変更時の画面最新化
  useEffect(() => {
    mutateSectionList();
  }, [LoginContext.loginUser]);

  useEffect(() => {
    props.set_display_role_list(useUserRoleList);
  }, [useUserRoleList, props.display_role_list]);

  // SWR error
  useEffect(() => {
    if (userError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_USER, "error");
    }
    if (userRoleError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_ROLE, "error");
    }
    if (sectionError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_SECTION_LIST, "error");
    }
  }, [userError, userRoleError, sectionError]);

  // sort for display user
  let userListDisplayRows = new UserAllDataDisplayRows();
  if (userList) {
    if (userList.user !== undefined) {
      let sorted = new UserAllDataResponse();
      sorted.user = sortByAsc(userList.user, "login_id");
      sorted.user = sortByDesc(userList.user, "enabled");
      if (sorted.user !== undefined) userListDisplayRows.setData(sorted);
    }
  }

  return (
    <div>
      <UserHistoryDialog
        open={openHistoryDialog}
        userId={historyUserId}
        onClose={() => {
          setOpenHistoryDialog(false);
        }}
      />
      <UserSettingDialog
        open={props.open_dialog}
        target={props.select_user}
        editSelectUser={editSelectUser}
        editSelectRoles={editSelectRoles}
        handleDialogAction={DialogActionHandle}
        displayRoleList={props.display_role_list}
        setDisplayRoleList={props.set_display_role_list}
        usrPass={props.usr_pass}
        setUserPW={props.set_usr_pass}
        authority={props.authority}
        setAuthority={props.set_authority}
        putUser={putUser}
        useUserRoleList={useUserRoleList}
        getUserRoleList={getUserRoleList}
        getSectionList={getSectionList}
        sectionList={sectionList}
        mutateUserList={mutateUserList}
        mutateUserRoleList={mutateUserRoleList}
        mutateSectionList={mutateSectionList}
      />
      <UserSignUpDialog open={props.openSignUpDialog} handleDialogAction={SignUpDialogActionHandle} />

      <TableContainer
        component={Paper}
        style={{
          height: windowSizeContext.height - 112,
          minHeight: "100px",
          overflow: "auto",
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headerUser.map((h, index) => {
                if (!props.showEditButtons && (h.headerName === UserListPageLabel.EDIT || h.headerName === UserListPageLabel.DELETE)) {
                  return <></>;
                }
                if (
                  LoginContext.loginUser.authority !== Authority.ADMIN &&
                  (h.headerName === UserListPageLabel.SUSPENSION || h.headerName === UserListPageLabel.HISTORY)
                ) {
                  return <></>;
                }
                return (
                  <TableCell style={{ width: h.headerWidth, whiteSpace: "nowrap" }} key={index} align="center">
                    <div>{h.headerName}</div>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {userListDisplayRows.rows.map((row, index) => {
              if (LoginContext.loginUser.authority === Authority.ADMIN || (LoginContext.loginUser.authority === Authority.EDITOR && row.enable)) {
                return (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row" align="center">
                      {row.id}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {row.name}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {GetAuthText(Number(row.authority))}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {row.module_role === "MODULE_MANAGER" ? <PersonIcon /> : row.module_role === null ? <ReportIcon color="error" /> : ""}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {row.unit_role === "UNIT_LEADER" ? <PersonIcon /> : row.unit_role === null ? <ReportIcon color="error" /> : ""}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {row.team_role === "TEAM_LEADER" ? <PersonIcon /> : row.team_role === null ? <ReportIcon color="error" /> : ""}
                    </TableCell>
                    {LoginContext.loginUser.authority === Authority.ADMIN && (
                      <TableCell component="th" scope="row" align="center">
                        {row.enable ? "" : UserListPageLabel.SUSPENSION}
                      </TableCell>
                    )}
                    {LoginContext.loginUser.authority === Authority.ADMIN && (
                      <TableCell align="center">
                        <IconButton
                          size="small"
                          onClick={() => {
                            setHistoryUserId(row.user_id);
                            setOpenHistoryDialog(true);
                          }}
                        >
                          <HistoryIcon />
                        </IconButton>
                      </TableCell>
                    )}
                    {props.showEditButtons && (
                      <>
                        <TableCell align="center">
                          {row.enable === 1 && (
                            <IconButton
                              size="small"
                              onClick={() => {
                                handleUserEdit(new SelectedUserInfo().setData(row.user_id, row.id, row.name, row.authority));
                                getSectionList(String(row.user_id));
                              }}
                            >
                              <CreateIcon />
                            </IconButton>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {row.enable === 1 && (
                            <IconButton
                              size="small"
                              onClick={() => {
                                userDeleteHandeler(row.user_id);
                              }}
                            >
                              <RemoveCircleOutlineIcon color="error" />
                            </IconButton>
                          )}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                );
              } else {
                return <></>;
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default UserListTable;
