import React, { useContext, useState } from "react";
import Chip from "@material-ui/core/Chip";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { EditInfo } from "../../context/appContextData";
import { WorkStandardSafetyPointLimits } from "../../consts/inputLimits";

interface Props {
  chipContent: string;
  handleDelete: any;
  handleChange: any;
  handleUpdate: any;
  id: number;
  isError: Boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexdirection: "row",
      padding: 5,
      scrollSnapAlign: "center",
    },
    chip: {
      fontSize: 18,
      borderRadius: 5,
      height: "100%",
      minHeight: 35,
      minWidth: 70,
      "& .MuiChip-label": {
        // wordWrap: "break-word",
        overflowWrap: "anywhere",
        whiteSpace: "normal",
        textOverflow: "clip",
        width: "100%",
      },
    },
    chiperror: {
      fontSize: 18,
      borderRadius: 5,
      height: "100%",
      minHeight: 35,
      minWidth: 70,
      backgroundColor: "#F75D59",
      "& .MuiChip-label": {
        // wordWrap: "break-word",
        overflowWrap: "anywhere",
        whiteSpace: "normal",
        textOverflow: "clip",
        width: "100%",
      },
    },

    input: {
      width: 300,
    },
  })
);

export const BasicChip = (props: Props) => {
  const styles = useStyles();
  const [onEdit, setOnEdit] = useState(false);
  const EditContext = useContext(EditInfo);

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    props.handleChange(props.id, e);
  };

  const handleOnBlur = (e: React.FormEvent<HTMLInputElement>) => {
    props.handleUpdate(props.id, e);
    setOnEdit(false);
  };

  const handleOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      if (!e.nativeEvent.isComposing) {
        props.handleUpdate(props.id, e);
        setOnEdit(false);
      }
    }
    props.handleChange(props.id, e);
  };

  const voiceFunc = (readingMsg: string) => {
    var msg = new SpeechSynthesisUtterance();
    msg.text = readingMsg;
    msg.lang = "ja-JP";
    window.speechSynthesis.speak(msg);
  };

  return (
    <div className={styles.root}>
      {EditContext.editMode ? (
        <Chip
          className={props.isError ? styles.chiperror : styles.chip}
          label={
            onEdit ? (
              <input
                className={styles.input}
                type="text"
                maxLength={WorkStandardSafetyPointLimits.INPUT_MAX_LENGTH}
                value={props.chipContent}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onKeyPress={handleOnKeyPress}
                autoFocus={true}
              />
            ) : (
              <div>{props.chipContent}</div>
            )
          }
          onDelete={() => props.handleDelete(props.id)}
          onClick={() => setOnEdit(true)}
        />
      ) : (
        <Chip className={styles.chip} label={props.chipContent} onClick={() => voiceFunc(props.chipContent)} />
      )}
    </div>
  );
};

export default BasicChip;
