import React, { useContext, useState } from "react";
import { Grid, TextField, Typography, Button } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { ErrorMessages, SuccessMessages, WarningMessages } from "../../consts/messages";
import { UserLogin } from "../../apicaller/domain/request/authentication";
import { isLengthCorrect, isOnlyHalfAlphabetAndNumber } from "../../function/validationCheck";
import { postLogin } from "../../apicaller/repository/authentication";
import { LoginInfo, Message } from "../../context/appContextData";
import { UserInfoLimits } from "../../consts/inputLimits";
import { LoginPageLabel } from "../../consts/label";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      flexDirection: "column",
    },
    component: {
      margin: 10,
    },
    button: {
      margin: 10,
      color: "white",
      backgroundColor: "indianred",
    },
    icon: {
      color: "royalblue",
      cursor: "pointer",
    },
    text: {
      color: "royalblue",
      cursor: "pointer",
    },
  })
);

const Login = () => {
  const styles = useStyles();
  const LoginContext = useContext(LoginInfo);
  const snackBarMessage = useContext(Message);
  const [userID, setUserID] = useState("");
  const [userPW, setUserPW] = useState("");

  const sendLoginRequest = () => {
    let user = new UserLogin();
    user.login_id = userID;
    user.password = userPW;

    if (!isLengthCorrect(user.login_id, UserInfoLimits.ID_MIN_LENGTH, UserInfoLimits.ID_MAX_LENGTH)) {
      snackBarMessage.setMessage(WarningMessages.LOGIN_ID_INPUT_LENGTH, "warning");
      return;
    }
    if (!isLengthCorrect(user.password, UserInfoLimits.PW_MIN_LENGTH, UserInfoLimits.PW_MAX_LENGTH)) {
      snackBarMessage.setMessage(WarningMessages.LOGIN_PW_INPUT_LENGTH, "warning");
      return;
    }

    postLogin(user).then(
      (data: any) => {
        LoginContext.getLoginUser();
        snackBarMessage.setMessage(SuccessMessages.POST_LOGIN, "success");
      },
      (error: any) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.POST_LOGIN, "error");
      }
    );
  };

  return (
    <div>
      <Grid className={styles.grid}>
        <Typography className={styles.component} variant="h2">
          {LoginPageLabel.LOGIN_TITLE}
        </Typography>
        <TextField
          className={styles.component}
          id="outlined-password-input"
          label={LoginPageLabel.ID}
          aria-label={"id"}
          type="text"
          autoComplete="current-password"
          variant="outlined"
          value={userID}
          inputProps={{ maxLength: UserInfoLimits.ID_MAX_LENGTH }}
          onChange={(e) => {
            if (!isOnlyHalfAlphabetAndNumber(e.currentTarget.value)) {
              snackBarMessage.setMessage(WarningMessages.NOT_ONLY_HALF_ALPHABET_AND_NUMBER, "warning");
              return;
            }
            setUserID(e.currentTarget.value);
          }}
        />
        <TextField
          className={styles.component}
          id="outlined-password-input"
          label={LoginPageLabel.PASSWORD}
          aria-label={"password"}
          type="password"
          autoComplete="current-password"
          variant="outlined"
          value={userPW}
          inputProps={{ maxLength: UserInfoLimits.PW_MAX_LENGTH }}
          onChange={(e) => {
            if (!isOnlyHalfAlphabetAndNumber(e.currentTarget.value)) {
              snackBarMessage.setMessage(WarningMessages.NOT_ONLY_HALF_ALPHABET_AND_NUMBER, "warning");
              return;
            }
            setUserPW(e.currentTarget.value);
          }}
        />
        <Button className={styles.button} aria-label={"login"} variant="contained" size="large" onClick={sendLoginRequest}>
          {LoginPageLabel.LOGIN_BUTTON}
        </Button>
      </Grid>
    </div>
  );
};

export default Login;
