export class SafetyEquipmentList {
  safety_equipment: SafetyEquipment[] = [];
}

export class SafetyEquipment {
  constructor(operation_id: number, safety_equipment_id: number, safety_equipment_necessity_id: number) {
    this.operation_id = operation_id;
    this.safety_equipment_id = safety_equipment_id;
    this.safety_equipment_necessity_id = safety_equipment_necessity_id;
  }

  operation_id: number = 0;
  safety_equipment_id: number = 0;
  safety_equipment_necessity_id: number = 0;
  login_id: string = "";
  user_name: string = "";
  created_at: string = "";
  updated_at: string = "";
}

export class RequirementList {
  requirement: Requirement[] = [];
}

export class Requirement {
  requirement_necessity_id: number = 0;
  operation_id: number = 0;
  requirement_id: number = 0;
  created_at: string = "";
  updated_at: string = "";
  login_id: string = "";
  user_name: string = "";
}

export class Operation {
  operation_id: number = 0;
  team_id: number = 0;
  model_id: number = 0;
  operation_name: string = "";
  managed_number: number = 0;
  revision_number: number = 0;
  original_operation_id: number = 0;
  editting_user_id: number = 0;
  editting_login_id: number = 0;
  editting_user_name: string = "";
  status: string = "";
  illustration_s3_key: string = "";
  is_waiting_approve_archive: boolean = false;
  is_link_not_archived_management_book: boolean = false;
  author_id: number = 0;
  login_id: string = "";
  user_name: string = "";
  created_at: string = "";
  updated_at: string = "";
}

export class ApproverList {
  approver: Approver[] = [];
}

export class Approver {
  operation_id: number = 0;
  user_id: number = 0;
  user_name: string = "";
  login_id: string = "";
}

export class ConfirmerList {
  confirmer: Confirmer[] = [];
}

export class Confirmer {
  operation_id: number = 0;
  user_id: number = 0;
  user_name: string = "";
  login_id: string = "";
}

// Safety Point
export class SafetyPointList {
  safety_point: SafetyPoint[] = [];
}

export class SafetyPoint {
  operation_id: number = 0;
  safety_point_id: number = 0;
  content: string = "";
}

// Parts
export class PartsList {
  parts: Parts[] = [];
}

export class Parts {
  operation_id: number = 0;
  parts_id: number = 0;
  name: string = "";
  count: number = 0;
  created_at: string = "";
  updated_at: string = "";
}

// History
export class HistoryList {
  operation_history_list: History[] = [];
}

export class History {
  operation_history_id: number = 0;
  operation_id: number = 0;
  title: string = "";
  detail: string = "";
  created_at: string = "";
  author_id: number = 0;
  author_name: string = "";
  author_login_id: string = "";
  approver_id: number = 0;
  approver_name: string = "";
  approver_login_id: string = "";
  revision_number: number = 0;
}

// Operator Signature
export class CanOperatorSign {
  can_operator_sign: boolean = false;
}

// OperationSignature
export class OperationSignatureList {
  operator_signature_list: OperationSignature[] = [];
}

export class OperationSignature {
  operation_id: number = 0;
  user_id: number = 0;
  login_id: string = "";
  user_name: string = "";
  created_at: string = "";
}

// Operation Card
export class OperationCardList {
  operation_card: OperationCard[] = [];
}

export class OperationCard {
  operation_card_id: number = 0;
  operation_id: number = 0;
  title: string = "";
  card_order: number = 0;
  person_seconds: number = 0;
}

export class IllustrationCircleList {
  illustration_circle: IllustrationCircle[] = [];
}

export class IllustrationCircle {
  illustration_circle_id: number = 0;
  illustration_id: number = 0;
  height: number = 0;
  width: number = 0;
  border_size: number = 0;
  color: string = "";
  transparency: number = 0;
  x_cordinate: number = 0;
  y_cordinate: number = 0;
  z_index: number = 0;
}

// Operation Point
export class OperationPointList {
  operation_point: OperationPoint[] = [];
}

export class OperationPoint {
  operation_point_id: number = 0;
  operation_card_id: number = 0;
  page: number = 0;
  category: number = 0;
  content: string = "";
}

export class OperationHashItemList {
  operation_hash_item: OperationHashItem[] = [];
}

export class OperationHashItem {
  operation_hash_item_id: number = 0;
  operation_card_id: number = 0;
  operation_hash_master_id: number = 0;
}

export class OperationHashMasterList {
  operation_hash: OperationHashMaster[] = [];
}

export class OperationHashMaster {
  operation_hash_master_id: number = 0;
  content: string = "";
}

export class IllustrationPageList {
  illustration_page_list: IllustrationPage[] = [];
}

export class IllustrationPage {
  illustration_page_id: number = 0;
  operation_card_id: number = 0;
  page: number = 0;
  illustration_s3_key: string = "";
}

export class IllustrationList {
  illustration_list: Illustration[] = [];
}

export class Illustration {
  operation_id: number = 0;
  illustration_id: number = 0;
}
