export class ManagementBookItem {
  management_book_id: number = 0;
  model_id: number = 0;
  module_id: number = 0;
  unit_id: number = 0;
  team_id: number = 0;
  production_capacity_id: number = 0;
  revision_number: number = 0;
  status: string = "";
  original_management_book_id: number = 0;
  editing_user_id: number = 0;
  editing_user_login_id: string = "";
  editing_user_name: string = "";
  approver_id: number = 0;
  approver_login_id: string = "";
  approver_name: string = "";
}

export class ManagementBookListResponse {
  management_book_list: ManagementBookItem[] = [];
}

export class ProcessListResponse {
  process: ProcessList[] = [];
}

export class ProcessList {
  process_id: number = 0;
  management_book_id: number = 0;
  process_name: string = "";
  process_order: number = 0;
}

export class OperationInProcessResponse {
  process_list: OperationInProcess[] = [];
  unlinked_operation_list: UnlinkedWordStandard[] = [];
}

export class OperationInProcess {
  process_id: number = 0;
  management_book_id: number = 0;
  process_name: string = "";
  process_order: number = 0;
  operation_list: Operation[] = [];
}

export class UnlinkedWordStandard {
  operation_id: number = 0;
  operation_name: string = "";
  managed_number: number = 0;
}

export class Operation {
  operation_in_process_id: number = 0;
  operation_id: number = 0;
  operation_order: number = 0;
  operation_name: string = "";
  managed_number: number = 0;
}

export class ManagementBookHistory {
  management_book_id: number = 0;
  revision_number: number = 0;
  created_at: string = "";
  editor_login_id: string = "";
  editor_name: string = "";
  approver_login_id: string = "";
  approver_name: string = "";
  detail: string = "";
}

export class ManagementBookHistoryListResponse {
  history: ManagementBookHistory[] = [];
}

export class ManagementBookUnderRevisionResponse {
  under_revision_management_book_id: number = 0;
}
