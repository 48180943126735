import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import * as schemaHelper from "./schemaHelper";

export type AxiosConfigWrapper<Path extends schemaHelper.UrlPaths, Method extends schemaHelper.HttpMethods> =
  {
    url: Path
    method: Method & schemaHelper.HttpMethodsFilteredByPath<Path>
    params?: schemaHelper.RequestParameters<Path, Method>
    paths?: schemaHelper.RequestUrlPaths<Path, Method>
    data?: schemaHelper.RequestData<Path, Method>
  }

export function request<Path extends schemaHelper.UrlPaths,
  Method extends schemaHelper.HttpMethods>(config: AxiosConfigWrapper<Path, Method>) {
  const { url, paths, ...baseConfig } = config;
  const requestConfig: AxiosRequestConfig = {
    ...baseConfig,
    url: Object.entries(paths ?? {}).reduce(
      (previous, [key, value]) =>
        previous.replace(new RegExp(`\\{${key}\\}`), String(value)),
      url as string,
    ),
    baseURL: process.env.REACT_APP_ROOT_PATH_FOR_API_CALL,
    withCredentials: true
  };

  return axios.request<schemaHelper.ResponseData<Path, Method>,
    AxiosResponse<schemaHelper.ResponseData<Path, Method>>,
    AxiosConfigWrapper<Path, Method>["data"]>
  (requestConfig);
}
