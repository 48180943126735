import React, { useContext, useEffect } from "react";
import { useFactory, useLine, useModel, usePlant, useTeam, useUnit } from "../../apicaller/repository/datamanagament";
import { ErrorMessages } from "../../consts/messages";
import { Message } from "../../context/appContextData";
import {
  FactoryListResponse,
  LineListResponse,
  ModelListResponse,
  PlantListResponse,
  TeamListResponse,
  UnitListResponse,
} from "../../apicaller/domain/response/datamanagement";
import { sortByAsc } from "../../function/sort";
import ListBox, { ListOption } from "../Common/ListBox";
import { Label } from "../../consts/label";
import {
  FactoryDisplayListBox,
  ModelDisplayListBox,
  ModuleDisplayListBox,
  PlantDisplayListBox,
  TeamDisplayListBox,
  UnitDisplayListBox,
} from "../Common/display/display";
import { useUserBelonging } from "../../apicaller/repository/authentication";

interface Props {
  disabled?: boolean;
  factoryValue: ListOption;
  plantValue: ListOption;
  moduleValue: ListOption;
  unitValue: ListOption;
  teamValue: ListOption;
  modelValue: ListOption;
  onChangeFactory: (value: ListOption) => void;
  onChangePlant: (value: ListOption) => void;
  onChangeModule: (value: ListOption) => void;
  onChangeUnit: (value: ListOption) => void;
  onChangeTeam: (value: ListOption) => void;
  onChangeModel: (value: ListOption) => void;
}

export const Selector = (props: Props) => {
  const snackBarMessage = useContext(Message);

  const [userBelonging, getUserBelonging, resetUserBelonging, mutateUserBelonging, userBelongingError] = useUserBelonging();
  const [factoryList, getFactoryList, resetFactoryList, mutateFactoryList, factoryError] = useFactory();
  const [plantList, getPlantList, resetPlantList, mutatePlantList, plantError] = usePlant();
  const [moduleList, getModuleList, resetModuleList, mutateModuleList, moduleError] = useLine();
  const [unitList, getUnitList, resetUnitList, mutateUnitList, unitError] = useUnit();
  const [teamList, getTeamList, resetTeamList, mutateTeamList, teamError] = useTeam();
  const [modelList, getModelList, resetModelList, mutateModelList, modelError] = useModel();

  useEffect(() => {
    getUserBelonging();
    getFactoryList();
  }, []);

  // 拠点の初期選択項目を設定
  useEffect(() => {
    if (!factoryList?.factory) return;
    if (!userBelonging?.factory_id) return;
    factoryList.factory.map((row) => {
      if (row.factory_id === userBelonging.factory_id) {
        props.onChangeFactory({ key: row.factory_id, value: row.factory_name });
      }
    });
  }, [userBelonging, factoryList]);

  // 拠点の選択項目が変更されたときに工場リストを取得
  useEffect(() => {
    if (!props.factoryValue) return;
    getPlantList(String(props.factoryValue.key));
  }, [props.factoryValue]);

  // 工場の初期選択項目を設定
  useEffect(() => {
    if (!plantList?.plant) return;
    plantList.plant.map((row) => {
      if (row.plant_id === userBelonging.plant_id) {
        props.onChangePlant({ key: row.plant_id, value: row.plant_name });
      }
    });
  }, [plantList]);

  // 工場の選択項目が変更されたときにモジュールリストを取得
  useEffect(() => {
    if (!props.plantValue) return;
    getModuleList(props.plantValue.key);
    getModelList(String(props.plantValue.key));
  }, [props.plantValue]);

  // モジュールの初期選択項目を設定
  useEffect(() => {
    if (!moduleList?.line) return;
    moduleList.line.map((row) => {
      if (row.line_id === userBelonging.module_id) {
        props.onChangeModule({ key: row.line_id, value: row.line_name });
      }
    });
  }, [moduleList]);

  // モジュールの選択項目が変更されたときにユニットリストを取得
  useEffect(() => {
    if (!props.moduleValue) return;
    getUnitList(String(props.moduleValue.key));
  }, [props.moduleValue]);

  // ユニットの初期選択項目を設定
  useEffect(() => {
    if (!unitList?.unit) return;
    unitList.unit.map((row) => {
      if (row.unit_id === userBelonging.unit_id) {
        props.onChangeUnit({ key: row.unit_id, value: row.unit_name });
      }
    });
  }, [unitList]);

  // ユニットの選択項目が変更されたときにチームリストを取得
  useEffect(() => {
    if (!props.unitValue) return;
    getTeamList(String(props.unitValue.key));
  }, [props.unitValue]);

  // チームの初期選択項目を設定
  useEffect(() => {
    if (!teamList?.team) return;
    teamList.team.map((row) => {
      if (row.team_id === userBelonging.team_id) {
        props.onChangeTeam({ key: row.team_id, value: row.team_name });
      }
    });
  }, [teamList]);

  const factoryChangeHandler = (value: ListOption) => {
    props.onChangeFactory(null);
    props.onChangePlant(null);
    props.onChangeModule(null);
    props.onChangeUnit(null);
    props.onChangeTeam(null);
    props.onChangeModel(null);

    resetPlantList();
    resetModuleList();
    resetUnitList();
    resetTeamList();
    resetModelList();

    if (value !== null) {
      props.onChangeFactory(value);
    }
  };

  // change select Plant
  const plantChangeHandler = (value: ListOption) => {
    props.onChangePlant(null);
    props.onChangeModule(null);
    props.onChangeUnit(null);
    props.onChangeTeam(null);
    props.onChangeModel(null);

    resetModuleList();
    resetUnitList();
    resetTeamList();
    resetModelList();

    if (value !== null) {
      props.onChangePlant(value);
    }
  };

  const moduleChangeHandler = (value: ListOption) => {
    props.onChangeTeam(null);
    props.onChangeUnit(null);
    props.onChangeModule(null);

    resetUnitList();
    resetTeamList();

    if (value !== null) {
      props.onChangeModule(value);
    }
  };
  const unitChangeHandler = (value: ListOption) => {
    props.onChangeTeam(null);
    props.onChangeUnit(null);

    resetTeamList();

    if (value !== null) {
      props.onChangeUnit(value);
    }
    return;
  };
  const teamChangeHandler = (value: ListOption) => {
    props.onChangeTeam(null);
    if (value !== null) {
      props.onChangeTeam(value);
    }
    return;
  };
  const modelChangeHandler = (value: ListOption) => {
    props.onChangeModel(null);
    if (value !== null) {
      props.onChangeModel(value);
    }
    return;
  };

  // SWR error
  useEffect(() => {
    if (userBelongingError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_USER_BELOGING, "error");
    }
    if (factoryError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_FACTORY, "error");
    }
    if (plantError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_PLANT, "error");
    }
    if (moduleError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_MODULE, "error");
    }
    if (unitError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_UNIT, "error");
    }
    if (teamError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_TEAM, "error");
    }
    if (modelError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_MODEL, "error");
    }
  }, [userBelongingError, factoryError, plantError, moduleError, unitError, teamError, modelError]);

  let factoryDisplayListBox = new FactoryDisplayListBox();
  if (factoryList?.factory) {
    let sorted = new FactoryListResponse();
    sorted.factory = sortByAsc(factoryList.factory, "factory_id");
    if (sorted.factory !== undefined) factoryDisplayListBox.setData(sorted);
  }

  let plantDisplayListBox = new PlantDisplayListBox();
  if (plantList?.plant) {
    let sorted = new PlantListResponse();
    sorted.plant = sortByAsc(plantList.plant, "plant_id");
    if (sorted.plant !== undefined) plantDisplayListBox.setData(sorted);
  }

  let moduleDisplayListBox = new ModuleDisplayListBox();
  if (moduleList?.line) {
    let sorted = new LineListResponse();
    sorted.line = sortByAsc(moduleList.line, "line_id");
    if (sorted.line !== undefined) moduleDisplayListBox.setData(sorted);
  }

  let unitDisplayListBox = new UnitDisplayListBox();
  if (unitList?.unit) {
    let sorted = new UnitListResponse();
    sorted.unit = sortByAsc(unitList.unit, "unit_id");
    if (sorted.unit !== undefined) unitDisplayListBox.setData(sorted);
  }

  let teamDisplayListBox = new TeamDisplayListBox();
  if (teamList?.team) {
    let sorted = new TeamListResponse();
    sorted.team = sortByAsc(teamList.team, "team_id");
    if (sorted.team !== undefined) teamDisplayListBox.setData(sorted);
  }

  let modelDisplayListBox = new ModelDisplayListBox();
  if (modelList?.model) {
    let sorted = new ModelListResponse();
    sorted.model = sortByAsc(modelList.model, "model_id");
    if (sorted.model !== undefined) modelDisplayListBox.setData(sorted);
  }

  return (
    <>
      <ListBox
        disable
        label={Label.FACTORY}
        options={factoryDisplayListBox.options}
        value={props.factoryValue}
        onChange={factoryChangeHandler}
      />
      <ListBox
        disable
        label={Label.PLANT}
        options={plantDisplayListBox.options}
        value={props.plantValue}
        onChange={plantChangeHandler}
      />
      <ListBox
        disable={props.disabled}
        label={Label.MODULE}
        options={moduleDisplayListBox.options}
        value={props.moduleValue}
        onChange={moduleChangeHandler}
      />
      <ListBox
        disable={props.disabled}
        label={Label.UNIT}
        options={unitDisplayListBox.options}
        value={props.unitValue}
        onChange={unitChangeHandler}
      />
      <ListBox
        disable={props.disabled}
        label={Label.TEAM}
        options={teamDisplayListBox.options}
        value={props.teamValue}
        onChange={teamChangeHandler}
      />
      <ListBox
        disable={props.disabled}
        label={Label.MODEL}
        options={modelDisplayListBox.options}
        value={props.modelValue}
        onChange={modelChangeHandler}
      />
    </>
  );
};
