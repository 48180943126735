import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TreeItem from "@material-ui/lab/TreeItem";
import { Link, Tooltip, Typography } from "@material-ui/core";
import { OperationDisplay, OperationInProcessDisplayRows } from "../components/OperationManagementBook/display/operationManagementBook";
import { Message, MiniDrawerInfo } from "../context/appContextData";
import { ErrorMessages } from "../consts/messages";
import { useLocation } from "react-router-dom";
import { useManagementBookList, useOperationInProcessList } from "../apicaller/repository/managementbook";
import { ListOption } from "../components/Common/ListBox";
import { ManagementBookItem } from "../apicaller/domain/response/managementbook";
import { WorkFlowStatus } from "../consts/workFlow";
import { WorkStandard } from "../components/WorkStandard/WorkStandard";
import { purple } from "@material-ui/core/colors";
import { WorkStandardPageLabel } from "../consts/label";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      marginTop: 50,
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    content: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    treeview: {
      height: 240,
      flexGrow: 1,
      maxWidth: 400,
    },
    drawerText: {
      flexGrow: 1,
      width: "200px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      verticalAlign: "middle",
    },
    selectedDrawerText: {
      backgroundColor: purple[100],
      fontWeight: "bold",
      flexGrow: 1,
      width: "200px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      verticalAlign: "middle",
    },
    drawerLabel: {
      color: "gray",
    },
    searchButtonItem: {
      justifyContent: "flex-end",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    warningMessage: {
      marginTop: 60,
      marginLeft: 10,
    },
    errorMessage: {
      color: "indianred",
      whiteSpace: "pre-wrap",
    },
  })
);

//Home画面に渡す検索条件
export type SelectedConditions = {
  factory: ListOption;
  plant: ListOption;
  module: ListOption;
  unit: ListOption;
  team: ListOption;
  model: ListOption;
  productionCapacity: ListOption;
};

export type SelectedOperationInfo = {
  operation_id: number;
  managed_number: number;
  operation_name: string;
  operation_in_process_id: number | undefined;
};

export default function OperationDrawer() {
  const styles = useStyles();
  const miniDrawerContext = useContext(MiniDrawerInfo);
  const snackBarMessage = useContext(Message);
  const location = useLocation();

  const [managementBookList, getManagementBookList, resetManagementBookList, mutateManagementBookList, managementBookListError] =
    useManagementBookList();
  const [operationInProcessList, getOperationInProcessList, resetOperationInProcessList, mutateOperationInProcessList, operationInProcessListError] =
    useOperationInProcessList();

  const [drawerOpen, setDrawerOpen] = useState(true);
  const [operationListDisplay, setOperationListDisplay] = useState<OperationInProcessDisplayRows>(new OperationInProcessDisplayRows());
  const [selectedConditions, setSelectedConditions] = useState<SelectedConditions>();
  const [selectedOperationInfo, setSelectedOperationInfo] = useState<SelectedOperationInfo>();
  //管理台帳未登録状態のフラグ
  const [unregistManagementBook, setUnregistManagementBook] = useState<Boolean>(false);
  //作業標準未登録状態のフラグ
  const [unregistOperation, setUnregistOperation] = useState<Boolean>(false);

  const handleDrawerInvert = () => {
    //minidrawer（メニュー）を閉じてからdrawer（工程一覧リスト）を開く
    miniDrawerContext.handleDrawerClose();
    setDrawerOpen(true);
  };

  const handleSelectOperationInfo = (selectedOperation: OperationDisplay, selectedProcessId: number) => {
    setSelectedOperationInfo({
      operation_id: selectedOperation.operation_id,
      managed_number: selectedOperation.managed_number,
      operation_name: selectedOperation.operation_name,
      operation_in_process_id: selectedOperation.operation_in_process_id,
    });
  };

  useEffect(() => {
    const locationState: any = location.state;
    if (locationState) {
      const factory = locationState.factory;
      const plant = locationState.plant;
      const module = locationState.module;
      const unit = locationState.unit;
      const team = locationState.team;
      const model = locationState.model;
      const productionCapacity = locationState.productionCapacity;
      const operation_id = locationState.operation_id;
      const operation_name = locationState.operation_name;
      const operation_in_process_id = locationState.operation_in_process_id;
      const managed_number = locationState.managed_number;

      // 型変換
      const tmp_factory: ListOption = { key: factory.id, value: factory.name };
      const tmp_plant: ListOption = { key: plant.id, value: plant.name };
      const tmp_module: ListOption = { key: module.id, value: module.name };
      const tmp_unit: ListOption = { key: unit.id, value: unit.name };
      const tmp_team: ListOption = { key: team.id, value: team.name };
      const tmp_model: ListOption = { key: model.id, value: model.name };
      const tmp_productionCapacity: ListOption = { key: productionCapacity.id, value: productionCapacity.name };

      setSelectedConditions({
        factory: tmp_factory,
        plant: tmp_plant,
        module: tmp_module,
        unit: tmp_unit,
        team: tmp_team,
        model: tmp_model,
        productionCapacity: tmp_productionCapacity,
      });

      setSelectedOperationInfo({
        operation_id: operation_id,
        managed_number: managed_number,
        operation_name: operation_name,
        operation_in_process_id: operation_in_process_id,
      });
      setUnregistManagementBook(false);
      setDrawerOpen(false);
      setUnregistOperation(false);
    }
  }, [location]);

  useEffect(() => {
    //minidrawer(メニュー)が開いたらdrawer（工程一覧リスト）を閉じる
    if (miniDrawerContext.open) {
      setDrawerOpen(false);
    }
  }, [miniDrawerContext.open]);

  useEffect(() => {
    if (!selectedConditions) return;
    getManagementBookList(
      String(selectedConditions.model.key),
      String(selectedConditions.team.key),
      String(selectedConditions.productionCapacity.key)
    );
  }, [selectedConditions]);

  useEffect(() => {
    if (!managementBookList || !managementBookList.management_book_list) return;
    const publishedManagementBook = managementBookList.management_book_list.find((e: ManagementBookItem) => e.status === WorkFlowStatus.PUBLISHED);
    setUnregistManagementBook(false);

    if (!publishedManagementBook) {
      setUnregistManagementBook(true);
      return;
    }
    getOperationInProcessList(String(publishedManagementBook.management_book_id));
  }, [managementBookList]);

  useEffect(() => {
    if (!operationInProcessList) return;
    if (!operationInProcessList.process_list) return;
    if (operationInProcessList.process_list.length === 0) return;
    //表示用クラスに変換
    setOperationListDisplay(new OperationInProcessDisplayRows().setData(operationInProcessList));
  }, [operationInProcessList]);

  useEffect(() => {
    if (managementBookListError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_MANAGEMENT_BOOK, "error");
    }
    if (operationInProcessListError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_OPERATION_IN_PROCESS, "error");
    }
  }, [managementBookListError, operationInProcessListError]);

  if (!selectedOperationInfo || managementBookListError) {
    return (
      <div className={styles.warningMessage}>
        <Typography className={styles.errorMessage} variant="h5">
          {WorkStandardPageLabel.WORK_STANDARD_GET_ERROR_FROM_MANAGEMENT_BOOK_OPEN}
        </Typography>
      </div>
    );
  }

  //管理台帳未登録状態（選択した検索条件で管理台帳が存在しない場合）
  if (unregistManagementBook) {
    return (
      <div className={styles.warningMessage}>
        <Typography className={styles.errorMessage} variant="h5">
          {WorkStandardPageLabel.MANAGEMENT_BOOK_UNREGISTED}
        </Typography>
      </div>
    );
  }

  //作業標準未登録状態（工程に紐づく作業標準が１つも存在しない場合）
  if (unregistOperation) {
    return (
      <div className={styles.warningMessage}>
        <Typography className={styles.errorMessage} variant="h5">
          {WorkStandardPageLabel.WORK_STANDARD_UNLINKED}
        </Typography>
      </div>
    );
  }

  const selectedConditionsInHome = {
    factory: selectedConditions!.factory,
    plant: selectedConditions!.plant,
    module: selectedConditions!.module,
    unit: selectedConditions!.unit,
    team: selectedConditions!.team,
    model: selectedConditions!.model,
    productionCapacity: selectedConditions!.productionCapacity,
  };
  const selectedOperationInfoInHome = {
    operation_id: selectedOperationInfo.operation_id,
    managed_number: selectedOperationInfo.managed_number,
    operation_name: selectedOperationInfo.operation_name,
  };

  //検索条件を正しく選択できている場合
  return (
    <>
      <Drawer
        className={styles.drawer}
        anchor="left"
        open={drawerOpen}
        classes={{
          paper: styles.drawerPaper,
        }}
        onClose={() => {
          setDrawerOpen(false);
        }}
      >
        <List>
          <ListItem>
            <Typography className={styles.drawerLabel}>{WorkStandardPageLabel.PRODUCTION_CAPACITY}</Typography>
            <Typography>{selectedConditions!.productionCapacity.value}</Typography>
          </ListItem>
        </List>
        <Divider />
        <List>
          <TreeView className={styles.treeview} defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
            {operationListDisplay.processRows.map((row, index) => {
              return (
                <TreeItem
                  key={index}
                  nodeId={String(index)}
                  label={
                    <Tooltip title={row.process_name}>
                      <Typography className={styles.drawerText}>{row.process_name}</Typography>
                    </Tooltip>
                  }
                >
                  {row.operation_list.map((operationRow, rowIndex) => {
                    const operationLabel = operationRow.managed_number + "." + operationRow.operation_name;
                    return (
                      <Tooltip title={operationLabel} key={rowIndex}>
                        <Link style={{ cursor: "pointer" }} onClick={() => handleSelectOperationInfo(operationRow, row.process_id)}>
                          <Typography
                            className={
                              operationRow.operation_id === selectedOperationInfo.operation_id ? styles.selectedDrawerText : styles.drawerText
                            }
                          >
                            {operationLabel}
                          </Typography>
                        </Link>
                      </Tooltip>
                    );
                  })}
                </TreeItem>
              );
            })}
          </TreeView>
        </List>
      </Drawer>
      <main
        className={clsx(styles.content, {
          [styles.contentShift]: drawerOpen,
        })}
      >
        <div className={styles.drawerHeader} />
        <WorkStandard
          showSideBar={true}
          drawerOpen={drawerOpen}
          selectedConditions={selectedConditionsInHome!}
          selectedWorkStandardInfo={selectedOperationInfoInHome!}
          handleWorkStandardDrawerInvert={handleDrawerInvert}
        />
      </main>
    </>
  );
}
