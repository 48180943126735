import i18n from "i18next";

export const hashMap = new Map<string, string>([
  ["重要項目", "重要項目"],
  ["重要工程管理項目", "重要工程管理項目"],
]);

const updateHashMap = () => {
  hashMap.set("重要項目", i18n.t("work-standard-work-tab-label.important-items"));
  hashMap.set("重要工程管理項目", i18n.t("work-standard-work-tab-label.important-project-management-items"));
};

if (i18n.isInitialized) {
  updateHashMap();
}

i18n.on("languageChanged", () => {
  updateHashMap();
});

i18n.on("loaded", () => {
  updateHashMap();
});
