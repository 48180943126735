import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import SelectModel from "../components/Common/SelectModel";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Switch,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import PropTypes from "prop-types";
import ProcessManagementBookTable from "../components/OperationManagementBook/ProcessManagementBookTable";
import { EditInfo, InnerAreaSize, LoginInfo, Message, WindowSize } from "../context/appContextData";
import { DialogMessages, ErrorMessages, SuccessMessages, TooltipMessages, WarningMessages } from "../consts/messages";
import {
  useFactory,
  useLine,
  useModel,
  usePlant,
  useProductionCapacityHistory,
  useProductionCapacityListOfPublishedManagementBook,
  useTeam,
  useUnit,
  useUnitProductionCapacity,
  useVolume,
} from "../apicaller/repository/datamanagament";
import {
  FactoryDisplayComboBox,
  LineDisplayComboBox,
  ModelDisplayComboBox,
  PlantDisplayComboBox,
  TeamDisplayComboBox,
  UnitDisplayComboBox,
  VolumeDisplayComboBox,
} from "../components/Common/display/display";
import {
  FactoryListResponse,
  LineListResponse,
  ModelListResponse,
  PlantListResponse,
  ProductionCapacityHistoryListResponse,
  TeamListResponse,
  UnitListResponse,
  VolumeListResponse,
} from "../apicaller/domain/response/datamanagement";
import { sortByAsc, sortByDesc } from "../function/sort";
import { DropResult } from "react-smooth-dnd";
import { OperationInProcessDisplayRows, Process, ProcessRows } from "../components/OperationManagementBook/display/operationManagementBook";
import OperationManagementBookTable from "../components/OperationManagementBook/OperationManagementBookTable";
import SelectArea from "../components/Common/SelectArea";
import { useHistory } from "react-router-dom";
import { useUserBelonging } from "../apicaller/repository/authentication";
import UnlinkedWorkStandardTable from "../components/OperationManagementBook/UnlinkedWorkStandardTable";
import InfomationManagementBookTab from "../components/OperationManagementBook/InfomationManagementBookTab";
import {
  deleteOperationInProcess,
  postDuplicate,
  postManagementBook,
  putEditingOff,
  putEditingOn,
  PutOperationInProcess,
  putProcessOrder,
  useManagementBook,
  useManagementBookHistory,
  useManagementBookList,
  useManagementBookUnderRevision,
  useOperationInProcessList,
  useProcessList,
  useRejectMessage,
} from "../apicaller/repository/managementbook";
import {
  DuplicateRequest,
  ManagementBookRegistRequest,
  OperationInProcessOrder,
  OperationInProcessOrderUpdateRequest,
  ProcessOrderUpdateRequest,
} from "../apicaller/domain/request/managementbook";
import { ManagementBookApplicationRequest, ManagementBookIdRequest } from "../apicaller/domain/request/workflow";
import { ManagementBookHistoryListResponse, ManagementBookItem } from "../apicaller/domain/response/managementbook";
import { Authority } from "../consts/authority";
import { Role } from "../consts/role";
import { CreateNew, WorkFlowStatus } from "../consts/workFlow";
import {
  putManagementBookApplicationRequest,
  putManagementBookApproveRequest,
  putManagementBookPublishRequest,
} from "../apicaller/repository/workflow";
import {
  postManagementBookReviseRequest,
  putManagementBookCancelRequest,
  putManagementBookModifyRequest,
  putManagementBookRejectRequest,
} from "../apicaller/repository/managementbookworkflow";
import { ApplicationUnderRevisionDialog } from "../components/OperationManagementBook/ApplicationUnderRevisionDialog";
import { useGetElementProperty } from "../hooks/getElementProperty";
import ListBox, { ListOption } from "../components/Common/ListBox";
import { StatusCodes } from "http-status-codes";
import { Errors } from "../apicaller/domain/response/common";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import {
  EditModeLabel,
  ManagementBookProcessListTabLabel,
  ManagementBookPageLabel,
  ManagementBookWorkStandardListTabLabel,
  RevisionNumberLabel,
  WorkFlowButtonLabel,
  WorkFlowStatusNamesLabel,
} from "../consts/label";
import { RejectMessageDialog } from "../components/OperationManagementBook/RejectMessageDialog";
import { ApiBody } from "../utils/schemaUtil";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBarSpacer: {
      height: 48,
    },
    listBoxes: {
      flexGrow: 1,
      paddingInline: 10,
    },
    button: {
      marginInline: theme.spacing(1),
    },
    iconButton: {
      marginRight: "15px",
      marginLeft: "-5px",
    },
    message: {
      color: theme.palette.error.main,
      fontWeight: "bold",
    },
    revisionNumber: {
      fontWeight: "bold",
    },
  })
);

function TabPanel(props: { [x: string]: any; children: any; value: any; index: any }) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const DEFAULT_SELECT_OBJ = { id: 0, name: "" };

const OperationManagementBook = () => {
  const theme = useTheme();
  const styles = useStyles();
  const [value, setValue] = useState(0);
  const EditContext = useContext(EditInfo);
  const LoginContext = useContext(LoginInfo);
  const snackBarMessage = useContext(Message);
  const history = useHistory();
  const listBoxesRef = useRef(null);
  const tabBarRef = useRef(null);

  const [userBelonging, getUserBelonging, resetUserBelonging, mutateUserBelonging, userBelongingError] = useUserBelonging();
  const [factoryList, getFactoryList, resetFactoryList, mutateFactoryList, factoryError] = useFactory();
  const [plantList, getPlantList, resetPlantList, mutatePlantList, plantError] = usePlant();
  const [lineList, getLineList, resetLineList, mutateLineList, lineError] = useLine();
  const [unitList, getUnitList, resetUnitList, mutateUnitList, unitError] = useUnit();
  const [teamList, getTeamList, resetTeamList, mutateTeamList, teamError] = useTeam();
  const [modelList, getModelList, resetModelList, mutateModelList, modelError] = useModel();
  const [volumeList, getVolumeList, resetVolumeList, mutateVolumeList, volumeError] = useVolume();
  const [
    productionCapacityListOfPublishedManagementBookList,
    getProductionCapacityListOfPublishedManagementBook,
    resetProductionCapacityListOfPublishedManagementBook,
    mutateProductionCapacityListOfPublishedManagementBook,
    productionCapacityListOfPublishedManagementBookError,
  ] = useProductionCapacityListOfPublishedManagementBook();
  const [
    productionCapacityHistoryList,
    getProductionCapacityHistoryList,
    resetProductionCapacityHistoryList,
    mutateProductionCapacityHistoryList,
    productionCapacityHistoryError,
  ] = useProductionCapacityHistory();
  const [
    unitProductionCapacityList,
    getUnitProductionCapacityList,
    resetUnitProductionCapacityList,
    mutateUnitProductionCapacityList,
    unitProductionCapacityError,
  ] = useUnitProductionCapacity();
  const [managementBookList, getManagementBookList, resetManagementBookList, mutateManagementBookList, managementBookListError] =
    useManagementBookList();
  const [
    managementBookHistoryList,
    getManagementBookHistoryList,
    resetManagementBookHistoryList,
    mutateManagementBookHistoryList,
    managementBookHistoryError,
  ] = useManagementBookHistory();
  const [processList, getProcessList, resetProcessList, mutateProcessList, processError] = useProcessList();
  const [operationInProcessList, getOperationInProcessList, resetOperationInProcessList, mutateOperationInProcessList, operationInProcessListError] =
    useOperationInProcessList();
  const [managementBook, getManagementBook, resetManagementBook, mutateManagementBook, managementBookError] = useManagementBook();
  const [
    managementBookUnderRevision,
    getManagementBookUnderRevision,
    resetManagementBookUnderRevision,
    mutateManagementBookUnderRevision,
    managementBookUnderRevisionError,
  ] = useManagementBookUnderRevision();
  const [rejectMessage, getRejectMessage, resetRejectMessage, mutateRejectMessage, rejectMessageError] = useRejectMessage();

  const [selectFactoryItem, setSelectFactoryItem] = useState(DEFAULT_SELECT_OBJ);
  const [selectPlantItem, setSelectPlantItem] = useState(DEFAULT_SELECT_OBJ);
  const [selectLineItem, setSelectLineItem] = useState(DEFAULT_SELECT_OBJ);
  const [selectUnitItem, setSelectUnitItem] = useState(DEFAULT_SELECT_OBJ);
  const [selectTeamItem, setSelectTeamItem] = useState(DEFAULT_SELECT_OBJ);
  const [selectModelItem, setSelectModelItem] = useState({ ...DEFAULT_SELECT_OBJ });
  const [selectVolumeItem, setSelectVolumeItem] = useState({ ...DEFAULT_SELECT_OBJ });
  const [processListDisplay, setProcessListDisplay] = useState<ProcessRows>(new ProcessRows());
  const [operationListDisplay, setOperationListDisplay] = useState<OperationInProcessDisplayRows>(new OperationInProcessDisplayRows());
  const [managementBookId, setManagementBookId] = useState<number>(0);
  const [isOpenApplicationDialog, setIsOpenApplicationDialog] = useState<boolean>(false);
  const [disableApplicationDialogButton, setDisableApplicationDialogButton] = useState<boolean>(false);
  const [isOpenViewRejectMessageDialog, setIsOpenViewRejectMessageDialog] = useState(false);
  const [isOpenInputRejectMessageDialog, setIsOpenInputRejectMessageDialog] = useState(false);
  const [applicationDialogDetail, setApplicationDialogDetail] = useState<string>("");
  const [checkedDuplicate, setCheckedDuplicate] = useState<boolean>(false);
  const [selectedDuplicateProductionCapacity, setSelectedDuplicateProductionCapacity] = useState<ListOption>(null);
  const [disableEditing, setDisableEditing] = useState<boolean>(false);
  const [inputRejectMessage, setInputRejectMessage] = useState("");

  const InnerAreaSizeContext = useContext(InnerAreaSize);

  const listBoxesHeight = useGetElementProperty(listBoxesRef).getElementProperty("height");
  const tabBarHeight = useGetElementProperty(tabBarRef).getElementProperty("height");

  const tabContentHeight = useMemo(() => {
    return InnerAreaSizeContext.height - (listBoxesHeight + tabBarHeight);
  }, [InnerAreaSizeContext, listBoxesHeight, tabBarHeight]);

  //#region ---USE EFFECT---
  useEffect(() => {
    getUserBelonging();
    getFactoryList();
  }, []);

  // 拠点の初期選択項目を設定
  useEffect(() => {
    if (!factoryList) return;
    if (!userBelonging) return;
    if (!userBelonging.factory_id) return;
    if (userBelonging.factory_id === 0) return;
    factoryList.factory.forEach((row) => {
      if (row.factory_id === userBelonging.factory_id) {
        setSelectFactoryItem({ id: row.factory_id, name: row.factory_name });
      }
    });
  }, [userBelonging, factoryList]);

  // 拠点の選択項目が変更されたときに工場リストを取得
  useEffect(() => {
    if (!selectFactoryItem) return;
    if (!selectFactoryItem.id) return;
    if (selectFactoryItem.id === 0) return;
    getPlantList(String(selectFactoryItem.id));
  }, [selectFactoryItem]);

  // 工場の初期選択項目を設定
  useEffect(() => {
    if (!plantList) return;
    if (!plantList.plant) return;
    if (plantList.plant.length === 0) return;
    plantList.plant.forEach((row) => {
      if (row.plant_id === userBelonging.plant_id) {
        setSelectPlantItem({ id: row.plant_id, name: row.plant_name });
      }
    });
  }, [plantList]);

  // 工場の選択項目が変更されたときにモジュールリストを取得
  useEffect(() => {
    if (!selectPlantItem) return;
    if (!selectPlantItem.id) return;
    if (selectPlantItem.id === 0) return;
    getLineList(selectPlantItem.id);
    getModelList(String(selectPlantItem.id));
  }, [selectPlantItem]);

  // モジュールの初期選択項目を設定
  useEffect(() => {
    if (!lineList) return;
    if (!lineList.line) return;
    if (lineList.line.length === 0) return;
    lineList.line.forEach((row) => {
      if (row.line_id === userBelonging.module_id) {
        setSelectLineItem({ id: row.line_id, name: row.line_name });
      }
    });
  }, [lineList]);

  // モジュールの選択項目が変更されたときにユニットリストを取得
  useEffect(() => {
    if (!selectLineItem) return;
    if (!selectLineItem.id) return;
    if (selectLineItem.id === 0) return;
    getUnitList(String(selectLineItem.id));
    getUnitProductionCapacityList(String(selectLineItem.id));
  }, [selectLineItem]);

  // ユニットの初期選択項目を設定
  useEffect(() => {
    if (!unitList) return;
    if (!unitList.unit) return;
    if (unitList.unit.length === 0) return;
    unitList.unit.forEach((row) => {
      if (row.unit_id === userBelonging.unit_id) {
        setSelectUnitItem({ id: row.unit_id, name: row.unit_name });
      }
    });
  }, [unitList]);

  useEffect(() => {
    if (!unitProductionCapacityList) return;
    if (!unitProductionCapacityList.unit) return;
    if (unitProductionCapacityList.unit.length === 0) return;
    unitProductionCapacityList.unit.forEach((row) => {
      if (row.unit_id === selectUnitItem.id) {
        const currentProductionCapacity = row.production_capacity.find((e) => e.production_capacity_id === row.current_production_capacity_id);
        if (!currentProductionCapacity) return;
        setSelectVolumeItem({
          id: currentProductionCapacity.production_capacity_id,
          name: String(currentProductionCapacity.production_capacity),
        });
      }
    });
  }, [unitProductionCapacityList, selectUnitItem]);

  // ユニットの選択項目が変更されたとき
  useEffect(() => {
    if (!selectUnitItem) return;
    if (!selectUnitItem.id) return;
    if (selectUnitItem.id === 0) return;
    getTeamList(String(selectUnitItem.id));
    getVolumeList(String(selectUnitItem.id));
  }, [selectUnitItem]);

  // チームの初期選択項目を設定
  useEffect(() => {
    if (!teamList) return;
    if (!teamList.team) return;
    if (teamList.team.length === 0) return;
    teamList.team.forEach((row) => {
      if (row.team_id === userBelonging.team_id) {
        setSelectTeamItem({ id: row.team_id, name: row.team_name });
      }
    });
  }, [teamList]);

  // change in SWR data(sort for display process list)
  useEffect(() => {
    if (!processList) return;
    if (!processList.process) return;
    if (!operationInProcessList) return;
    if (!operationInProcessList.process_list) return;
    setProcessListDisplay(processListDisplay.setData(processList, operationInProcessList).sortByAsc());
  }, [processList, operationInProcessList]);

  useEffect(() => {
    if (!operationInProcessList) return;
    if (!operationInProcessList.process_list) return;
    setOperationListDisplay(new OperationInProcessDisplayRows().setData(operationInProcessList));
  }, [operationInProcessList]);

  useEffect(() => {
    if (selectModelItem.id === 0 || selectTeamItem.id === 0 || selectVolumeItem.id === 0) {
      setManagementBookId(0);
      resetManagementBook();
      resetManagementBookUnderRevision();
      return;
    }
    getProductionCapacityHistoryList(String(selectVolumeItem.id));
    getManagementBookList(String(selectModelItem.id), String(selectTeamItem.id), String(selectVolumeItem.id));
  }, [selectModelItem, selectTeamItem, selectVolumeItem]);

  useEffect(() => {
    setSelectedDuplicateProductionCapacity(null);
    if (LoginContext.loginUser.authority === Authority.GENERAL) return;
    if (selectModelItem.id === 0 || selectTeamItem.id === 0) return;
    getProductionCapacityListOfPublishedManagementBook(String(selectModelItem.id), String(selectTeamItem.id));
  }, [selectModelItem, selectTeamItem]);

  useEffect(() => {
    if (!managementBookList || !managementBookList.management_book_list) {
      setManagementBookId(0);
      return;
    }

    var item = managementBookList.management_book_list.find((e: ManagementBookItem) => e.status === WorkFlowStatus.PUBLISHED);
    if (item) {
      setManagementBookId(item.management_book_id);
    } else {
      setManagementBookId(0);
    }
  }, [managementBookList]);

  useEffect(() => {
    if (managementBookId === 0) {
      resetManagementBook();
      resetManagementBookUnderRevision();
      resetRejectMessage();
    } else {
      getManagementBook(String(managementBookId));
      getManagementBookUnderRevision(String(managementBookId));
      getRejectMessage(managementBookId);
    }

    if (EditContext.editMode) {
      EditContext.invert();
    }
  }, [managementBookId]);

  useEffect(() => {
    if (managementBook === undefined) return;
    if (managementBook.management_book_id === undefined) return;
    if (managementBook.management_book_id === 0) return;
    getProcessList(String(managementBook.management_book_id));
    getOperationInProcessList(String(managementBook.management_book_id));
    getManagementBookHistoryList(String(managementBook.management_book_id));

    if (managementBook?.editing_user_id === 0) {
      // 編集中のユーザーがいないときEditトグルをオフにする
      updateEditMode(false);
      setDisableEditing(false);
    } else if (managementBook?.editing_user_id !== LoginContext.loginUser.user_id) {
      // 他のユーザーが編集中のときEditトグルを非活性にする
      updateEditMode(false);
      setDisableEditing(true);
    } else if (managementBook?.editing_user_id === LoginContext.loginUser.user_id) {
      // ログインユーザーが編集中のとき
      updateEditMode(true);
      setDisableEditing(false);
    }
  }, [managementBook]);

  useEffect(() => {
    if (!productionCapacityHistoryList || !productionCapacityHistoryList.history) return;
    productionCapacityHistoryList.history = sortByDesc(productionCapacityHistoryList.history, "created_at");
  }, [productionCapacityHistoryList]);

  // SWR error
  useEffect(() => {
    if (userBelongingError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_USER_BELOGING, "error");
    }
    if (factoryError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_FACTORY, "error");
    }
    if (plantError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_PLANT, "error");
    }
    if (lineError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_MODULE, "error");
    }
    if (unitError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_UNIT, "error");
    }
    if (teamError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_TEAM, "error");
    }
    if (modelError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_MODEL, "error");
    }
    if (volumeError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_VOLUME, "error");
    }
    if (productionCapacityListOfPublishedManagementBookError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_PRODUCTION_CAPACITY_LIST_OF_PUBLISHED_MANAGEMENT_BOOK, "error");
    }
    if (productionCapacityHistoryError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_PRODUCTION_CAPACITY_HISTORY, "error");
    }
    if (managementBookListError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_MANAGEMENT_BOOK_LIST, "error");
    }
    if (processError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_PROCESS, "error");
    }
    if (operationInProcessListError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_OPERATION, "error");
    }
    if (managementBookHistoryError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_MANAGEMENT_BOOK_HISTORY, "error");
    }
    if (managementBookError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_MANAGEMENT_BOOK, "error");
    }
    if (managementBookUnderRevisionError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_MANAGEMENT_BOOK_UNDER_REVISION, "error");
    }
    if (rejectMessageError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_MANAGEMENT_BOOK_REJECT_MESSAGE, "error");
    }
  }, [
    userBelongingError,
    factoryError,
    plantError,
    lineError,
    unitError,
    teamError,
    modelError,
    volumeError,
    productionCapacityListOfPublishedManagementBookError,
    productionCapacityHistoryError,
    managementBookListError,
    processError,
    operationInProcessListError,
    managementBookHistoryError,
    managementBookError,
    managementBookUnderRevisionError,
    rejectMessageError,
  ]);
  //#endregion

  // HOMEに遷移(サイドバーあり)
  const handleShowHome = (
    operation_id: number,
    operation_name: string,
    operation_in_process_id: number,
    managed_number: number,
    process_id: number
  ) => {
    history.push({
      pathname: "/workStandard",
      state: {
        factory: selectFactoryItem,
        plant: selectPlantItem,
        module: selectLineItem,
        unit: selectUnitItem,
        team: selectTeamItem,
        model: selectModelItem,
        productionCapacity: selectVolumeItem,
        operation_id: operation_id,
        operation_name: operation_name,
        operation_in_process_id: operation_in_process_id,
        managed_number: managed_number,
        process_id: process_id,
      },
    });
  };

  // タブ遷移処理
  const handleChangeTab = (_: any, newValue: React.SetStateAction<number>) => {
    setValue(newValue);
  };

  // 編集モード切り替え処理
  const handleEditModeChange = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    let result: boolean;
    if (checked) {
      result = window.confirm(DialogMessages.EDIT_MODE_ON);
      if (result) {
        putEditingOn({ management_book_id: managementBookId })
          .then(() => {
            updateEditMode(true, true);
          })
          .catch(() => {
            snackBarMessage.setMessage(ErrorMessages.UPDATE_EDITING_ON, "error");
          });
      }
    } else {
      result = window.confirm(DialogMessages.EDIT_MODE_OFF);
      if (result) {
        putEditingOff({ management_book_id: managementBookId })
          .then(() => {
            updateEditMode(false, true);
          })
          .catch((e: Errors) => {
            if (e.status_code === StatusCodes.CONFLICT) {
              updateEditMode(false, true);
              snackBarMessage.setMessage(WarningMessages.WORK_STANDARD_EDITING_OFF_OTHER_USER, "warning");
            } else {
              snackBarMessage.setMessage(ErrorMessages.UPDATE_EDITING_OFF, "error");
            }
          });
      }
    }
  };

  const handleEditingOff = () => {
    const result: boolean = window.confirm(DialogMessages.EDIT_MODE_OFF_ADMIN);
    if (result) {
      putEditingOff({ management_book_id: managementBookId })
        .then(() => {
          updateEditMode(false);
        })
        .catch((err: Errors) => {
          console.log(err);
          snackBarMessage.setMessage(ErrorMessages.UPDATE_EDITING_OFF, "error");
        });
    }
  };

  // 管理台帳新規作成処理
  const handleRegistManagementBook = () => {
    // 選択してない時はボタン非表示になるが、ダブルチェックとしてチェックを行う
    if (selectModelItem.id === 0 || selectTeamItem.id === 0 || selectVolumeItem.id === 0) {
      return;
    }

    if (checkedDuplicate) {
      if (selectedDuplicateProductionCapacity == null) {
        snackBarMessage.setMessage(WarningMessages.MANAGEMENT_BOOK_NOT_SELECTED_DUPLICATE_PRODUCTION_CAPACITY, "warning");
        return;
      }

      // 複製の場合
      postDuplicate(new DuplicateRequest(selectedDuplicateProductionCapacity.key, selectVolumeItem.id))
        .then(() => {
          mutateManagementBookList();
          snackBarMessage.setMessage(SuccessMessages.INSERT_MANAGEMENT_BOOK, "success");
        })
        .catch((err) => {
          console.log(err);
          snackBarMessage.setMessage(ErrorMessages.INSERT_MANAGEMENT_BOOK, "error");
        });
    } else {
      // 新規作成の場合
      postManagementBook(new ManagementBookRegistRequest(selectModelItem.id, selectTeamItem.id, selectVolumeItem.id)).then(
        () => {
          mutateManagementBookList();
          snackBarMessage.setMessage(SuccessMessages.INSERT_MANAGEMENT_BOOK, "success");
        },
        (error) => {
          console.log(error);
          snackBarMessage.setMessage(ErrorMessages.INSERT_MANAGEMENT_BOOK, "error");
        }
      );
    }
  };

  const updateEditMode = (mode: boolean, isSelf?: boolean) => {
    if (mode) {
      if (!EditContext.editMode) {
        EditContext.invert();
      }
    } else {
      if (EditContext.editMode) {
        EditContext.invert();
        if (!isSelf) {
          window.location.reload();
        }
      }
    }
  };

  //#region ---リストボックス選択時の処理---
  //管理台帳情報、作業一覧と工程一覧と管理のデータをクリアする
  const resetProcessAndOperation = () => {
    resetManagementBookList();
    resetProcessList();
    setProcessListDisplay(new ProcessRows());
    resetOperationInProcessList();
    setOperationListDisplay(new OperationInProcessDisplayRows());
    resetProductionCapacityHistoryList();
    resetManagementBookHistoryList();
  };

  // change select Factory
  const handleSelectFactory = (value: string) => {
    setSelectFactoryItem({ ...DEFAULT_SELECT_OBJ });
    setSelectPlantItem({ ...DEFAULT_SELECT_OBJ });
    setSelectLineItem({ ...DEFAULT_SELECT_OBJ });
    setSelectUnitItem({ ...DEFAULT_SELECT_OBJ });
    setSelectTeamItem({ ...DEFAULT_SELECT_OBJ });
    setSelectModelItem({ ...DEFAULT_SELECT_OBJ });
    setSelectVolumeItem({ ...DEFAULT_SELECT_OBJ });

    resetProcessAndOperation();
    resetPlantList();
    resetLineList();
    resetUnitList();
    resetTeamList();
    resetModelList();
    resetVolumeList();
    if (value !== null) {
      const factory_id = factoryList.factory[factoryList.factory.findIndex((e: { factory_name: string }) => e.factory_name === value)].factory_id;
      setSelectFactoryItem({ ...{ id: factory_id, name: value } });
      getPlantList(String(factory_id));
    }
  };

  // change select Plant
  const handleSelectPlant = (value: string) => {
    setSelectPlantItem({ ...DEFAULT_SELECT_OBJ });
    setSelectLineItem({ ...DEFAULT_SELECT_OBJ });
    setSelectUnitItem({ ...DEFAULT_SELECT_OBJ });
    setSelectTeamItem({ ...DEFAULT_SELECT_OBJ });
    setSelectModelItem({ ...DEFAULT_SELECT_OBJ });
    setSelectVolumeItem({ ...DEFAULT_SELECT_OBJ });

    resetProcessAndOperation();
    resetLineList();
    resetUnitList();
    resetTeamList();
    resetModelList();
    resetVolumeList();
    if (value !== null) {
      const plant_id = plantList.plant[plantList.plant.findIndex((e: { plant_name: string }) => e.plant_name === value)].plant_id;
      setSelectPlantItem({ ...{ id: plant_id, name: value } });
      getLineList(String(plant_id));
      getModelList(String(plant_id));
    }
  };

  // change select Line
  const handleSelectLine = (value: string) => {
    setSelectLineItem({ ...DEFAULT_SELECT_OBJ });
    setSelectUnitItem({ ...DEFAULT_SELECT_OBJ });
    setSelectTeamItem({ ...DEFAULT_SELECT_OBJ });
    setSelectVolumeItem({ ...DEFAULT_SELECT_OBJ });

    resetProcessAndOperation();
    resetUnitList();
    resetTeamList();
    resetVolumeList();
    if (value !== null) {
      if (!lineList) return;
      const line_id = lineList.line[lineList.line.findIndex((e: { line_name: string }) => e.line_name === value)].line_id;
      setSelectLineItem({ ...{ id: line_id, name: value } });
      getUnitList(String(line_id));
    }
  };

  // change select Unit
  const handleSelectUnit = (value: string) => {
    setSelectUnitItem({ ...DEFAULT_SELECT_OBJ });
    setSelectTeamItem({ ...DEFAULT_SELECT_OBJ });
    setSelectVolumeItem({ ...DEFAULT_SELECT_OBJ });

    resetProcessAndOperation();
    resetTeamList();
    resetVolumeList();
    if (value !== null) {
      const unit_id = unitList.unit[unitList.unit.findIndex((e: { unit_name: string }) => e.unit_name === value)].unit_id;
      setSelectUnitItem({ ...{ id: unit_id, name: value } });
      getTeamList(String(unit_id));
      getVolumeList(String(unit_id));
    }
  };

  // change select Team
  const handleSelectTeam = (value: string) => {
    setSelectTeamItem({ ...DEFAULT_SELECT_OBJ });

    resetProcessAndOperation();
    if (value !== null) {
      const team_id = teamList.team[teamList.team.findIndex((e: { team_name: string }) => e.team_name === value)].team_id;
      setSelectTeamItem({ ...{ id: team_id, name: value } });
    }
  };

  // change select model
  const handleSelectModel = (value: string) => {
    setSelectModelItem({ ...DEFAULT_SELECT_OBJ });
    resetProcessAndOperation();

    if (value !== null) {
      const model_id = modelList.model[modelList.model.findIndex((e: { model_name: string }) => e.model_name === value)].model_id;
      setSelectModelItem({ id: model_id, name: value });
    }
  };

  // change select volume
  const handleSelectVolume = (value: string | null) => {
    if (value === null) {
      setSelectVolumeItem({ ...DEFAULT_SELECT_OBJ });
      resetProcessAndOperation();
    } else {
      const volume_id = volumeList.volume[volumeList.volume.findIndex((e: { volume: number }) => e.volume === Number(value))].volume_id;
      setSelectVolumeItem({ id: volume_id, name: value });
    }
  };
  //#endregion

  //#region ---PROCESS---
  const handleRefleshProcess = () => {
    mutateProcessList();
    mutateOperationInProcessList();
    return;
  };

  // swap process
  const handleSwapProcess = (dropResult: DropResult) => {
    setProcessListDisplay(processListDisplay.replaceData(dropResult));

    const reqData = new ProcessOrderUpdateRequest();
    reqData.process_order_list = processListDisplay.processRows.map((row: Process, index) => {
      return { process_id: row.process_id, process_order: index + 1 };
    });

    putProcessOrder(reqData).then(
      () => {
        mutateProcessList();
        mutateOperationInProcessList();
        snackBarMessage.setMessage(SuccessMessages.UPDATE_PROCESS_ORDER, "success");
      },
      (error) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.UPDATE_PROCESS_ORDER, "error");
      }
    );
  };
  //#endregion

  //#region ---operation in process---
  const handleAddedOperationInProcess = () => {
    mutateOperationInProcessList();
  };

  const handleOperationInProcessOrderUpdate = (processIndex: number, dropResult: DropResult) => {
    setOperationListDisplay(operationListDisplay.replaceData(processIndex, dropResult));

    const reqData = new OperationInProcessOrderUpdateRequest();
    reqData.operation_order = operationListDisplay.processRows[processIndex].operation_list.map((row, index): OperationInProcessOrder => {
      return { operation_in_process_id: row.operation_in_process_id, operation_order: index + 1 };
    });

    PutOperationInProcess(reqData).then(
      () => {
        mutateOperationInProcessList();
        snackBarMessage.setMessage(SuccessMessages.UPDATE_OPERATION_IN_PROCESS_ORDER, "success");
      },
      (error) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.UPDATE_OPERATION_IN_PROCESS_ORDER, "error");
      }
    );
  };

  const handleOperationInProcessDelete = (operation_in_process_id: string) => {
    deleteOperationInProcess(operation_in_process_id).then(
      () => {
        mutateOperationInProcessList();
        snackBarMessage.setMessage(SuccessMessages.DELETE_OPERATION_IN_PROCESS, "success");
      },
      (error) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.DELETE_OPERATION_IN_PROCESS, "error");
      }
    );
  };
  //#endregion

  //#region ボタン表示/非表示
  // 否認理由確認ボタン(管理台帳が1回以上否認されていて、ステータスが「作成中」または「承認待ち」または「否認」かつユーザーが「編集権限以上」の場合のみ表示する)
  const isRejectMessageButtonVisible = (): boolean => {
    return (
      (managementBook?.status === WorkFlowStatus.EDITING ||
        managementBook?.status === WorkFlowStatus.WAITING_APPROVE ||
        managementBook?.status === WorkFlowStatus.REJECTED) &&
      LoginContext.loginUser.authority >= Authority.EDITOR &&
      rejectMessage?.login_id.length !== 0
    );
  };

  // 承認依頼ボタン(管理台帳が「作成中」かつユーザーが「編集権限以上」の場合のみ表示する)
  const isApplicationButtonVisible = (): boolean => {
    return managementBook?.status === WorkFlowStatus.EDITING && LoginContext.loginUser.authority >= Authority.EDITOR;
  };

  // 引き戻しボタン(管理台帳が「承認待ち」かつユーザーが「編集権限以上」の場合のみ表示する)
  const isCancelButtonVisible = (): boolean => {
    return managementBook?.status === WorkFlowStatus.WAITING_APPROVE && LoginContext.loginUser.authority >= Authority.EDITOR;
  };

  // 承認ボタンを表示するかどうか
  const isApproveButtonVisible = (): boolean => {
    // 承認待ちでないとき非表示
    if (managementBook?.status !== WorkFlowStatus.WAITING_APPROVE) return false;
    // 一般権限のユーザーのとき非表示
    if (LoginContext.loginUser.authority === Authority.GENERAL) return false;

    // モジュールマネージャーロールのとき表示
    let role = LoginContext.loginUser.module_role.find((v) => v.id === managementBook.module_id)?.role;
    if (Role.MODULE_MANAGER === role) {
      return true;
    }

    // ユニットリーダーロールのとき表示
    role = LoginContext.loginUser.unit_role.find((v) => v.id === managementBook.unit_id)?.role;
    if (Role.UNIT_LEADER === role) {
      return true;
    }

    return false;
  };

  // 否認ボタン(管理台帳が「承認待ち」かつユーザーが「編集権限以上」の場合のみ表示する)
  const isRejectButtonVisible = (): boolean => {
    // 承認待ちでないとき非表示
    if (managementBook?.status !== WorkFlowStatus.WAITING_APPROVE) {
      return false;
    }
    // 一般権限のユーザーのとき非表示
    if (LoginContext.loginUser.authority === Authority.GENERAL) {
      return false;
    }

    // 管理者権限のユーザーのとき表示
    if (LoginContext.loginUser.authority === Authority.ADMIN) {
      return true;
    }

    // モジュールマネージャーロールのとき表示
    let role = LoginContext.loginUser.module_role.find((v) => v.id === managementBook.module_id)?.role;
    if (Role.MODULE_MANAGER === role) {
      return true;
    }

    // ユニットリーダーロールのとき表示
    role = LoginContext.loginUser.unit_role.find((v) => v.id === managementBook.unit_id)?.role;
    if (Role.UNIT_LEADER === role) {
      return true;
    }

    return false;
  };

  // 修正ボタン(管理台帳が「否認」かつユーザーが「編集権限以上」の場合のみ表示する)
  const isModifyButtonVisible = (): boolean => {
    return managementBook?.status === WorkFlowStatus.REJECTED && LoginContext.loginUser.authority >= Authority.EDITOR;
  };

  // 発行ボタンを表示するかどうか
  const isPublishButtonVisible = (): boolean => {
    return managementBook?.status === WorkFlowStatus.APPROVED && LoginContext.loginUser.authority >= Authority.EDITOR;
  };

  // 改訂ボタンを表示するかどうか
  const isReviseButtonVisible = (): boolean => {
    if (!managementBookUnderRevision) return false;
    return (
      managementBook?.status === WorkFlowStatus.PUBLISHED &&
      LoginContext.loginUser.authority >= Authority.EDITOR &&
      managementBookUnderRevision.under_revision_management_book_id === 0
    );
  };

  // 作成中ボタンを表示するかどうか(初版のものだけしかない　且つ　初版のものが公開されていない)
  const isCreatingButtonVisible = (): boolean => {
    if (LoginContext.loginUser.authority < Authority.EDITOR) return false;
    if (!managementBookList) return false;
    if (!managementBookList.management_book_list) return false;
    const item = managementBookList.management_book_list.find(
      (e: ManagementBookItem) => e.status !== WorkFlowStatus.PUBLISHED && e.status !== WorkFlowStatus.ARCHIVED
    );
    if (!item) return false;
    if (item.original_management_book_id !== 0) return false;
    return managementBookId !== item.management_book_id;
  };

  // 改訂中ボタンを表示するかどうか
  const isRevisingButtonVisible = (): boolean => {
    if (LoginContext.loginUser.authority < Authority.EDITOR) return false;
    if (!managementBookUnderRevision) return false;
    return managementBookUnderRevision.under_revision_management_book_id !== 0;
  };

  // Editトグルボタン
  const isEditModeButtonVisible = (): boolean => {
    return managementBook?.status === WorkFlowStatus.EDITING && LoginContext.loginUser.authority >= Authority.EDITOR;
  };
  //#endregion

  //#region フロー系ボタン押下時の処理
  // 台帳ページの承認依頼ボタン
  const handleApplication = () => {
    if (EditContext.editMode) {
      alert(DialogMessages.PROMPTS_TO_DISABLE_EDIT_MODE);
      return;
    }

    if (managementBook.original_management_book_id === 0) {
      application(CreateNew);
    } else {
      setIsOpenApplicationDialog(true);
    }
  };

  const isEditingOffButtonVisible = () => {
    return (
      managementBook?.status === WorkFlowStatus.EDITING &&
      LoginContext.loginUser.authority === Authority.ADMIN &&
      managementBook?.editing_user_id !== 0 &&
      managementBook?.editing_user_id !== LoginContext.loginUser.user_id
    );
  };

  // 引き戻しボタン
  const handleCancel = () => {
    const reqData = new ManagementBookIdRequest(managementBook?.management_book_id);
    putManagementBookCancelRequest(reqData)
      .then(() => {
        mutateManagementBook();
        mutateManagementBookHistoryList();
        snackBarMessage.setMessage(SuccessMessages.CANCEL, "success");
      })
      .catch((error: any) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.CANCEL, "error");
      });
  };

  // 承認ボタン
  const handleApprove = () => {
    const reqData = new ManagementBookIdRequest(managementBook?.management_book_id);
    putManagementBookApproveRequest(reqData)
      .then(() => {
        mutateManagementBookHistoryList();
        mutateManagementBook();
        snackBarMessage.setMessage(SuccessMessages.APPROVE, "success");
      })
      .catch((error: any) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.APPROVE, "error");
      });
  };

  // 否認ボタン
  const handleReject = () => {
    const reqData: ApiBody<"/management_book_work_flow/reject", "put"> = {
      management_book_id: managementBook?.management_book_id,
      message: inputRejectMessage,
    };
    putManagementBookRejectRequest(reqData)
      .then(() => {
        mutateManagementBook();
        mutateRejectMessage();
        setInputRejectMessage("");
        snackBarMessage.setMessage(SuccessMessages.REJECT, "success");
        setIsOpenInputRejectMessageDialog(false);
      })
      .catch((error: any) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.REJECT, "error");
      });
  };

  // 修正ボタン
  const handleModify = () => {
    const reqData = new ManagementBookIdRequest(managementBook?.management_book_id);
    putManagementBookModifyRequest(reqData)
      .then(() => {
        mutateManagementBook();
        mutateManagementBookHistoryList();
        snackBarMessage.setMessage(SuccessMessages.MODIFY, "success");
      })
      .catch((error: any) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.MODIFY, "error");
      });
  };

  // 公開ボタン
  const handlePublish = () => {
    const reqData = new ManagementBookIdRequest(managementBook?.management_book_id);
    putManagementBookPublishRequest(reqData)
      .then(() => {
        mutateManagementBook();
        snackBarMessage.setMessage(SuccessMessages.PUBLISH, "success");
      })
      .catch((error: any) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.PUBLISH, "error");
      });
  };

  // 改訂ボタン
  const handleRevise = () => {
    const reqData = new ManagementBookIdRequest(managementBook?.management_book_id);
    postManagementBookReviseRequest(reqData)
      .then(() => {
        mutateManagementBookList();
        mutateManagementBook();
        mutateManagementBookUnderRevision();
        snackBarMessage.setMessage(SuccessMessages.REVISE, "success");
      })
      .catch((error: any) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.REVISE, "error");
      });
  };

  // 作成中ボタン
  const handleSwitchToCreating = () => {
    const item = managementBookList.management_book_list.find(
      (e: ManagementBookItem) => e.status !== WorkFlowStatus.PUBLISHED && e.status !== WorkFlowStatus.ARCHIVED
    );
    if (item) {
      setManagementBookId(item.management_book_id);
    }
  };

  // 改訂版に遷移する
  const handleSwitchToRevising = () => {
    if (managementBookUnderRevision === undefined) return;
    if (managementBookUnderRevision.under_revision_management_book_id !== 0) {
      setManagementBookId(managementBookUnderRevision.under_revision_management_book_id);
    }
  };
  //#endregion

  // 承認依頼
  const application = (detail: string) => {
    if (detail === "") {
      snackBarMessage.setMessage(WarningMessages.MANAGEMENT_BOOK_HISTORY_INPUT_LENGTH, "warning");
      return;
    }

    setDisableApplicationDialogButton(true);
    const reqData = new ManagementBookApplicationRequest(managementBook?.management_book_id, detail);

    putManagementBookApplicationRequest(reqData)
      .then(() => {
        setIsOpenApplicationDialog(false);
        setApplicationDialogDetail("");
        mutateManagementBookHistoryList();
        mutateManagementBook();
        snackBarMessage.setMessage(SuccessMessages.APPLICATION, "success");
      })
      .catch((error: any) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.APPLICATION, "error");
      })
      .finally(() => {
        setDisableApplicationDialogButton(false);
      });
  };

  // 承認依頼ダイアログの改訂内容入力時の処理
  const handleChangeDetail = (v: string) => {
    setApplicationDialogDetail(v);
  };

  // 承認依頼ダイアログを閉じる
  const closeApplicationDialog = () => {
    setIsOpenApplicationDialog(false);
    setApplicationDialogDetail("");
  };

  // 管理台帳改訂履歴の行をクリック
  const showSelectedManagementBook = (management_book_id: number) => {
    if (EditContext.editMode) return;
    if (management_book_id === managementBookId) return;
    if (LoginContext.loginUser.authority === Authority.GENERAL) return;
    if (window.confirm(DialogMessages.JUMP_MANAGEMENT_BOOK)) {
      setManagementBookId(management_book_id);
    }
  };

  //#region 表示用データセットする
  // sort for display factory combobox
  let factoryDisplayComboBox = new FactoryDisplayComboBox();
  if (factoryList) {
    if (factoryList.factory !== undefined) {
      let sorted = new FactoryListResponse();
      sorted.factory = sortByAsc(factoryList.factory, "factory_id");
      if (sorted.factory !== undefined) factoryDisplayComboBox.setData(sorted);
    }
  }

  // sort for display plant combobox
  let plantDisplayComboBox = new PlantDisplayComboBox();
  if (plantList) {
    if (plantList.plant !== undefined) {
      let sorted = new PlantListResponse();
      sorted.plant = sortByAsc(plantList.plant, "plant_id");
      if (sorted.plant !== undefined) plantDisplayComboBox.setData(sorted);
    }
  }

  // sort for display line combobox
  let lineDisplayComboBox = new LineDisplayComboBox();
  if (lineList) {
    if (lineList.line !== undefined) {
      let sorted = new LineListResponse();
      sorted.line = sortByAsc(lineList.line, "line_id");
      if (sorted.line !== undefined) lineDisplayComboBox.setData(sorted);
    }
  }

  // sort for display unit combobox
  let unitDisplayComboBox = new UnitDisplayComboBox();
  if (unitList) {
    if (unitList.unit !== undefined) {
      let sorted = new UnitListResponse();
      sorted.unit = sortByAsc(unitList.unit, "unit_id");
      if (sorted.unit !== undefined) unitDisplayComboBox.setData(sorted);
    }
  }

  // sort for display team combobox
  let teamDisplayComboBox = new TeamDisplayComboBox();
  if (teamList) {
    if (teamList.team !== undefined) {
      let sorted = new TeamListResponse();
      sorted.team = sortByAsc(teamList.team, "team_id");
      if (sorted.team !== undefined) teamDisplayComboBox.setData(sorted);
    }
  }

  // sort for display model combobox
  let modelDisplayComboBox = new ModelDisplayComboBox();
  if (modelList) {
    if (modelList.model !== undefined) {
      let sorted = new ModelListResponse();
      sorted.model = sortByAsc(modelList.model, "model_id");
      if (sorted.model !== undefined) modelDisplayComboBox.setData(sorted);
    }
  }

  // sort for display volume combobox
  let volumeDisplayComboBox = new VolumeDisplayComboBox();
  if (volumeList) {
    if (volumeList.volume !== undefined) {
      let sorted = new VolumeListResponse();
      sorted.volume = sortByAsc(volumeList.volume, "volume_id");
      if (sorted.volume !== undefined) volumeDisplayComboBox.setData(sorted);
    }
  }

  const productionCapacityOptions = useMemo<ListOption[]>(() => {
    if (!productionCapacityListOfPublishedManagementBookList) return [];
    if (!productionCapacityListOfPublishedManagementBookList.production_capacity_and_management_book_id_list) return [];
    return productionCapacityListOfPublishedManagementBookList.production_capacity_and_management_book_id_list.map((v): ListOption => {
      return { key: v.management_book_id, value: String(v.production_capacity) };
    });
  }, [productionCapacityListOfPublishedManagementBookList]);
  //#endregion

  //#region 表示の分岐が多いのでここでやる
  var comp;
  if (selectModelItem.id === 0 || selectTeamItem.id === 0 || selectVolumeItem.id === 0) {
    comp = (
      <Grid container spacing={1} style={{ margin: theme.spacing(0, 1) }}>
        <Grid item xs={12}>
          <Typography className={styles.message}>{ManagementBookPageLabel.MANAGEMENT_BOOK_UNSELECTED}</Typography>
        </Grid>
      </Grid>
    );
  } else if (managementBookList?.management_book_list === null) {
    comp = (
      <Grid container spacing={1} style={{ margin: theme.spacing(0, 1) }}>
        <Grid item xs={12}>
          <Typography className={styles.message}>{ManagementBookPageLabel.MANAGEMENT_BOOK_UNCREATED}</Typography>
        </Grid>
        {LoginContext.loginUser.authority > Authority.GENERAL && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormGroup row>
                <FormControlLabel
                  control={<Checkbox checked={checkedDuplicate} onChange={() => setCheckedDuplicate(!checkedDuplicate)} />}
                  label={ManagementBookPageLabel.MANAGEMENT_BOOK_COPY_CHECKBOX_LABEL}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={2}>
              <ListBox
                disable={!checkedDuplicate}
                disableClearable
                label={ManagementBookPageLabel.MANAGEMENT_BOOK_COPY_SOURCE_LISTBOX_LABEL}
                options={productionCapacityOptions}
                value={selectedDuplicateProductionCapacity}
                onChange={(e) => {
                  setSelectedDuplicateProductionCapacity(e);
                }}
              />
            </Grid>
            <Grid item xs={10} />
            <Grid item xs={12}>
              <Button onClick={handleRegistManagementBook} variant={"contained"} color={"secondary"}>
                {ManagementBookPageLabel.MANAGEMENT_BOOK_CREATE_BUTTON_LABEL}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  } else if (isCreatingButtonVisible()) {
    comp = (
      <Grid container spacing={1} style={{ margin: theme.spacing(0, 1) }}>
        <Grid item xs={12}>
          <Typography className={styles.message}>{ManagementBookPageLabel.MANAGEMENT_BOOK_UNPUBLISHED_LABEL}</Typography>
        </Grid>
      </Grid>
    );
  } else {
    comp = (
      <>
        <TabPanel value={value} index={0}>
          <Grid container spacing={1} style={{ height: tabContentHeight, padding: theme.spacing(0, 1) }}>
            <Grid item xs={6} direction="column" style={{ display: "flex", height: "100%" }}>
              <Typography variant="h6" noWrap style={{ fontWeight: "bold" }}>
                {ManagementBookWorkStandardListTabLabel.MANAGEMENT_BOOK_TABLE_TITLE}
              </Typography>
              <OperationManagementBookTable
                processContents={operationListDisplay}
                selectModelId={selectModelItem.id}
                selectTeamId={selectTeamItem.id}
                handleAddedOperationInProcess={handleAddedOperationInProcess}
                handleOperationInProcessUpdate={handleOperationInProcessOrderUpdate}
                handleOperationInProcessDelete={handleOperationInProcessDelete}
                handleShowHome={handleShowHome}
              />
            </Grid>
            {LoginContext.loginUser.authority !== 0 && (
              <Grid item xs={6} direction="column" style={{ display: "flex", height: "100%" }}>
                <Typography variant="h6" noWrap style={{ fontWeight: "bold" }}>
                  {ManagementBookWorkStandardListTabLabel.UNLINK_TABLE_TITLE}
                </Typography>
                <UnlinkedWorkStandardTable workStandardDisplayRows={operationListDisplay} handleSwapProcess={handleSwapProcess} />
              </Grid>
            )}
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container spacing={1} style={{ height: tabContentHeight, padding: theme.spacing(0, 1) }}>
            <Grid item xs={12} direction="column" style={{ display: "flex", height: "100%" }}>
              <Typography variant="h6" noWrap style={{ fontWeight: "bold" }}>
                {ManagementBookProcessListTabLabel.PROCESS_TABLE_TITLE}
              </Typography>
              <ProcessManagementBookTable
                processDisplayRows={processListDisplay}
                managementBookId={managementBook?.management_book_id}
                handleRefleshProcess={handleRefleshProcess}
                handleSwapProcess={handleSwapProcess}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <InfomationManagementBookTab
            contentHeight={tabContentHeight}
            managementBook={managementBook ? managementBook : new ManagementBookItem()}
            productionCapacityHistoryList={productionCapacityHistoryList ?? new ProductionCapacityHistoryListResponse()}
            managementBookHistoryList={managementBookHistoryList ?? new ManagementBookHistoryListResponse()}
            handleClickHistoryRow={showSelectedManagementBook}
          />
        </TabPanel>
      </>
    );
  }
  //#endregion

  return (
    <>
      <div className={styles.appBarSpacer} />
      <div ref={listBoxesRef} className={styles.listBoxes}>
        <ApplicationUnderRevisionDialog
          open={isOpenApplicationDialog}
          onChangeDetail={handleChangeDetail}
          onSubmit={() => application(applicationDialogDetail)}
          onCancel={closeApplicationDialog}
          disableRegisterButton={disableApplicationDialogButton}
        />
        <RejectMessageDialog
          open={isOpenViewRejectMessageDialog}
          rejectMessage={rejectMessage}
          onClose={() => {
            setIsOpenViewRejectMessageDialog(false);
          }}
        />
        <RejectMessageDialog
          open={isOpenInputRejectMessageDialog}
          isInput
          rejectMessage={{
            message: inputRejectMessage,
            login_id: LoginContext.loginUser.login_id,
            user_name: LoginContext.loginUser.user_name,
          }}
          onChange={setInputRejectMessage}
          onClose={() => {
            setInputRejectMessage("");
            setIsOpenInputRejectMessageDialog(false);
          }}
          onReject={handleReject}
        />
        <SelectArea
          factoryDisplayComboBox={factoryDisplayComboBox}
          plantDisplayComboBox={plantDisplayComboBox}
          lineDisplayComboBox={lineDisplayComboBox}
          unitDisplayComboBox={unitDisplayComboBox}
          teamDisplayComboBox={teamDisplayComboBox}
          selectedFactoryItem={selectFactoryItem.name}
          selectedPlantItem={selectPlantItem.name}
          selectedLineItem={selectLineItem.name}
          selectedUnitItem={selectUnitItem.name}
          selectedTeamItem={selectTeamItem.name}
          handleSelectFactory={handleSelectFactory}
          handleSelectPlant={handleSelectPlant}
          handleSelectModule={handleSelectLine}
          handleSelectUnit={handleSelectUnit}
          handleSelectTeam={handleSelectTeam}
        />
        <SelectModel
          modelDisplayComboBox={modelDisplayComboBox}
          volumeDisplayComboBox={volumeDisplayComboBox}
          selectedModelItem={selectModelItem.name}
          selectedVolumeItem={selectVolumeItem.name}
          handleSelectModel={handleSelectModel}
          handleSelectVolume={handleSelectVolume}
        />
      </div>
      <Grid>
        <Toolbar ref={tabBarRef}>
          <Tabs value={value} onChange={handleChangeTab}>
            <Tab label={ManagementBookPageLabel.WORK_STANDARD_LIST_TAB} />
            <Tab label={ManagementBookPageLabel.PROCESS_LIST_TAB} />
            <Tab label={ManagementBookPageLabel.MANAGEMENT_TAB} />
          </Tabs>
          {/* 否認理由確認ボタン */}
          {isRejectMessageButtonVisible() && (
            <Button
              className={styles.button}
              size={"small"}
              variant={"contained"}
              color={"secondary"}
              onClick={() => {
                setIsOpenViewRejectMessageDialog(true);
              }}
            >
              {ManagementBookPageLabel.REJECT_MESSAGE}
            </Button>
          )}
          {/* 承認依頼ボタン */}
          {isApplicationButtonVisible() && (
            <Button className={styles.button} size={"small"} variant={"contained"} color={"secondary"} onClick={handleApplication}>
              {WorkFlowButtonLabel.APPLICATION}
            </Button>
          )}
          {/* 引き戻しボタン */}
          {isCancelButtonVisible() && (
            <Button className={styles.button} size={"small"} variant={"contained"} color={"secondary"} onClick={handleCancel}>
              {WorkFlowButtonLabel.CANCEL}
            </Button>
          )}
          {/* 否認ボタン */}
          {isRejectButtonVisible() && (
            <Button
              className={styles.button}
              size={"small"}
              variant={"contained"}
              color={"secondary"}
              onClick={() => {
                setIsOpenInputRejectMessageDialog(true);
              }}
            >
              {WorkFlowButtonLabel.REJECT}
            </Button>
          )}
          {/* 修正ボタン */}
          {isModifyButtonVisible() && (
            <Button className={styles.button} size={"small"} variant={"contained"} color={"secondary"} onClick={handleModify}>
              {WorkFlowButtonLabel.MODIFY}
            </Button>
          )}
          {/* 承認ボタン */}
          {isApproveButtonVisible() && (
            <Button className={styles.button} size={"small"} variant={"contained"} color={"secondary"} onClick={handleApprove}>
              {WorkFlowButtonLabel.APPROVE}
            </Button>
          )}
          {/* 発行ボタン */}
          {isPublishButtonVisible() && (
            <Button className={styles.button} size={"small"} variant={"contained"} color={"secondary"} onClick={handlePublish}>
              {WorkFlowButtonLabel.PUBLISH}
            </Button>
          )}
          {/* 改訂ボタン */}
          {isReviseButtonVisible() && (
            <Button className={styles.button} size={"small"} variant={"contained"} color={"secondary"} onClick={handleRevise}>
              {WorkFlowButtonLabel.REVISE}
            </Button>
          )}
          {/* 作成中ボタン */}
          {isCreatingButtonVisible() && (
            <Button className={styles.button} size={"small"} variant={"contained"} color={"secondary"} onClick={handleSwitchToCreating}>
              {WorkFlowButtonLabel.EDITING}
            </Button>
          )}
          {/* 改訂中ボタン */}
          {isRevisingButtonVisible() && (
            <Button className={styles.button} size={"small"} variant={"contained"} color={"secondary"} onClick={handleSwitchToRevising}>
              {WorkFlowButtonLabel.REVISING}
            </Button>
          )}
          {/* Editモードボタン */}
          {isEditModeButtonVisible() && (
            <>
              <Typography className={"edit-label"}>{EditModeLabel.EDIT}</Typography>
              <Tooltip
                title={
                  disableEditing
                    ? managementBook.editing_user_name + " (" + managementBook.editing_user_login_id + ") " + TooltipMessages.EDITING_OTHER_USER
                    : ""
                }
                placement="top"
              >
                <div>
                  <Switch disabled={disableEditing} checked={EditContext.editMode} onChange={handleEditModeChange} />
                </div>
              </Tooltip>
            </>
          )}
          {isEditingOffButtonVisible() && (
            <Tooltip title={TooltipMessages.EDITING_OFF_ADMIN} placement="top">
              <IconButton className={styles.iconButton} size={"small"} onClick={handleEditingOff}>
                <LockOpenIcon />
              </IconButton>
            </Tooltip>
          )}
          {managementBook && (
            <Typography className={styles.revisionNumber}>
              {RevisionNumberLabel.PREFIX + managementBook.revision_number + RevisionNumberLabel.SUFFIX} (
              {WorkFlowStatusNamesLabel[managementBook.status]})
            </Typography>
          )}
        </Toolbar>
        {/* 表示用 */}
        {comp}
      </Grid>
    </>
  );
};

export default OperationManagementBook;
