const urlRoot = process.env.REACT_APP_ROOT_PATH_FOR_API_CALL;
const urlAuthentication = "/authentication";
const urlDataManagement = "/datamanagement";
const urlManagementBook = "/management_book";
const urlOperation = "/operation";
const urlFile = "/fileupload";
const urlOperationList = "/operationlist";
const urlWorkStandard = "/work_standard";
const urlWorkStandardList = "/work_standard_list";
const urlWorkFlow = "/work_flow";
const urlManagementBookWorkFlow = "/management_book_work_flow";
const urlExtra = "/extra";

export type ApiInfo = {
  name: ApiNameType;
  // ApiInfoを既に使用している箇所に影響があるので、
  // 下記は一旦 ?(省略可)としている。呼び出し側修正後に ? を消す方向。
  method?: ApiMethod;
};

type ApiNameType = (typeof ApiName)[keyof typeof ApiName];
export const ApiName = {
  // Authentication
  SignUp: urlAuthentication + "/sign_up",
  Login: urlAuthentication + "/login",
  Logout: urlAuthentication + "/logout",
  User: urlAuthentication + "/user",
  UserAuthority: urlAuthentication + "/user_authority",
  UserAttach: urlAuthentication + "/user_attach",
  UserAttachMaster: urlAuthentication + "/user_attach_master",
  UserBelonging: urlAuthentication + "/user_belonging",

  // DataManagement
  UserDelete: urlDataManagement + "/user_delete",
  UserRole: urlDataManagement + "/user_role",
  SetRole: urlDataManagement + "/set_role",
  UserAllData: urlDataManagement + "/user_list",
  FactoryList: urlDataManagement + "/factory_list",
  PlantList: urlDataManagement + "/plant_list",
  LineList: urlDataManagement + "/line_list",
  Unit: urlDataManagement + "/unit",
  UnitUpdate: urlDataManagement + "/unit_name",
  UnitList: urlDataManagement + "/unit_list",
  ProductionCapacityCurrently: urlDataManagement + "/production_capacity_currently",
  ProductionCapacityHistory: urlDataManagement + "/production_capacity_history",
  UnitAndProductionCapacityList: urlDataManagement + "/unit_and_production_capacity_list",
  Team: urlDataManagement + "/team",
  TeamUpdate: urlDataManagement + "/team_name",
  TeamList: urlDataManagement + "/team_list",
  Model: urlDataManagement + "/model",
  ModelUpdate: urlDataManagement + "/model_name",
  ModelList: urlDataManagement + "/model_list",
  Volume: urlDataManagement + "/volume",
  VolumeList: urlDataManagement + "/volume_list",
  ProductionCapacityListOfPublishedManagementBook: urlDataManagement + "/production_capacity_list_of_published_management_book",
  UserSectionList: urlDataManagement + "/user_section_list",

  // ManagementBook
  ManagementBook: urlManagementBook + "/management_book",
  Duplicate: urlManagementBook + "/duplicate",
  ManagementBookList: urlManagementBook + "/management_book_list",
  ManagementBookProcessList: urlManagementBook + "/process_list",
  ManagementBookProcess: urlManagementBook + "/process",
  ManagementBookProcessName: urlManagementBook + "/process_name",
  ProcessOrder: urlManagementBook + "/process_order",
  ManagementBookOperationInProcessList: urlManagementBook + "/operation_in_process_list",
  ManagementBookHistory: urlManagementBook + "/management_book_history",
  ManagementBookUnderRevision: urlManagementBook + "/under_revision",
  OperationInProcess: urlManagementBook + "/operation_in_process",
  ManagementBookEditingOffAll: urlManagementBook + "/editing_off_all",

  // Operation
  Operation: urlOperation + "/operation",
  OperationName: urlOperation + "/operation_name",
  Parts: urlOperation + "/parts",
  Equipment: urlOperation + "/equipment",
  Requirement: urlOperation + "/requirement",
  ApproverList: urlOperation + "/approver_list",
  Approver: urlOperation + "/approver",
  Confirmer_list: urlOperation + "/confirmer_list",
  Confirmer: urlOperation + "/confirmer",
  SafetyPointList: urlOperation + "/safety_point_list",
  SafetyPoint: urlOperation + "/safety_point",
  HistoryList: urlOperation + "/operation_history_list",
  History: urlOperation + "/operation_history",
  OperatorSignatureList: urlOperation + "/operator_signature_list",
  OperatorSignature: urlOperation + "/operator_signature",
  OperationCardList: urlOperation + "/operation_card_list",
  OperationCard: urlOperation + "/operation_card",
  OperationPointList: urlOperation + "/operation_point_list",
  OperationHashItemList: urlOperation + "/operation_hash_item_list",
  OperationHashMasterList: urlOperation + "/operation_hash_master_list",
  OperationHashItem: urlOperation + "/operation_hash_item",
  OperationPoint: urlOperation + "/operation_point",
  OperationPointLink: urlOperation + "/operation_point_link",
  OperationRevise: urlOperation + "/revise",
  IllustrationPageList: urlOperation + "/illustration_page_list",
  IllustrationPage: urlOperation + "/illustration_page",
  IllustrationList: urlOperation + "/illustration_list",

  // WorkStandard
  WorkStandardDelete: urlWorkStandard + "/work_standard",
  WorkStandardDuplicate: urlWorkStandard + "/duplicate",
  EditingOn: urlWorkStandard + "/editing_on",
  EditingOff: urlWorkStandard + "/editing_off",
  EditingOffAll: urlWorkStandard + "/editing_off_all",
  CanOperatorSign: urlWorkStandard + "/can_operator_sign",
  WorkStandardHistoryDetail: urlWorkStandard + "/work_standard_history_detail",

  // File
  UploadSignedUrl: urlFile + "/upload_signed_url",
  ViewSignedUrl: urlFile + "/view_signed_url",

  // OperationList
  OperationPublishList: urlOperationList + "/operation_publish_list",

  // WorkStandardList
  WorkStandardList: urlWorkStandardList + "/work_standard_list",

  // WorkFlow
  WorkFlowApplication: urlWorkFlow + "/application",
  WorkFlowPublish: urlWorkFlow + "/publish",
  WorkFlowCancel: urlWorkFlow + "/cancel",
  WorkFlowReject: urlWorkFlow + "/reject",
  WorkFlowModify: urlWorkFlow + "/modify",
  WorkFlowRevisingApplication: urlWorkFlow + "/revising_application",
  WorkFlowUnderRevision: urlWorkFlow + "/under_revision",
  AuthorizedOperation: urlWorkFlow + "/authorized_operation",
  WorkFlowRevisingApprove: urlWorkFlow + "/revising_approve",
  WorkFlowCancelUnderRevision: urlWorkFlow + "/cancel_under_revision",
  WorkFlowModifyUnderRevision: urlWorkFlow + "/modify_under_revision",
  WorkFlowPublishUnderRevision: urlWorkFlow + "/publish_under_revision",

  // ManagementBookWorkFlow
  ManagementBookWorkFlowApplication: urlManagementBookWorkFlow + "/application",
  ManagementBookWorkFlowCancel: urlManagementBookWorkFlow + "/cancel",
  ManagementBookWorkFlowApprove: urlManagementBookWorkFlow + "/approve",
  ManagementBookWorkFlowReject: urlManagementBookWorkFlow + "/reject",
  ManagementBookWorkFlowModify: urlManagementBookWorkFlow + "/modify",
  ManagementBookWorkFlowPublish: urlManagementBookWorkFlow + "/publish",
  ManagementBookWorkFlowRevise: urlManagementBookWorkFlow + "/revise",

  ModuleRole: urlDataManagement + "/module_role",
  UnitRole: urlDataManagement + "/unit_role",
  TeamRole: urlDataManagement + "/team_role",

  // Extra
  UserFeedback: urlExtra + "/user_feedback",
} as const;

export enum ApiMethod {
  Post,
  Get,
  Put,
  Delete,
  Patch,
}

const paramSet = "?";
const paramAnd = "&";
const paramSlash = "/";
const paramFactoryId = "factory_id=";
const paramPlantId = "plant_id=";
const paramLineId = "line_id=";
const paramModuleId = "module_id=";
const paramUnitId = "unit_id=";
const paramTeamId = "team_id=";
const paramModelId = "model_id=";
const paramProductionCapacityId = "production_capacity_id=";
const paramManagementBookId = "management_book_id=";
const paramOperationId = "operation_id=";
const paramOperationCardId = "operation_card_id=";
const paramWorkStandardId = "work_standard_id=";
const paramUserId = "user_id=";
const paramSectionId = "section_id=";
const key = "key=";
const OperationCardId = "operation_card_id=";

export const createEndPoint = (targetApi: ApiInfo, param?: string[]): string => {
  let endPoint = urlRoot + targetApi.name;

  switch (targetApi.name) {
    //ユーザーの役職を一括で取得
    case ApiName.UserRole:
      if (param !== undefined && param.length === 1) {
        endPoint += paramSet + paramUserId + param[0];
      }
      break;

    // ユーザ一覧取得
    case ApiName.UserAllData:
      if (param !== undefined && param.length === 5) {
        endPoint +=
          paramSet +
          paramFactoryId +
          param[0] +
          paramAnd +
          paramPlantId +
          param[1] +
          paramAnd +
          paramModuleId +
          param[2] +
          paramAnd +
          paramUnitId +
          param[3] +
          paramAnd +
          paramTeamId +
          param[4];
      }
      break;

    // 役職一覧取得
    case ApiName.UserSectionList:
      if (param !== undefined && param.length === 1) {
        endPoint += paramSet + paramUserId + param[0];
      }
      break;

    // 工場一覧取得
    case ApiName.PlantList:
      if (param !== undefined && param.length === 1) {
        endPoint += paramSet + paramFactoryId + param[0];
      }
      break;

    // ライン一覧取得
    case ApiName.LineList:
      if (param !== undefined && param.length === 1) {
        endPoint += paramSet + paramPlantId + param[0];
      }
      break;

    // ユニット一覧取得
    case ApiName.UnitList:
      if (param !== undefined && param.length === 1) {
        endPoint += paramSet + paramLineId + param[0];
      }
      break;

    // 生産台数履歴を取得
    case ApiName.ProductionCapacityHistory:
      if (param !== undefined && param.length === 1) {
        endPoint += paramSet + paramProductionCapacityId + param[0];
      }
      break;

    // ユニットと生産台数の一覧を取得
    case ApiName.UnitAndProductionCapacityList:
      if (param !== undefined && param.length === 1) {
        endPoint += paramSet + paramModuleId + param[0];
      }
      break;

    // チーム一覧取得
    case ApiName.TeamList:
      if (param !== undefined && param.length === 1) {
        endPoint += paramSet + paramUnitId + param[0];
      }
      break;

    // モデル(機種)一覧取得
    case ApiName.ModelList:
      if (param !== undefined && param.length === 1) {
        endPoint += paramSet + paramPlantId + param[0];
      }
      break;

    // 生産台数一覧取得
    case ApiName.VolumeList:
      if (param !== undefined && param.length === 1) {
        endPoint += paramSet + paramUnitId + param[0];
      }
      break;

    // 公開中の管理台帳があるユニット生産台数一覧取得
    case ApiName.ProductionCapacityListOfPublishedManagementBook:
      if (param !== undefined && param.length === 2) {
        endPoint += paramSet + paramModelId + param[0] + paramAnd + paramTeamId + param[1];
      }
      break;

    // 管理台帳取得
    case ApiName.ManagementBook:
      if (targetApi.method === ApiMethod.Get) {
        endPoint += paramSet + paramManagementBookId + param[0];
      }
      break;

    case ApiName.ManagementBookList:
      if (targetApi.method === ApiMethod.Get && param !== undefined && param.length === 3) {
        endPoint += paramSet + paramModelId + param[0] + paramAnd + paramTeamId + param[1] + paramAnd + paramProductionCapacityId + param[2];
      }
      break;

    // 管理台帳の工程一覧取得
    case ApiName.ManagementBookProcessList:
      if (param !== undefined && param.length === 1) {
        endPoint += paramSet + paramManagementBookId + param[0];
      }
      break;

    case ApiName.ManagementBookProcess:
      if (param !== undefined && param.length === 1) {
        endPoint += paramSlash + param[0];
      }
      break;

    // 管理台帳の工程に紐づく作業標準一覧取得
    case ApiName.ManagementBookOperationInProcessList:
      if (param !== undefined && param.length === 1) {
        endPoint += paramSet + paramManagementBookId + param[0];
      }
      break;

    // 管理台帳の改訂履歴取得
    case ApiName.ManagementBookHistory:
      if (param !== undefined && param.length === 1) {
        endPoint += paramSet + paramManagementBookId + param[0];
      }
      break;

    // 管理台帳の改訂履歴取得
    case ApiName.ManagementBookUnderRevision:
      if (param !== undefined && param.length === 1) {
        endPoint += paramSet + paramManagementBookId + param[0];
      }
      break;

    // 管理台帳の工程に紐づく作業標準の操作
    case ApiName.OperationInProcess:
      if (targetApi.method === ApiMethod.Delete && param !== undefined && param.length === 1) {
        endPoint += paramSlash + param[0];
      }
      break;

    // 作業標準
    case ApiName.Operation:
      if (targetApi.method === ApiMethod.Get && param !== undefined && param.length === 1) {
        endPoint += paramSet + paramOperationId + param[0];
      }
      break;

    // 部品
    case ApiName.Parts:
      if (targetApi.method === ApiMethod.Get && param !== undefined && param.length === 1) {
        endPoint += paramSet + paramOperationId + param[0];
      } else if (targetApi.method === ApiMethod.Delete && param !== undefined && param.length === 1) {
        endPoint += paramSlash + param[0];
      }
      break;

    //安全保護具
    case ApiName.Equipment:
      if (targetApi.method === ApiMethod.Get && param !== undefined && param.length === 1) {
        endPoint += paramSet + paramOperationId + param[0];
      } else if (targetApi.method === ApiMethod.Delete && param !== undefined && param.length === 1) {
        endPoint += paramSlash + param[0];
      }
      break;

    // 管理項目
    case ApiName.Requirement:
      if (targetApi.method === ApiMethod.Get && param !== undefined && param.length === 1) {
        endPoint += paramSet + paramOperationId + param[0];
      } else if (targetApi.method === ApiMethod.Delete && param !== undefined && param.length === 1) {
        endPoint += paramSlash + param[0];
      }
      break;

    //承認者
    case ApiName.ApproverList:
      if (param !== undefined && param.length === 1) {
        endPoint += paramSet + paramOperationId + param[0];
      }
      break;

    //承認
    case ApiName.Approver:
      if (targetApi.method === ApiMethod.Delete && param !== undefined && param.length === 1) {
        endPoint += paramSlash + param[0];
      }
      break;

    //確認者
    case ApiName.Confirmer_list:
      if (targetApi.method === ApiMethod.Get && param !== undefined && param.length === 1) {
        endPoint += paramSet + paramOperationId + param[0];
      }
      break;

    //確認
    case ApiName.Confirmer:
      if (targetApi.method === ApiMethod.Delete && param !== undefined && param.length === 1) {
        endPoint += paramSlash + param[0];
      }
      break;

    // 安全ポイント取得
    case ApiName.SafetyPointList:
      if (param !== undefined && param.length === 1) {
        endPoint += paramSet + paramOperationId + param[0];
      }
      break;

    // 安全ポイント　登録/更新/削除
    case ApiName.SafetyPoint:
      if (targetApi.method === ApiMethod.Delete && param !== undefined && param.length === 1) {
        endPoint += paramSlash + param[0];
      }
      break;

    // 履歴 取得
    case ApiName.HistoryList:
      if (param !== undefined && param.length === 1) {
        endPoint += paramSet + paramOperationId + param[0];
      }
      break;

    // 作業標準を削除
    case ApiName.WorkStandardDelete:
      if (param !== undefined && param.length === 1) {
        endPoint += paramSlash + param[0];
      }
      break;

    // 作業者サインができるかどうか確認
    case ApiName.CanOperatorSign:
      if (param !== undefined && param.length === 1) {
        endPoint += paramSet + paramOperationId + param[0];
      }
      break;

    // 作業標準の改訂詳細履歴
    case ApiName.WorkStandardHistoryDetail:
      if (targetApi.method === ApiMethod.Get && param !== undefined && param.length === 1) {
        endPoint += paramSet + paramWorkStandardId + param[0];
      } else if (targetApi.method === ApiMethod.Delete && param !== undefined && param.length === 1) {
        endPoint += paramSlash + param[0];
      }
      break;

    // 作業確認者 取得
    case ApiName.OperatorSignatureList:
      if (param !== undefined && param.length === 1) {
        endPoint += paramSet + paramOperationId + param[0];
      }
      break;

    // 作業確認者 登録/削除
    case ApiName.OperatorSignature:
      if (targetApi.method === ApiMethod.Delete && param !== undefined && param.length === 1) {
        endPoint += paramSlash + param[0];
      }
      break;

    // 作業標準詳細取得
    case ApiName.OperationCardList:
      if (param !== undefined && param.length === 1) {
        endPoint += paramSet + paramOperationId + param[0];
      }
      break;

    // 作業標準詳細 更新
    case ApiName.OperationCard:
      if (targetApi.method === ApiMethod.Delete && param !== undefined && param.length === 1) {
        endPoint += paramSlash + param[0];
      }
      break;

    // 作業標準詳細ポイント取得
    case ApiName.OperationPointList:
      if (param !== undefined && param.length === 1) {
        endPoint += paramSet + paramOperationCardId + param[0];
      }
      break;

    // 作業標準詳細のハッシュ取得
    case ApiName.OperationHashItemList:
      if (param !== undefined && param.length === 1) {
        endPoint += paramSet + paramOperationCardId + param[0];
      }
      break;

    case ApiName.OperationHashItem:
      if (targetApi.method === ApiMethod.Delete && param !== undefined && param.length === 1) {
        endPoint += paramSlash + param[0];
      }
      break;

    // 作業標準詳細ポイント 登録/更新/削除
    case ApiName.OperationPoint:
      if (targetApi.method === ApiMethod.Delete && param !== undefined && param.length === 1) {
        endPoint += paramSlash + param[0];
      }
      break;

    // 作業標準詳細ポイント
    case ApiName.OperationPointLink:
      if (targetApi.method === ApiMethod.Patch && param !== undefined && param.length === 1) {
        endPoint += paramSlash + param[0];
      }
      break;

    case ApiName.UploadSignedUrl:
      if (param !== undefined && param.length === 1) {
        endPoint += paramSet + key + param[0];
      }
      break;

    case ApiName.ViewSignedUrl:
      if (param !== undefined && param.length === 1) {
        endPoint += paramSet + key + param[0];
      }
      break;

    case ApiName.IllustrationPageList:
      if (param !== undefined && param.length === 1) {
        endPoint += paramSet + OperationCardId + param[0];
      }
      break;

    case ApiName.IllustrationPage:
      if (targetApi.method === ApiMethod.Delete && param !== undefined && param.length === 1) {
        endPoint += paramSlash + param[0];
      }
      break;

    case ApiName.OperationPublishList:
      if (param !== undefined && param.length === 2) {
        endPoint += paramSet + paramTeamId + param[0] + paramAnd + paramModelId + param[1];
      }
      break;

    case ApiName.IllustrationList:
      if (param !== undefined && param.length === 1) {
        endPoint += paramSet + paramOperationId + param[0];
      }
      break;

    case ApiName.WorkStandardList:
      if (param !== undefined && param.length === 2) {
        endPoint += paramSet + paramTeamId + param[0] + paramAnd + paramModelId + param[1];
      }
      break;

    case ApiName.WorkFlowUnderRevision:
      if (param !== undefined && param.length === 1) {
        endPoint += paramSet + paramOperationId + param[0];
      }
      break;

    case ApiName.AuthorizedOperation:
      if (param !== undefined && param.length === 2) {
        endPoint += paramSet + paramOperationId + param[0] + paramAnd + paramUserId + param[1];
      }
      break;

    //指定されたモジュール/ユニット/チームの権限削除
    case ApiName.ModuleRole:
    case ApiName.UnitRole:
    case ApiName.TeamRole:
      if (param !== undefined && param.length === 2) {
        endPoint += paramSet + paramSectionId + param[0] + paramAnd + paramUserId + param[1];
      }
      break;
    default:
      break;
  }

  return endPoint;
};
