import React, { useContext } from "react";
import Chip from "@material-ui/core/Chip";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { EditInfo } from "../../context/appContextData";
import { OperationHashItemChip } from "./display/home";
import { HashContents } from "../../consts/opeationHashItem";
import { hashMap } from "../../consts/hash";
interface Props {
  chipContent: OperationHashItemChip;
  handleDelete: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexdirection: "row",
      padding: 5,
    },
    redChip: {
      color: "rgb(234,54,35)",
      fontSize: 18,
      fontWeight: "bold",
      borderRadius: 5,
      height: "100%",
      minHeight: 35,
      minWidth: 100,
      backgroundColor: "#FFFFFF",
      "&.MuiChip-outlined	": {
        borderColor: "rgb(234,54,35)",
        borderWidth: "3px",
      },
      "& .MuiChip-label": {
        overflowWrap: "anywhere",
        whiteSpace: "normal",
        textOverflow: "clip",
        width: "100%",
      },
    },
    blueChip: {
      color: "royalblue",
      fontSize: 18,
      fontWeight: "bold",
      borderRadius: 5,
      height: "100%",
      minHeight: 35,
      minWidth: 100,
      backgroundColor: "#FFFFFF",
      "&.MuiChip-outlined	": {
        borderColor: "royalblue",
        borderWidth: "3px",
      },
      "& .MuiChip-label": {
        overflowWrap: "anywhere",
        whiteSpace: "normal",
        textOverflow: "clip",
        width: "100%",
      },
    },
    chiperror: {
      fontSize: 18,
      borderRadius: 5,
      height: "100%",
      minHeight: 35,
      minWidth: 100,
      backgroundColor: "#F75D59",
      "& .MuiChip-label": {
        overflowWrap: "anywhere",
        whiteSpace: "normal",
        textOverflow: "clip",
        width: "100%",
      },
    },
    input: {
      width: 300,
    },
    tooltips: {
      fontSize: 18,
    },
  })
);

export const CompOperationHashItemChip = (props: Props) => {
  const styles = useStyles();
  const EditContext = useContext(EditInfo);

  return (
    <div className={styles.root}>
      {EditContext.editMode ? (
        <Chip
          className={props.chipContent.content === HashContents.ImportantProcessManagementItems ? styles.redChip : styles.blueChip}
          variant="outlined"
          label={"#" + hashMap.get(props.chipContent.content)}
          onDelete={() => props.handleDelete(props.chipContent.operation_hash_item_id)}
        />
      ) : (
        <Chip
          className={props.chipContent.content === HashContents.ImportantProcessManagementItems ? styles.redChip : styles.blueChip}
          variant="outlined"
          label={"#" + hashMap.get(props.chipContent.content)}
        />
      )}
    </div>
  );
};

export default CompOperationHashItemChip;
