import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { createStyles, makeStyles, TextField, Theme } from "@material-ui/core";
import { OperationHistory } from "./display/home";
import { grey } from "@material-ui/core/colors";
import { HistoryLimits } from "../../consts/inputLimits";
import { DialogButtonLabel, WorkStandardPageLabel } from "../../consts/label";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    create: {
      backgroundColor: theme.palette.secondary.main,
      color: "white",
      textAlign: "center",
      padding: 7,
    },
    cancel: {
      backgroundColor: grey[300],
      textAlign: "center",
      padding: 7,
    },
  })
);

export interface Props {
  open: boolean;
  historyDispData: OperationHistory;
  handleChangeTitle: any;
  handleChangeDetail: any;
  handleApply: any;
  handleCancel: any;
  disableRegisterButton: boolean;
}

export default function ApplyUnderRevisionDialog(props: Props) {
  const styles = useStyles();

  return (
    <Dialog aria-labelledby="definitive-regist-dialog-title" open={props.open}>
      <DialogTitle style={{ textAlign: "center" }} id="definitive-regist-dialog-title">
        {WorkStandardPageLabel.REVISING_DIALOG_TITLE}
      </DialogTitle>
      <List>
        <ListItem>
          <TextField
            size="small"
            label={WorkStandardPageLabel.OVERVIEW}
            onChange={(e) => {
              props.handleChangeTitle(e.currentTarget.value);
            }}
            inputProps={{ maxLength: HistoryLimits.INPUT_TITLE_MAX_LENGTH }}
          />
        </ListItem>
        <ListItem>
          <TextField
            size="small"
            label={WorkStandardPageLabel.DETAIL}
            onChange={(e) => {
              props.handleChangeDetail(e.currentTarget.value);
            }}
            inputProps={{ maxLength: HistoryLimits.INPUT_DETAIL_MAX_LENGTH }}
          />
        </ListItem>
        <ListItem>
          <TextField disabled size="small" defaultValue={props.historyDispData.author_name} label={WorkStandardPageLabel.CREATOR} />
        </ListItem>
        <ListItem
          button
          disabled={props.disableRegisterButton}
          onClick={() => {
            props.handleApply();
          }}
        >
          <ListItemText className={styles.create} primary={WorkStandardPageLabel.APPLICATION} />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            props.handleCancel();
          }}
        >
          <ListItemText className={styles.cancel} primary={DialogButtonLabel.CANCEL} />
        </ListItem>
      </List>
    </Dialog>
  );
}
