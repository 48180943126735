import { useContext } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { EditInfo, WindowSize } from "../../context/appContextData";
import { IconButton, Tooltip } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CreateIcon from "@material-ui/icons/Create";
import { DataManagementDisplayRows } from "../Common/display/display";
import Paper from "@material-ui/core/Paper";
import { purple } from "@material-ui/core/colors";

interface Props {
  headers: string[];
  contentRows: DataManagementDisplayRows;
  height: number;
  cellPadding: number;
  tableType: string;
  selectedId: number | null;
  canAddRow: boolean;
  canEditRow?: boolean;
  handleOnRowClick: (id: number, type: string) => void;
  handleEditRow: (id: number, isAdd: boolean, title: string, type: string, createdAt: string, updatedAt: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      overflow: "auto",
      textAlign: "center",
    },
    tableRow: {
      "&.Mui-selected": {
        backgroundColor: purple[100],
      },
    },
    nameRowDiv: {
      width: "150px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      verticalAlign: "middle",
      textAlign: "center",
      margin: "0 auto",
    },
  })
);

export default function DataManagementTable({
  headers,
  contentRows,
  height,
  cellPadding,
  tableType,
  selectedId,
  canAddRow,
  canEditRow = false,
  handleOnRowClick,
  handleEditRow,
}: Props) {
  const styles = useStyles();
  const EditContext = useContext(EditInfo);

  // click row event
  const onRowClick = (e: any, id: number) => {
    handleOnRowClick(id, tableType);
  };

  return (
    <div>
      <TableContainer component={Paper} className={styles.container} style={{ height: height }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {headers.map((header, index) => {
                return (
                  <TableCell key={index} align="center" style={{ padding: cellPadding }}>
                    {header}
                  </TableCell>
                );
              })}
              {/* editmodeに応じたheader切替 */}
              {/* editmode:on  TableHeadに追加ボタン配置 */}
              {EditContext.editMode && canAddRow && (
                <TableCell align="center" style={{ padding: cellPadding }}>
                  <IconButton size="small" onClick={() => handleEditRow(0, true, "", tableType, "", "")}>
                    <AddCircleIcon />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {contentRows.row.map((row, rowindex) => {
              return (
                <TableRow key={rowindex} className={styles.tableRow} hover onClick={(e) => onRowClick(e, row.id)} selected={row.id === selectedId}>
                  <TableCell align="center" style={{ padding: cellPadding }}>
                    {rowindex + 1}
                  </TableCell>

                  <TableCell align="center" style={{ padding: cellPadding }}>
                    <Tooltip title={row.name}>
                      <div className={styles.nameRowDiv}>{row.name}</div>
                    </Tooltip>
                  </TableCell>

                  {/* editmodeに応じたbody切替 */}
                  {/* editmode:on  TableBodyに編集ボタン配置 */}
                  {EditContext.editMode && (
                    <TableCell align="center" style={{ padding: cellPadding }}>
                      {canEditRow && (
                        <IconButton size="small" onClick={() => handleEditRow(row.id, false, row.name, tableType, row.createdAt, row.updatedAt)}>
                          <CreateIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
