import { fetchDelete, get, post, put } from "../apiCaller";
import { ApiMethod, ApiName, createEndPoint } from "../createEndPoint";
import { DataManagement, UserRoleUpdateRequest } from "../domain/request/datamanagement";
import useSWR from "swr";
import { useState } from "react";
import {
  FactoryListResponse,
  LineListResponse,
  ModelListResponse,
  PlantListResponse,
  ProductionCapacityHistoryListResponse,
  ProductionCapacityListOfPublishedManagementBookResponse,
  SectionListResponse,
  TeamListResponse,
  UnitListResponse,
  UnitProductionCapacityListResponse,
  UserAllDataResponse,
  UserAllRoleRespose,
  VolumeListResponse,
} from "../domain/response/datamanagement";
import { useAppSWR } from "../../hooks/useAppSWR";
import { AxiosError } from "axios";
import { ApiResponse } from "../../utils/schemaUtil"

//---FACTORY---
export const useFactory = (): [FactoryListResponse, () => void, () => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get);
  const getData = () => {
    setEndPoint(createEndPoint({ name: ApiName.FactoryList, method: ApiMethod.Get }));
  };
  const resetData = () => {
    setEndPoint(null);
  };
  const mutateData = () => {
    mutate(endPoint);
  };
  return [data, getData, resetData, mutateData, error];
};

//---PLANT---
export const usePlant = (): [PlantListResponse, (factory_id: string) => void, () => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get);
  const getData = (factory_id: string) => {
    setEndPoint(createEndPoint({ name: ApiName.PlantList, method: ApiMethod.Get }, [factory_id]));
  };
  const resetData = () => {
    setEndPoint(null);
  };
  const mutateData = () => {
    mutate(endPoint);
  };
  return [data, getData, resetData, mutateData, error];
};

//---Section---
export const useSectionList = (): [SectionListResponse, (user_id: string) => void, () => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get);
  const getData = (user_id: string) => {
    setEndPoint(createEndPoint({ name: ApiName.UserSectionList, method: ApiMethod.Get }, [user_id]));
  };
  const resetData = () => {
    setEndPoint(null);
  };
  const mutateData = () => {
    mutate(endPoint);
  };
  return [data, getData, resetData, mutateData, error];
};

//---LINE---
export const useLine = (): [ApiResponse<"/datamanagement/line_list", "get"> | undefined, (plantId: number) => void, () => void, () => void, AxiosError | undefined] => {
  const [stateData, setStateData] = useState<number>(0);

  const { data, mutate, error } = useAppSWR({
    url: "/datamanagement/line_list",
    method: "get",
    params: {
      plant_id: stateData,
    },
  });
  const getData = (plantId: number) => {
    mutate();
    setStateData(plantId);
  };
  const resetData = () => {
    mutate(undefined, false);
  };
  const mutateData = () => {
    mutate();
  };
  return [data, getData, resetData, mutateData, error];
};

//---UNIT---
export const postUnit = (body: DataManagement): Promise<any> => {
  const bodyJson = JSON.stringify(body);
  return post(createEndPoint({ name: ApiName.Unit }), bodyJson);
};

//名称変更
export const putUnit = (body: DataManagement): Promise<any> => {
  const bodyJson = JSON.stringify(body);
  return put(createEndPoint({ name: ApiName.UnitUpdate, method: ApiMethod.Put }), bodyJson);
};

export const useUnit = (): [UnitListResponse, (line_id: string) => void, () => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get);
  const getData = (line_id: string) => {
    setEndPoint(createEndPoint({ name: ApiName.UnitList }, [line_id]));
  };
  const resetData = () => {
    setEndPoint(null);
  };
  const mutateData = () => {
    mutate(endPoint);
  };
  return [data, getData, resetData, mutateData, error];
};

//---TEAM---
export const postTeam = (body: DataManagement): Promise<any> => {
  const bodyJson = JSON.stringify(body);
  return post(createEndPoint({ name: ApiName.Team }), bodyJson);
};

//名称変更
export const putTeam = (body: DataManagement): Promise<any> => {
  const bodyJson = JSON.stringify(body);
  return put(createEndPoint({ name: ApiName.TeamUpdate, method: ApiMethod.Put }), bodyJson);
};

export const useTeam = (): [TeamListResponse, (unit_id: string) => void, () => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get);
  const getData = (unit_id: string) => {
    setEndPoint(createEndPoint({ name: ApiName.TeamList }, [unit_id]));
  };
  const resetData = () => {
    setEndPoint(null);
  };
  const mutateData = () => {
    mutate(endPoint);
  };
  return [data, getData, resetData, mutateData, error];
};

//---MODEL---
export const postModel = (body: DataManagement): Promise<any> => {
  const bodyJson = JSON.stringify(body);
  return post(createEndPoint({ name: ApiName.Model, method: ApiMethod.Post }), bodyJson);
};

//名称変更
export const putModel = (body: DataManagement): Promise<any> => {
  const bodyJson = JSON.stringify(body);
  return put(createEndPoint({ name: ApiName.ModelUpdate, method: ApiMethod.Put }), bodyJson);
};

export const useModel = (): [ModelListResponse, (plant_id: string) => void, () => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get);
  const getData = (plant_id: string) => {
    setEndPoint(createEndPoint({ name: ApiName.ModelList, method: ApiMethod.Get }, [plant_id]));
  };
  const resetData = () => {
    setEndPoint(null);
  };
  const mutateData = () => {
    mutate(endPoint);
  };
  return [data, getData, resetData, mutateData, error];
};

//---VOLUME---
export const postVolume = (body: DataManagement): Promise<any> => {
  const bodyJson = JSON.stringify(body);
  return post(createEndPoint({ name: ApiName.Volume }), bodyJson);
};

export const useVolume = (): [VolumeListResponse, (unit_id: string) => void, () => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get);
  const getData = (unit_id: string) => {
    setEndPoint(createEndPoint({ name: ApiName.VolumeList }, [unit_id]));
  };
  const resetData = () => {
    setEndPoint(null);
  };
  const mutateData = () => {
    mutate(endPoint);
  };
  return [data, getData, resetData, mutateData, error];
};

export const useProductionCapacityListOfPublishedManagementBook = (): [ProductionCapacityListOfPublishedManagementBookResponse, (model_id: string, team_id: string) => void, () => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get);
  const getData = (model_id: string, team_id: string) => {
    setEndPoint(createEndPoint({ name: ApiName.ProductionCapacityListOfPublishedManagementBook }, [model_id, team_id]));
  };
  const resetData = () => {
    setEndPoint(null);
  };
  const mutateData = () => {
    mutate(endPoint);
  };
  return [data, getData, resetData, mutateData, error];
};

//---UNIT PRODUCTION CAPACITY---
export const useUnitProductionCapacity = (): [UnitProductionCapacityListResponse, (module_id: string) => void, () => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get, {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const getData = (module_id: string) => {
    setEndPoint(createEndPoint({ name: ApiName.UnitAndProductionCapacityList, method: ApiMethod.Get }, [module_id]));
  };
  const resetData = () => {
    setEndPoint(null);
  };
  const mutateData = () => {
    mutate(endPoint);
  };
  return [data, getData, resetData, mutateData, error];
};

//---PRODUCTION CAPACITY CURRENTLY---
export const putProductionCapacityCurrently = (body: DataManagement): Promise<any> => {
  const bodyJson = JSON.stringify(body);
  return put(createEndPoint({ name: ApiName.ProductionCapacityCurrently, method: ApiMethod.Put }), bodyJson);
};

//---UNIT PRODUCTION CAPACITY---
export const useProductionCapacityHistory = (): [
  ProductionCapacityHistoryListResponse,
  (production_capacity_id: string) => void,
  () => void,
  () => void,
  string
] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get);
  const getData = (production_capacity_id: string) => {
    setEndPoint(createEndPoint({ name: ApiName.ProductionCapacityHistory, method: ApiMethod.Get }, [production_capacity_id]));
  };
  const resetData = () => {
    setEndPoint(null);
  };
  const mutateData = () => {
    mutate(endPoint);
  };
  return [data, getData, resetData, mutateData, error];
};

//---USER DELETE---
export const putUserDelete = (body: DataManagement): Promise<any> => {
  const bodyJson = JSON.stringify(body);
  return put(createEndPoint({ name: ApiName.UserDelete, method: ApiMethod.Put }), bodyJson);
};

//---USER_HISTORY---
export const useUserHistory = (): [ApiResponse<"/datamanagement/user_history", "get"> | undefined, (userId: number) => void, () => void, () => void, AxiosError | undefined] => {
  const [stateData, setStateData] = useState<number>(0);

  const { data, mutate, error } = useAppSWR({
    url: "/datamanagement/user_history",
    method: "get",
    params: {
      user_id: stateData,
    },
  });
  const getData = (userId: number) => {
    mutate();
    setStateData(userId);
  };
  const resetData = () => {
    mutate(undefined, false);
  };
  const mutateData = () => {
    mutate();
  };
  return [data, getData, resetData, mutateData, error];
};

//---USER_Role---
export const useUserRole = (): [UserAllRoleRespose, (user_id: number) => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get);
  const getData = (user_id: number) => {
    setEndPoint(createEndPoint({ name: ApiName.UserRole, method: ApiMethod.Get }, [user_id.toString()]));
  };
  const mutateData = () => {
    mutate(endPoint);
  };
  return [data, getData, mutateData, error];
};

//---USER---
export const useAllUser = (): [
  UserAllDataResponse,
  (factory_id: number, plant_id: number, module_id: number, unit_id: number, team_id: number) => void,
  () => void,
  string
] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get);
  const getData = (factory_id: number, plant_id: number, module_id: number, unit_id: number, team_id: number) => {
    setEndPoint(
      createEndPoint({ name: ApiName.UserAllData, method: ApiMethod.Get }, [
        factory_id.toString(),
        plant_id.toString(),
        module_id.toString(),
        unit_id.toString(),
        team_id.toString(),
      ])
    );
  };
  const mutateData = () => {
    mutate(endPoint);
  };
  return [data, getData, mutateData, error];
};

export const putUserRole = (body: UserRoleUpdateRequest): Promise<any> => {
  const bodyJson = JSON.stringify(body);
  return put(createEndPoint({ name: ApiName.SetRole, method: ApiMethod.Put }), bodyJson);
};

export const postUserRole = (body: UserRoleUpdateRequest): Promise<any> => {
  const bodyJson = JSON.stringify(body);
  return post(createEndPoint({ name: ApiName.SetRole, method: ApiMethod.Post }), bodyJson);
};

export const deleteUserRole = (select_section: string, section_id: number, user_id: number): Promise<any> => {
  if (select_section === "Module") {
    return fetchDelete(createEndPoint({ name: ApiName.ModuleRole, method: ApiMethod.Delete }, [section_id.toString(), user_id.toString()]));
  } else if (select_section === "Unit") {
    return fetchDelete(createEndPoint({ name: ApiName.UnitRole, method: ApiMethod.Delete }, [section_id.toString(), user_id.toString()]));
  } else if (select_section === "Team") {
    return fetchDelete(createEndPoint({ name: ApiName.TeamRole, method: ApiMethod.Delete }, [section_id.toString(), user_id.toString()]));
  }

  return new Promise(() => {
  });
};
