import React, { Component } from "react";
import PropTypes from "prop-types";
import i18n from "i18next";

import { Flex } from "../flex";
import { CommonButton } from "../common";
import { DialogMessages } from "../../../consts/messages";

class ImageMapHeaderToolbar extends Component {
  static propTypes = {
    canvasRef: PropTypes.any,
    selectedItem: PropTypes.object,
    onDownloadFiles: PropTypes.func,
  };

  state = {
    open: false,
  };

  render() {
    const { canvasRef, selectedItem, onDownloadFiles } = this.props;
    const isCropping = canvasRef ? canvasRef.handler?.interactionMode === "crop" : false;
    return (
      <Flex className="rde-editor-header-toolbar-container" flex="1">
        <Flex.Item className="rde-canvas-toolbar rde-canvas-toolbar-add">
          <CommonButton
            className="rde-action-btn"
            shape="circle"
            disabled={isCropping}
            onClick={() => {
              const imageUploader = document.getElementById("image upload dialog");
              imageUploader.click();
              return;
            }}
            icon="image"
            tooltipTitle={i18n.t("action.image")}
            iconClassName="rde-action-btn-icon"
          />
          <CommonButton
            className="rde-action-btn"
            shape="circle"
            disabled={isCropping}
            onClick={() => {
              const videoUploader = document.getElementById("video upload dialog");
              videoUploader.click();
              return;
            }}
            icon="film"
            tooltipTitle={i18n.t("action.video")}
            iconClassName="rde-action-btn-icon"
          />
        </Flex.Item>
        <Flex.Item className="rde-canvas-toolbar rde-canvas-toolbar-download">
          <CommonButton
            className="rde-action-btn"
            shape="circle"
            disabled={isCropping}
            onClick={onDownloadFiles}
            icon="download"
            tooltipTitle={i18n.t("action.download")}
            iconClassName="rde-action-btn-icon"
          />
        </Flex.Item>
        <Flex.Item className="rde-canvas-toolbar rde-canvas-toolbar-delete">
          <CommonButton
            className="rde-action-btn"
            shape="circle"
            disabled={!selectedItem}
            onClick={() => {
              const result = window.confirm(DialogMessages.DELETE_OBJECT);
              if (result) {
                canvasRef.handler?.remove();
              }
              return;
            }}
            icon="trash"
            tooltipTitle={i18n.t("action.delete")}
            iconClassName="rde-action-btn-icon"
          />
        </Flex.Item>
        {
          // 振る舞い残す系なので残している
        }
        {/* <Flex.Item className="rde-canvas-toolbar rde-canvas-toolbar-history">
					<CommonButton
						className="rde-action-btn"
						disabled={isCropping || (canvasRef && !canvasRef.handler?.transactionHandler.undos.length)}
						onClick={() => canvasRef.handler?.transactionHandler.undo()}
					>
						<Icon name="undo-alt" style={{ marginRight: 8 }} />
						Undo
					</CommonButton>
					<CommonButton
						className="rde-action-btn"
						disabled={isCropping || (canvasRef && !canvasRef.handler?.transactionHandler.redos.length)}
						onClick={() => canvasRef.handler?.transactionHandler.redo()}
					>
						Redo
						<Icon name="redo-alt" style={{ marginLeft: 8 }} />
					</CommonButton>
				</Flex.Item> */}
      </Flex>
    );
  }
}

export default ImageMapHeaderToolbar;
