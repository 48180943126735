import { StatusCodes } from "../consts/statusCodes";
import { CommonErrors } from "./domain/response/common";

//GET
export const get = async (url: string): Promise<any> => {
  const res = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Content-Length": "0",
    },
    credentials: "include",
  }).catch((error) => {
    throw error.message;
  });

  if (!res.ok) {
    let commonErrors = new CommonErrors();
    commonErrors = await res.json();
    throw commonErrors.errors.message;
  }

  return res.json();
};

//POST
export const post = async (url: string, body: string): Promise<any> => {
  const res = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Content-Length": String(body.length),
    },
    body: body,
    credentials: "include",
  }).catch((error) => {
    throw error.message;
  });

  if (!res.ok) {
    let commonErrors = new CommonErrors();
    commonErrors = await res.json();
    throw commonErrors.errors.message;
  }

  return res.json();
};

//PUT
export const put = async (url: string, body: string): Promise<any> => {
  const res = await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Content-Length": String(body.length),
    },
    body: body,
    credentials: "include",
  }).catch((error) => {
    throw error.message;
  });

  if (!res.ok && res.status !== StatusCodes.NO_UPDATE) {
    let commonErrors = new CommonErrors();
    commonErrors = await res.json();
    throw commonErrors.errors;
  }

  // put(response code=204)時はレスポンスbody部なしなのでresをreturn
  return res;
};

// PUT
export const putKeepalive = async (url: string, body: string): Promise<any> => {
  const res = await fetch(url, {
    method: "PUT",
    keepalive: true,
    headers: {
      "Content-Type": "application/json",
      "Content-Length": String(body.length),
    },
    body: body,
    credentials: "include",
  }).catch((error) => {
    throw error.message;
  });

  if (!res.ok && res.status !== StatusCodes.NO_UPDATE) {
    let commonErrors = new CommonErrors();
    commonErrors = await res.json();
    throw commonErrors.errors;
  }

  // put(response code=204)時はレスポンスbody部なしなのでresをreturn
  return res;
};

//DELETE
// "delete"は変数宣言の名前として使用できないので、"fetchDelete"(仮)
export const fetchDelete = async (url: string): Promise<any> => {
  const res = await fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Content-Length": "0",
    },
    credentials: "include",
  }).catch((error) => {
    throw error.message;
  });

  if (!res.ok) {
    let commonErrors = new CommonErrors();
    commonErrors = await res.json();
    throw commonErrors.errors.message;
  }

  // delete(response code=204)時はレスポンスbody部なしなのでresをreturn
  return res;
};

//PATCH
export const patch = async (url: string, body: string): Promise<any> => {
  const res = await fetch(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "Content-Length": String(body.length),
    },
    body: body,
    credentials: "include",
  }).catch((error) => {
    throw error.message;
  });

  if (!res.ok) {
    let commonErrors = new CommonErrors();
    commonErrors = await res.json();
    throw commonErrors.errors.message;
  }

  // PATCH(response code=204)時はレスポンスbody部なしなのでresをreturn
  return res;
};

//PUT
export const putS3 = async (url: string, body: File): Promise<Response> => {
  const res = await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": body.type,
    },
    body: body,
  }).catch((error) => {
    throw error.message;
  });

  return res;
};
