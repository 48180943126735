import {
  LineListResponse,
  Line,
  TeamListResponse,
  TeamList,
  UnitListResponse,
  UnitList,
  ModelListResponse,
  ModelList,
  VolumeListResponse,
  VolumeList,
  FactoryListResponse,
  Factory,
  PlantListResponse,
  Plant,
  UnitProductionCapacityListResponse,
  UnitProductionCapacity,
  ProductionCapacityItem,
} from "../../../apicaller/domain/response/datamanagement";
import { ListOption } from "../ListBox";

export class DataManagementDisplayRow {
  id: number = 0;
  name: string = "";
  createdAt: string = "";
  updatedAt: string = "";
}

export interface DataManagementDisplayRows {
  row: DataManagementDisplayRow[];
}

export class UnitDisplayRows implements DataManagementDisplayRows {
  row: DataManagementDisplayRow[] = [];

  public setData(data: UnitListResponse) {
    this.row = data.unit.map((e: UnitList) => {
      return { id: e.unit_id, name: e.unit_name, createdAt: e.created_at, updatedAt: e.updated_at };
    });
  }
}

export class TeamDisplayRows implements DataManagementDisplayRows {
  row: DataManagementDisplayRow[] = [];

  public setData(data: TeamListResponse) {
    this.row = data.team.map((e: TeamList) => {
      return { id: e.team_id, name: e.team_name, createdAt: e.created_at, updatedAt: e.updated_at };
    });
  }
}

export class ModelDisplayRows implements DataManagementDisplayRows {
  row: DataManagementDisplayRow[] = [];

  public setData(data: ModelListResponse) {
    this.row = data.model.map((e: ModelList) => {
      return { id: e.model_id, name: e.model_name, createdAt: e.created_at, updatedAt: e.updated_at };
    });
  }
}

export class VolumeDisplayRows implements DataManagementDisplayRows {
  row: DataManagementDisplayRow[] = [];

  public setData(data: VolumeListResponse) {
    this.row = data.volume.map((e: VolumeList) => {
      return { id: e.volume_id, name: String(e.volume), createdAt: e.created_at, updatedAt: e.updated_at };
    });
  }
}

export interface DataManagementDisplayComboBox {
  text: string[];
}

export interface DataManagementDisplayListBox {
  options: ListOption[];
}

export class FactoryDisplayListBox implements DataManagementDisplayListBox {
  options: ListOption[] = [];

  public setData(data: FactoryListResponse) {
    this.options = data.factory.map<ListOption>((e: Factory) => {
      return { key: e.factory_id, value: e.factory_name };
    });
  }
}

export class PlantDisplayListBox implements DataManagementDisplayListBox {
  options: ListOption[] = [];

  public setData(data: PlantListResponse) {
    this.options = data.plant.map<ListOption>((e: Plant) => {
      return { key: e.plant_id, value: e.plant_name };
    });
  }
}

export class ModuleDisplayListBox implements DataManagementDisplayListBox {
  options: ListOption[] = [];

  public setData(data: LineListResponse) {
    this.options = data.line.map<ListOption>((e: Line) => {
      return { key: e.line_id, value: e.line_name };
    });
  }
}

export class UnitDisplayListBox implements DataManagementDisplayListBox {
  options: ListOption[] = [];

  public setData(data: UnitListResponse) {
    this.options = data.unit.map<ListOption>((e: UnitList) => {
      return { key: e.unit_id, value: e.unit_name };
    });
  }
}

export class TeamDisplayListBox implements DataManagementDisplayListBox {
  options: ListOption[] = [];

  public setData(data: TeamListResponse) {
    this.options = data.team.map<ListOption>((e: TeamList) => {
      return { key: e.team_id, value: e.team_name };
    });
  }
}

export class ModelDisplayListBox implements DataManagementDisplayListBox {
  options: ListOption[] = [];

  public setData(data: ModelListResponse) {
    this.options = data.model.map<ListOption>((e: ModelList) => {
      return { key: e.model_id, value: e.model_name };
    });
  }
}

export class FactoryDisplayComboBox implements DataManagementDisplayComboBox {
  text: string[] = [];

  public setData(data: FactoryListResponse) {
    this.text = data.factory.map((e: Factory) => e.factory_name);
  }
}

export class PlantDisplayComboBox implements DataManagementDisplayComboBox {
  text: string[] = [];

  public setData(data: PlantListResponse) {
    this.text = data.plant.map((e: Plant) => e.plant_name);
  }
}

export class LineDisplayComboBox implements DataManagementDisplayComboBox {
  text: string[] = [];

  public setData(data: LineListResponse) {
    this.text = data.line.map((e: Line) => e.line_name);
  }
}

export class UnitDisplayComboBox implements DataManagementDisplayComboBox {
  text: string[] = [];

  public setData(data: UnitListResponse) {
    this.text = data.unit.map((e: UnitList) => e.unit_name);
  }
}

export class TeamDisplayComboBox implements DataManagementDisplayComboBox {
  text: string[] = [];

  public setData(data: TeamListResponse) {
    this.text = data.team.map((e: TeamList) => e.team_name);
  }
}

export class ModelDisplayComboBox implements DataManagementDisplayComboBox {
  text: string[] = [];

  public setData(data: ModelListResponse) {
    this.text = data.model.map((e: ModelList) => e.model_name);
  }
}

export class VolumeDisplayComboBox implements DataManagementDisplayComboBox {
  text: string[] = [];

  public setData(data: VolumeListResponse) {
    this.text = data.volume.map((e: VolumeList) => String(e.volume));
  }
}

export class UnitProductionCapacityDisplayRow {
  id: number = 0;
  unitName: string = "";
  currentProductionCapacity: ListOption = { key: 0, value: "" };
  productionCapacityList: ListOption[] = [];
}

export class UnitProductionCapacityDisplayRows {
  row: UnitProductionCapacityDisplayRow[] = [];

  public setData(data: UnitProductionCapacityListResponse) {
    this.row = data.unit.map((e: UnitProductionCapacity): UnitProductionCapacityDisplayRow => {
      const productionCapacityList = e.production_capacity.map((ee: ProductionCapacityItem): ListOption => {
        return { key: ee.production_capacity_id, value: String(ee.production_capacity) };
      });
      const productionCapacityMap = Object.fromEntries(productionCapacityList.map((ee) => [ee.key, ee.value]));
      return {
        id: e.unit_id,
        unitName: e.unit_name,
        currentProductionCapacity: { key: e.current_production_capacity_id, value: String(productionCapacityMap[e.current_production_capacity_id]) },
        productionCapacityList: productionCapacityList,
      };
    });
  }
}
