import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { createStyles, makeStyles, TextField } from "@material-ui/core";
import { isOnlyHalfNumber } from "../../function/validationCheck";
import { Parts } from "./display/home";
import { Message } from "../../context/appContextData";
import { WarningMessages } from "../../consts/messages";
import { useContext } from "react";
import { Theme } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import { WorkStandardPartsLimits } from "../../consts/inputLimits";
import { DialogButtonLabel, WorkStandardWorkTabLabel } from "../../consts/label";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    regist: {
      backgroundColor: theme.palette.secondary.main,
      color: "white",
      textAlign: "center",
      padding: 7,
    },
    cancel: {
      backgroundColor: grey[300],
      textAlign: "center",
      padding: 7,
    },
    textField: {
      padding: 0,
    },
  })
);

export interface Props {
  open: boolean;
  partsDispData: Parts;
  handleChangeName: any;
  handleChangeCount: any;
  handleAdd: any;
  handleCancel: any;
}

export default function PartsTableAddDialog(props: Props) {
  const styles = useStyles();
  const snackBarMessage = useContext(Message);

  return (
    <Dialog aria-labelledby="parts-add-dialog" open={props.open}>
      <DialogTitle style={{ textAlign: "center" }} id="parts-add-dialog">
        {WorkStandardWorkTabLabel.ADD_PARTS_DIALOG_TITLE}
      </DialogTitle>
      <List>
        <ListItem>
          <TextField
            size="small"
            value={props.partsDispData.name}
            label={WorkStandardWorkTabLabel.PARTS_NAME}
            onChange={(e) => {
              props.handleChangeName(e.currentTarget.value);
            }}
            className={styles.textField}
            inputProps={{ maxLength: WorkStandardPartsLimits.INPUT_MAX_LENGTH }}
          />
        </ListItem>
        <ListItem>
          <TextField
            size="small"
            value={props.partsDispData.count}
            label={WorkStandardWorkTabLabel.PARTS_COUNT}
            className={styles.textField}
            onChange={(e) => {
              if (!isOnlyHalfNumber(e.currentTarget.value)) {
                snackBarMessage.setMessage(WarningMessages.NOT_ONLY_HALF_NUMBER, "warning");
                return;
              }
              if (e.currentTarget.value.length > 5) {
                snackBarMessage.setMessage(WarningMessages.OPERATION_PARTS_MAX_COUNT, "warning");
                return;
              }
              props.handleChangeCount(e.currentTarget.value);
            }}
          />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            props.handleAdd();
          }}
        >
          <ListItemText className={styles.regist} primary={DialogButtonLabel.REGIST} />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            props.handleCancel();
          }}
        >
          <ListItemText className={styles.cancel} primary={DialogButtonLabel.CANCEL} />
        </ListItem>
      </List>
    </Dialog>
  );
}
