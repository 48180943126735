import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { OperationChip } from "./OperationChip";
import DialogBoard from "../Home/DialogBoard";
import { EditInfo, Message } from "../../context/appContextData";
import {
  deleteOperationPoint,
  patchOperationPointLink,
  postOperationPoint,
  putOperationPointContent,
  putOperationPointLink,
  useOperationPoint,
} from "../../apicaller/repository/operation";
import { OperationPoint } from "../../apicaller/domain/response/operation";
import { OperationPointChip, OperationPointChipGroup } from "../Home/display/home";
import { sortByAsc } from "../../function/sort";
import ChipPoint from "../../icons/ChipPoint.png";
import ChipQualityCharacteristics from "../../icons/ChipQualityCharacteristics.png";
import ChipEventOccurring from "../../icons/ChipEventOccurring.png";
import ChipTools from "../../icons/ChipTools.png";
import ChipTrouble from "../../icons/ChipTrouble.png";
import { OperationPointRegistRequest, OperationPointUpdateContentRequest, OperationPointUpdateLinkRequest, } from "../../apicaller/domain/request/operation";
import { ErrorMessages, SuccessMessages } from "../../consts/messages";
import { useGetElementProperty } from "../../hooks/getElementProperty";
import { DialogContent } from "../Home/ChipDialog";
import { WorkStandardWorkTabLabel } from "../../consts/label";

interface Props {
  stepsCount: number;
  operationCardId: number;
  displayllustrationPageId: number;
  handleShowLinkedPage: any;
  isCardSelected: boolean;
}

const AvatarSource: string[] = [ChipPoint, ChipQualityCharacteristics, ChipEventOccurring, ChipTools, ChipTrouble];

export const OperationChipGroup = (props: Props) => {
  const EditContext = useContext(EditInfo);
  const snackBarMessage = useContext(Message);
  const [operationPointList, getOperationPointList, mutateOperationPointList, operationPointError] = useOperationPoint();
  const [operationPointDisplay, setOperationPointDisplay] = useState<OperationPointChipGroup>({ operation_points: [] });
  const [chipDialogContents, setChipDialogContents] = useState<DialogContent[]>([
    {
      name: WorkStandardWorkTabLabel.QUALITY_CHARACTERISTIC,
      image: ChipQualityCharacteristics,
      avatarSourceId: 1,
    },
    {
      name: WorkStandardWorkTabLabel.POINT,
      image: ChipPoint,
      avatarSourceId: 0,
    },
    {
      name: WorkStandardWorkTabLabel.TOOL,
      image: ChipTools,
      avatarSourceId: 3,
    },
    {
      name: WorkStandardWorkTabLabel.EVENT_OCCURRING,
      image: ChipEventOccurring,
      avatarSourceId: 2,
    },
    {
      name: WorkStandardWorkTabLabel.TROUBLE,
      image: ChipTrouble,
      avatarSourceId: 4,
    },
  ]);

  const groupRef = useRef(null);
  const groupWidth = useGetElementProperty(groupRef).getElementProperty("width");

  const compFunc = (a: OperationPointChip, b: OperationPointChip) => {
    const sourceId = chipDialogContents.map((e: DialogContent) => {
      return e.avatarSourceId;
    });
    if (sourceId.indexOf(a.category) < sourceId.indexOf(b.category)) {
      return -1;
    }
    if (sourceId.indexOf(a.category) > sourceId.indexOf(b.category)) {
      return 1;
    }
    return 0;
  };

  const sortedOperationPointDisplay: OperationPointChipGroup = useMemo(() => {
    return { operation_points: operationPointDisplay.operation_points.sort(compFunc) };
  }, [operationPointDisplay]);

  //repositoryのデータからdisplayデータを生成
  const createDisplayData = (): OperationPointChipGroup => {
    let ret = new OperationPointChipGroup();
    if (operationPointList.operation_point) {
      const tmp = operationPointList.operation_point.map((e: OperationPoint) => {
        return {
          operation_point_id: e.operation_point_id,
          operation_card_id: e.operation_card_id,
          page: e.page,
          category: e.category,
          source: AvatarSource[e.category],
          content: e.content,
          isError: false,
        };
      });
      ret.operation_points = sortByAsc(tmp, "operation_point_id");
    }
    return ret;
  };

  // add chip
  const handleChipAdd = (sourceId: number) => {
    const reqData = new OperationPointRegistRequest(props.operationCardId, sourceId, "");
    postOperationPoint(reqData).then(
      (data) => {
        mutateOperationPointList();
        snackBarMessage.setMessage(SuccessMessages.INSERT_OPERATION_POINT, "success");
      },
      (error) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.INSERT_OPERATION_POINT, "error");
      }
    );
  };

  // input change chip
  const handleChipChange = (operationPointId: number, e: React.FormEvent<HTMLInputElement>) => {
    const index = operationPointDisplay.operation_points.findIndex((e: OperationPointChip) => e.operation_point_id === operationPointId);
    operationPointDisplay.operation_points[index].content = e.currentTarget.value;
    operationPointDisplay.operation_points[index].isError = false;
    setOperationPointDisplay({ ...operationPointDisplay });
  };

  // update chip
  const handleChipContentUpdate = (operationPointId: number, e: React.FormEvent<HTMLInputElement>) => {
    // chipの更新処理はここに記述
    const req = new OperationPointUpdateContentRequest(operationPointId, e.currentTarget.value);

    putOperationPointContent(req).then(
      (data) => {
        mutateOperationPointList();
        snackBarMessage.setMessage(SuccessMessages.UPDATE_OPERATION_POINT, "success");
      },
      (error) => {
        //更新に失敗した場合はswrのデータから表示データを復元する
        const tmp = createDisplayData();
        //失敗したoperation_point_idのchipをエラー状態にする
        const index = tmp.operation_points.findIndex((op) => op.operation_point_id === operationPointId);
        tmp.operation_points[index].isError = true;
        setOperationPointDisplay(tmp);
        snackBarMessage.setMessage(ErrorMessages.UPDATE_OPERATION_POINT, "error");
      }
    );
  };

  // delete chip
  const handleChipDelete = (operationPointId: number) => {
    deleteOperationPoint(String(operationPointId)).then(
      (data) => {
        mutateOperationPointList();
        snackBarMessage.setMessage(SuccessMessages.DELETE_OPERATION_POINT, "success");
      },
      (error) => {
        snackBarMessage.setMessage(ErrorMessages.DELETE_OPERATION_POINT, "error");
      }
    );
  };

  // update link chip
  const handleLinkRegist = (operationPointId: number) => {
    const tmp = new OperationPointUpdateLinkRequest(operationPointId, props.displayllustrationPageId);
    putOperationPointLink(tmp).then(
      (data) => {
        mutateOperationPointList();
        snackBarMessage.setMessage(SuccessMessages.INSERT_OPERATION_POINT_LINK, "success");
      },
      (error) => {
        snackBarMessage.setMessage(ErrorMessages.INSERT_OPERATION_POINT_LINK, "error");
      }
    );
  };

  //  view link
  const handleLinkCancel = (operation_point_id: number) => {
    patchOperationPointLink(String(operation_point_id)).then(
      (data) => {
        mutateOperationPointList();
        snackBarMessage.setMessage(SuccessMessages.DELETE_OPERATION_POINT_LINK, "success");
      },
      (error) => {
        snackBarMessage.setMessage(ErrorMessages.DELETE_OPERATION_POINT_LINK, "error");
      }
    );
  };

  useEffect(() => {
    getOperationPointList(String(props.operationCardId));
  }, [props.operationCardId]);

  // change in SWR data
  useEffect(() => {
    if (!operationPointList) return;
    setOperationPointDisplay(createDisplayData());
  }, [operationPointList]);

  useEffect(() => {
    mutateOperationPointList();
  }, [props.stepsCount]);

  return (
    <div ref={groupRef}>
      {sortedOperationPointDisplay.operation_points.map((chipContent, index) => {
        return (
          <OperationChip
            key={index}
            parentWidth={groupWidth - 78}
            chipContent={chipContent}
            handleDelete={handleChipDelete}
            handleChange={handleChipChange}
            handleUpdate={handleChipContentUpdate}
            handleLinkRegist={handleLinkRegist}
            handleLinkCancel={handleLinkCancel}
            handleShowLinkedPage={props.handleShowLinkedPage}
            isCardSelected={props.isCardSelected}
          />
        );
      })}
      {EditContext.editMode && <DialogBoard AddHandle={handleChipAdd} chipDialogContents={chipDialogContents} />}
    </div>
  );
};
