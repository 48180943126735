import i18n from "i18next";

// インフォメーション
export class InfoMessages {}

// 成功
export const SuccessMessages = {
  //---COMMON---
  NO_UPDATE: "登録情報と更新内容が同じです。",
  //---LOGIN---
  POST_LOGIN: "ログインしました。",
  POST_LOGOUT: "ログアウトしました。",
  POST_ROLE: "役職を更新しました。",
  ROLE_DELETE: "役職を削除しました",
  //---HOME---
  // Safety_equipment
  UPDATE_SAFETY_EQIPMENT: "安全保護具情報を更新しました。",
  // Requirement
  INSERT_REQUIREMENT: "管理項目情報を登録しました。",
  DELETE_REQUIREMENT: "管理項目情報を削除しました。",
  // Parts
  INSERT_PARTS: "部品情報を登録しました。",
  UPDATE_PARTS: "部品情報を更新しました。",
  DELETE_PARTS: "部品情報を削除しました。",
  // Safety_point
  INSERT_POINT: "安全ポイント情報を登録しました。",
  UPDATE_POINT: "安全ポイント情報を更新しました。",
  DELETE_POINT: "安全ポイント情報を削除しました。",
  // History
  INSERT_HISTORY: "改廃履歴情報を登録しました。",
  // History Detail
  INSERT_HISTORY_DETAIL: "改訂履歴詳細を登録しました。",
  UPDATE_HISTORY_DETAIL: "改訂履歴詳細を更新しました。",
  DELETE_HISTORY_DETAIL: "改訂履歴詳細を削除しました。",
  //---WORKFLOW---
  APPLICATION: "承認依頼しました",
  CANCEL: "引き戻しました",
  CONFIRM: "確認しました",
  APPROVE: "承認しました",
  REJECT: "否認しました",
  MODIFY: "作成中にしました",
  PUBLISH: "発行しました",
  ARCHIVE_APPLICATION: "アーカイブ承認依頼しました",
  ARCHIVE_REJECT: "アーカイブ否認しました",
  ARCHIVE_APPROVE: "アーカイブにしました",
  REVISE: "改訂版を作成しました",
  // OperatorSignature
  INSERT_OPERATOR_SIGNATURE: "確認者サインを登録しました。",
  DELETE_OPERATOR_SIGNATURE: "確認者サインの取り消しをしました。",
  // Operation_card
  INSERT_OPERATION_CARD: "カードを追加しました。",
  UPDATE_OPERATION_CARD: "作業標準詳細タイトルを更新しました。",
  DELETE_OPERATION_CARD: "カードを削除しました。",
  // Operation_page
  INSERT_OPERATION_PAGE: "ページを追加しました。",
  DELETE_OPERATION_PAGE: "ページを削除しました。",
  // Operation_point
  INSERT_OPERATION_POINT: "作業標準詳細ポイントを登録しました。",
  UPDATE_OPERATION_POINT: "作業標準詳細ポイントを更新しました。",
  DELETE_OPERATION_POINT: "作業標準詳細ポイントを削除しました。",
  // Operation_Equipment
  INSERT_SAFETY_EQUIPMENT: "作業標準安全保護具を登録しました。",
  DELETE_SAFETY_EQUIPMENT: "作業標準安全保護具を削除しました。",
  // Operation_HashItem
  INSERT_OPERATION_HASH_ITEM: "作業標準詳細ハッシュを登録しました。",
  DELETE_OPERATION_HASH_ITEM: "作業標準詳細ハッシュを削除しました。",
  // Operation_point_link
  INSERT_OPERATION_POINT_LINK: "作業標準詳細ポイント紐付けを登録しました。",
  DELETE_OPERATION_POINT_LINK: "作業標準詳細ポイント紐付けを解除しました。",
  // Copy
  INSERT_COPIED_OPERATION: "改訂のため作業標準を複製しました。",
  //---OPERATION MANAGEMENT BOOK---
  INSERT_MANAGEMENT_BOOK: "管理台帳を作成しました。",
  UPDATE_PROCESS_ORDER: "工程順を更新しました。",
  // process
  INSERT_PROCESS: "工程を登録しました。",
  UPDATE_PROCESS: "工程を更新しました。",
  DELETE_PROCESS: "工程を削除しました。",
  // OperationInProcess
  INSERT_OPERATION_IN_PROCESS: "工程に作業の紐付けをしました。",
  UPDATE_OPERATION_IN_PROCESS_ORDER: "作業順を更新しました。",
  DELETE_OPERATION_IN_PROCESS: "工程から作業の紐付けを解除しました。",
  //---WORK STANDARD---
  CHANGE_WORK_STANDARD_NAME: "作業標準名を変更しました。",
  MOVE_WORK_STANDARD: "作業標準を他のチームに移動しました。",
  WORK_PERSON_SECONDS: "作業の標準工数を変更しました。",
  DELETE_WORK_STANDARD: "作業標準を削除しました。",
  //---DATA MANAGEMENT---
  // line
  INSERT_LINE: "ライン情報を登録しました。",
  UPDATE_LINE: "ライン情報を更新しました。",
  DELETE_LINE: "ライン情報を削除しました。",
  // unit
  INSERT_UNIT: "ユニット情報を登録しました。",
  UPDATE_UNIT: "ユニット情報を更新しました。",
  DELETE_UNIT: "ユニット情報を削除しました。",
  // production capacity
  UPDATE_UNIT_PRODUCTION_CAPACITY: "現在の生産台数を更新しました。",
  // team
  INSERT_TEAM: "チーム情報を登録しました。",
  UPDATE_TEAM: "チーム情報を更新しました。",
  DELETE_TEAM: "チーム情報を削除しました。",
  // model
  INSERT_MODEL: "機種情報を登録しました。",
  UPDATE_MODEL: "機種情報を更新しました。",
  DELETE_MODEL: "機種情報を削除しました。",
  // volume
  INSERT_VOLUME: "生産台数情報を登録しました。",
  DELETE_VOLUME: "生産台数情報を削除しました。",
  //---CREATE NEW---
  INSERT_OPERATION: "作業標準を作成しました。",
  // user
  POST_SIGN_UP: "新規ユーザーを登録しました。",
  DELETE_USER: "ユーザーを削除しました。",
  UPDATE_AUTHORITY: "ユーザー権限を更新しました。",
  UPDATE_ATTACH: "ユーザー情報の更新に成功しました。",
  //---EXTRA---
  // user feedback
  INSERT_USER_FEEDBACK: "ご意見ありがとうございます！より良いシステム開発に利用させていただきます！",
};

const updateSuccessMessages = () => {
  SuccessMessages.NO_UPDATE = i18n.t("success-messages.no-update");
  SuccessMessages.POST_LOGIN = i18n.t("success-messages.post-login");
  SuccessMessages.POST_LOGOUT = i18n.t("success-messages.post-logout");
  SuccessMessages.POST_ROLE = i18n.t("success-messages.post-role");
  SuccessMessages.ROLE_DELETE = i18n.t("success-messages.role-delete");
  SuccessMessages.UPDATE_SAFETY_EQIPMENT = i18n.t("success-messages.update-safety-eqipment");
  SuccessMessages.INSERT_REQUIREMENT = i18n.t("success-messages.insert-requirement");
  SuccessMessages.DELETE_REQUIREMENT = i18n.t("success-messages.delete-requirement");
  SuccessMessages.INSERT_PARTS = i18n.t("success-messages.insert-parts");
  SuccessMessages.UPDATE_PARTS = i18n.t("success-messages.update-parts");
  SuccessMessages.DELETE_PARTS = i18n.t("success-messages.delete-parts");
  SuccessMessages.INSERT_POINT = i18n.t("success-messages.insert-point");
  SuccessMessages.UPDATE_POINT = i18n.t("success-messages.update-point");
  SuccessMessages.DELETE_POINT = i18n.t("success-messages.delete-point");
  SuccessMessages.INSERT_HISTORY = i18n.t("success-messages.insert-history");
  SuccessMessages.INSERT_HISTORY_DETAIL = i18n.t("success-messages.insert-history-detail");
  SuccessMessages.UPDATE_HISTORY_DETAIL = i18n.t("success-messages.update-history-detail");
  SuccessMessages.DELETE_HISTORY_DETAIL = i18n.t("success-messages.delete-history-detail");
  SuccessMessages.APPLICATION = i18n.t("success-messages.application");
  SuccessMessages.CANCEL = i18n.t("success-messages.cancel");
  SuccessMessages.CONFIRM = i18n.t("success-messages.confirm");
  SuccessMessages.APPROVE = i18n.t("success-messages.approve");
  SuccessMessages.REJECT = i18n.t("success-messages.reject");
  SuccessMessages.MODIFY = i18n.t("success-messages.modify");
  SuccessMessages.PUBLISH = i18n.t("success-messages.publish");
  SuccessMessages.ARCHIVE_APPLICATION = i18n.t("success-messages.archive-application");
  SuccessMessages.ARCHIVE_REJECT = i18n.t("success-messages.archive-reject");
  SuccessMessages.ARCHIVE_APPROVE = i18n.t("success-messages.archive-approve");
  SuccessMessages.REVISE = i18n.t("success-messages.revise");
  SuccessMessages.INSERT_OPERATOR_SIGNATURE = i18n.t("success-messages.insert-operator-signature");
  SuccessMessages.DELETE_OPERATOR_SIGNATURE = i18n.t("success-messages.delete-operator-signature");
  SuccessMessages.INSERT_OPERATION_CARD = i18n.t("success-messages.insert-operation-card");
  SuccessMessages.UPDATE_OPERATION_CARD = i18n.t("success-messages.update-operation-card");
  SuccessMessages.DELETE_OPERATION_CARD = i18n.t("success-messages.delete-operation-card");
  SuccessMessages.INSERT_OPERATION_PAGE = i18n.t("success-messages.insert-operation-page");
  SuccessMessages.DELETE_OPERATION_PAGE = i18n.t("success-messages.delete-operation-page");
  SuccessMessages.INSERT_OPERATION_POINT = i18n.t("success-messages.insert-operation-point");
  SuccessMessages.UPDATE_OPERATION_POINT = i18n.t("success-messages.update-operation-point");
  SuccessMessages.DELETE_OPERATION_POINT = i18n.t("success-messages.delete-operation-point");
  SuccessMessages.INSERT_SAFETY_EQUIPMENT = i18n.t("success-messages.insert-safety-equipment");
  SuccessMessages.DELETE_SAFETY_EQUIPMENT = i18n.t("success-messages.delete-safety-equipment");
  SuccessMessages.INSERT_OPERATION_HASH_ITEM = i18n.t("success-messages.insert-operation-hash-item");
  SuccessMessages.DELETE_OPERATION_HASH_ITEM = i18n.t("success-messages.delete-operation-hash-item");
  SuccessMessages.INSERT_OPERATION_POINT_LINK = i18n.t("success-messages.insert-operation-point-link");
  SuccessMessages.DELETE_OPERATION_POINT_LINK = i18n.t("success-messages.delete-operation-point-link");
  SuccessMessages.INSERT_COPIED_OPERATION = i18n.t("success-messages.insert-copied-operation");
  SuccessMessages.INSERT_MANAGEMENT_BOOK = i18n.t("success-messages.insert-management-book");
  SuccessMessages.UPDATE_PROCESS_ORDER = i18n.t("success-messages.update-process-order");
  SuccessMessages.INSERT_PROCESS = i18n.t("success-messages.insert-process");
  SuccessMessages.UPDATE_PROCESS = i18n.t("success-messages.update-process");
  SuccessMessages.DELETE_PROCESS = i18n.t("success-messages.delete-process");
  SuccessMessages.INSERT_OPERATION_IN_PROCESS = i18n.t("success-messages.insert-operation-in-process");
  SuccessMessages.UPDATE_OPERATION_IN_PROCESS_ORDER = i18n.t("success-messages.update-operation-in-process-order");
  SuccessMessages.DELETE_OPERATION_IN_PROCESS = i18n.t("success-messages.delete-operation-in-process");
  SuccessMessages.CHANGE_WORK_STANDARD_NAME = i18n.t("success-messages.change-work-standard-name");
  SuccessMessages.MOVE_WORK_STANDARD = i18n.t("success-messages.move-work-standard");
  SuccessMessages.WORK_PERSON_SECONDS = i18n.t("success-messages.work-person-seconds");
  SuccessMessages.DELETE_WORK_STANDARD = i18n.t("success-messages.delete-work-standard");
  SuccessMessages.INSERT_LINE = i18n.t("success-messages.insert-line");
  SuccessMessages.UPDATE_LINE = i18n.t("success-messages.update-line");
  SuccessMessages.DELETE_LINE = i18n.t("success-messages.delete-line");
  SuccessMessages.INSERT_UNIT = i18n.t("success-messages.insert-unit");
  SuccessMessages.UPDATE_UNIT = i18n.t("success-messages.update-unit");
  SuccessMessages.DELETE_UNIT = i18n.t("success-messages.delete-unit");
  SuccessMessages.UPDATE_UNIT_PRODUCTION_CAPACITY = i18n.t("success-messages.update-unit-production-capacity");
  SuccessMessages.INSERT_TEAM = i18n.t("success-messages.insert-team");
  SuccessMessages.UPDATE_TEAM = i18n.t("success-messages.update-team");
  SuccessMessages.DELETE_TEAM = i18n.t("success-messages.delete-team");
  SuccessMessages.INSERT_MODEL = i18n.t("success-messages.insert-model");
  SuccessMessages.UPDATE_MODEL = i18n.t("success-messages.update-model");
  SuccessMessages.DELETE_MODEL = i18n.t("success-messages.delete-model");
  SuccessMessages.INSERT_VOLUME = i18n.t("success-messages.insert-volume");
  SuccessMessages.DELETE_VOLUME = i18n.t("success-messages.delete-volume");
  SuccessMessages.INSERT_OPERATION = i18n.t("success-messages.insert-operation");
  SuccessMessages.POST_SIGN_UP = i18n.t("success-messages.post-sign-up");
  SuccessMessages.DELETE_USER = i18n.t("success-messages.delete-user");
  SuccessMessages.UPDATE_AUTHORITY = i18n.t("success-messages.update-authority");
  SuccessMessages.UPDATE_ATTACH = i18n.t("success-messages.update-attach");
  SuccessMessages.INSERT_USER_FEEDBACK = i18n.t("success-messages.insert-user-feedback");
};

// エラー
export const ErrorMessages = {
  //---COMMON---
  UNCONNECT: "サーバーに接続出来ませんでした。",
  SERVER: "サーバーでエラーが発生しました。",
  UPDATE_EDITING_ON: "編集モードへの切り替えに失敗しました。",
  UPDATE_EDITING_OFF: "編集モードの終了に失敗しました。",
  //---LOGIN---
  POST_LOGIN: "ログインに失敗しました。",
  POST_LOGOUT: "ログアウトに失敗しました。",
  POST_ROLE: "役職の更新に失敗しました。",
  ROLE_DELETE: "役職の削除に失敗しました。",
  //---AUTHENTICATION---
  GET_ROLE: "役職の取得に失敗しました。",
  GET_SECTION_LIST: "役職の選択肢の取得に失敗しました。",
  // userBeloging
  GET_USER_BELOGING: "ユーザーの所属情報の取得に失敗しました。",
  //---HOME---
  // OperationDrawer
  OPERATIONDRAWER_INITIAL_OPERATION: "作業標準情報がありません。工程に作業標準を登録してください。",
  // Approver
  GET_APPROVE: "承認情報の取得に失敗しました。",
  // Confirmer
  GET_CONFIRM: "確認情報の取得に失敗しました。",
  // Safety_equipment
  GET_SAFETY_EQIPMENT: "安全保護具情報の取得に失敗しました。",
  UPDATE_SAFETY_EQIPMENT: "安全保護具情報の更新に失敗しました。",
  // Requirement
  GET_REQUIREMENT: "管理項目情報の取得に失敗しました。",
  INSERT_REQUIREMENT: "管理項目情報の登録に失敗しました。",
  DELETE_REQUIREMENT: "管理項目情報の削除に失敗しました。",
  // Parts
  GET_PARTS: "部品情報の取得に失敗しました。",
  INSERT_PARTS: "部品情報の登録に失敗しました。",
  UPDATE_PARTS: "部品情報の更新に失敗しました。",
  DELETE_PARTS: "部品情報の削除に失敗しました。",
  // Safety_point
  GET_POINT: "安全ポイント情報の取得に失敗しました。",
  INSERT_POINT: "安全ポイント情報の登録に失敗しました。",
  UPDATE_POINT: "安全ポイント情報の更新に失敗しました。",
  DELETE_POINT: "安全ポイント情報の削除に失敗しました。",
  // History
  GET_HISTORY: "改廃履歴情報の取得に失敗しました。",
  INSERT_HISTORY: "改廃履歴情報の登録に失敗しました。",
  // History Detail
  GET_HISTORY_DETAIL: "改訂履歴詳細の取得に失敗しました。",
  INSERT_HISTORY_DETAIL: "改訂履歴詳細の登録に失敗しました。",
  UPDATE_HISTORY_DETAIL: "改訂履歴詳細の更新に失敗しました。",
  DELETE_HISTORY_DETAIL: "改訂履歴詳細の削除に失敗しました。",
  // OperatorSignature
  GET_OPERATOR_SIGNATURE: "確認者サイン情報の取得に失敗しました。",
  INSERT_OPERATOR_SIGNATURE: "確認者サインの登録に失敗しました。",
  DELETE_OPERATOR_SIGNATURE: "確認者サインの取り消しに失敗しました。",
  // Operation_card
  GET_OPERATION_CARD: "作業標準詳細情報の取得に失敗しました。",
  UPDATE_OPERATION_CARD: "作業標準詳細タイトルの更新に失敗しました。",
  INSERT_OPERATION_CARD: "カードの追加に失敗しました。",
  DELETE_OPERATION_CARD: "カードの削除に失敗しました。",
  // Operation_page
  GET_OPERATION_PAGE: "ページの取得に失敗しました。",
  INSERT_OPERATION_PAGE: "ページの追加に失敗しました。",
  DELETE_OPERATION_PAGE: "ページの削除に失敗しました。",
  // Operation_point
  GET_OPERATION_POINT: "作業標準詳細ポイントの取得に失敗しました。",
  INSERT_OPERATION_POINT: "作業標準詳細ポイントの登録に失敗しました。",
  UPDATE_OPERATION_POINT: "作業標準詳細ポイントの更新に失敗しました。",
  DELETE_OPERATION_POINT: "作業標準詳細ポイントの削除に失敗しました。",
  // Operation_Equipment
  INSERT_SAFETY_EQUIPMENT: "作業標準安全保護具の登録に失敗しました。",
  DELETE_SAFETY_EQUIPMENT: "作業標準安全保護具の削除に失敗しました。",
  // Operation_HashItem
  GET_OPERATION_HASH_ITEM: "作業標準詳細ハッシュの取得に失敗しました。",
  INSERT_OPERATION_HASH_ITEM: "作業標準詳細ハッシュの登録に失敗しました。",
  DELETE_OPERATION_HASH_ITEM: "作業標準詳細ハッシュの削除に失敗しました。",
  // Operation_point_link
  INSERT_OPERATION_POINT_LINK: "作業標準詳細ポイント紐付けの登録に失敗しました。",
  DELETE_OPERATION_POINT_LINK: "作業標準詳細ポイント紐付けの解除に失敗しました。",
  // Reject Message
  GET_WORK_STANDARD_REJECT_MESSAGE:"作業標準の否認理由の取得に失敗しました。",
  // WorkFlow Under Revision
  GET_UNDER_REVISION: "改訂中の作業標準の取得に失敗しました。",
  GET_WORK_STANDARD: "指定された作業標準が存在しません。",
  // WorkFlow Authorized
  GET_AUTHORIZED: "作業標準に対してのサイン権限の取得に失敗しました。",
  //---WORKFLOW---
  APPLICATION: "承認依頼に失敗しました",
  CANCEL: "引き戻しに失敗しました",
  CONFIRM: "確認に失敗しました",
  APPROVE: "承認に失敗しました",
  REJECT: "否認に失敗しました",
  MODIFY: "作成中にできませんでした",
  PUBLISH: "発行に失敗しました",
  ARCHIVE_APPLICATION: "アーカイブ承認依頼に失敗しました",
  ARCHIVE_REJECT: "アーカイブ否認に失敗しました",
  ARCHIVE_APPROVE: "アーカイブ承認に失敗しました",
  REVISE: "改訂版の作成に失敗しました",
  // Illustration
  GET_ILLUSTRATION_LIST: "図解の取得に失敗しました。",
  // Copy
  INSERT_COPIED_OPERATION: "改訂のための作業標準の複製に失敗しました。",
  //---OPERATION MANAGEMENT BOOK---
  INSERT_MANAGEMENT_BOOK: "管理台帳の作成に失敗しました。",
  UPDATE_PROCESS_ORDER: "工程順の更新に失敗しました。",
  GET_PUBLISHED: "公開済作業標準情報の取得に失敗しました。",
  GET_MANAGEMENT_BOOK_REJECT_MESSAGE:"管理台帳の否認理由の取得に失敗しました。",
  // OperationInProcess
  GET_OPERATION_IN_PROCESS: "作業の取得に失敗しました。",
  INSERT_OPERATION_IN_PROCESS: "作業の登録に失敗しました。",
  UPDATE_OPERATION_IN_PROCESS_ORDER: "作業順の更新に失敗しました。",
  DELETE_OPERATION_IN_PROCESS: "作業の削除に失敗しました。",
  //---DATA MANAGEMENT---
  // factory
  GET_FACTORY: "拠点情報の取得に失敗しました。",
  // plant
  GET_PLANT: "工場情報の取得に失敗しました。",
  // line
  GET_MODULE: "モジュール情報の取得に失敗しました。",
  INSERT_MODULE: "モジュール情報の登録に失敗しました。",
  UPDATE_MODULE: "モジュール情報の更新に失敗しました。",
  DELETE_MODULE: "モジュール情報の削除に失敗しました。",
  // unit
  GET_UNIT: "ユニット情報の取得に失敗しました。",
  INSERT_UNIT: "ユニット情報の登録に失敗しました。",
  UPDATE_UNIT: "ユニット情報の更新に失敗しました。",
  DELETE_UNIT: "ユニット情報の削除に失敗しました。",
  // unit production capacity
  GET_UNIT_PRODUCTION_CAPACITY: "ユニットと生産台数の取得に失敗しました。",
  UPDATE_UNIT_PRODUCTION_CAPACITY: "現在の生産台数の更新に失敗しました。",
  // team
  GET_TEAM: "チーム情報の取得に失敗しました。",
  INSERT_TEAM: "チーム情報の登録に失敗しました。",
  UPDATE_TEAM: "チーム情報の更新に失敗しました。",
  DELETE_TEAM: "チーム情報の削除に失敗しました。",
  // model
  GET_MODEL: "機種情報の取得に失敗しました。",
  INSERT_MODEL: "機種情報の登録に失敗しました。",
  UPDATE_MODEL: "機種情報の更新に失敗しました。",
  DELETE_MODEL: "機種情報の削除に失敗しました。",
  // volume
  GET_VOLUME: "生産台数情報の取得に失敗しました。",
  INSERT_VOLUME: "生産台数情報の登録に失敗しました。",
  DELETE_VOLUME: "生産台数情報の削除に失敗しました。",
  GET_PRODUCTION_CAPACITY_LIST_OF_PUBLISHED_MANAGEMENT_BOOK: "公開中の管理台帳がある生産台数情報の取得に失敗しました。",
  GET_PRODUCTION_CAPACITY_HISTORY: "生産台数運用履歴の取得に失敗しました。",
  // managementBook
  GET_MANAGEMENT_BOOK: "管理台帳情報の取得に失敗しました。",
  GET_MANAGEMENT_BOOK_LIST: "管理台帳リスト情報の取得に失敗しました。",
  GET_MANAGEMENT_BOOK_HISTORY: "管理台帳改訂履歴情報の取得に失敗しました。",
  GET_MANAGEMENT_BOOK_UNDER_REVISION: "改訂中の管理台帳情報の取得に失敗しました。",
  // process
  GET_PROCESS: "工程の取得に失敗しました。",
  INSERT_PROCESS: "工程の登録に失敗しました。",
  UPDATE_PROCESS: "工程の更新に失敗しました。",
  DELETE_PROCESS: "工程の削除に失敗しました。",
  // operation
  GET_OPERATION: "作業標準情報の取得に失敗しました。",
  //---CREATE NEW---
  INSERT_OPERATION: "作業標準の作成に失敗しました。",
  GET_S3_URL_OPERATION: "S3の署名付きURLの取得に失敗しました。",
  S3_PUT_OPERATION: "S3へのアップロードに失敗しました。",
  // user
  POST_SIGN_UP: "新規ユーザーの登録に失敗しました。",
  DELETE_USER: "ユーザーの削除に失敗しました。",
  GET_USER: "ユーザー情報の取得に失敗しました。",
  UPDATE_AUTHORITY: "ユーザー権限の更新に失敗しました。",
  UPDATE_ATTACH: "ユーザー情報の更新に失敗しました。",
  GET_USER_HISTORY: "ユーザー履歴情報の取得に失敗しました。",
  //---WORK STANDARD---
  CHANGE_WORK_STANDARD_NAME: "作業標準名の変更に失敗しました。",
  MOVE_WORK_STANDARD: "作業標準の移動に失敗しました。",
  DELETE_WORK_STANDARD: "作業標準の削除に失敗しました。",
  WORK_PERSON_SECONDS: "作業の標準工数の変更に失敗しました。",
  GET_CAN_OPERATOR_SIGN: "作業者サインできるかどうかの確認に失敗しました。",
  //---WORK STANDARD LIST---
  GET_WORK_STANDARD_LIST: "作業標準リストの取得に失敗しました。",
  //---EXTRA---
  // user feedback
  INSERT_USER_FEEDBACK: "フィードバックの投稿に失敗しました。",
};

const updateErrorMessages = () => {
  ErrorMessages.UNCONNECT = i18n.t("error-messages.unconnect");
  ErrorMessages.SERVER = i18n.t("error-messages.server");
  ErrorMessages.UPDATE_EDITING_ON = i18n.t("error-messages.update-editing-on");
  ErrorMessages.UPDATE_EDITING_OFF = i18n.t("error-messages.update-editing-off");
  ErrorMessages.POST_LOGIN = i18n.t("error-messages.post-login");
  ErrorMessages.POST_LOGOUT = i18n.t("error-messages.post-logout");
  ErrorMessages.POST_ROLE = i18n.t("error-messages.post-role");
  ErrorMessages.ROLE_DELETE = i18n.t("error-messages.role-delete");
  ErrorMessages.GET_ROLE = i18n.t("error-messages.get-role");
  ErrorMessages.GET_SECTION_LIST = i18n.t("error-messages.get-section-list");
  ErrorMessages.GET_USER_BELOGING = i18n.t("error-messages.get-user-beloging");
  ErrorMessages.OPERATIONDRAWER_INITIAL_OPERATION = i18n.t("error-messages.operationdrawer-initial-operation");
  ErrorMessages.GET_APPROVE = i18n.t("error-messages.get-approve");
  ErrorMessages.GET_CONFIRM = i18n.t("error-messages.get-confirm");
  ErrorMessages.GET_SAFETY_EQIPMENT = i18n.t("error-messages.get-safety-eqipment");
  ErrorMessages.UPDATE_SAFETY_EQIPMENT = i18n.t("error-messages.update-safety-eqipment");
  ErrorMessages.GET_REQUIREMENT = i18n.t("error-messages.get-requirement");
  ErrorMessages.INSERT_REQUIREMENT = i18n.t("error-messages.insert-requirement");
  ErrorMessages.DELETE_REQUIREMENT = i18n.t("error-messages.delete-requirement");
  ErrorMessages.GET_PARTS = i18n.t("error-messages.get-parts");
  ErrorMessages.INSERT_PARTS = i18n.t("error-messages.insert-parts");
  ErrorMessages.UPDATE_PARTS = i18n.t("error-messages.update-parts");
  ErrorMessages.DELETE_PARTS = i18n.t("error-messages.delete-parts");
  ErrorMessages.GET_POINT = i18n.t("error-messages.get-point");
  ErrorMessages.INSERT_POINT = i18n.t("error-messages.insert-point");
  ErrorMessages.UPDATE_POINT = i18n.t("error-messages.update-point");
  ErrorMessages.DELETE_POINT = i18n.t("error-messages.delete-point");
  ErrorMessages.GET_HISTORY = i18n.t("error-messages.get-history");
  ErrorMessages.INSERT_HISTORY = i18n.t("error-messages.insert-history");
  ErrorMessages.GET_HISTORY_DETAIL = i18n.t("error-messages.get-history-detail");
  ErrorMessages.INSERT_HISTORY_DETAIL = i18n.t("error-messages.insert-history-detail");
  ErrorMessages.UPDATE_HISTORY_DETAIL = i18n.t("error-messages.update-history-detail");
  ErrorMessages.DELETE_HISTORY_DETAIL = i18n.t("error-messages.delete-history-detail");
  ErrorMessages.GET_OPERATOR_SIGNATURE = i18n.t("error-messages.get-operator-signature");
  ErrorMessages.INSERT_OPERATOR_SIGNATURE = i18n.t("error-messages.insert-operator-signature");
  ErrorMessages.DELETE_OPERATOR_SIGNATURE = i18n.t("error-messages.delete-operator-signature");
  ErrorMessages.GET_OPERATION_CARD = i18n.t("error-messages.get-operation-card");
  ErrorMessages.UPDATE_OPERATION_CARD = i18n.t("error-messages.update-operation-card");
  ErrorMessages.INSERT_OPERATION_CARD = i18n.t("error-messages.insert-operation-card");
  ErrorMessages.DELETE_OPERATION_CARD = i18n.t("error-messages.delete-operation-card");
  ErrorMessages.GET_OPERATION_PAGE = i18n.t("error-messages.get-operation-page");
  ErrorMessages.INSERT_OPERATION_PAGE = i18n.t("error-messages.insert-operation-page");
  ErrorMessages.DELETE_OPERATION_PAGE = i18n.t("error-messages.delete-operation-page");
  ErrorMessages.GET_OPERATION_POINT = i18n.t("error-messages.get-operation-point");
  ErrorMessages.INSERT_OPERATION_POINT = i18n.t("error-messages.insert-operation-point");
  ErrorMessages.UPDATE_OPERATION_POINT = i18n.t("error-messages.update-operation-point");
  ErrorMessages.DELETE_OPERATION_POINT = i18n.t("error-messages.delete-operation-point");
  ErrorMessages.INSERT_SAFETY_EQUIPMENT = i18n.t("error-messages.insert-safety-equipment");
  ErrorMessages.DELETE_SAFETY_EQUIPMENT = i18n.t("error-messages.delete-safety-equipment");
  ErrorMessages.GET_OPERATION_HASH_ITEM = i18n.t("error-messages.get-operation-hash-item");
  ErrorMessages.INSERT_OPERATION_HASH_ITEM = i18n.t("error-messages.insert-operation-hash-item");
  ErrorMessages.DELETE_OPERATION_HASH_ITEM = i18n.t("error-messages.delete-operation-hash-item");
  ErrorMessages.INSERT_OPERATION_POINT_LINK = i18n.t("error-messages.insert-operation-point-link");
  ErrorMessages.DELETE_OPERATION_POINT_LINK = i18n.t("error-messages.delete-operation-point-link");
  ErrorMessages.GET_UNDER_REVISION = i18n.t("error-messages.get-under-revision");
  ErrorMessages.GET_WORK_STANDARD = i18n.t("error-messages.get-work-standard");
  ErrorMessages.GET_AUTHORIZED = i18n.t("error-messages.get-authorized");
  ErrorMessages.APPLICATION = i18n.t("error-messages.application");
  ErrorMessages.CANCEL = i18n.t("error-messages.cancel");
  ErrorMessages.CONFIRM = i18n.t("error-messages.confirm");
  ErrorMessages.APPROVE = i18n.t("error-messages.approve");
  ErrorMessages.REJECT = i18n.t("error-messages.reject");
  ErrorMessages.MODIFY = i18n.t("error-messages.modify");
  ErrorMessages.PUBLISH = i18n.t("error-messages.publish");
  ErrorMessages.ARCHIVE_APPLICATION = i18n.t("error-messages.archive-application");
  ErrorMessages.ARCHIVE_REJECT = i18n.t("error-messages.archive-reject");
  ErrorMessages.ARCHIVE_APPROVE = i18n.t("error-messages.archive-approve");
  ErrorMessages.REVISE = i18n.t("error-messages.revise");
  ErrorMessages.GET_ILLUSTRATION_LIST = i18n.t("error-messages.get-illustration-list");
  ErrorMessages.INSERT_COPIED_OPERATION = i18n.t("error-messages.insert-copied-operation");
  ErrorMessages.INSERT_MANAGEMENT_BOOK = i18n.t("error-messages.insert-management-book");
  ErrorMessages.UPDATE_PROCESS_ORDER = i18n.t("error-messages.update-process-order");
  ErrorMessages.GET_PUBLISHED = i18n.t("error-messages.get-published");
  ErrorMessages.GET_MANAGEMENT_BOOK_REJECT_MESSAGE = i18n.t("error-messages.get-management-book-reject-message");
  ErrorMessages.GET_OPERATION_IN_PROCESS = i18n.t("error-messages.get-operation-in-process");
  ErrorMessages.INSERT_OPERATION_IN_PROCESS = i18n.t("error-messages.insert-operation-in-process");
  ErrorMessages.UPDATE_OPERATION_IN_PROCESS_ORDER = i18n.t("error-messages.update-operation-in-process-order");
  ErrorMessages.DELETE_OPERATION_IN_PROCESS = i18n.t("error-messages.delete-operation-in-process");
  ErrorMessages.GET_FACTORY = i18n.t("error-messages.get-factory");
  ErrorMessages.GET_PLANT = i18n.t("error-messages.get-plant");
  ErrorMessages.GET_MODULE = i18n.t("error-messages.get-module");
  ErrorMessages.INSERT_MODULE = i18n.t("error-messages.insert-module");
  ErrorMessages.UPDATE_MODULE = i18n.t("error-messages.update-module");
  ErrorMessages.DELETE_MODULE = i18n.t("error-messages.delete-module");
  ErrorMessages.GET_UNIT = i18n.t("error-messages.get-unit");
  ErrorMessages.INSERT_UNIT = i18n.t("error-messages.insert-unit");
  ErrorMessages.UPDATE_UNIT = i18n.t("error-messages.update-unit");
  ErrorMessages.DELETE_UNIT = i18n.t("error-messages.delete-unit");
  ErrorMessages.GET_UNIT_PRODUCTION_CAPACITY = i18n.t("error-messages.get-unit-production-capacity");
  ErrorMessages.UPDATE_UNIT_PRODUCTION_CAPACITY = i18n.t("error-messages.update-unit-production-capacity");
  ErrorMessages.GET_TEAM = i18n.t("error-messages.get-team");
  ErrorMessages.INSERT_TEAM = i18n.t("error-messages.insert-team");
  ErrorMessages.UPDATE_TEAM = i18n.t("error-messages.update-team");
  ErrorMessages.DELETE_TEAM = i18n.t("error-messages.delete-team");
  ErrorMessages.GET_MODEL = i18n.t("error-messages.get-model");
  ErrorMessages.INSERT_MODEL = i18n.t("error-messages.insert-model");
  ErrorMessages.UPDATE_MODEL = i18n.t("error-messages.update-model");
  ErrorMessages.DELETE_MODEL = i18n.t("error-messages.delete-model");
  ErrorMessages.GET_VOLUME = i18n.t("error-messages.get-volume");
  ErrorMessages.INSERT_VOLUME = i18n.t("error-messages.insert-volume");
  ErrorMessages.DELETE_VOLUME = i18n.t("error-messages.delete-volume");
  ErrorMessages.GET_PRODUCTION_CAPACITY_LIST_OF_PUBLISHED_MANAGEMENT_BOOK = i18n.t(
    "error-messages.get-production-capacity-list-of-published-management-book"
  );
  ErrorMessages.GET_PRODUCTION_CAPACITY_HISTORY = i18n.t("error-messages.get-production-capacity-history");
  ErrorMessages.GET_MANAGEMENT_BOOK = i18n.t("error-messages.get-management-book");
  ErrorMessages.GET_MANAGEMENT_BOOK_LIST = i18n.t("error-messages.get-management-book-list");
  ErrorMessages.GET_MANAGEMENT_BOOK_HISTORY = i18n.t("error-messages.get-management-book-history");
  ErrorMessages.GET_MANAGEMENT_BOOK_UNDER_REVISION = i18n.t("error-messages.get-management-book-under-revision");
  ErrorMessages.GET_PROCESS = i18n.t("error-messages.get-process");
  ErrorMessages.INSERT_PROCESS = i18n.t("error-messages.insert-process");
  ErrorMessages.UPDATE_PROCESS = i18n.t("error-messages.update-process");
  ErrorMessages.DELETE_PROCESS = i18n.t("error-messages.delete-process");
  ErrorMessages.GET_OPERATION = i18n.t("error-messages.get-operation");
  ErrorMessages.INSERT_OPERATION = i18n.t("error-messages.insert-operation");
  ErrorMessages.GET_S3_URL_OPERATION = i18n.t("error-messages.get-s3-url-operation");
  ErrorMessages.S3_PUT_OPERATION = i18n.t("error-messages.s3-put-operation");
  ErrorMessages.POST_SIGN_UP = i18n.t("error-messages.post-sign-up");
  ErrorMessages.DELETE_USER = i18n.t("error-messages.delete-user");
  ErrorMessages.GET_USER = i18n.t("error-messages.get-user");
  ErrorMessages.UPDATE_AUTHORITY = i18n.t("error-messages.update-authority");
  ErrorMessages.UPDATE_ATTACH = i18n.t("error-messages.update-attach");
  ErrorMessages.GET_USER_HISTORY = i18n.t("error-messages.get-user-history");
  ErrorMessages.CHANGE_WORK_STANDARD_NAME = i18n.t("error-messages.change-work-standard-name");
  ErrorMessages.MOVE_WORK_STANDARD = i18n.t("error-messages.move-work-standard");
  ErrorMessages.DELETE_WORK_STANDARD = i18n.t("error-messages.delete-work-standard");
  ErrorMessages.WORK_PERSON_SECONDS = i18n.t("error-messages.work-person-seconds");
  ErrorMessages.GET_CAN_OPERATOR_SIGN = i18n.t("error-messages.get-can-operator-sign");
  ErrorMessages.GET_WORK_STANDARD_LIST = i18n.t("error-messages.get-work-standard-list");
  ErrorMessages.INSERT_USER_FEEDBACK = i18n.t("error-messages.insert-user-feedback");
};

// 警告
export const WarningMessages = {
  // VALIDATION
  SELECTED_UNIT: "ユニットを選択してください。",
  SELECTED_TEAM: "チームを選択してください。",
  NOT_ONLY_HALF_ALPHABET_AND_NUMBER: "半角のアルファベットか数字を入力してください。",
  NOT_ONLY_HALF_NUMBER: "半角の数字を入力してください。",
  //---HOME---
  HOME_REGIST: "確認と承認がされていないため、本登録できません。",
  HOME_REVISED_APPROVE_REQUEST: "作成中でないため、改訂後の承認依頼ができません。",
  // Parts
  INSERT_PARTS_EMPTY_NAME: "部品名を入力してください。",
  INSERT_PARTS_SAME_NAME: "既に登録された部品名のため、部品登録ができません。",
  INSERT_PARTS_EMPTY_COUNT: "個数を1個以上で入力してください。",
  // History
  HOME_HISTORY_TITLE_INPUT_LENGTH: "概要を1～100文字で入力してください。",
  HOME_HISTORY_DETAIL_INPUT_LENGTH: "詳細を1～200文字で入力してください。",
  // History Detail
  HISTORY_DETAIL_TARGET: "項目を選択してください。",
  HISTORY_DETAIL_INPUT_LENGTH: "変更箇所を1～200文字で入力してください。",
  //---DATA MANAGEMENT---
  DATAMANAGEMENT_INPUT_LENGTH: "1～50文字で入力してください。",
  DATAMANAGEMENT_PRODUCTION_CAPACITY_INPUT_LENGTH: "0～99999で入力してください。",
  DATAMANAGEMENT_VOLUME_MAX_COUNT: "生産台数はこれ以上入力ができません。",
  //---CREATE NEW---
  CREATENEW_SELECTED_FACTORY: "拠点を選択してください。",
  CREATENEW_SELECTED_PLANT: "工場を選択してください。",
  CREATENEW_SELECTED_MODULE: "モジュールを選択してください。",
  CREATENEW_SELECTED_MODEL: "機種を選択してください。",
  CREATENEW_NAME_INPUT_LENGTH: "作業標準名を1～200文字で入力してください。",
  CREATENEW_COPY_SOURCE: "コピー元情報を選択してください。",
  // user
  LOGIN_ID_INPUT_LENGTH: "IDを8～30文字で入力してください。",
  LOGIN_PW_INPUT_LENGTH: "Passwordを8～30文字で入力してください。",
  LOGIN_NAME_INPUT_LENGTH: "ユーザー名を1～30文字で入力してください。",
  SIGNUP_EMPTY_MODULE: "モジュールを1つ以上選択してください。",
  UPDATE_AUTHORITY: "更新前と同じユーザー権限のため操作を中止しました。",
  UPDATE_LOGINID_INPUT_LENGTH: "ログインIDは８文字以上30文字以下としてください。",
  UPDATE_NAME_EMPTY: "ユーザー名を入力してください。",
  // ---OPERATION MANAGEMENT BOOK---
  OPERATION_BOOK_SELECTED_OPEREATION: "作業を選択してください。",
  OPERATION_BOOK_NO_PUBLISHED: "登録可能な作業標準がありません。作業標準を作成して本登録してください。",
  OPERATION_BOOK_ALL_REGISTERED: "作成した作業標準は全て工程に登録済みです。",
  MANAGEMENT_BOOK_NOT_SELECTED_DUPLICATE_PRODUCTION_CAPACITY: "コピー元生産台数を選択してください。",
  MANAGEMENT_BOOK_HISTORY_INPUT_LENGTH: "改訂内容を1～200文字で入力してください。",
  // ---OPERATION LIST---
  NO_OPERATION_LIST: "検索条件に一致する作業標準書が存在しません。",
  // ---WORK STANDARD---
  OPERATION_PARTS_MAX_COUNT: "個数はこれ以上入力ができません。",
  WORK_STANDARD_EDITING_ON_OTHER_USER: "他のユーザーが編集中のため、編集モードをオンにできません。",
  WORK_STANDARD_EDITING_OFF_OTHER_USER: "他のユーザーが編集中です。",
  WORK_STANDARD_APPLICATION_OTHER_USER: "他のユーザーが編集中のため、承認依頼できません。",
  PERSON_SECONDS_LENGTH: "標準工数は8桁以内で入力してください。",
};

const updateWarningMessages = () => {
  WarningMessages.SELECTED_UNIT = i18n.t("warning-messages.selected-unit");
  WarningMessages.SELECTED_TEAM = i18n.t("warning-messages.selected-team");
  WarningMessages.NOT_ONLY_HALF_ALPHABET_AND_NUMBER = i18n.t("warning-messages.not-only-half-alphabet-and-number");
  WarningMessages.NOT_ONLY_HALF_NUMBER = i18n.t("warning-messages.not-only-half-number");
  WarningMessages.HOME_REGIST = i18n.t("warning-messages.home-regist");
  WarningMessages.HOME_REVISED_APPROVE_REQUEST = i18n.t("warning-messages.home-revised-approve-request");
  WarningMessages.INSERT_PARTS_EMPTY_NAME = i18n.t("warning-messages.insert-parts-empty-name");
  WarningMessages.INSERT_PARTS_SAME_NAME = i18n.t("warning-messages.insert-parts-same-name");
  WarningMessages.INSERT_PARTS_EMPTY_COUNT = i18n.t("warning-messages.insert-parts-empty-count");
  WarningMessages.HOME_HISTORY_TITLE_INPUT_LENGTH = i18n.t("warning-messages.home-history-title-input-length");
  WarningMessages.HOME_HISTORY_DETAIL_INPUT_LENGTH = i18n.t("warning-messages.home-history-detail-input-length");
  WarningMessages.HISTORY_DETAIL_TARGET = i18n.t("warning-messages.history-detail-target");
  WarningMessages.HISTORY_DETAIL_INPUT_LENGTH = i18n.t("warning-messages.history-detail-input-length");
  WarningMessages.DATAMANAGEMENT_INPUT_LENGTH = i18n.t("warning-messages.datamanagement-input-length");
  WarningMessages.DATAMANAGEMENT_PRODUCTION_CAPACITY_INPUT_LENGTH = i18n.t("warning-messages.datamanagement-production-capacity-input-length");
  WarningMessages.DATAMANAGEMENT_VOLUME_MAX_COUNT = i18n.t("warning-messages.datamanagement-volume-max-count");
  WarningMessages.CREATENEW_SELECTED_FACTORY = i18n.t("warning-messages.createnew-selected-factory");
  WarningMessages.CREATENEW_SELECTED_PLANT = i18n.t("warning-messages.createnew-selected-plant");
  WarningMessages.CREATENEW_SELECTED_MODULE = i18n.t("warning-messages.createnew-selected-module");
  WarningMessages.CREATENEW_SELECTED_MODEL = i18n.t("warning-messages.createnew-selected-model");
  WarningMessages.CREATENEW_NAME_INPUT_LENGTH = i18n.t("warning-messages.createnew-name-input-length");
  WarningMessages.CREATENEW_COPY_SOURCE = i18n.t("warning-messages.createnew-copy-source");
  WarningMessages.LOGIN_ID_INPUT_LENGTH = i18n.t("warning-messages.login-id-input-length");
  WarningMessages.LOGIN_PW_INPUT_LENGTH = i18n.t("warning-messages.login-pw-input-length");
  WarningMessages.LOGIN_NAME_INPUT_LENGTH = i18n.t("warning-messages.login-name-input-length");
  WarningMessages.SIGNUP_EMPTY_MODULE = i18n.t("warning-messages.signup-empty-module");
  WarningMessages.UPDATE_AUTHORITY = i18n.t("warning-messages.update-authority");
  WarningMessages.UPDATE_LOGINID_INPUT_LENGTH = i18n.t("warning-messages.update-loginid-input-length");
  WarningMessages.UPDATE_NAME_EMPTY = i18n.t("warning-messages.update-name-empty");
  WarningMessages.OPERATION_BOOK_SELECTED_OPEREATION = i18n.t("warning-messages.operation-book-selected-opereation");
  WarningMessages.OPERATION_BOOK_NO_PUBLISHED = i18n.t("warning-messages.operation-book-no-published");
  WarningMessages.OPERATION_BOOK_ALL_REGISTERED = i18n.t("warning-messages.operation-book-all-registered");
  WarningMessages.MANAGEMENT_BOOK_NOT_SELECTED_DUPLICATE_PRODUCTION_CAPACITY = i18n.t(
    "warning-messages.management-book-not-selected-duplicate-production-capacity"
  );
  WarningMessages.MANAGEMENT_BOOK_HISTORY_INPUT_LENGTH = i18n.t("warning-messages.management-book-history-input-length");
  WarningMessages.NO_OPERATION_LIST = i18n.t("warning-messages.no-operation-list");
  WarningMessages.OPERATION_PARTS_MAX_COUNT = i18n.t("warning-messages.operation-parts-max-count");
  WarningMessages.WORK_STANDARD_EDITING_ON_OTHER_USER = i18n.t("warning-messages.work-standard-editing-on-other-user");
  WarningMessages.WORK_STANDARD_EDITING_OFF_OTHER_USER = i18n.t("warning-messages.work-standard-editing-off-other-user");
  WarningMessages.WORK_STANDARD_APPLICATION_OTHER_USER = i18n.t("warning-messages.work-standard-application-other-user");
  WarningMessages.PERSON_SECONDS_LENGTH = i18n.t("warning-messages.person-seconds-length");
};

export const DialogMessages = {
  // ---COMMON---
  COMMON_DELETE: "削除前の状態に戻すことが出来ません。本当に削除しますか？",
  //ユーザー削除
  DELETE_USER: "ユーザーを削除しますか？",
  DELETE_SECTION: "関連する役職が全て削除されますがよろしいですか？",

  //EDIT MODE
  EDIT_MODE_ON: "編集モードをオンにしますか？",
  EDIT_MODE_OFF: "編集モードをオフにしますか？",
  EDIT_MODE_OFF_ADMIN: "他のユーザーが編集中です。\n本当に編集モードをオフにしますか？",
  //PROMPTS TO DISABLE EDIT MODE
  PROMPTS_TO_DISABLE_EDIT_MODE: "編集モードがONのため、承認依頼を実施できません。\n編集モードをOFFにしてから承認依頼を実施してください。",
  //DELETE ILLUSTRATION PAGE
  DELETE_ILLUSTRATION_PAGE: "削除前の状態に戻すことか出来ません。本当に削除しますか？",
  //put user auth
  PUT_AUTH: "権限を変更すると管理者に戻すことができませんが、よろしいですか？",
  JUMP_HOME: "過去の作業標準情報を表示します、よろしいですか？",
  //PiC
  OTHMAN: "変更内容が保存されていません。破棄されますがよろしいですか？",
  DELETE_OBJECT: "削除してよろしいですか？",
  NO_DOWNLOADABLE_IMAGES: "ダウンロード可能な画像がありません",
  //Show old operation
  NOT_ALLOWED_SHOW_OLD_OPERATION: "一般権限のため旧版の作業標準を表示できません。",
  //work standard history detail
  DELETE_WORK_STANDARD_HISTORY_DETAIL: "削除前の状態に戻すことか出来ません。本当に削除しますか？",
  // Management Book
  JUMP_MANAGEMENT_BOOK: "選択した版の管理台帳を表示しますか？",
};

const updateDialogMessages = () => {
  DialogMessages.COMMON_DELETE = i18n.t("dialog-messages.common-delete");
  DialogMessages.DELETE_USER = i18n.t("dialog-messages.delete-user");
  DialogMessages.DELETE_SECTION = i18n.t("dialog-messages.delete-section");
  DialogMessages.EDIT_MODE_ON = i18n.t("dialog-messages.edit-mode-on");
  DialogMessages.EDIT_MODE_OFF = i18n.t("dialog-messages.edit-mode-off");
  DialogMessages.EDIT_MODE_OFF_ADMIN = i18n.t("dialog-messages.edit-mode-off-admin");
  DialogMessages.PROMPTS_TO_DISABLE_EDIT_MODE = i18n.t("dialog-messages.prompts-to-disable-edit-mode");
  DialogMessages.DELETE_ILLUSTRATION_PAGE = i18n.t("dialog-messages.delete-illustration-page");
  DialogMessages.PUT_AUTH = i18n.t("dialog-messages.put-auth");
  DialogMessages.JUMP_HOME = i18n.t("dialog-messages.jump-home");
  DialogMessages.OTHMAN = i18n.t("dialog-messages.othman");
  DialogMessages.DELETE_OBJECT = i18n.t("dialog-messages.delete-object");
  DialogMessages.NO_DOWNLOADABLE_IMAGES = i18n.t("dialog-messages.no-downloadable-images");
  DialogMessages.NOT_ALLOWED_SHOW_OLD_OPERATION = i18n.t("dialog-messages.not-allowed-show-old-operation");
  DialogMessages.DELETE_WORK_STANDARD_HISTORY_DETAIL = i18n.t("dialog-messages.delete-work-standard-history-detail");
  DialogMessages.JUMP_MANAGEMENT_BOOK = i18n.t("dialog-messages.jump-management-book");
};

export const TooltipMessages = {
  EDITING_OTHER_USER: "が編集中です",
  EDITING_OFF_ADMIN: "編集モードを強制的にオフにする",
  UNDELETABLE_PROCESS: "作業標準が紐付いているため削除できません",
};

const updateTooltipMessages = () => {
  TooltipMessages.EDITING_OTHER_USER = i18n.t("tooltip-messages.editing-other-user");
  TooltipMessages.EDITING_OFF_ADMIN = i18n.t("tooltip-messages.editing-off-admin");
  TooltipMessages.UNDELETABLE_PROCESS = i18n.t("tooltip-messages.undeletable-process");
};

if (i18n.isInitialized) {
  updateSuccessMessages();
  updateErrorMessages();
  updateWarningMessages();
  updateDialogMessages();
  updateTooltipMessages();
}

i18n.on("languageChanged", () => {
  updateSuccessMessages();
  updateErrorMessages();
  updateWarningMessages();
  updateDialogMessages();
  updateTooltipMessages();
});

i18n.on("loaded", () => {
  updateSuccessMessages();
  updateErrorMessages();
  updateWarningMessages();
  updateDialogMessages();
  updateTooltipMessages();
});
