import React, { useContext, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import DialogHashBoard from "./DialogHashBoard";
import { EditInfo, Message } from "../../context/appContextData";
import { sortByAsc } from "../../function/sort";
import { ErrorMessages, SuccessMessages } from "../../consts/messages";
import { deleteOperationHashItem, postOperationHashItem, useOperationHashItem, useOperationHashMaster } from "../../apicaller/repository/operation";
import { OperationHashItem } from "../../apicaller/domain/response/operation";
import { OperationHashItemChipGroup } from "./display/home";
import CompOperationHashItemChip from "./CompOperationHashItemChip";
import { OperationHashItemRegistRequest } from "../../apicaller/domain/request/operation";

interface Props {
  operationCardId: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outterDiv: {
      height: "100%",
      overflowY: "auto",
      overflowX: "hidden",
    },
    innerDiv: {
      flexGrow: 1,
      padding: 10,
      display: "flex",
      flexdirection: "row",
      flexWrap: "wrap",
    },
  })
);

const CompOperationHashItemChipGroup = (props: Props) => {
  const styles = useStyles();
  const EditContext = useContext(EditInfo);
  const [operationHashItemList, getOperationHashItemList, mutateOperationHashItemList, operationHashItemError] = useOperationHashItem();
  const [operationHashMasterList, getOperationHashMasterItemList, mutateOperationHashMasterItem, operationHashMasterItemError] =
    useOperationHashMaster();
  const [operationHashItemChipList, setOperationHashItemChipList] = useState<OperationHashItemChipGroup>({ operation_hash_item_chip_group: [] });
  const snackBarMessage = useContext(Message);

  //repositoryのデータからdisplayデータを生成
  const createDisplayData = (): OperationHashItemChipGroup => {
    let ret = new OperationHashItemChipGroup();

    if (!operationHashItemList || !operationHashMasterList) return ret;
    if (!operationHashItemList.operation_hash_item || !operationHashMasterList.operation_hash) return ret;

    if (operationHashItemList.operation_hash_item.length && operationHashMasterList.operation_hash.length) {
      const tmp = operationHashItemList.operation_hash_item.map((e: OperationHashItem) => {
        return {
          operation_hash_item_id: e.operation_hash_item_id,
          operation_card_id: e.operation_card_id,
          operation_hash_master_id: e.operation_hash_master_id,
          content: operationHashMasterList.operation_hash[e.operation_hash_master_id - 1].content,
        };
      });
      ret.operation_hash_item_chip_group = sortByAsc(tmp, "operation_hash_item_id");
    }
    return ret;
  };

  // add chip
  const handleChipAdd = (masterHashID: number) => {
    const reqData = new OperationHashItemRegistRequest(props.operationCardId, masterHashID);
    postOperationHashItem(reqData).then(
      (data) => {
        mutateOperationHashItemList();
        snackBarMessage.setMessage(SuccessMessages.INSERT_OPERATION_HASH_ITEM, "success");
      },
      (error) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.INSERT_OPERATION_HASH_ITEM, "error");
      }
    );
  };

  const handleChipDelete = (operationHashItemId: number) => {
    deleteOperationHashItem(String(operationHashItemId)).then(
      (data) => {
        mutateOperationHashItemList();
        snackBarMessage.setMessage(SuccessMessages.DELETE_OPERATION_HASH_ITEM, "success");
      },
      (error) => {
        snackBarMessage.setMessage(ErrorMessages.DELETE_OPERATION_HASH_ITEM, "error");
      }
    );
  };

  useEffect(() => {
    mutateOperationHashItemList();
    getOperationHashMasterItemList();
    getOperationHashItemList(String(props.operationCardId));
  }, []);

  useEffect(() => {
    getOperationHashMasterItemList();
    getOperationHashItemList(String(props.operationCardId));
  }, [props.operationCardId]);

  // change in SWR data
  useEffect(() => {
    if (!operationHashItemList || !operationHashMasterList) return;
    setOperationHashItemChipList(createDisplayData());
  }, [operationHashItemList, operationHashMasterList]);

  return (
    <div className={styles.outterDiv}>
      <div className={styles.innerDiv}>
        {operationHashItemChipList.operation_hash_item_chip_group.map((hashChip, index) => {
          return <CompOperationHashItemChip key={index} chipContent={hashChip} handleDelete={handleChipDelete} />;
        })}
        {operationHashMasterList && EditContext.editMode && (
          <DialogHashBoard AddHandle={handleChipAdd} HashDialogContents={operationHashMasterList} HashRegisteredList={operationHashItemChipList} />
        )}
      </div>
    </div>
  );
};

export default CompOperationHashItemChipGroup;
