import i18n from "i18next";

// 共通
export const Label = {
  FACTORY: "拠点",
  PLANT: "工場",
  MODEL: "機種",
  MODULE: "モジュール",
  UNIT: "ユニット",
  TEAM: "チーム",
  PRODUCTION_CAPACITY_SHORT: "生産台数",
  PRODUCTION_CAPACITY: "ユニット生産台数",
  WORK_STANDARD: "作業標準",
};

const updateLabel = () => {
  Label.FACTORY = i18n.t("label.factory");
  Label.PLANT = i18n.t("label.plant");
  Label.MODEL = i18n.t("label.model");
  Label.MODULE = i18n.t("label.module");
  Label.UNIT = i18n.t("label.unit");
  Label.TEAM = i18n.t("label.team");
  Label.PRODUCTION_CAPACITY_SHORT = i18n.t("label.production-capacity-short");
  Label.PRODUCTION_CAPACITY = i18n.t("label.production-capacity");
  Label.WORK_STANDARD = i18n.t("label.work-standard");
};

export const AlwaysEnglishAreaLabel = {
  MODULE: "MODULE",
  UNIT: "UNIT",
  TEAM: "TEAM",
  MODEL: "MODEL",
  WORK_STANDARD: "WORK STANDARD",
};

const updateAlwaysEnglishAreaLabel = () => {
  AlwaysEnglishAreaLabel.MODULE = i18n.t("always-english-area-label.module");
  AlwaysEnglishAreaLabel.UNIT = i18n.t("always-english-area-label.unit");
  AlwaysEnglishAreaLabel.TEAM = i18n.t("always-english-area-label.team");
  AlwaysEnglishAreaLabel.MODEL = i18n.t("always-english-area-label.model");
  AlwaysEnglishAreaLabel.WORK_STANDARD = i18n.t("always-english-area-label.work-standard");
};

export const DialogButtonLabel = {
  OK: "OK",
  CANCEL: "キャンセル",
  REGIST: "登録",
  DELETE: "削除",
  CLOSE: "閉じる",
};

const updateDialogButtonLabel = () => {
  DialogButtonLabel.OK = i18n.t("dialog-button-label.ok");
  DialogButtonLabel.CANCEL = i18n.t("dialog-button-label.cancel");
  DialogButtonLabel.REGIST = i18n.t("dialog-button-label.regist");
  DialogButtonLabel.DELETE = i18n.t("dialog-button-label.delete");
  DialogButtonLabel.CLOSE = i18n.t("dialog-button-label.close");
};

export const UserIconLabel = {
  AUTHORITY: "権限",
};

const updateUserIconLabel = () => {
  UserIconLabel.AUTHORITY = i18n.t("user-icon-label.authority");
};

export const AuthorityLabel = {
  GENERAL: "一般",
  EDITOR: "編集者",
  ADMIN: "管理者",
};

const updateAuthorityLabel = () => {
  AuthorityLabel.GENERAL = i18n.t("authority-label.general");
  AuthorityLabel.EDITOR = i18n.t("authority-label.editor");
  AuthorityLabel.ADMIN = i18n.t("authority-label.admin");
};

export const Role = {
  //BEにあるASSOCIATEの綴りが間違っていたため、FEもわざと間違えている
  ASSOCIATE: "ASSCOCIATE",
  TEAM_LEADER: "TEAM_LEADER",
  UNIT_LEADER: "UNIT_LEADER",
  MODULE_MANAGER: "MODULE_MANAGER",
};

export const LoginLabel = {
  LOGIN: "ログイン",
  LOGOUT: "ログアウト",
};

const updateLoginLabel = () => {
  LoginLabel.LOGIN = i18n.t("login-label.login");
  LoginLabel.LOGOUT = i18n.t("login-label.logout");
};

export const WorkFlowButtonLabel = {
  APPLICATION: "承認依頼",
  CANCEL: "引き戻し",
  REJECT: "否認",
  MODIFY: "修正",
  CONFIRM: "確認",
  CONFIRMED: "確認済み",
  APPROVE: "承認",
  APPROVED: "承認済み",
  PUBLISH: "発行",
  REVISE: "改訂",
  EDITING: "作成中",
  REVISING: "改訂中",
  ARCHIVE_APPLICATION: "アーカイブ承認依頼",
  ARCHIVE_REJECT: "アーカイブ否認",
  ARCHIVE_APPROVE: "アーカイブ承認",
};

const updateWorkFlowButtonLabel = () => {
  WorkFlowButtonLabel.APPLICATION = i18n.t("work-flow-button-label.application");
  WorkFlowButtonLabel.CANCEL = i18n.t("work-flow-button-label.cancel");
  WorkFlowButtonLabel.REJECT = i18n.t("work-flow-button-label.reject");
  WorkFlowButtonLabel.MODIFY = i18n.t("work-flow-button-label.modify");
  WorkFlowButtonLabel.CONFIRM = i18n.t("work-flow-button-label.confirm");
  WorkFlowButtonLabel.CONFIRMED = i18n.t("work-flow-button-label.confirmed");
  WorkFlowButtonLabel.APPROVE = i18n.t("work-flow-button-label.approve");
  WorkFlowButtonLabel.APPROVED = i18n.t("work-flow-button-label.approved");
  WorkFlowButtonLabel.PUBLISH = i18n.t("work-flow-button-label.publish");
  WorkFlowButtonLabel.REVISE = i18n.t("work-flow-button-label.revise");
  WorkFlowButtonLabel.EDITING = i18n.t("work-flow-button-label.editing");
  WorkFlowButtonLabel.REVISING = i18n.t("work-flow-button-label.revising");
  WorkFlowButtonLabel.ARCHIVE_APPLICATION = i18n.t("work-flow-button-label.archive-application");
  WorkFlowButtonLabel.ARCHIVE_REJECT = i18n.t("work-flow-button-label.archive-reject");
  WorkFlowButtonLabel.ARCHIVE_APPROVE = i18n.t("work-flow-button-label.archive-approve");
};

export const SideBarLabel = {
  MANAGEMENT_BOOK: "作業標準管理台帳",
  CREATE_NEW: "新規作成",
  OPERATION_LIST: "作業標準リスト",
  DATA_MANAGEMENT: "データ管理",
  USER_LIST: "ユーザー管理",
  LOGIN: "ログイン",
  INSTRUCTIONS: "使い方",
};

const updateSideBarLabel = () => {
  SideBarLabel.MANAGEMENT_BOOK = i18n.t("side-bar-label.management-book");
  SideBarLabel.CREATE_NEW = i18n.t("side-bar-label.create-new");
  SideBarLabel.OPERATION_LIST = i18n.t("side-bar-label.operation-list");
  SideBarLabel.DATA_MANAGEMENT = i18n.t("side-bar-label.data-management");
  SideBarLabel.USER_LIST = i18n.t("side-bar-label.user-list");
  SideBarLabel.LOGIN = i18n.t("side-bar-label.login");
  SideBarLabel.INSTRUCTIONS = i18n.t("side-bar-label.instructions");
};

export const WorkFlowStatusNamesLabel = {
  EDITING: "作成中",
  WAITING_CONFIRM: "確認待ち",
  WAITING_APPROVE: "承認待ち",
  REJECTED: "否認",
  APPROVED: "承認済み",
  PUBLISHED: "公開中",
  ARCHIVED: "アーカイブ",
  WAITING_APPROVE_ARCHIVED: "(アーカイブ承認待ち)",
};

const updateWorkFlowStatusNamesLabel = () => {
  WorkFlowStatusNamesLabel.EDITING = i18n.t("work-flow-status-names-label.editing");
  WorkFlowStatusNamesLabel.WAITING_CONFIRM = i18n.t("work-flow-status-names-label.waiting-confirm");
  WorkFlowStatusNamesLabel.WAITING_APPROVE = i18n.t("work-flow-status-names-label.waiting-approve");
  WorkFlowStatusNamesLabel.REJECTED = i18n.t("work-flow-status-names-label.rejected");
  WorkFlowStatusNamesLabel.APPROVED = i18n.t("work-flow-status-names-label.approved");
  WorkFlowStatusNamesLabel.PUBLISHED = i18n.t("work-flow-status-names-label.published");
  WorkFlowStatusNamesLabel.ARCHIVED = i18n.t("work-flow-status-names-label.archived");
  WorkFlowStatusNamesLabel.WAITING_APPROVE_ARCHIVED = i18n.t("work-flow-status-names-label.waiting-approve-archived");
};

export const EditModeLabel = {
  EDIT: "Edit",
};

const updateEditModeLabel = () => {
  EditModeLabel.EDIT = i18n.t("edit-mode-label.edit");
};

// 作業標準ページ
export const WorkStandardLabel = {
  WORK: "作業",
  REVISE_HISTORY: "改訂履歴",
  MANAGEMENT: "管理",
};

const updateWorkStandardLabel = () => {
  WorkStandardLabel.WORK = i18n.t("work-standard-label.work");
  WorkStandardLabel.REVISE_HISTORY = i18n.t("work-standard-label.revise-history");
  WorkStandardLabel.MANAGEMENT = i18n.t("work-standard-label.management");
};

export const RevisionNumberLabel = {
  PREFIX: "第",
  SUFFIX: "版",
};

const updateRevisionNumberLabel = () => {
  RevisionNumberLabel.PREFIX = i18n.t("revision-number-label.prefix");
  RevisionNumberLabel.SUFFIX = i18n.t("revision-number-label.suffix");
};

// 管理台帳ページ
export const ManagementBookPageLabel = {
  MANAGEMENT_BOOK_UNSELECTED: "検索条件を選択してください。",
  MANAGEMENT_BOOK_UNCREATED: "作業標準管理台帳が未作成です。",
  MANAGEMENT_BOOK_COPY_CHECKBOX_LABEL: "作業標準管理台帳をコピーする",
  MANAGEMENT_BOOK_COPY_SOURCE_LISTBOX_LABEL: "コピー元生産台数",
  MANAGEMENT_BOOK_CREATE_BUTTON_LABEL: "作業標準管理台帳を作成",
  MANAGEMENT_BOOK_UNPUBLISHED_LABEL: "作業標準管理台帳が未発行です。",
  WORK_STANDARD_LIST_TAB: "作業標準一覧",
  PROCESS_LIST_TAB: "工程一覧",
  MANAGEMENT_TAB: "管理",
  APPLICATION_DIALOG_TITLE: "承認依頼",
  REVISE_CONTENT: "改訂内容",
  CREATOR: "作成者",
  MANAGEMENT_BOOK_APPLICATION_DIALOG_BUTTON_LABEL: "承認依頼",
  REJECT_MESSAGE: "否認理由",
  REJECTOR: "否認者",
};

const updateManagementBookPageLabel = () => {
  ManagementBookPageLabel.MANAGEMENT_BOOK_UNSELECTED = i18n.t("management-book-page-label.management-book-unselected");
  ManagementBookPageLabel.MANAGEMENT_BOOK_UNCREATED = i18n.t("management-book-page-label.management-book-uncreated");
  ManagementBookPageLabel.MANAGEMENT_BOOK_COPY_CHECKBOX_LABEL = i18n.t("management-book-page-label.management-book-copy-checkbox-label");
  ManagementBookPageLabel.MANAGEMENT_BOOK_COPY_SOURCE_LISTBOX_LABEL = i18n.t("management-book-page-label.management-book-copy-source-listbox-label");
  ManagementBookPageLabel.MANAGEMENT_BOOK_CREATE_BUTTON_LABEL = i18n.t("management-book-page-label.management-book-create-button-label");
  ManagementBookPageLabel.MANAGEMENT_BOOK_UNPUBLISHED_LABEL = i18n.t("management-book-page-label.management-book-unpublished-label");
  ManagementBookPageLabel.WORK_STANDARD_LIST_TAB = i18n.t("management-book-page-label.work-standard-list-tab");
  ManagementBookPageLabel.PROCESS_LIST_TAB = i18n.t("management-book-page-label.process-list-tab");
  ManagementBookPageLabel.MANAGEMENT_TAB = i18n.t("management-book-page-label.management-tab");
  ManagementBookPageLabel.APPLICATION_DIALOG_TITLE = i18n.t("management-book-page-label.application-dialog-title");
  ManagementBookPageLabel.REVISE_CONTENT = i18n.t("management-book-page-label.revise-content");
  ManagementBookPageLabel.CREATOR = i18n.t("management-book-page-label.creator");
  ManagementBookPageLabel.MANAGEMENT_BOOK_APPLICATION_DIALOG_BUTTON_LABEL = i18n.t(
    "management-book-page-label.management-book-application-dialog-button-label"
  );
  ManagementBookPageLabel.REJECT_MESSAGE = i18n.t("management-book-page-label.reject-message");
  ManagementBookPageLabel.REJECTOR = i18n.t("management-book-page-label.rejector");
};

export const ManagementBookWorkStandardListTabLabel = {
  MANAGEMENT_BOOK_TABLE_TITLE: "管理台帳",
  UNLINK_TABLE_TITLE: "未紐付き作業標準",
  WORK_STANDARD_ORDER: "作業順序",
  MANAGED_NUMBER: "管理番号",
  WORK_STANDARD_NAME: "作業標準名",
  WORK_STANDARD_NAME_SHORT: "作業名",
  NUMBER: "No.",
  WORK_STANDARD_DIALOG_TITLE: "作業標準",
  PROCESS_NAME: "工程名",
};

const updateManagementBookWorkStandardListTabLabel = () => {
  ManagementBookWorkStandardListTabLabel.MANAGEMENT_BOOK_TABLE_TITLE = i18n.t(
    "management-book-work-standard-list-tab-label.management-book-table-title"
  );
  ManagementBookWorkStandardListTabLabel.UNLINK_TABLE_TITLE = i18n.t("management-book-work-standard-list-tab-label.unlink-table-title");
  ManagementBookWorkStandardListTabLabel.WORK_STANDARD_ORDER = i18n.t("management-book-work-standard-list-tab-label.work-standard-order");
  ManagementBookWorkStandardListTabLabel.MANAGED_NUMBER = i18n.t("management-book-work-standard-list-tab-label.managed-number");
  ManagementBookWorkStandardListTabLabel.WORK_STANDARD_NAME = i18n.t("management-book-work-standard-list-tab-label.work-standard-name");
  ManagementBookWorkStandardListTabLabel.WORK_STANDARD_NAME_SHORT = i18n.t("management-book-work-standard-list-tab-label.work-standard-name-short");
  ManagementBookWorkStandardListTabLabel.NUMBER = i18n.t("management-book-work-standard-list-tab-label.number");
  ManagementBookWorkStandardListTabLabel.WORK_STANDARD_DIALOG_TITLE = i18n.t(
    "management-book-work-standard-list-tab-label.work-standard-dialog-title"
  );
  ManagementBookWorkStandardListTabLabel.PROCESS_NAME = i18n.t("management-book-work-standard-list-tab-label.process-name");
};

export const ManagementBookProcessListTabLabel = {
  PROCESS_TABLE_TITLE: "工程一覧",
  PROCESS_ORDER: "工程順序",
  PROCESS_NAME: "工程名",
  PROCESS_DIALOG_TITLE: "工程",
  REGISTER: "登録者",
};

const updateManagementBookProcessListTabLabel = () => {
  ManagementBookProcessListTabLabel.PROCESS_TABLE_TITLE = i18n.t("management-book-process-list-tab-label.process-table-title");
  ManagementBookProcessListTabLabel.PROCESS_ORDER = i18n.t("management-book-process-list-tab-label.process-order");
  ManagementBookProcessListTabLabel.PROCESS_NAME = i18n.t("management-book-process-list-tab-label.process-name");
  ManagementBookProcessListTabLabel.PROCESS_DIALOG_TITLE = i18n.t("management-book-process-list-tab-label.process-dialog-title");
  ManagementBookProcessListTabLabel.REGISTER = i18n.t("management-book-process-list-tab-label.register");
};

export const ManagementBookManagementTabLabel = {
  CURRENT_PRODUCTION_CAPACITY_TABLE_TITLE: "生産台数運用履歴",
  REVISE_HISTORY_TABLE_TITLE: "管理台帳改訂履歴",
  DATE_TIME: "日時",
  CHARGE: "担当",
  CHARGE_PERSON: "担当者",
  CURRENT_HISTORY: "運用履歴",
  REVISION_NUMBER: "版数",
  APPROVER: "承認者",
  REVISE_CONTENT: "改訂内容",
};

const updateManagementBookManagementTabLabel = () => {
  ManagementBookManagementTabLabel.CURRENT_PRODUCTION_CAPACITY_TABLE_TITLE = i18n.t(
    "management-book-management-tab-label.current-production-capacity-table-title"
  );
  ManagementBookManagementTabLabel.REVISE_HISTORY_TABLE_TITLE = i18n.t("management-book-management-tab-label.revise-history-table-title");
  ManagementBookManagementTabLabel.DATE_TIME = i18n.t("management-book-management-tab-label.date-time");
  ManagementBookManagementTabLabel.CHARGE = i18n.t("management-book-management-tab-label.charge");
  ManagementBookManagementTabLabel.CHARGE_PERSON = i18n.t("management-book-management-tab-label.charge-person");
  ManagementBookManagementTabLabel.CURRENT_HISTORY = i18n.t("management-book-management-tab-label.current-history");
  ManagementBookManagementTabLabel.REVISION_NUMBER = i18n.t("management-book-management-tab-label.revision-number");
  ManagementBookManagementTabLabel.APPROVER = i18n.t("management-book-management-tab-label.approver");
  ManagementBookManagementTabLabel.REVISE_CONTENT = i18n.t("management-book-management-tab-label.revise-content");
};

// 新規作成ページ
export const CreateNewPageLabel = {
  LOADING: "新規作成中です",
  TITLEL: "新規作成する作業標準の情報を入力してください",
  COPY_TO: "コピー先",
  COPY_FROM: "コピー元",
  WORK_STANDARD_NAME: "作業標準名",
  CREATE_BUTTON_LABEL: "作業標準を作成",
  COPY_CHECKBOX_LABEL: "作業標準をコピーする",
};

const updateCreateNewPageLabel = () => {
  CreateNewPageLabel.LOADING = i18n.t("create-new-page-label.loading");
  CreateNewPageLabel.TITLEL = i18n.t("create-new-page-label.titlel");
  CreateNewPageLabel.COPY_TO = i18n.t("create-new-page-label.copy-to");
  CreateNewPageLabel.COPY_FROM = i18n.t("create-new-page-label.copy-from");
  CreateNewPageLabel.WORK_STANDARD_NAME = i18n.t("create-new-page-label.work-standard-name");
  CreateNewPageLabel.CREATE_BUTTON_LABEL = i18n.t("create-new-page-label.create-button-label");
  CreateNewPageLabel.COPY_CHECKBOX_LABEL = i18n.t("create-new-page-label.copy-checkbox-label");
};

// 作業標準リストページ
export const WorkStandardListPageLabel = {
  NUMBER: "No.",
  WORK_STANDARD_NAME: "作業標準名",
  STATUS: "ステータス",
  REVISION_NUMBER: "版数",
  MANAGED_NUMBER: "管理番号",
  CREATOR: "作成者 (ログインID)",
  DATE: "作成日",
  CHANGE_NAME: "名前変更",
  MOVE: "移動",
  DELETE: "削除",
  CHANGE_NAME_DIALOG_TITLE: "作業標準名を変更",
  CHANGE_NAME_BUTTON_LABEL: "変更",
  MOVE_DIALOG_TITLE: "作業標準を移動",
  MOVE_TO: "移動先",
  MOVE_BUTTON_LABEL: "移動",
};

const updateWorkStandardListPageLabel = () => {
  WorkStandardListPageLabel.NUMBER = i18n.t("work-standard-list-page-label.number");
  WorkStandardListPageLabel.WORK_STANDARD_NAME = i18n.t("work-standard-list-page-label.work-standard-name");
  WorkStandardListPageLabel.STATUS = i18n.t("work-standard-list-page-label.status");
  WorkStandardListPageLabel.REVISION_NUMBER = i18n.t("work-standard-list-page-label.revision-number");
  WorkStandardListPageLabel.MANAGED_NUMBER = i18n.t("work-standard-list-page-label.managed-number");
  WorkStandardListPageLabel.CREATOR = i18n.t("work-standard-list-page-label.creator");
  WorkStandardListPageLabel.DATE = i18n.t("work-standard-list-page-label.date");
  WorkStandardListPageLabel.CHANGE_NAME = i18n.t("work-standard-list-page-label.change-name");
  WorkStandardListPageLabel.MOVE = i18n.t("work-standard-list-page-label.move");
  WorkStandardListPageLabel.DELETE = i18n.t("work-standard-list-page-label.delete");
  WorkStandardListPageLabel.CHANGE_NAME_DIALOG_TITLE = i18n.t("work-standard-list-page-label.change-name-dialog-title");
  WorkStandardListPageLabel.CHANGE_NAME_BUTTON_LABEL = i18n.t("work-standard-list-page-label.change-name-button-label");
  WorkStandardListPageLabel.MOVE_DIALOG_TITLE = i18n.t("work-standard-list-page-label.move-dialog-title");
  WorkStandardListPageLabel.MOVE_TO = i18n.t("work-standard-list-page-label.move-to");
  WorkStandardListPageLabel.MOVE_BUTTON_LABEL = i18n.t("work-standard-list-page-label.move-button-label");
};

// データ管理ページ
export const DataManagementPageLabel = {
  AREA_MANEGEMENT_TAB: "拠点管理",
  PRODUCTION_CAPACITY_MANAGEMENT_TAB: "生産台数管理",
  CREATE_DATE_TIME: "登録日",
  UPDATE_DATE_TIME: "最終更新日",
  REGISTER: "登録者",
};

const updateDataManagementPageLabel = () => {
  DataManagementPageLabel.AREA_MANEGEMENT_TAB = i18n.t("data-management-page-label.area-manegement-tab");
  DataManagementPageLabel.PRODUCTION_CAPACITY_MANAGEMENT_TAB = i18n.t("data-management-page-label.production-capacity-management-tab");
  DataManagementPageLabel.CREATE_DATE_TIME = i18n.t("data-management-page-label.create-date-time");
  DataManagementPageLabel.UPDATE_DATE_TIME = i18n.t("data-management-page-label.update-date-time");
  DataManagementPageLabel.REGISTER = i18n.t("data-management-page-label.register");
};

export const DataManagementAreaTabLabel = {
  NUMBER: "No.",
  MODEL: "機種",
  MODEL_NAME: "機種名",
  UNIT: "ユニット",
  UNIT_NAME: "ユニット名",
  TEAM: "チーム",
  TEAM_NAME: "チーム名",
  UNIT_PRODUCTION_CAPACITY: "ユニット生産台数",
  PRODUCTION_CAPACITY: "生産台数",
};

const updateDataManagementAreaTabLabel = () => {
  DataManagementAreaTabLabel.NUMBER = i18n.t("data-management-area-tab-label.number");
  DataManagementAreaTabLabel.MODEL = i18n.t("data-management-area-tab-label.model");
  DataManagementAreaTabLabel.MODEL_NAME = i18n.t("data-management-area-tab-label.model-name");
  DataManagementAreaTabLabel.UNIT = i18n.t("data-management-area-tab-label.unit");
  DataManagementAreaTabLabel.UNIT_NAME = i18n.t("data-management-area-tab-label.unit-name");
  DataManagementAreaTabLabel.TEAM = i18n.t("data-management-area-tab-label.team");
  DataManagementAreaTabLabel.TEAM_NAME = i18n.t("data-management-area-tab-label.team-name");
  DataManagementAreaTabLabel.UNIT_PRODUCTION_CAPACITY = i18n.t("data-management-area-tab-label.unit-production-capacity");
  DataManagementAreaTabLabel.PRODUCTION_CAPACITY = i18n.t("data-management-area-tab-label.production-capacity");
};

export const DataManagementProductionCapacityTabLabel = {
  NUMBER: "No.",
  UNIT: "ユニット",
  CURRENT_PRODUCTION_CAPACITY: "運用中生産台数",
  NO_CURRENT_PRODUCTION_CAPACITY: "現在運用中の生産台数がありません",
};

const updateDataManagementProductionCapacityTabLabel = () => {
  DataManagementProductionCapacityTabLabel.NUMBER = i18n.t("data-management-production-capacity-tab-label.number");
  DataManagementProductionCapacityTabLabel.UNIT = i18n.t("data-management-production-capacity-tab-label.unit");
  DataManagementProductionCapacityTabLabel.CURRENT_PRODUCTION_CAPACITY = i18n.t(
    "data-management-production-capacity-tab-label.current-production-capacity"
  );
  DataManagementProductionCapacityTabLabel.NO_CURRENT_PRODUCTION_CAPACITY = i18n.t(
    "data-management-production-capacity-tab-label.no-current-production-capacity"
  );
};

export const DataManagementType = {
  Model: "機種",
  Unit: "ユニット",
  Team: "チーム",
  ProductionCapacity: "ユニット生産台数",
};

const updateDataManagementType = () => {
  DataManagementType.Model = i18n.t("data-management-type.model");
  DataManagementType.Unit = i18n.t("data-management-type.unit");
  DataManagementType.Team = i18n.t("data-management-type.team");
  DataManagementType.ProductionCapacity = i18n.t("data-management-type.production-capacity");
};

// ユーザー管理ページ
export const UserListPageLabel = {
  CREATE_NEW_USER: "ユーザー新規登録",
  LOGIN_ID: "ログインID",
  USER_NAME: "ユーザー名",
  AUTHORITY: "権限",
  BASIC_INFO_TITLE: "基本情報",
  AFFILIATION_INFO_TITLE: "所属情報",
  ROLE: "役職",
  MODULE: "モジュール",
  MODULE_ROLE: "モジュール役職",
  UNIT: "ユニット",
  UNIT_ROLE: "ユニット役職",
  TEAM: "チーム",
  TEAM_ROLE: "チーム役職",
  SUSPENSION: "停止",
  HISTORY: "履歴",
  EDIT: "編集",
  DELETE: "削除",
  SAVE: "保存",
  USER_HISTORY_DIALOG_TITLE: "ユーザー履歴",
  USER_HISTORY_DIALOG_MANIPULATION: "操作",
  USER_HISTORY_DIALOG_DATE_TIME: "日時",
  USER_SETTING_DIALOG_TITLE: "ユーザー情報変更",
  USER_SETTING_DIALOG_SAVE_DELETE: "保存 / 削除",
  USER_SIGN_UP_DIALOG_TITLE: "ユーザー登録",
  USER_SIGN_UP_DIALOG_ID: "ID",
  USER_SIGN_UP_DIALOG_PASSWORD: "Password",
};

const updateUserListPageLabel = () => {
  UserListPageLabel.CREATE_NEW_USER = i18n.t("user-list-page-label.create-new-user");
  UserListPageLabel.LOGIN_ID = i18n.t("user-list-page-label.login-id");
  UserListPageLabel.USER_NAME = i18n.t("user-list-page-label.user-name");
  UserListPageLabel.AUTHORITY = i18n.t("user-list-page-label.authority");
  UserListPageLabel.BASIC_INFO_TITLE = i18n.t("user-list-page-label.basic-info-title");
  UserListPageLabel.AFFILIATION_INFO_TITLE = i18n.t("user-list-page-label.affiliation-info-title");
  UserListPageLabel.ROLE = i18n.t("user-list-page-label.role");
  UserListPageLabel.MODULE = i18n.t("user-list-page-label.module");
  UserListPageLabel.MODULE_ROLE = i18n.t("user-list-page-label.module-role");
  UserListPageLabel.UNIT = i18n.t("user-list-page-label.unit");
  UserListPageLabel.UNIT_ROLE = i18n.t("user-list-page-label.unit-role");
  UserListPageLabel.TEAM = i18n.t("user-list-page-label.team");
  UserListPageLabel.TEAM_ROLE = i18n.t("user-list-page-label.team-role");
  UserListPageLabel.SUSPENSION = i18n.t("user-list-page-label.suspension");
  UserListPageLabel.HISTORY = i18n.t("user-list-page-label.history");
  UserListPageLabel.EDIT = i18n.t("user-list-page-label.edit");
  UserListPageLabel.DELETE = i18n.t("user-list-page-label.delete");
  UserListPageLabel.SAVE = i18n.t("user-list-page-label.save");
  UserListPageLabel.USER_HISTORY_DIALOG_TITLE = i18n.t("user-list-page-label.user-history-dialog-title");
  UserListPageLabel.USER_HISTORY_DIALOG_MANIPULATION = i18n.t("user-list-page-label.user-history-dialog-manipulation");
  UserListPageLabel.USER_HISTORY_DIALOG_DATE_TIME = i18n.t("user-list-page-label.user-history-dialog-date-time");
  UserListPageLabel.USER_SETTING_DIALOG_TITLE = i18n.t("user-list-page-label.user-setting-dialog-title");
  UserListPageLabel.USER_SETTING_DIALOG_SAVE_DELETE = i18n.t("user-list-page-label.user-setting-dialog-save-delete");
  UserListPageLabel.USER_SIGN_UP_DIALOG_TITLE = i18n.t("user-list-page-label.user-sign-up-dialog-title");
  UserListPageLabel.USER_SIGN_UP_DIALOG_ID = i18n.t("user-list-page-label.user-sign-up-dialog-id");
  UserListPageLabel.USER_SIGN_UP_DIALOG_PASSWORD = i18n.t("user-list-page-label.user-sign-up-dialog-password");
};

// ログインページ
export const LoginPageLabel = {
  LOGIN_TITLE: "Login",
  LOGOUT_TITLE: "Logout",
  LOGIN_BUTTON: "ログイン",
  LOGOUT_BUTTON: "ログアウト",
  ID: "ID",
  PASSWORD: "Password",
  CONTACT_INFORMATION: "問い合わせ先:",
  CONTACT_INFORMATION_MAIL: "tw_digitech_support@hm.honda.co.jp",
};

const updateLoginPageLabel = () => {
  LoginPageLabel.LOGIN_TITLE = i18n.t("login-page-label.login-title");
  LoginPageLabel.LOGOUT_TITLE = i18n.t("login-page-label.logout-title");
  LoginPageLabel.LOGIN_BUTTON = i18n.t("login-page-label.login-button");
  LoginPageLabel.LOGOUT_BUTTON = i18n.t("login-page-label.logout-button");
  LoginPageLabel.ID = i18n.t("login-page-label.id");
  LoginPageLabel.PASSWORD = i18n.t("login-page-label.password");
  LoginPageLabel.CONTACT_INFORMATION = i18n.t("login-page-label.contact-information");
  LoginPageLabel.CONTACT_INFORMATION_MAIL = i18n.t("login-page-label.contact-information-mail");
};

export const InstructionsPageLabel = {
  INTRODUCTION: "はじめに",
  INTRODUCTION_LOGIN: "ログイン",
  INTRODUCTION_LOGOUT: "ログアウト",
  INTRODUCTION_SYSTEM_AUTHORITY: "システム権限",
  INTRODUCTION_WORK_STANDARD_STATUS: "作業標準のステータス",
  INTRODUCTION_MANAGEMENT_BOOK_STATUS: "管理台帳のステータス",
  INTRODUCTION_SEARCH: "ページ内の検索",

  WORK_STANDARD_VIEW: "作業標準を閲覧",
  WORK_STANDARD_VIEW_OPEN: "作業標準を開く",
  WORK_STANDARD_VIEW_WORK_STANDARD_SELECT: "作業標準を切り替える",
  WORK_STANDARD_VIEW_WORK_STANDARD_OPERATOR_SIGN: "作業者サイン",

  CREATE_NEW: "新しい作業標準を作成",
  CREATE_NEW_CREATE_NEW: "新規作成",

  WORK_STANDARD_EDIT: "作業標準を編集",
  WORK_STANDARD_EDIT_WORK_STANDARD_LIST: "作業標準を開く",
  WORK_STANDARD_EDIT_EDIT_MODE: "編集モード",
  WORK_STANDARD_EDIT_EDIT_CARD: "作業詳細カード",
  WORK_STANDARD_EDIT_EDIT_DETAIL_ILLUSTRATION: "図解",
  WORK_STANDARD_EDIT_EDIT_SAFETY_POINT: "安全ポイント",
  WORK_STANDARD_EDIT_EDIT_SAFETY_EQUIPMENT: "安全保護具",
  WORK_STANDARD_EDIT_EDIT_REQUIREMENT: "管理項目",
  WORK_STANDARD_EDIT_EDIT_PARTS: "部品表",

  WORK_STANDARD_CHANGE_NAME: "作業標準名を変更",
  WORK_STANDARD_CHANGE_NAME_WORK_STANDARD_CHANGE_NAME: "名前を変更",

  WORK_STANDARD_MOVE: "作業標準を移動",
  WORK_STANDARD_MOVE_WORK_STANDARD_MOVE: "他チームに移動",

  WORK_STANDARD_DELETE: "作業標準を削除",
  WORK_STANDARD_DELETE_WORK_STANDARD_DELETE: "削除",

  WORK_STANDARD_PUBLISH: "作業標準を公開",
  WORK_STANDARD_PUBLISH_APPLICATION: "承認依頼",
  WORK_STANDARD_PUBLISH_CONFIRMER: "確認者サイン",
  WORK_STANDARD_PUBLISH_APPROVER: "承認者サイン",
  WORK_STANDARD_PUBLISH_PUBLISH: "発行",
  WORK_STANDARD_PUBLISH_CANCEL: "引き戻し",
  WORK_STANDARD_PUBLISH_REJECT: "否認",
  WORK_STANDARD_PUBLISH_MODIFY: "修正",

  WORK_STANDARD_REVISE: "公開した作業標準を改訂",
  WORK_STANDARD_REVISE_REVISE: "改訂",
  WORK_STANDARD_REVISE_HISTORY_DETAIL: "改訂履歴タブ_改訂履歴",

  WORK_STANDARD_PUBLISH_UNDER_REVISION: "改訂した作業標準を公開",
  WORK_STANDARD_PUBLISH_UNDER_REVISION_APPLICATION: "承認依頼",
  WORK_STANDARD_PUBLISH_UNDER_REVISION_APPROVER: "承認者サイン",
  WORK_STANDARD_PUBLISH_UNDER_REVISION_PUBLISH: "発行",
  WORK_STANDARD_PUBLISH_UNDER_REVISION_CANCEL: "引き戻し",
  WORK_STANDARD_PUBLISH_UNDER_REVISION_REJECT: "否認",
  WORK_STANDARD_PUBLISH_UNDER_REVISION_MODIFY: "修正",

  WORK_STANDARD_ARCHIVE: "作業標準をアーカイブ",
  WORK_STANDARD_ARCHIVE_ARCHIVE_APPLICATION: "アーカイブ承認依頼",
  WORK_STANDARD_ARCHIVE_ARCHIVE_APPROVE: "アーカイブ承認",
  WORK_STANDARD_ARCHIVE_ARCHIVE_REJECT: "アーカイブ否認",

  MANAGEMENT_BOOK_OPEN: "管理台帳を閲覧",
  MANAGEMENT_BOOK_OPEN_MANAGEMENT_BOOK_PAGE: "管理台帳を開く",

  MANAGEMENT_BOOK_EDIT: "管理台帳を編集",
  MANAGEMENT_BOOK_EDIT_MANAGEMENT_BOOK_CREATE: "新規作成",
  MANAGEMENT_BOOK_EDIT_EDIT_MODE: "編集モード",
  MANAGEMENT_BOOK_EDIT_WORK_STANDARD_IN_PROCESS: "作業標準一覧タブ",
  MANAGEMENT_BOOK_EDIT_PROCESS: "工程一覧タブ",

  MANAGEMENT_BOOK_PUBLISH: "管理台帳を公開",
  MANAGEMENT_BOOK_PUBLISH_APPLICATION: "承認依頼",
  MANAGEMENT_BOOK_PUBLISH_APPROVE: "承認者サイン",
  MANAGEMENT_BOOK_PUBLISH_PUBLISH: "発行",
  MANAGEMENT_BOOK_PUBLISH_CANCEL: "引き戻し",
  MANAGEMENT_BOOK_PUBLISH_REJECT: "否認",
  MANAGEMENT_BOOK_PUBLISH_MODIFY: "修正",

  MANAGEMENT_BOOK_REVISE: "公開した管理台帳を改訂",
  MANAGEMENT_BOOK_REVISE_REVISE: "改訂",

  DATA_MANAGEMENT: "マスターデータを編集",
  DATA_MANAGEMENT_MODEL: "機種",
  DATA_MANAGEMENT_UNIT: "ユニット",
  DATA_MANAGEMENT_TEAM: "チーム",
  DATA_MANAGEMENT_PRODUCTION_CAPACITY: "ユニット生産台数",
  DATA_MANAGEMENT_PRODUCTION_CAPACITY_CURRENTLY: "運用中生産台数",

  USER_LIST: "ユーザーを管理",
  USER_LIST_VIEW: "閲覧",
  USER_LIST_REGISTER: "登録",
  USER_LIST_MODIFY: "編集",
  USER_LIST_DELETE: "削除",
};

const updateInstructionsPageLabel = () => {
  InstructionsPageLabel.INTRODUCTION = i18n.t("instructions-page-label.introduction");
  InstructionsPageLabel.INTRODUCTION_LOGIN = i18n.t("instructions-page-label.introduction-login");
  InstructionsPageLabel.INTRODUCTION_LOGOUT = i18n.t("instructions-page-label.introduction-logout");
  InstructionsPageLabel.INTRODUCTION_SYSTEM_AUTHORITY = i18n.t("instructions-page-label.introduction-system-authority");
  InstructionsPageLabel.INTRODUCTION_WORK_STANDARD_STATUS = i18n.t("instructions-page-label.introduction-work-standard-status");
  InstructionsPageLabel.INTRODUCTION_MANAGEMENT_BOOK_STATUS = i18n.t("instructions-page-label.introduction-management-book-status");
  InstructionsPageLabel.INTRODUCTION_SEARCH = i18n.t("instructions-page-label.introduction-search");
  InstructionsPageLabel.WORK_STANDARD_VIEW = i18n.t("instructions-page-label.work-standard-view");
  InstructionsPageLabel.WORK_STANDARD_VIEW_OPEN = i18n.t("instructions-page-label.work-standard-view-open");
  InstructionsPageLabel.WORK_STANDARD_VIEW_WORK_STANDARD_SELECT = i18n.t("instructions-page-label.work-standard-view-work-standard-select");
  InstructionsPageLabel.WORK_STANDARD_VIEW_WORK_STANDARD_OPERATOR_SIGN = i18n.t(
    "instructions-page-label.work-standard-view-work-standard-operator-sign"
  );
  InstructionsPageLabel.CREATE_NEW = i18n.t("instructions-page-label.create-new");
  InstructionsPageLabel.CREATE_NEW_CREATE_NEW = i18n.t("instructions-page-label.create-new-create-new");
  InstructionsPageLabel.WORK_STANDARD_EDIT = i18n.t("instructions-page-label.work-standard-edit");
  InstructionsPageLabel.WORK_STANDARD_EDIT_WORK_STANDARD_LIST = i18n.t("instructions-page-label.work-standard-edit-work-standard-list");
  InstructionsPageLabel.WORK_STANDARD_EDIT_EDIT_MODE = i18n.t("instructions-page-label.work-standard-edit-edit-mode");
  InstructionsPageLabel.WORK_STANDARD_EDIT_EDIT_CARD = i18n.t("instructions-page-label.work-standard-edit-edit-card");
  InstructionsPageLabel.WORK_STANDARD_EDIT_EDIT_DETAIL_ILLUSTRATION = i18n.t("instructions-page-label.work-standard-edit-edit-detail-illustration");
  InstructionsPageLabel.WORK_STANDARD_EDIT_EDIT_SAFETY_POINT = i18n.t("instructions-page-label.work-standard-edit-edit-safety-point");
  InstructionsPageLabel.WORK_STANDARD_EDIT_EDIT_SAFETY_EQUIPMENT = i18n.t("instructions-page-label.work-standard-edit-edit-safety-equipment");
  InstructionsPageLabel.WORK_STANDARD_EDIT_EDIT_REQUIREMENT = i18n.t("instructions-page-label.work-standard-edit-edit-requirement");
  InstructionsPageLabel.WORK_STANDARD_EDIT_EDIT_PARTS = i18n.t("instructions-page-label.work-standard-edit-edit-parts");
  InstructionsPageLabel.WORK_STANDARD_CHANGE_NAME = i18n.t("instructions-page-label.work-standard-change-name");
  InstructionsPageLabel.WORK_STANDARD_CHANGE_NAME_WORK_STANDARD_CHANGE_NAME = i18n.t(
    "instructions-page-label.work-standard-change-name-work-standard-change-name"
  );
  InstructionsPageLabel.WORK_STANDARD_MOVE = i18n.t("instructions-page-label.work-standard-move");
  InstructionsPageLabel.WORK_STANDARD_MOVE_WORK_STANDARD_MOVE = i18n.t("instructions-page-label.work-standard-move-work-standard-move");
  InstructionsPageLabel.WORK_STANDARD_DELETE = i18n.t("instructions-page-label.work-standard-delete");
  InstructionsPageLabel.WORK_STANDARD_DELETE_WORK_STANDARD_DELETE = i18n.t("instructions-page-label.work-standard-delete-work-standard-delete");
  InstructionsPageLabel.WORK_STANDARD_PUBLISH = i18n.t("instructions-page-label.work-standard-publish");
  InstructionsPageLabel.WORK_STANDARD_PUBLISH_APPLICATION = i18n.t("instructions-page-label.work-standard-publish-application");
  InstructionsPageLabel.WORK_STANDARD_PUBLISH_CONFIRMER = i18n.t("instructions-page-label.work-standard-publish-confirmer");
  InstructionsPageLabel.WORK_STANDARD_PUBLISH_APPROVER = i18n.t("instructions-page-label.work-standard-publish-approver");
  InstructionsPageLabel.WORK_STANDARD_PUBLISH_PUBLISH = i18n.t("instructions-page-label.work-standard-publish-publish");
  InstructionsPageLabel.WORK_STANDARD_PUBLISH_CANCEL = i18n.t("instructions-page-label.work-standard-publish-cancel");
  InstructionsPageLabel.WORK_STANDARD_PUBLISH_REJECT = i18n.t("instructions-page-label.work-standard-publish-reject");
  InstructionsPageLabel.WORK_STANDARD_PUBLISH_MODIFY = i18n.t("instructions-page-label.work-standard-publish-modify");
  InstructionsPageLabel.WORK_STANDARD_REVISE = i18n.t("instructions-page-label.work-standard-revise");
  InstructionsPageLabel.WORK_STANDARD_REVISE_REVISE = i18n.t("instructions-page-label.work-standard-revise-revise");
  InstructionsPageLabel.WORK_STANDARD_REVISE_HISTORY_DETAIL = i18n.t("instructions-page-label.work-standard-revise-history-detail");
  InstructionsPageLabel.WORK_STANDARD_PUBLISH_UNDER_REVISION = i18n.t("instructions-page-label.work-standard-publish-under-revision");
  InstructionsPageLabel.WORK_STANDARD_PUBLISH_UNDER_REVISION_APPLICATION = i18n.t(
    "instructions-page-label.work-standard-publish-under-revision-application"
  );
  InstructionsPageLabel.WORK_STANDARD_PUBLISH_UNDER_REVISION_APPROVER = i18n.t(
    "instructions-page-label.work-standard-publish-under-revision-approver"
  );
  InstructionsPageLabel.WORK_STANDARD_PUBLISH_UNDER_REVISION_PUBLISH = i18n.t("instructions-page-label.work-standard-publish-under-revision-publish");
  InstructionsPageLabel.WORK_STANDARD_PUBLISH_UNDER_REVISION_CANCEL = i18n.t("instructions-page-label.work-standard-publish-under-revision-cancel");
  InstructionsPageLabel.WORK_STANDARD_PUBLISH_UNDER_REVISION_REJECT = i18n.t("instructions-page-label.work-standard-publish-under-revision-reject");
  InstructionsPageLabel.WORK_STANDARD_PUBLISH_UNDER_REVISION_MODIFY = i18n.t("instructions-page-label.work-standard-publish-under-revision-modify");
  InstructionsPageLabel.WORK_STANDARD_ARCHIVE = i18n.t("instructions-page-label.work-standard-archive");
  InstructionsPageLabel.WORK_STANDARD_ARCHIVE_ARCHIVE_APPLICATION = i18n.t("instructions-page-label.work-standard-archive-archive-application");
  InstructionsPageLabel.WORK_STANDARD_ARCHIVE_ARCHIVE_APPROVE = i18n.t("instructions-page-label.work-standard-archive-archive-approve");
  InstructionsPageLabel.WORK_STANDARD_ARCHIVE_ARCHIVE_REJECT = i18n.t("instructions-page-label.work-standard-archive-archive-reject");
  InstructionsPageLabel.MANAGEMENT_BOOK_OPEN = i18n.t("instructions-page-label.management-book-open");
  InstructionsPageLabel.MANAGEMENT_BOOK_OPEN_MANAGEMENT_BOOK_PAGE = i18n.t("instructions-page-label.management-book-open-management-book-page");
  InstructionsPageLabel.MANAGEMENT_BOOK_EDIT = i18n.t("instructions-page-label.management-book-edit");
  InstructionsPageLabel.MANAGEMENT_BOOK_EDIT_MANAGEMENT_BOOK_CREATE = i18n.t("instructions-page-label.management-book-edit-management-book-create");
  InstructionsPageLabel.MANAGEMENT_BOOK_EDIT_EDIT_MODE = i18n.t("instructions-page-label.management-book-edit-edit-mode");
  InstructionsPageLabel.MANAGEMENT_BOOK_EDIT_WORK_STANDARD_IN_PROCESS = i18n.t(
    "instructions-page-label.management-book-edit-work-standard-in-process"
  );
  InstructionsPageLabel.MANAGEMENT_BOOK_EDIT_PROCESS = i18n.t("instructions-page-label.management-book-edit-process");
  InstructionsPageLabel.MANAGEMENT_BOOK_PUBLISH = i18n.t("instructions-page-label.management-book-publish");
  InstructionsPageLabel.MANAGEMENT_BOOK_PUBLISH_APPLICATION = i18n.t("instructions-page-label.management-book-publish-application");
  InstructionsPageLabel.MANAGEMENT_BOOK_PUBLISH_APPROVE = i18n.t("instructions-page-label.management-book-publish-approve");
  InstructionsPageLabel.MANAGEMENT_BOOK_PUBLISH_PUBLISH = i18n.t("instructions-page-label.management-book-publish-publish");
  InstructionsPageLabel.MANAGEMENT_BOOK_PUBLISH_CANCEL = i18n.t("instructions-page-label.management-book-publish-cancel");
  InstructionsPageLabel.MANAGEMENT_BOOK_PUBLISH_REJECT = i18n.t("instructions-page-label.management-book-publish-reject");
  InstructionsPageLabel.MANAGEMENT_BOOK_PUBLISH_MODIFY = i18n.t("instructions-page-label.management-book-publish-modify");
  InstructionsPageLabel.MANAGEMENT_BOOK_REVISE = i18n.t("instructions-page-label.management-book-revise");
  InstructionsPageLabel.MANAGEMENT_BOOK_REVISE_REVISE = i18n.t("instructions-page-label.management-book-revise-revise");
  InstructionsPageLabel.DATA_MANAGEMENT = i18n.t("instructions-page-label.data-management");
  InstructionsPageLabel.DATA_MANAGEMENT_MODEL = i18n.t("instructions-page-label.data-management-model");
  InstructionsPageLabel.DATA_MANAGEMENT_UNIT = i18n.t("instructions-page-label.data-management-unit");
  InstructionsPageLabel.DATA_MANAGEMENT_TEAM = i18n.t("instructions-page-label.data-management-team");
  InstructionsPageLabel.DATA_MANAGEMENT_PRODUCTION_CAPACITY = i18n.t("instructions-page-label.data-management-production-capacity");
  InstructionsPageLabel.DATA_MANAGEMENT_PRODUCTION_CAPACITY_CURRENTLY = i18n.t(
    "instructions-page-label.data-management-production-capacity-currently"
  );
  InstructionsPageLabel.USER_LIST = i18n.t("instructions-page-label.user-list");
  InstructionsPageLabel.USER_LIST_VIEW = i18n.t("instructions-page-label.user-list-view");
  InstructionsPageLabel.USER_LIST_REGISTER = i18n.t("instructions-page-label.user-list-register");
  InstructionsPageLabel.USER_LIST_MODIFY = i18n.t("instructions-page-label.user-list-modify");
  InstructionsPageLabel.USER_LIST_DELETE = i18n.t("instructions-page-label.user-list-delete");
};

// 作業標準ページ
export const WorkStandardPageLabel = {
  WORK_STANDARD_GET_ERROR_FROM_MANAGEMENT_BOOK_OPEN: "作業標準の取得に失敗しました。\nメニューバーからやり直してください。",
  MANAGEMENT_BOOK_UNREGISTED: "管理台帳が登録されていません。\n登録後に再度実行してください。",
  WORK_STANDARD_UNLINKED: "工程に紐づく作業標準が登録されていません。\n登録後に再度実行してください。",
  PRODUCTION_CAPACITY: "生産台数：",
  WORK_STANDARD_GET_ERROR: "作業標準の取得に失敗しました。もう一度開き直してください。",
  WORK_STANDARD_GET_ERROR_CONTACT: "問題が解決しない場合は、システム管理者に連絡してください。",
  LOADING: "改訂版作成中です",
  ARCHIVE_APPROVE_DIALOG_TITLE: "アーカイブ承認",
  ARCHIVE_APPROVE_DIALOG_MESSAGE: "アーカイブ承認依頼を承認して、作業標準のステータスをアーカイブにしますか？",
  ARCHIVE_APPROVE_DIALOG_APPROVE_BUTTON: "承認",
  OPERATOR_SIGN: "作業者サイン",
  WORK_TAB: "作業",
  REVISE_HISTORY_TAB: "改訂履歴",
  MANAGEMENT_TAB: "管理",
  WORK_STANDARD_CLOSE: "作業標準を閉じる",
  REVISING_DIALOG_TITLE: "改訂内容入力",
  OVERVIEW: "概要",
  DETAIL: "詳細",
  CREATOR: "作成者",
  APPLICATION: "承認依頼",
  REJECT_MESSAGE: "否認理由",
  REJECTOR: "否認者",
};

const updateWorkStandardPageLabel = () => {
  WorkStandardPageLabel.WORK_STANDARD_GET_ERROR_FROM_MANAGEMENT_BOOK_OPEN = i18n.t(
    "work-standard-page-label.work-standard-get-error-from-management-book-open"
  );
  WorkStandardPageLabel.MANAGEMENT_BOOK_UNREGISTED = i18n.t("work-standard-page-label.management-book-unregisted");
  WorkStandardPageLabel.WORK_STANDARD_UNLINKED = i18n.t("work-standard-page-label.work-standard-unlinked");
  WorkStandardPageLabel.PRODUCTION_CAPACITY = i18n.t("work-standard-page-label.production-capacity");
  WorkStandardPageLabel.WORK_STANDARD_GET_ERROR = i18n.t("work-standard-page-label.work-standard-get-error");
  WorkStandardPageLabel.WORK_STANDARD_GET_ERROR_CONTACT = i18n.t("work-standard-page-label.work-standard-get-error-contact");
  WorkStandardPageLabel.LOADING = i18n.t("work-standard-page-label.loading");
  WorkStandardPageLabel.ARCHIVE_APPROVE_DIALOG_TITLE = i18n.t("work-standard-page-label.archive-approve-dialog-title");
  WorkStandardPageLabel.ARCHIVE_APPROVE_DIALOG_MESSAGE = i18n.t("work-standard-page-label.archive-approve-dialog-message");
  WorkStandardPageLabel.ARCHIVE_APPROVE_DIALOG_APPROVE_BUTTON = i18n.t("work-standard-page-label.archive-approve-dialog-approve-button");
  WorkStandardPageLabel.OPERATOR_SIGN = i18n.t("work-standard-page-label.operator-sign");
  WorkStandardPageLabel.WORK_TAB = i18n.t("work-standard-page-label.work-tab");
  WorkStandardPageLabel.REVISE_HISTORY_TAB = i18n.t("work-standard-page-label.revise-history-tab");
  WorkStandardPageLabel.MANAGEMENT_TAB = i18n.t("work-standard-page-label.management-tab");
  WorkStandardPageLabel.WORK_STANDARD_CLOSE = i18n.t("work-standard-page-label.work-standard-close");
  WorkStandardPageLabel.REVISING_DIALOG_TITLE = i18n.t("work-standard-page-label.revising-dialog-title");
  WorkStandardPageLabel.OVERVIEW = i18n.t("work-standard-page-label.overview");
  WorkStandardPageLabel.DETAIL = i18n.t("work-standard-page-label.detail");
  WorkStandardPageLabel.CREATOR = i18n.t("work-standard-page-label.creator");
  WorkStandardPageLabel.APPLICATION = i18n.t("work-standard-page-label.application");
  WorkStandardPageLabel.REJECT_MESSAGE = i18n.t("work-standard-page-label.reject-message");
  WorkStandardPageLabel.REJECTOR = i18n.t("work-standard-page-label.rejector");
};

export const WorkStandardWorkTabLabel = {
  NO_ILLUSTRATION_LINE1: "図解が",
  NO_ILLUSTRATION_LINE2: "ありません",
  ILLUSTRATION_TABLE_TITLE: "図解",
  SAFETY_POINT_TABLE_TITLE: "安全ポイント",
  SAFETY_EQUIPMENT_TABLE_TITLE: "安全保護具",
  REQUIREMENT_TABLE_TITLE: "管理項目",
  PARTS_TABLE_TITLE: "部品表",
  CANVAS_TITLE: "Pivot-Canvas",
  OVERVIEW_ILLUSTRATION: "概要イラスト",
  SECOND: "秒",
  END_OF_WORK: "作業の末尾です",
  COPY: "複製",
  DELETE: "削除",
  POINT: "作業ポイント",
  QUALITY_CHARACTERISTIC: "品質特性",
  EVENT_OCCURRING: "発生事象",
  TOOL: "工具と補助材",
  TROUBLE: "過去トラ",
  ADD_HASH: "ハッシュ追加",
  SELECT_ADD_HASH: "追加ハッシュ選択",
  ADD_INFORMATION: "情報追加",
  SELECT_ADD_INFORMATION: "追加情報選択",
  ADD_REQUIREMENT: "管理項目追加",
  ALL_REGISTERED: "すべて登録済み",
  ADD_SAFE_EQUIPMENT: "安全保護具追加",
  PARTS_TABLE_NUMBER: "No.",
  PARTS_NAME: "部品名",
  PARTS_COUNT: "個数",
  ADD_PARTS_DIALOG_TITLE: "部品",
};

const updateWorkStandardWorkTabLabel = () => {
  WorkStandardWorkTabLabel.NO_ILLUSTRATION_LINE1 = i18n.t("work-standard-work-tab-label.no-illustration-line1");
  WorkStandardWorkTabLabel.NO_ILLUSTRATION_LINE2 = i18n.t("work-standard-work-tab-label.no-illustration-line2");
  WorkStandardWorkTabLabel.ILLUSTRATION_TABLE_TITLE = i18n.t("work-standard-work-tab-label.illustration-table-title");
  WorkStandardWorkTabLabel.SAFETY_POINT_TABLE_TITLE = i18n.t("work-standard-work-tab-label.safety-point-table-title");
  WorkStandardWorkTabLabel.SAFETY_EQUIPMENT_TABLE_TITLE = i18n.t("work-standard-work-tab-label.safety-equipment-table-title");
  WorkStandardWorkTabLabel.REQUIREMENT_TABLE_TITLE = i18n.t("work-standard-work-tab-label.requirement-table-title");
  WorkStandardWorkTabLabel.PARTS_TABLE_TITLE = i18n.t("work-standard-work-tab-label.parts-table-title");
  WorkStandardWorkTabLabel.CANVAS_TITLE = i18n.t("work-standard-work-tab-label.canvas-title");
  WorkStandardWorkTabLabel.OVERVIEW_ILLUSTRATION = i18n.t("work-standard-work-tab-label.overview-illustration");
  WorkStandardWorkTabLabel.SECOND = i18n.t("work-standard-work-tab-label.second");
  WorkStandardWorkTabLabel.END_OF_WORK = i18n.t("work-standard-work-tab-label.end-of-work");
  WorkStandardWorkTabLabel.COPY = i18n.t("work-standard-work-tab-label.copy");
  WorkStandardWorkTabLabel.DELETE = i18n.t("work-standard-work-tab-label.delete");
  WorkStandardWorkTabLabel.POINT = i18n.t("work-standard-work-tab-label.point");
  WorkStandardWorkTabLabel.QUALITY_CHARACTERISTIC = i18n.t("work-standard-work-tab-label.quality-characteristic");
  WorkStandardWorkTabLabel.EVENT_OCCURRING = i18n.t("work-standard-work-tab-label.event-occurring");
  WorkStandardWorkTabLabel.TOOL = i18n.t("work-standard-work-tab-label.tool");
  WorkStandardWorkTabLabel.TROUBLE = i18n.t("work-standard-work-tab-label.trouble");
  WorkStandardWorkTabLabel.ADD_HASH = i18n.t("work-standard-work-tab-label.add-hash");
  WorkStandardWorkTabLabel.SELECT_ADD_HASH = i18n.t("work-standard-work-tab-label.select-add-hash");
  WorkStandardWorkTabLabel.ADD_INFORMATION = i18n.t("work-standard-work-tab-label.add-information");
  WorkStandardWorkTabLabel.SELECT_ADD_INFORMATION = i18n.t("work-standard-work-tab-label.select-add-information");
  WorkStandardWorkTabLabel.ADD_REQUIREMENT = i18n.t("work-standard-work-tab-label.add-requirement");
  WorkStandardWorkTabLabel.ALL_REGISTERED = i18n.t("work-standard-work-tab-label.all-registered");
  WorkStandardWorkTabLabel.ADD_SAFE_EQUIPMENT = i18n.t("work-standard-work-tab-label.add-safe-equipment");
  WorkStandardWorkTabLabel.PARTS_TABLE_NUMBER = i18n.t("work-standard-work-tab-label.parts-table-number");
  WorkStandardWorkTabLabel.PARTS_NAME = i18n.t("work-standard-work-tab-label.parts-name");
  WorkStandardWorkTabLabel.PARTS_COUNT = i18n.t("work-standard-work-tab-label.parts-count");
  WorkStandardWorkTabLabel.ADD_PARTS_DIALOG_TITLE = i18n.t("work-standard-work-tab-label.add-parts-dialog-title");
};

export const SafetyEquipment = {
  FACE_COVER: "顔面保護具",
  WELDING_MASK: "溶接面",
  HELMET: "ヘルメット",
  ANTI_STATIC_CAP: "静電帽",
  CAP: "帽子",
  DUST_PROOF_HAT: "防塵帽",
  GLASSES: "保護メガネ",
  WELDING_GLASSES: "溶接メガネ",
  SOLVENT_RESISTANT_GLASSES: "耐溶剤メガネ",
  CHEMICAL_RESISTANT_GLASSES: "耐薬品メガネ",
  SOLVENT_RESISTANT_GOGGLES: "耐溶剤ゴーグル",
  CHEMICAL_RESISTANT_GOGGLES: "耐薬品ゴーグル",
  GLOVES: "手袋",
  GAUNTLETS: "手甲",
  SIMON_GLOVES: "シモン手袋",
  COTTON_GLOVES: "綿手袋",
  MEXCEL_GLOVES: "メクセル手袋",
  NYLON_GLOVES: "ナイロン手袋",
  LEATHER_GLOVES: "革手袋",
  ANTI_STATIC_GLOVES: "静電手袋",
  SOLVENT_RESISTANT_GLOVES: "耐溶剤手袋",
  CHEMICAL_RESISTANT_GLOVES: "耐薬品手袋",
  OIL_RESISTANT_GLOVES: "耐油手袋",
  SAFETY_SHOES: "安全靴",
  LEG_GUARDS: "レガース/脛当て",
  LEG_GAUNTLETS: "足の甲",
  ANTI_STATIC_SAFETY_SHOES: "静電靴",
  SAFETY_HALF_BOOTS: "半長靴",
  ANTI_STATIC_SAFETY_HALF_BOOTS: "静電半長靴",
  SAFETY_BOOTS: "長靴",
  LEG_PROTECTOR: "プロテクター",
  ARM_COVER: "アームカバー",
  MEXCEL_ARM_COVER: "メクセルカバー",
  EARPLUG: "耳栓",
  EARMUFFS: "イヤーマフ",
  APRON: "前掛け",
  DUST_PROOF_CLOTHING: "防塵着",
  SAFETY_BELT: "安全帯",
  SOLVENT_RESISTANT_APRON: "耐溶剤エプロン",
  CHEMICAL_RESISTANT_APRON: "耐薬品エプロン",
  MASK: "マスク",
  GAS_MASK: "防毒",
  DUST_MASK: "防塵マスク",
};

const updateSafetyEquipment = () => {
  SafetyEquipment.FACE_COVER = i18n.t("safety-equipment.face-cover");
  SafetyEquipment.WELDING_MASK = i18n.t("safety-equipment.welding-mask");
  SafetyEquipment.HELMET = i18n.t("safety-equipment.helmet");
  SafetyEquipment.ANTI_STATIC_CAP = i18n.t("safety-equipment.anti-static-cap");
  SafetyEquipment.CAP = i18n.t("safety-equipment.cap");
  SafetyEquipment.DUST_PROOF_HAT = i18n.t("safety-equipment.dust-proof-hat");
  SafetyEquipment.GLASSES = i18n.t("safety-equipment.glasses");
  SafetyEquipment.WELDING_GLASSES = i18n.t("safety-equipment.welding-glasses");
  SafetyEquipment.SOLVENT_RESISTANT_GLASSES = i18n.t("safety-equipment.solvent-resistant-glasses");
  SafetyEquipment.CHEMICAL_RESISTANT_GLASSES = i18n.t("safety-equipment.chemical-resistant-glasses");
  SafetyEquipment.SOLVENT_RESISTANT_GOGGLES = i18n.t("safety-equipment.solvent-resistant-goggles");
  SafetyEquipment.CHEMICAL_RESISTANT_GOGGLES = i18n.t("safety-equipment.chemical-resistant-goggles");
  SafetyEquipment.GLOVES = i18n.t("safety-equipment.gloves");
  SafetyEquipment.GAUNTLETS = i18n.t("safety-equipment.gauntlets");
  SafetyEquipment.SIMON_GLOVES = i18n.t("safety-equipment.simon-gloves");
  SafetyEquipment.COTTON_GLOVES = i18n.t("safety-equipment.cotton-gloves");
  SafetyEquipment.MEXCEL_GLOVES = i18n.t("safety-equipment.mexcel-gloves");
  SafetyEquipment.NYLON_GLOVES = i18n.t("safety-equipment.nylon-gloves");
  SafetyEquipment.LEATHER_GLOVES = i18n.t("safety-equipment.leather-gloves");
  SafetyEquipment.ANTI_STATIC_GLOVES = i18n.t("safety-equipment.anti-static-gloves");
  SafetyEquipment.SOLVENT_RESISTANT_GLOVES = i18n.t("safety-equipment.solvent-resistant-gloves");
  SafetyEquipment.CHEMICAL_RESISTANT_GLOVES = i18n.t("safety-equipment.chemical-resistant-gloves");
  SafetyEquipment.OIL_RESISTANT_GLOVES = i18n.t("safety-equipment.oil-resistant-gloves");
  SafetyEquipment.SAFETY_SHOES = i18n.t("safety-equipment.safety-shoes");
  SafetyEquipment.LEG_GUARDS = i18n.t("safety-equipment.leg-guards");
  SafetyEquipment.LEG_GAUNTLETS = i18n.t("safety-equipment.leg-gauntlets");
  SafetyEquipment.ANTI_STATIC_SAFETY_SHOES = i18n.t("safety-equipment.anti-static-safety-shoes");
  SafetyEquipment.SAFETY_HALF_BOOTS = i18n.t("safety-equipment.safety-half-boots");
  SafetyEquipment.ANTI_STATIC_SAFETY_HALF_BOOTS = i18n.t("safety-equipment.anti-static-safety-half-boots");
  SafetyEquipment.SAFETY_BOOTS = i18n.t("safety-equipment.safety-boots");
  SafetyEquipment.LEG_PROTECTOR = i18n.t("safety-equipment.leg-protector");
  SafetyEquipment.ARM_COVER = i18n.t("safety-equipment.arm-cover");
  SafetyEquipment.MEXCEL_ARM_COVER = i18n.t("safety-equipment.mexcel-arm-cover");
  SafetyEquipment.EARPLUG = i18n.t("safety-equipment.earplug");
  SafetyEquipment.EARMUFFS = i18n.t("safety-equipment.earmuffs");
  SafetyEquipment.APRON = i18n.t("safety-equipment.apron");
  SafetyEquipment.DUST_PROOF_CLOTHING = i18n.t("safety-equipment.dust-proof-clothing");
  SafetyEquipment.SAFETY_BELT = i18n.t("safety-equipment.safety-belt");
  SafetyEquipment.SOLVENT_RESISTANT_APRON = i18n.t("safety-equipment.solvent-resistant-apron");
  SafetyEquipment.CHEMICAL_RESISTANT_APRON = i18n.t("safety-equipment.chemical-resistant-apron");
  SafetyEquipment.MASK = i18n.t("safety-equipment.mask");
  SafetyEquipment.GAS_MASK = i18n.t("safety-equipment.gas-mask");
  SafetyEquipment.DUST_MASK = i18n.t("safety-equipment.dust-mask");
};

export const Requirement = {
  TORQUE: "トルク",
  GLUE: "接着剤",
  GREASE: "グリス",
  OIL: "オイル",
  SLIDING_AGENT: "滑走剤",
  ANTI_RUST: "防錆剤",
  CLASS_A: "トルクA級",
  CLASS_B: "トルクB級",
};

const updateRequirement = () => {
  Requirement.TORQUE = i18n.t("requirement.torque");
  Requirement.GLUE = i18n.t("requirement.glue");
  Requirement.GREASE = i18n.t("requirement.grease");
  Requirement.OIL = i18n.t("requirement.oil");
  Requirement.SLIDING_AGENT = i18n.t("requirement.sliding-agent");
  Requirement.ANTI_RUST = i18n.t("requirement.anti-rust");
  Requirement.CLASS_A = i18n.t("requirement.class-a");
  Requirement.CLASS_B = i18n.t("requirement.class-b");
};

export const WorkStandardHistoryDetailTabLabel = {
  TARGET: "項目",
  DETAIL: "変更箇所",
  CHARGE_PERSON: "担当者",
  HISTORY_DETAIL_TABLE_REVISE: "改訂",
  HISTORY_DETAIL_TABLE_UPDATED_AT: "変更日時",
  HISTORY_DETAIL_DIALOG_TITLE: "改訂履歴詳細",
};

const updateWorkStandardHistoryDetailTabLabel = () => {
  WorkStandardHistoryDetailTabLabel.TARGET = i18n.t("work-standard-history-detail-tab-label.target");
  WorkStandardHistoryDetailTabLabel.DETAIL = i18n.t("work-standard-history-detail-tab-label.detail");
  WorkStandardHistoryDetailTabLabel.CHARGE_PERSON = i18n.t("work-standard-history-detail-tab-label.charge-person");
  WorkStandardHistoryDetailTabLabel.HISTORY_DETAIL_TABLE_REVISE = i18n.t("work-standard-history-detail-tab-label.history-detail-table-revise");
  WorkStandardHistoryDetailTabLabel.HISTORY_DETAIL_TABLE_UPDATED_AT = i18n.t(
    "work-standard-history-detail-tab-label.history-detail-table-updated-at"
  );
  WorkStandardHistoryDetailTabLabel.HISTORY_DETAIL_DIALOG_TITLE = i18n.t("work-standard-history-detail-tab-label.history-detail-dialog-title");
};

export const WorkStandardManagementTabLabel = {
  MANAGED_NUMBER: "管理No",
  CREATOR: "作成者",
  CONFIRM: "確認",
  APPROVE: "承認",
  REVISION: "版",
  CREATED_AT: "作成日時",
  OVERVIEW: "概要",
  DETAIL: "詳細",
  APPROVER: "承認者",
  CHECKED_AT: "確認日時",
  CHECKED_PERSON: "作業確認者",
};

const updateWorkStandardManagementTabLabel = () => {
  WorkStandardManagementTabLabel.MANAGED_NUMBER = i18n.t("work-standard-management-tab-label.managed-number");
  WorkStandardManagementTabLabel.CREATOR = i18n.t("work-standard-management-tab-label.creator");
  WorkStandardManagementTabLabel.CONFIRM = i18n.t("work-standard-management-tab-label.confirm");
  WorkStandardManagementTabLabel.APPROVE = i18n.t("work-standard-management-tab-label.approve");
  WorkStandardManagementTabLabel.REVISION = i18n.t("work-standard-management-tab-label.revision");
  WorkStandardManagementTabLabel.CREATED_AT = i18n.t("work-standard-management-tab-label.created-at");
  WorkStandardManagementTabLabel.OVERVIEW = i18n.t("work-standard-management-tab-label.overview");
  WorkStandardManagementTabLabel.DETAIL = i18n.t("work-standard-management-tab-label.detail");
  WorkStandardManagementTabLabel.APPROVER = i18n.t("work-standard-management-tab-label.approver");
  WorkStandardManagementTabLabel.CHECKED_AT = i18n.t("work-standard-management-tab-label.checked-at");
  WorkStandardManagementTabLabel.CHECKED_PERSON = i18n.t("work-standard-management-tab-label.checked-person");
};

if (i18n.isInitialized) {
  updateLabel();
  updateAlwaysEnglishAreaLabel();
  updateDialogButtonLabel();
  updateUserIconLabel();
  updateAuthorityLabel();
  updateLoginLabel();
  updateWorkFlowButtonLabel();
  updateSideBarLabel();
  updateWorkFlowStatusNamesLabel();
  updateEditModeLabel();
  updateWorkStandardLabel();
  updateRevisionNumberLabel();
  updateManagementBookPageLabel();
  updateManagementBookWorkStandardListTabLabel();
  updateManagementBookProcessListTabLabel();
  updateManagementBookManagementTabLabel();
  updateCreateNewPageLabel();
  updateWorkStandardListPageLabel();
  updateDataManagementPageLabel();
  updateDataManagementAreaTabLabel();
  updateDataManagementProductionCapacityTabLabel();
  updateDataManagementType();
  updateUserListPageLabel();
  updateLoginPageLabel();
  updateInstructionsPageLabel();
  updateWorkStandardPageLabel();
  updateWorkStandardWorkTabLabel();
  updateSafetyEquipment();
  updateRequirement();
  updateWorkStandardHistoryDetailTabLabel();
  updateWorkStandardManagementTabLabel();
}

i18n.on("languageChanged", () => {
  updateLabel();
  updateAlwaysEnglishAreaLabel();
  updateDialogButtonLabel();
  updateUserIconLabel();
  updateAuthorityLabel();
  updateLoginLabel();
  updateWorkFlowButtonLabel();
  updateSideBarLabel();
  updateWorkFlowStatusNamesLabel();
  updateEditModeLabel();
  updateWorkStandardLabel();
  updateRevisionNumberLabel();
  updateManagementBookPageLabel();
  updateManagementBookWorkStandardListTabLabel();
  updateManagementBookProcessListTabLabel();
  updateManagementBookManagementTabLabel();
  updateCreateNewPageLabel();
  updateWorkStandardListPageLabel();
  updateDataManagementPageLabel();
  updateDataManagementAreaTabLabel();
  updateDataManagementProductionCapacityTabLabel();
  updateDataManagementType();
  updateUserListPageLabel();
  updateLoginPageLabel();
  updateInstructionsPageLabel();
  updateWorkStandardPageLabel();
  updateWorkStandardWorkTabLabel();
  updateSafetyEquipment();
  updateRequirement();
  updateWorkStandardHistoryDetailTabLabel();
  updateWorkStandardManagementTabLabel();
});

i18n.on("loaded", () => {
  updateLabel();
  updateAlwaysEnglishAreaLabel();
  updateDialogButtonLabel();
  updateUserIconLabel();
  updateAuthorityLabel();
  updateLoginLabel();
  updateWorkFlowButtonLabel();
  updateSideBarLabel();
  updateWorkFlowStatusNamesLabel();
  updateEditModeLabel();
  updateWorkStandardLabel();
  updateRevisionNumberLabel();
  updateManagementBookPageLabel();
  updateManagementBookWorkStandardListTabLabel();
  updateManagementBookProcessListTabLabel();
  updateManagementBookManagementTabLabel();
  updateCreateNewPageLabel();
  updateWorkStandardListPageLabel();
  updateDataManagementPageLabel();
  updateDataManagementAreaTabLabel();
  updateDataManagementProductionCapacityTabLabel();
  updateDataManagementType();
  updateUserListPageLabel();
  updateLoginPageLabel();
  updateInstructionsPageLabel();
  updateWorkStandardPageLabel();
  updateWorkStandardWorkTabLabel();
  updateSafetyEquipment();
  updateRequirement();
  updateWorkStandardHistoryDetailTabLabel();
  updateWorkStandardManagementTabLabel();
});
