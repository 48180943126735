import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { postLogout } from "../../apicaller/repository/authentication";
import { LoginInfo } from "../../context/appContextData";
import { Message } from "../../context/appContextData";
import { ErrorMessages } from "../../consts/messages";
import { putEditingOffAll as putWsEditingOffAll } from "../../apicaller/repository/workstandard";
import { putEditingOffAll as putMbEditingOffAll } from "../../apicaller/repository/managementbook";
import { LoginLabel, UserIconLabel } from "../../consts/label.ts";
import { authorityMap } from "../../consts/authority.ts";

const useStyles = makeStyles({
  card: {
    minWidth: "200px",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    fontSize: 14,
    marginTop: 6,
    fontWeight: "bold",
  },
  logout: {
    justifyContent: "flex-end",
  },
});

export default function LoginUserCard() {
  const styles = useStyles();
  const LoginContext = useContext(LoginInfo);
  const auth = LoginContext.loginUser.authority;
  const snackBarMessage = useContext(Message);

  const handleLogout = () => {
    putWsEditingOffAll()
      .then(putMbEditingOffAll)
      .then(postLogout)
      .then(() => {
        window.location.reload();
      })
      .catch((error: any) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.POST_LOGOUT, "error");
      });
  };

  return (
    <Card className={styles.card}>
      <CardContent>
        <Typography className={styles.title} color="textSecondary" gutterBottom>
          {LoginContext.loginUser.login_id}
        </Typography>
        <Typography variant="h5">{LoginContext.loginUser.user_name}</Typography>
        <Typography className={styles.pos} color="textSecondary">
          {UserIconLabel.AUTHORITY}
        </Typography>
        <Typography className={styles.title} color="textSecondary">
          {authorityMap.get(auth)}
        </Typography>
      </CardContent>
      <CardActions className={styles.logout}>
        <Button onClick={handleLogout} size="small" variant="contained" color="secondary">
          {LoginLabel.LOGOUT}
        </Button>
      </CardActions>
    </Card>
  );
}
