import React, { useContext, useEffect, useState } from "react";
import { Checkbox, FormControlLabel, FormGroup, Grid, Switch, Toolbar, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { EditModeLabel, Label, WorkFlowStatusNamesLabel } from "../../consts/label";
import {
  LineDisplayComboBox,
  ModelDisplayComboBox,
  TeamDisplayComboBox,
  UnitDisplayComboBox,
  WorkStandardListDisplayRows,
} from "./display/operationList";
import { useFactory, useLine, useModel, usePlant, useTeam, useUnit } from "../../apicaller/repository/datamanagament";
import { DialogMessages, ErrorMessages, WarningMessages } from "../../consts/messages";
import { EditInfo, LoginInfo, Message } from "../../context/appContextData";
import {
  FactoryListResponse,
  LineListResponse,
  ModelListResponse,
  PlantListResponse,
  TeamListResponse,
  UnitListResponse,
} from "../../apicaller/domain/response/datamanagement";
import { sortByAsc } from "../../function/sort";
import OperationTable from "./OperationTable";
import { useWorkStandardList } from "../../apicaller/repository/workstandardlist";
import { WorkFlowStatus } from "../../consts/workFlow";
import ComboBox from "../Common/ComboBox";
import SelectArea from "../Common/SelectArea";
import { FactoryDisplayComboBox, PlantDisplayComboBox } from "../Common/display/display";
import { useUserBelonging } from "../../apicaller/repository/authentication";
import { WorkStandardDialog } from "../WorkStandard/WorkStandardDialog";
import { SelectedConditions, SelectedOperationInfo } from "../../types/workStandard";

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    typography: {
      fontSize: 24,
      marginTop: 30,
      marginLeft: 40,
    },
    text: {
      marginLeft: 45,
      width: "40%",
      height: 50,
    },
    button: {
      fontSize: 15,
      width: 150,
    },
    toolbar: {
      paddingLeft: 5,
      flexGrow: 1,
    },
  })
);

const DEFAULT_SELECT_OBJ = { id: 0, name: "" };

const OperationList = () => {
  const styles = useStyles();
  const theme = useTheme();
  const loginContext = useContext(LoginInfo);
  const editContext = useContext(EditInfo);
  const [userBelonging, getUserBelonging, resetUserBelonging, mutateUserBelonging, userBelongingError] = useUserBelonging();
  const [factoryList, getFactoryList, resetFactoryList, mutateFactoryList, factoryError] = useFactory();
  const [plantList, getPlantList, resetPlantList, mutatePlantList, plantError] = usePlant();
  const [lineList, getLineList, resetModuleList, mutateLineList, lineError] = useLine();
  const [unitList, getUnitList, resetUnitList, mutateUnitList, unitError] = useUnit();
  const [teamList, getTeamList, resetTeamList, mutateTeamList, teamError] = useTeam();
  const [modelList, getModelList, resetModelList, mutateModelList, modelError] = useModel();
  const [workStandardList, getWorkStandardList, resetWorkStandardData, mutateWorkStandardData, WorkStandardDataError] = useWorkStandardList();
  const [selectFactoryItem, setSelectFactoryItem] = useState(DEFAULT_SELECT_OBJ);
  const [selectPlantItem, setSelectPlantItem] = useState(DEFAULT_SELECT_OBJ);
  const [selectLineItem, setSelectLineItem] = useState(DEFAULT_SELECT_OBJ);
  const [selectUnitItem, setSelectUnitItem] = useState(DEFAULT_SELECT_OBJ);
  const [selectTeamItem, setSelectTeamItem] = useState(DEFAULT_SELECT_OBJ);
  const [selectModelItem, setSelectModelItem] = useState({ ...DEFAULT_SELECT_OBJ });
  const [selectCheckBoxes, setSelectCheckBoxes] = useState({
    EDITING: true,
    WAITING_CONFIRM: true,
    WAITING_APPROVE: true,
    REJECTED: true,
    APPROVED: true,
    PUBLISHED: true,
    ARCHIVED: true,
  });
  const [workStandardDialogOpen, setWorkStandardDialogOpen] = useState<boolean>(false);
  const [selectedConditions, setSelectedConditions] = useState<SelectedConditions>({
    factory: { key: 0, value: "" },
    plant: { key: 0, value: "" },
    module: { key: 0, value: "" },
    unit: { key: 0, value: "" },
    team: { key: 0, value: "" },
    model: { key: 0, value: "" },
  });
  const [selectWorkStandardInfo, setSelectWorkStandardInfo] = useState<SelectedOperationInfo>({
    operation_id: 0,
    managed_number: 0,
    operation_name: "",
  });

  const snackBarMessage = useContext(Message);
  const [displayRows, setDisplayRows] = useState<WorkStandardListDisplayRows>(new WorkStandardListDisplayRows());

  const handleEditModeChange = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    let result: boolean = false;
    if (checked) {
      result = window.confirm(DialogMessages.EDIT_MODE_ON);
    } else {
      result = window.confirm(DialogMessages.EDIT_MODE_OFF);
    }
    if (result) {
      editContext.invert();
    }
  };

  const handleCheckBoxesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectCheckBoxes({ ...selectCheckBoxes, [event.target.name]: event.target.checked });
  };

  const factoryChangeHandler = (value: string) => {
    setSelectFactoryItem({ ...DEFAULT_SELECT_OBJ });
    setSelectPlantItem({ ...DEFAULT_SELECT_OBJ });
    setSelectLineItem({ ...DEFAULT_SELECT_OBJ });
    setSelectUnitItem({ ...DEFAULT_SELECT_OBJ });
    setSelectTeamItem({ ...DEFAULT_SELECT_OBJ });
    setSelectModelItem({ ...DEFAULT_SELECT_OBJ });

    resetWorkStandardData();
    resetPlantList();
    resetModuleList();
    resetUnitList();
    resetTeamList();
    resetModelList();

    if (value !== null) {
      const factory_id = factoryList.factory[factoryList.factory.findIndex((e: { factory_name: string }) => e.factory_name === value)].factory_id;
      setSelectFactoryItem({ ...{ id: factory_id, name: value } });
    }
  };

  // change select Plant
  const plantChangeHandler = (value: string) => {
    setSelectPlantItem({ ...DEFAULT_SELECT_OBJ });
    setSelectLineItem({ ...DEFAULT_SELECT_OBJ });
    setSelectUnitItem({ ...DEFAULT_SELECT_OBJ });
    setSelectTeamItem({ ...DEFAULT_SELECT_OBJ });
    setSelectModelItem({ ...DEFAULT_SELECT_OBJ });

    resetWorkStandardData();
    resetModuleList();
    resetUnitList();
    resetTeamList();
    resetModelList();

    if (value !== null) {
      const plant_id = plantList.plant[plantList.plant.findIndex((e: { plant_name: string }) => e.plant_name === value)].plant_id;
      setSelectPlantItem({ ...{ id: plant_id, name: value } });
    }
  };

  const moduleChangeHandler = (value: string) => {
    setSelectTeamItem({ ...DEFAULT_SELECT_OBJ });
    setSelectUnitItem({ ...DEFAULT_SELECT_OBJ });
    setSelectLineItem({ ...DEFAULT_SELECT_OBJ });

    resetWorkStandardData();
    resetUnitList();
    resetTeamList();

    if (value !== null) {
      if (!lineList) return;
      const line_id = lineList.line[lineList.line.findIndex((e: { line_name: string }) => e.line_name === value)].line_id;
      setSelectLineItem({ ...{ id: line_id, name: value } });
    }
  };
  const unitChangeHandler = (value: string) => {
    setSelectTeamItem({ ...DEFAULT_SELECT_OBJ });
    setSelectUnitItem({ ...DEFAULT_SELECT_OBJ });

    resetWorkStandardData();
    resetTeamList();

    if (value !== null) {
      const unit_id = unitList.unit[unitList.unit.findIndex((e: { unit_name: string }) => e.unit_name === value)].unit_id;
      setSelectUnitItem({ ...{ id: unit_id, name: value } });
    }
    return;
  };
  const teamChangeHandler = (value: string) => {
    setSelectTeamItem({ ...DEFAULT_SELECT_OBJ });
    resetWorkStandardData();
    if (value !== null) {
      const team_id = teamList.team[teamList.team.findIndex((e: { team_name: string }) => e.team_name === value)].team_id;
      setSelectTeamItem({ ...{ id: team_id, name: value } });
    }
    return;
  };
  const selectedModelOnchangeHandler = (value: string) => {
    setSelectModelItem({ ...DEFAULT_SELECT_OBJ });
    resetWorkStandardData();
    if (value !== null) {
      const model_id = modelList.model[modelList.model.findIndex((e: { model_name: string }) => e.model_name === value)].model_id;
      setSelectModelItem({ id: model_id, name: value });
    }
    return;
  };

  //作業標準リスト取得処理
  const handleGetOperationList = () => {
    //displayrowsにセットする処理を必ず呼ぶためにここでリポジトリのデータを一旦リセットする
    resetWorkStandardData();

    getWorkStandardList(String(selectTeamItem.id), String(selectModelItem.id));
  };

  // 作業標準名編集完了時のmutate
  const mutateOperationList = () => {
    mutateWorkStandardData();
  };

  //条件選択できてない場合,描画をリセットする
  const resetDisplay = () => {
    setDisplayRows(new WorkStandardListDisplayRows());
    //再度条件選択したタイミングで再取得するために作業標準データもリセットする
    mutateWorkStandardData();
  };

  const handleOpenWorkStandard = (conditions: SelectedConditions, info: SelectedOperationInfo) => {
    setSelectedConditions(conditions);
    setSelectWorkStandardInfo(info);
    setWorkStandardDialogOpen(true);
  };

  const handleCloseWorkStandard = () => {
    mutateOperationList();
    setWorkStandardDialogOpen(false);
  };

  useEffect(() => {
    getUserBelonging();
    getFactoryList();
  }, []);

  // 拠点の初期選択項目を設定
  useEffect(() => {
    if (!factoryList) return;
    if (!userBelonging) return;
    if (!userBelonging.factory_id) return;
    if (userBelonging.factory_id === 0) return;
    factoryList.factory.map((row) => {
      if (row.factory_id === userBelonging.factory_id) {
        setSelectFactoryItem({ id: row.factory_id, name: row.factory_name });
      }
    });
  }, [userBelonging, factoryList]);

  // 拠点の選択項目が変更されたときに工場リストを取得
  useEffect(() => {
    if (!selectFactoryItem) return;
    if (!selectFactoryItem.id) return;
    if (selectFactoryItem.id === 0) return;
    getPlantList(String(selectFactoryItem.id));
  }, [selectFactoryItem]);

  // 工場の初期選択項目を設定
  useEffect(() => {
    if (!plantList) return;
    if (!plantList.plant) return;
    if (plantList.plant.length === 0) return;
    plantList.plant.map((row) => {
      if (row.plant_id === userBelonging.plant_id) {
        setSelectPlantItem({ id: row.plant_id, name: row.plant_name });
      }
    });
  }, [plantList]);

  // 工場の選択項目が変更されたときにモジュールリストを取得
  useEffect(() => {
    if (!selectPlantItem) return;
    if (!selectPlantItem.id) return;
    if (selectPlantItem.id === 0) return;
    getLineList(selectPlantItem.id);
    getModelList(String(selectPlantItem.id));
  }, [selectPlantItem]);

  // モジュールの初期選択項目を設定
  useEffect(() => {
    if (!lineList) return;
    if (!lineList.line) return;
    if (lineList.line.length === 0) return;
    lineList.line.map((row) => {
      if (row.line_id === userBelonging.module_id) {
        setSelectLineItem({ id: row.line_id, name: row.line_name });
      }
    });
  }, [lineList]);

  // モジュールの選択項目が変更されたときにユニットリストを取得
  useEffect(() => {
    if (!selectLineItem) return;
    if (!selectLineItem.id) return;
    if (selectLineItem.id === 0) return;
    getUnitList(String(selectLineItem.id));
  }, [selectLineItem]);

  // ユニットの初期選択項目を設定
  useEffect(() => {
    if (!unitList) return;
    if (!unitList.unit) return;
    if (unitList.unit.length === 0) return;
    unitList.unit.map((row) => {
      if (row.unit_id === userBelonging.unit_id) {
        setSelectUnitItem({ id: row.unit_id, name: row.unit_name });
      }
    });
  }, [unitList]);

  // ユニットの選択項目が変更されたときにチームリストを取得
  useEffect(() => {
    if (!selectUnitItem) return;
    if (!selectUnitItem.id) return;
    if (selectUnitItem.id === 0) return;
    getTeamList(String(selectUnitItem.id));
  }, [selectUnitItem]);

  // チームの初期選択項目を設定
  useEffect(() => {
    if (!teamList) return;
    if (!teamList.team) return;
    if (teamList.team.length === 0) return;
    teamList.team.map((row) => {
      if (row.team_id === userBelonging.team_id) {
        setSelectTeamItem({ id: row.team_id, name: row.team_name });
      }
    });
  }, [teamList]);

  useEffect(() => {
    if (!workStandardList) return;
    if (!workStandardList.operation_list) return;
    if (workStandardList.operation_list.length === 0) {
      snackBarMessage.setMessage(WarningMessages.NO_OPERATION_LIST, "warning");
      setDisplayRows(new WorkStandardListDisplayRows());
      return;
    }
    setDisplayRows(
      new WorkStandardListDisplayRows().setWorkStandardData(
        workStandardList,
        selectFactoryItem,
        selectPlantItem,
        selectLineItem,
        selectUnitItem,
        selectTeamItem,
        selectModelItem,
        selectCheckBoxes
      )
    );
  }, [selectCheckBoxes]);

  // チーム、機種が選択されたタイミングで自動取得する
  useEffect(() => {
    if (selectTeamItem.name === "" || selectModelItem.name === "") {
      //条件選択できてない場合,描画をリセットする
      resetDisplay();
      return;
    }

    // チームも機種も選択されている場合のみ取得
    handleGetOperationList();
  }, [selectTeamItem, selectModelItem]);

  useEffect(() => {
    if (!workStandardList) return;
    if (!workStandardList.operation_list) return;
    if (workStandardList.operation_list.length === 0) {
      snackBarMessage.setMessage(WarningMessages.NO_OPERATION_LIST, "warning");
      setDisplayRows(new WorkStandardListDisplayRows());
      return;
    }
    setDisplayRows(
      new WorkStandardListDisplayRows().setWorkStandardData(
        workStandardList,
        selectFactoryItem,
        selectPlantItem,
        selectLineItem,
        selectUnitItem,
        selectTeamItem,
        selectModelItem,
        selectCheckBoxes
      )
    );
  }, [workStandardList]);

  // SWR error
  useEffect(() => {
    if (userBelongingError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_USER_BELOGING, "error");
    }
    if (factoryError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_FACTORY, "error");
    }
    if (plantError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_PLANT, "error");
    }
    if (lineError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_MODULE, "error");
    }
    if (unitError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_UNIT, "error");
    }
    if (teamError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_TEAM, "error");
    }
    if (modelError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_MODEL, "error");
    }
    if (WorkStandardDataError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_WORK_STANDARD_LIST, "error");
    }
  }, [userBelongingError, factoryError, plantError, lineError, unitError, teamError, modelError, WorkStandardDataError]);

  // ユーザーカード変更時の画面最新化
  useEffect(() => {
    mutateWorkStandardData();
  }, [loginContext.loginUser]);

  // sort for display factory combobox
  let factoryDisplayComboBox = new FactoryDisplayComboBox();
  if (factoryList) {
    if (factoryList.factory !== undefined) {
      let sorted = new FactoryListResponse();
      sorted.factory = sortByAsc(factoryList.factory, "factory_id");
      if (sorted.factory !== undefined) factoryDisplayComboBox.setData(sorted);
    }
  }

  // sort for display plant combobox
  let plantDisplayComboBox = new PlantDisplayComboBox();
  if (plantList) {
    if (plantList.plant !== undefined) {
      let sorted = new PlantListResponse();
      sorted.plant = sortByAsc(plantList.plant, "plant_id");
      if (sorted.plant !== undefined) plantDisplayComboBox.setData(sorted);
    }
  }

  // sort for display line combobox
  let lineDisplayComboBox = new LineDisplayComboBox();
  if (lineList) {
    if (lineList.line !== undefined) {
      let sorted = new LineListResponse();
      sorted.line = sortByAsc(lineList.line, "line_id");
      if (sorted.line !== undefined) lineDisplayComboBox.setData(sorted);
    }
  }

  // sort for display unit combobox
  let unitDisplayComboBox = new UnitDisplayComboBox();
  if (unitList) {
    if (unitList.unit !== undefined) {
      let sorted = new UnitListResponse();
      sorted.unit = sortByAsc(unitList.unit, "unit_id");
      if (sorted.unit !== undefined) unitDisplayComboBox.setData(sorted);
    }
  }

  // sort for display team combobox
  let teamDisplayComboBox = new TeamDisplayComboBox();
  if (teamList) {
    if (teamList.team !== undefined) {
      let sorted = new TeamListResponse();
      sorted.team = sortByAsc(teamList.team, "team_id");
      if (sorted.team !== undefined) teamDisplayComboBox.setData(sorted);
    }
  }

  // sort for display model combobox
  let modelDisplayComboBox = new ModelDisplayComboBox();
  if (modelList) {
    if (modelList.model !== undefined) {
      let sorted = new ModelListResponse();
      sorted.model = sortByAsc(modelList.model, "model_id");
      if (sorted.model !== undefined) modelDisplayComboBox.setData(sorted);
    }
  }

  return (
    <>
      <WorkStandardDialog
        open={workStandardDialogOpen}
        selectedConditions={selectedConditions}
        selectedWorkStandardInfo={selectWorkStandardInfo}
        onClose={handleCloseWorkStandard}
      />
      <Grid container spacing={1} style={{ padding: theme.spacing(0, 1) }}>
        <Grid item xs={12}>
          <SelectArea
            factoryDisplayComboBox={factoryDisplayComboBox}
            plantDisplayComboBox={plantDisplayComboBox}
            lineDisplayComboBox={lineDisplayComboBox}
            unitDisplayComboBox={unitDisplayComboBox}
            teamDisplayComboBox={teamDisplayComboBox}
            selectedFactoryItem={selectFactoryItem.name}
            selectedPlantItem={selectPlantItem.name}
            selectedLineItem={selectLineItem.name}
            selectedUnitItem={selectUnitItem.name}
            selectedTeamItem={selectTeamItem.name}
            handleSelectFactory={factoryChangeHandler}
            handleSelectPlant={plantChangeHandler}
            handleSelectModule={moduleChangeHandler}
            handleSelectUnit={unitChangeHandler}
            handleSelectTeam={teamChangeHandler}
          />
        </Grid>
        <Grid item xs={2}>
          <ComboBox
            disable={!modelDisplayComboBox.text.length}
            label={Label.MODEL}
            options={modelDisplayComboBox.text}
            onChange={(value: any) => selectedModelOnchangeHandler(value)}
            value={selectModelItem.name}
          />
        </Grid>
        <Grid item xs={10} />
        <Grid item xs={11}>
          <Toolbar className={styles.toolbar}>
            <FormGroup row>
              <FormControlLabel
                control={<Checkbox checked={selectCheckBoxes.EDITING} onChange={handleCheckBoxesChange} name={WorkFlowStatus.EDITING} />}
                label={WorkFlowStatusNamesLabel.EDITING}
              />
              <FormControlLabel
                control={
                  <Checkbox checked={selectCheckBoxes.WAITING_CONFIRM} onChange={handleCheckBoxesChange} name={WorkFlowStatus.WAITING_CONFIRM} />
                }
                label={WorkFlowStatusNamesLabel.WAITING_CONFIRM}
              />
              <FormControlLabel
                control={
                  <Checkbox checked={selectCheckBoxes.WAITING_APPROVE} onChange={handleCheckBoxesChange} name={WorkFlowStatus.WAITING_APPROVE} />
                }
                label={WorkFlowStatusNamesLabel.WAITING_APPROVE}
              />
              <FormControlLabel
                control={<Checkbox checked={selectCheckBoxes.REJECTED} onChange={handleCheckBoxesChange} name={WorkFlowStatus.REJECTED} />}
                label={WorkFlowStatusNamesLabel.REJECTED}
              />
              <FormControlLabel
                control={<Checkbox checked={selectCheckBoxes.APPROVED} onChange={handleCheckBoxesChange} name={WorkFlowStatus.APPROVED} />}
                label={WorkFlowStatusNamesLabel.APPROVED}
              />
              <FormControlLabel
                control={<Checkbox checked={selectCheckBoxes.PUBLISHED} onChange={handleCheckBoxesChange} name={WorkFlowStatus.PUBLISHED} />}
                label={WorkFlowStatusNamesLabel.PUBLISHED}
              />
              <FormControlLabel
                control={<Checkbox checked={selectCheckBoxes.ARCHIVED} onChange={handleCheckBoxesChange} name={WorkFlowStatus.ARCHIVED} />}
                label={WorkFlowStatusNamesLabel.ARCHIVED}
              />
            </FormGroup>
          </Toolbar>
        </Grid>
        <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
          <Typography className={"edit-label"}>{EditModeLabel.EDIT}</Typography>
          <Switch checked={editContext.editMode} onChange={handleEditModeChange} />
        </Grid>
        <Grid item xs={12}>
          <OperationTable
            displayRows={displayRows}
            mutateOperationList={mutateOperationList}
            showOptionColumns={editContext.editMode}
            handleOpenWorkStandard={handleOpenWorkStandard}
            module={selectLineItem}
            teamId={selectTeamItem.id}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default OperationList;
