import React, { useContext } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import { WorkStandard } from "./WorkStandard";
import { SelectedConditions, SelectedOperationInfo } from "../../types/workStandard";
import { putEditingOff } from "../../apicaller/repository/workstandard";
import { EditInfo } from "../../context/appContextData";
import { Typography } from "@material-ui/core";
import { WorkStandardPageLabel } from "../../consts/label";

const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      width: "100VW",
      height: "100VH",
    },
    appBar: {
      position: "relative",
    },
  })
);

const Transition = React.forwardRef(function Transition(props: TransitionProps & { children?: React.ReactElement }, ref: React.Ref<unknown>) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface Props {
  open: boolean;
  selectedConditions: SelectedConditions;
  selectedWorkStandardInfo: SelectedOperationInfo;
  onClose: () => void;
}

export const WorkStandardDialog = (props: Props) => {
  const styles = useStyles();

  const EditContext = useContext(EditInfo);

  const handleCloseDialog = () => {
    if (EditContext.editMode) {
      EditContext.invert();
    }
    putEditingOff({ work_standard_id: props.selectedWorkStandardInfo.operation_id });
    props.onClose();
  };

  return (
    <Dialog fullScreen open={props.open} className={styles.dialog} onClose={handleCloseDialog} TransitionComponent={Transition}>
      <AppBar className={styles.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography>{WorkStandardPageLabel.WORK_STANDARD_CLOSE}</Typography>
        </Toolbar>
      </AppBar>
      <div style={{ height: "16px" }} />
      <div style={{ height: "99VH", width: "99VW" }}>
        <WorkStandard showSideBar={false} selectedConditions={props.selectedConditions} selectedWorkStandardInfo={props.selectedWorkStandardInfo} />
      </div>
    </Dialog>
  );
};
