import { Grid, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import Login from "../components/Login/Login";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import createdbyTW_trimmed from "../icons/createdbyTW_trimmed.png";
import Logout from "../components/Login/Logout";
import { LoginInfo } from "../context/appContextData";
import { DeployTarget, DeployTargetWarningMessage } from "../consts/deployTarget";
import { LoginPageLabel } from "../consts/label";

const deployTarget = process.env.REACT_APP_DEPLOY_TARGET;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridcontainer: {
      // AppBar表示分の余白
      marginTop: 120,
    },
    warningMessage: {
      color: "red",
      fontSize: 48,
      textAlign: "center",
    },
  })
);

export const LoginPage = () => {
  const styles = useStyles();
  const LoginContext = useContext(LoginInfo);

  const handleWarningMessage = () => {
    if (deployTarget === DeployTarget.LOCAL) {
      return DeployTargetWarningMessage.LOCAL;
    } else if (deployTarget === DeployTarget.IAC_DEV) {
      return DeployTargetWarningMessage.IAC_DEV;
    } else if (deployTarget === DeployTarget.IAC_PRD) {
      return DeployTargetWarningMessage.IAC_PRD;
    } else if (deployTarget === DeployTarget.FLEX_PRD) {
      return DeployTargetWarningMessage.FLEX_PRD;
    }
    return "";
  };

  return (
    <div>
      <Grid container className={styles.gridcontainer} alignItems="center">
        <Grid item xs={7}>
          <img src={createdbyTW_trimmed} width="80%" alt={createdbyTW_trimmed} style={{ marginLeft: "10%" }} />
        </Grid>
        <Grid item xs={5}>
          <Typography className={styles.warningMessage}>{handleWarningMessage()}</Typography>
          {LoginContext.loginUser ? <Logout /> : <Login />}
          <Typography style={{ marginTop: "30px", textAlign: "center", whiteSpace: "pre-wrap" }}>
            {LoginPageLabel.CONTACT_INFORMATION + LoginPageLabel.CONTACT_INFORMATION_MAIL}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default LoginPage;
