import React, { useContext, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Tooltip, IconButton } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CreateIcon from "@material-ui/icons/Create";
import { deleteParts, postParts, putParts, useParts } from "../../apicaller/repository/operation";
import { EditInfo, Message } from "../../context/appContextData";
import { Parts, PartsTableInfo } from "../Home/display/home";
import PartsTableAddDialog from "../Home/PartsTableAddDialog";
import PartsTableEditDialog from "../Home/PartsTableEditDialog";
import { PartsRegistRequest, PartsUpdateRequest } from "../../apicaller/domain/request/operation";
import { SuccessMessages, ErrorMessages, WarningMessages } from "../../consts/messages";
import { PartsList } from "../../apicaller/domain/response/operation";
import { sortByAsc } from "../../function/sort";
import { WorkStandardWorkTabLabel } from "../../consts/label";

interface Props {
  operationId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      overflow: "auto",
      textAlign: "center",
      height: "100%",
    },
    headerNoCell: {
      width: "20%",
      whiteSpace: "nowrap",
    },
    headerPartsNameCell: {
      width: "40%",
      whiteSpace: "nowrap",
    },
    headerPartsCountCell: {
      width: "30%",
      whiteSpace: "nowrap",
    },
    headerIcon: {
      width: "10%",
      whiteSpace: "nowrap",
    },
    bodyNoCell: {
      width: "20%",
      whiteSpace: "nowrap",
    },
    bodyPartsNameCell: {
      width: "40%",
      whiteSpace: "nowrap",
    },
    bodyPartsCountCell: {
      width: "30%",
      whiteSpace: "nowrap",
    },
    bodyicon: {
      width: "10%",
    },
  })
);

export default function PartsTable(props: Props) {
  const classes = useStyles();
  const EditContext = useContext(EditInfo);
  const snackBarMessage = useContext(Message);
  const [partsList, getPartsList, mutatePartsList, partsListError] = useParts();
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [dialogDisplayData, setDialogDisplayData] = useState<Parts>({
    no: "",
    name: "",
    count: "",
  });

  useEffect(() => {
    getPartsList(props.operationId);
  }, [props.operationId]);

  useEffect(() => {
    if (partsListError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_PARTS, "error");
    }
  }, [partsListError]);

  // open add dialog
  const handleClickAdd = () => {
    //現在のParts Tableの表示件数をカウント
    //dialogDisplayData.no;
    dialogDisplayData.name = "";
    dialogDisplayData.count = "";
    setDialogDisplayData({ ...dialogDisplayData });
    setIsAddDialogOpen(true);
  };

  // open edit dialog
  const handleClickEdit = (parts: Parts) => {
    dialogDisplayData.no = parts.no;
    dialogDisplayData.name = parts.name;
    dialogDisplayData.count = parts.count;
    setDialogDisplayData({ ...dialogDisplayData });
    setIsEditDialogOpen(true);
  };

  // change parts name (dialog action)
  const handleDialogChangeName = (name: string) => {
    dialogDisplayData.name = name;
    setDialogDisplayData({ ...dialogDisplayData });
  };

  // change parts count (dialog action)
  const handleDialogChangeCount = (count: string) => {
    dialogDisplayData.count = count;
    setDialogDisplayData({ ...dialogDisplayData });
  };

  // add parts (dialog action)
  const handleDialogAdd = () => {
    const reqData = new PartsRegistRequest(Number(props.operationId), dialogDisplayData.name, Number(dialogDisplayData.count));

    if (reqData.name === "") {
      snackBarMessage.setMessage(WarningMessages.INSERT_PARTS_EMPTY_NAME, "warning");
      return;
    }

    if (reqData.count <= 0) {
      snackBarMessage.setMessage(WarningMessages.INSERT_PARTS_EMPTY_COUNT, "warning");
      return;
    }

    if (partsList.parts.find((parts) => parts.name === reqData.name)) {
      snackBarMessage.setMessage(WarningMessages.INSERT_PARTS_SAME_NAME, "warning");
      return;
    }

    postParts(reqData).then(
      (data) => {
        // get latest data & close add dialog
        mutatePartsList();
        setIsAddDialogOpen(false);
        snackBarMessage.setMessage(SuccessMessages.INSERT_PARTS, "success");
      },
      (error) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.INSERT_PARTS, "error");
      }
    );
  };

  // update parts (dialog action)
  const handleDialogUpdate = () => {
    const reqData = new PartsUpdateRequest(Number(dialogDisplayData.no), dialogDisplayData.name, Number(dialogDisplayData.count));

    if (reqData.name === "") {
      snackBarMessage.setMessage(WarningMessages.INSERT_PARTS_EMPTY_NAME, "warning");
      return;
    }

    if (reqData.count <= 0) {
      snackBarMessage.setMessage(WarningMessages.INSERT_PARTS_EMPTY_COUNT, "warning");
      return;
    }

    const exceptTargetPartsList = partsList.parts.filter((parts) => parts.parts_id !== reqData.parts_id);
    if (exceptTargetPartsList.find((parts) => parts.name === reqData.name)) {
      snackBarMessage.setMessage(WarningMessages.INSERT_PARTS_SAME_NAME, "warning");
      return;
    }

    putParts(reqData).then(
      (data) => {
        // get latest data & close edit dialog
        mutatePartsList();
        setIsEditDialogOpen(false);
        snackBarMessage.setMessage(SuccessMessages.UPDATE_PARTS, "success");
      },
      (error) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.UPDATE_PARTS, "error");
      }
    );
  };

  // delete parts (dialog action)
  const handleDialogDelete = () => {
    deleteParts(dialogDisplayData.no).then(
      (data) => {
        // get latest data & close edit dialog
        mutatePartsList();
        setIsEditDialogOpen(false);
        snackBarMessage.setMessage(SuccessMessages.DELETE_PARTS, "success");
      },
      (error) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.DELETE_PARTS, "error");
      }
    );
  };

  // add cancel parts (dialog action)
  const handleDialogAddCancel = () => {
    setIsAddDialogOpen(false);
  };

  // edit cancel parts (dialog action)
  const handleDialogEditCancel = () => {
    setIsEditDialogOpen(false);
  };

  // change in SWR data(sort for display parts)
  let partsDisplay = new PartsTableInfo();
  if (partsList) {
    if (partsList.parts !== undefined) {
      let sorted = new PartsList();
      sorted.parts = sortByAsc(partsList.parts, "parts_id");
      if (sorted.parts !== undefined) partsDisplay.setData(sorted);
    }
  }

  return (
    <>
      {/* add dialog */}
      <PartsTableAddDialog
        open={isAddDialogOpen}
        partsDispData={dialogDisplayData}
        handleChangeName={handleDialogChangeName}
        handleChangeCount={handleDialogChangeCount}
        handleAdd={handleDialogAdd}
        handleCancel={handleDialogAddCancel}
      />

      {/* edit dialog */}
      <PartsTableEditDialog
        open={isEditDialogOpen}
        partsDispData={dialogDisplayData}
        handleChangeName={handleDialogChangeName}
        handleChangeCount={handleDialogChangeCount}
        handleUpdate={handleDialogUpdate}
        handleDelete={handleDialogDelete}
        handleCancel={handleDialogEditCancel}
      />

      <TableContainer className={classes.container}>
        <Table size="small" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="right" className={classes.headerNoCell}>
                {WorkStandardWorkTabLabel.PARTS_TABLE_NUMBER}
              </TableCell>
              <TableCell align="left" className={classes.headerPartsNameCell}>
                {WorkStandardWorkTabLabel.PARTS_NAME}
              </TableCell>
              <TableCell align="right" className={classes.headerPartsCountCell}>
                {WorkStandardWorkTabLabel.PARTS_COUNT}
              </TableCell>
              {EditContext.editMode && (
                <TableCell align="center" className={classes.headerIcon}>
                  <IconButton size="small" onClick={() => handleClickAdd()}>
                    <AddCircleIcon />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {partsDisplay.parts.map((partsData, index) => {
              return (
                <TableRow key={index}>
                  <TableCell align="right" className={classes.bodyNoCell}>
                    {index + 1 + " / " + partsDisplay.parts.length}
                  </TableCell>
                  <TableCell align="left" style={{ whiteSpace: "nowrap" }} className={classes.bodyPartsNameCell}>
                    <Tooltip placement={"bottom-start"} title={partsData.name}>
                      <div>{partsData.name}</div>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="right" className={classes.bodyPartsCountCell}>
                    {partsData.count}
                  </TableCell>
                  {EditContext.editMode && (
                    <TableCell align="center" className={classes.bodyicon}>
                      <IconButton size="small" onClick={() => handleClickEdit(partsData)}>
                        <CreateIcon />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
