import { useState } from "react";
import useSWR from "swr";
import { get, post, put } from "../apiCaller";
import { ApiMethod, ApiName, createEndPoint } from "../createEndPoint";
import { Authentification, UserAttachMasterUpdateRequest, UserAttachUpdateRequest, UserLogin, UserSignUp } from "../domain/request/authentication";
import { LoginUser, UserBelonging } from "../domain/response/authentication";

//Login
export const postLogin = (body: UserLogin): Promise<any> => {
  const bodyJson = JSON.stringify(body);
  return post(createEndPoint({ name: ApiName.Login }), bodyJson);
};

//Logout
export const postLogout = (): Promise<any> => {
  return post(createEndPoint({ name: ApiName.Logout }), "");
};

//SignUp
export const postSignUp = (body: UserSignUp): Promise<any> => {
  const bodyJson = JSON.stringify(body);
  return post(createEndPoint({ name: ApiName.SignUp }), bodyJson);
};

//GetLoginUser
export const useLoginUser = (): [LoginUser, () => Promise<any>, boolean, string] => {
  const endPoint = createEndPoint({ name: ApiName.User });
  const { data, mutate, error } = useSWR(endPoint, get, {
    shouldRetryOnError: false,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const isLoading = !error && !data;
  const getData = () => mutate(endPoint);
  return [data, getData, isLoading, error];
};

export const putUserAuthority = (body: Authentification): Promise<any> => {
  const bodyJson = JSON.stringify(body);
  return put(createEndPoint({ name: ApiName.UserAuthority, method: ApiMethod.Put }), bodyJson);
};

export const putUserAttach = (body: UserAttachUpdateRequest): Promise<any> => {
  const bodyJson = JSON.stringify(body);
  return put(createEndPoint({ name: ApiName.UserAttach, method: ApiMethod.Put }), bodyJson);
};

export const putUserAttachMaster = (body: UserAttachMasterUpdateRequest): Promise<any> => {
  const bodyJson = JSON.stringify(body);
  return put(createEndPoint({ name: ApiName.UserAttachMaster, method: ApiMethod.Put }), bodyJson);
};

//Get UserBelonging
export const useUserBelonging = (): [UserBelonging, () => void, () => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get);
  const getData = () => {
    setEndPoint(createEndPoint({ name: ApiName.UserBelonging }));
  };
  const resetData = () => {
    setEndPoint(null);
  };
  const mutateData = () => {
    mutate(endPoint);
  };
  return [data, getData, resetData, mutateData, error];
};
