import React, { useContext, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { EditInfo, Message } from "../../context/appContextData";
import { deleteOperationEquipment, postEquipment, useEquipment } from "../../apicaller/repository/operation";
import { OperationEquipmentRegistRequest } from "../../apicaller/domain/request/operation";
import { ErrorMessages, SuccessMessages } from "../../consts/messages";
import CompSafetyEquipmentChip from "./CompSafetyEquipmentChip";
import DialogEquipmentBoard from "./DialogEquipmentBoard";
import weldingMask from "../../icons/equipments/weldingMask.png";
import helmet from "../../icons/equipments/helmet.png";
import glasses from "../../icons/equipments/glasses.png";
import gloves from "../../icons/equipments/gloves.png";
import boot from "../../icons/equipments/boot.png";
import armCover from "../../icons/equipments/armCover.png";
import earplug from "../../icons/equipments/earplug.png";
import overall from "../../icons/equipments/overall.png";
import mask from "../../icons/equipments/mask.png";
import { SafetyEquipment as SafetyEquipmentLabel } from "../../consts/label";
import { SafetyEquipment } from "../../apicaller/domain/response/operation";

interface Props {
  operationId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outterDiv: {
      height: "100%",
      overflowY: "auto",
      overflowX: "hidden",
      scrollSnapType: "y mandatory",
    },
    innerDiv: {
      flexGrow: 1,
      padding: "0 10px 0 10px",
      display: "flex",
      flexdirection: "row",
      flexWrap: "wrap",
    },
  })
);

const CompSafetyEquipmentChipGroup = (props: Props) => {
  const styles = useStyles();
  const EditContext = useContext(EditInfo);
  const [equipmentList, getEquipmentList, mutateEquipmentList, equipmentError] = useEquipment();
  const snackBarMessage = useContext(Message);
  const equipmentItems = [
    { avatarSourceId: 7, name: SafetyEquipmentLabel.FACE_COVER, image: weldingMask },
    { avatarSourceId: 8, name: SafetyEquipmentLabel.WELDING_MASK, image: weldingMask },
    { avatarSourceId: 1, name: SafetyEquipmentLabel.HELMET, image: helmet },
    { avatarSourceId: 9, name: SafetyEquipmentLabel.ANTI_STATIC_CAP, image: helmet },
    { avatarSourceId: 10, name: SafetyEquipmentLabel.CAP, image: helmet },
    { avatarSourceId: 11, name: SafetyEquipmentLabel.DUST_PROOF_HAT, image: helmet },
    { avatarSourceId: 2, name: SafetyEquipmentLabel.GLASSES, image: glasses },
    { avatarSourceId: 12, name: SafetyEquipmentLabel.WELDING_GLASSES, image: glasses },
    { avatarSourceId: 13, name: SafetyEquipmentLabel.SOLVENT_RESISTANT_GLASSES, image: glasses },
    { avatarSourceId: 14, name: SafetyEquipmentLabel.CHEMICAL_RESISTANT_GLASSES, image: glasses },
    { avatarSourceId: 15, name: SafetyEquipmentLabel.SOLVENT_RESISTANT_GOGGLES, image: glasses },
    { avatarSourceId: 16, name: SafetyEquipmentLabel.CHEMICAL_RESISTANT_GOGGLES, image: glasses },
    { avatarSourceId: 3, name: SafetyEquipmentLabel.GLOVES, image: gloves },
    { avatarSourceId: 17, name: SafetyEquipmentLabel.GAUNTLETS, image: gloves },
    { avatarSourceId: 18, name: SafetyEquipmentLabel.SIMON_GLOVES, image: gloves },
    { avatarSourceId: 19, name: SafetyEquipmentLabel.COTTON_GLOVES, image: gloves },
    { avatarSourceId: 20, name: SafetyEquipmentLabel.MEXCEL_GLOVES, image: gloves },
    { avatarSourceId: 21, name: SafetyEquipmentLabel.NYLON_GLOVES, image: gloves },
    { avatarSourceId: 22, name: SafetyEquipmentLabel.LEATHER_GLOVES, image: gloves },
    { avatarSourceId: 23, name: SafetyEquipmentLabel.ANTI_STATIC_GLOVES, image: gloves },
    { avatarSourceId: 24, name: SafetyEquipmentLabel.SOLVENT_RESISTANT_GLOVES, image: gloves },
    { avatarSourceId: 25, name: SafetyEquipmentLabel.CHEMICAL_RESISTANT_GLOVES, image: gloves },
    { avatarSourceId: 26, name: SafetyEquipmentLabel.OIL_RESISTANT_GLOVES, image: gloves },
    { avatarSourceId: 4, name: SafetyEquipmentLabel.SAFETY_SHOES, image: boot },
    { avatarSourceId: 27, name: SafetyEquipmentLabel.LEG_GUARDS, image: boot },
    { avatarSourceId: 28, name: SafetyEquipmentLabel.LEG_GAUNTLETS, image: boot },
    { avatarSourceId: 29, name: SafetyEquipmentLabel.ANTI_STATIC_SAFETY_SHOES, image: boot },
    { avatarSourceId: 30, name: SafetyEquipmentLabel.SAFETY_HALF_BOOTS, image: boot },
    { avatarSourceId: 31, name: SafetyEquipmentLabel.ANTI_STATIC_SAFETY_HALF_BOOTS, image: boot },
    { avatarSourceId: 32, name: SafetyEquipmentLabel.SAFETY_BOOTS, image: boot },
    { avatarSourceId: 33, name: SafetyEquipmentLabel.LEG_PROTECTOR, image: boot },
    { avatarSourceId: 5, name: SafetyEquipmentLabel.ARM_COVER, image: armCover },
    { avatarSourceId: 34, name: SafetyEquipmentLabel.MEXCEL_ARM_COVER, image: armCover },
    { avatarSourceId: 6, name: SafetyEquipmentLabel.EARPLUG, image: earplug },
    { avatarSourceId: 35, name: SafetyEquipmentLabel.EARMUFFS, image: earplug },
    { avatarSourceId: 36, name: SafetyEquipmentLabel.APRON, image: overall },
    { avatarSourceId: 37, name: SafetyEquipmentLabel.DUST_PROOF_CLOTHING, image: overall },
    { avatarSourceId: 38, name: SafetyEquipmentLabel.SAFETY_BELT, image: overall },
    { avatarSourceId: 39, name: SafetyEquipmentLabel.SOLVENT_RESISTANT_APRON, image: overall },
    { avatarSourceId: 40, name: SafetyEquipmentLabel.CHEMICAL_RESISTANT_APRON, image: overall },
    { avatarSourceId: 41, name: SafetyEquipmentLabel.MASK, image: mask },
    { avatarSourceId: 42, name: SafetyEquipmentLabel.GAS_MASK, image: mask },
    { avatarSourceId: 43, name: SafetyEquipmentLabel.DUST_MASK, image: mask },
  ];
  const [dialogEquipmentList, setDialogEquipmentList] = useState(equipmentItems);

  // add chip
  const handleChipAdd = (equipment_id: number) => {
    const reqData = new OperationEquipmentRegistRequest(Number(props.operationId), equipment_id);
    postEquipment(reqData).then(
      (data) => {
        mutateEquipmentList();
        snackBarMessage.setMessage(SuccessMessages.INSERT_SAFETY_EQUIPMENT, "success");
      },
      (error) => {
        snackBarMessage.setMessage(ErrorMessages.INSERT_SAFETY_EQUIPMENT, "error");
      }
    );
  };

  // delete chip
  const handleChipDelete = (operationPointId: number) => {
    deleteOperationEquipment(String(operationPointId)).then(
      (data) => {
        mutateEquipmentList();
        snackBarMessage.setMessage(SuccessMessages.DELETE_SAFETY_EQUIPMENT, "success");
      },
      (error) => {
        snackBarMessage.setMessage(ErrorMessages.DELETE_SAFETY_EQUIPMENT, "error");
      }
    );
  };

  const setDialogData = () => {
    const list = setSafetyEquipmentList();

    //登録されていない安全保護具のリストの作成
    let tempList = list;
    for (let i = 0; i < equipmentItems.length; i = i + 1) {
      let exist = tempList.find((e) => e.safety_equipment_id == equipmentItems[i].avatarSourceId) ?? 0;
      if (exist !== 0) {
        equipmentItems.splice(i, 1);
        i = i - 1;
      }
    }
    setDialogEquipmentList(equipmentItems);
  };

  useEffect(() => {
    if (!equipmentList) return;
    if (!equipmentList.safety_equipment) return;

    setSafetyEquipmentList();
  }, [equipmentList]);

  useEffect(() => {
    getEquipmentList(props.operationId);
  }, [props.operationId]);

  const setSafetyEquipmentList = () => {
    if (!equipmentList) return [];

    const list: SafetyEquipment[] = [];
    // 登録されている安全保護具をリスト化
    if (!equipmentList.safety_equipment) return [];
    equipmentList.safety_equipment.map((e) => {
      list.push(e);
    });

    return list;
  };

  const compFunc = (a: SafetyEquipment, b: SafetyEquipment) => {
    const sourceId = equipmentItems.map((e) => {
      return e.avatarSourceId;
    });
    if (sourceId.indexOf(a.safety_equipment_id) < sourceId.indexOf(b.safety_equipment_id)) {
      return -1;
    }
    if (sourceId.indexOf(a.safety_equipment_id) > sourceId.indexOf(b.safety_equipment_id)) {
      return 1;
    }
    return 0;
  };

  return (
    <div className={styles.outterDiv}>
      <div className={styles.innerDiv}>
        {setSafetyEquipmentList()
          .sort(compFunc)
          .map((safetyEquipment) => {
            return <CompSafetyEquipmentChip safetyEquipmentData={safetyEquipment} handleDelete={handleChipDelete} equipmentItems={equipmentItems} />;
          })}
        {EditContext.editMode && <DialogEquipmentBoard AddHandle={handleChipAdd} getAddItems={setDialogData} equipmentItems={dialogEquipmentList} />}
      </div>
    </div>
  );
};

export default CompSafetyEquipmentChipGroup;
