import React, { useState } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ChipDialog, { DialogContent } from "./ChipDialog";
import { WorkStandardWorkTabLabel } from "../../consts/label";

interface AddHandleProps {
  AddHandle: any;
  chipDialogContents: DialogContent[];
}

export default function DialogBoard(props: AddHandleProps) {
  const [open, setOpen] = useState(false);
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Tooltip title={WorkStandardWorkTabLabel.ADD_INFORMATION}>
        <IconButton onClick={handleClickOpen}>
          <AddCircleIcon />
        </IconButton>
      </Tooltip>
      <ChipDialog
        title={WorkStandardWorkTabLabel.SELECT_ADD_INFORMATION}
        open={open}
        onClose={handleClose}
        AddHandle={props.AddHandle}
        DialogContents={props.chipDialogContents}
      />
    </div>
  );
}
