import React, { useContext, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import BasicChip from "./BasicChip";
import { EditInfo, Message } from "../../context/appContextData";
import { IconButton } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { SafetyPointChip, SafetyPointChipGroup } from "./display/home";
import { deleteSafetyPoint, postSafetyPoint, putSafetyPoint, useSafetyPoint } from "../../apicaller/repository/operation";
import { SafetyPointPutRequest, SafetyPointRegistRequest } from "../../apicaller/domain/request/operation";
import { SafetyPoint } from "../../apicaller/domain/response/operation";
import { SuccessMessages, ErrorMessages } from "../../consts/messages";
import { sortByAsc } from "../../function/sort";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root1: {
      height: "100%",
      maxWidth: "100%",
      overflowY: "auto",
      scrollSnapType: "y mandatory",
    },
    root: {
      flexGrow: 1,
      padding: 10,
      display: "flex",
      flexWrap: "wrap",
    },
  })
);

interface Props {
  operationId: string;
}

const BasicChipGroup = (props: Props) => {
  const styles = useStyles();
  const EditContext = useContext(EditInfo);
  const snackBarMessage = useContext(Message);
  const [safetyPointList, getSafetyPointList, mutateSafetyPointList, safetyPointError] = useSafetyPoint();
  const [safetyPointDisplay, setSafetyPointDisplay] = useState<SafetyPointChipGroup>({
    chipList: [],
  });

  // add chip
  const handleChipAdd = () => {
    const reqData = new SafetyPointRegistRequest(Number(props.operationId));
    postSafetyPoint(reqData).then(
      (data) => {
        mutateSafetyPointList();
        snackBarMessage.setMessage(SuccessMessages.INSERT_POINT, "success");
      },
      (error) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.INSERT_POINT, "error");
      }
    );
  };

  // input change chip
  const handleChipChange = (id: number, e: React.FormEvent<HTMLInputElement>) => {
    const index = safetyPointDisplay.chipList.findIndex((e: SafetyPointChip) => e.safetyPointId === id);
    safetyPointDisplay.chipList[index].content = e.currentTarget.value;
    safetyPointDisplay.chipList[index].isError = false;
    setSafetyPointDisplay({ ...safetyPointDisplay });
  };

  // update chip
  const handleChipUpdate = (id: number, e: React.FormEvent<HTMLInputElement>) => {
    const reqData = new SafetyPointPutRequest(id, e.currentTarget.value);

    putSafetyPoint(reqData).then(
      (data) => {
        mutateSafetyPointList();
        snackBarMessage.setMessage(SuccessMessages.UPDATE_POINT, "success");
      },
      (error) => {
        // Restore chip contents to pre-changed state & Change the color of the chip
        const chipList = safetyPointList.safety_point.map((e: SafetyPoint) => {
          return { safetyPointId: e.safety_point_id, content: e.content, isError: false };
        });
        const index = chipList.findIndex((e) => e.safetyPointId === id);
        chipList[index].isError = true;
        let sorted = new SafetyPointChipGroup();
        sorted.chipList = sortByAsc(chipList, "safety_point_id");
        if (sorted.chipList !== undefined) setSafetyPointDisplay(sorted);
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.UPDATE_POINT, "error");
      }
    );
  };

  // delete chip
  const handleChipDelete = (id: number) => {
    deleteSafetyPoint(id).then(
      (data) => {
        mutateSafetyPointList();
        snackBarMessage.setMessage(SuccessMessages.DELETE_POINT, "success");
      },
      (error) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.DELETE_POINT, "error");
      }
    );
  };

  useEffect(() => {
    getSafetyPointList(props.operationId);
  }, [props.operationId]);

  // change in SWR data(sort for display safety point)
  useEffect(() => {
    if (!safetyPointList) return;
    if (safetyPointList.safety_point) {
      const chipList = safetyPointList.safety_point.map((e: SafetyPoint) => {
        return { safetyPointId: e.safety_point_id, content: e.content, isError: false };
      });
      let sorted = new SafetyPointChipGroup();
      sorted.chipList = sortByAsc(chipList, "safety_point_id");
      if (sorted.chipList !== undefined) setSafetyPointDisplay(sorted);
    }
  }, [safetyPointList]);

  useEffect(() => {
    if (safetyPointError !== undefined) {
      snackBarMessage.setMessage(ErrorMessages.GET_POINT, "error");
    }
  }, [safetyPointError]);

  return (
    <div className={styles.root1}>
      <div className={styles.root}>
        {safetyPointDisplay.chipList.map((chipContent, index) => {
          return (
            <BasicChip
              key={index}
              chipContent={chipContent.content}
              handleDelete={handleChipDelete}
              handleChange={handleChipChange}
              handleUpdate={handleChipUpdate}
              id={chipContent.safetyPointId}
              isError={chipContent.isError}
            />
          );
        })}
        {EditContext.editMode && (
          <div>
            <IconButton
              onClick={handleChipAdd}
              style={{
                scrollSnapAlign: "center",
              }}
            >
              <AddCircleIcon />
            </IconButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default BasicChipGroup;
