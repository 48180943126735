import i18n from "i18next";

// システム権限
export class Authority {
  // 一般
  static readonly GENERAL = 0;
  // 編集者
  static readonly EDITOR = 1;
  // 管理者
  static readonly ADMIN = 2;
}

export const authorityMap = new Map<number, string>([
  [Authority.GENERAL, "一般"],
  [Authority.EDITOR, "編集者"],
  [Authority.ADMIN, "管理者"],
]);

const updateAuthority = () => {
  authorityMap.set(Authority.GENERAL,i18n.t("authority-label.general"))
  authorityMap.set(Authority.EDITOR,i18n.t("authority-label.editor"))
  authorityMap.set(Authority.ADMIN,i18n.t("authority-label.admin"))
};

if (i18n.isInitialized) {
  updateAuthority();
}

i18n.on("languageChanged", () => {
  updateAuthority();
});

i18n.on("loaded", () => {
  updateAuthority();
});
