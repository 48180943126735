export class OperationRegistRequest {
  operation_name: string = "";
  team_id: number = 0;
  model_id: number = 0;
  illustration_s3_key: string = "";
}

export class EquipmentRequest {
  operation_id: number;
  safety_equipment_id: number;

  constructor(operation_id: number, safety_equipment_id: number) {
    this.operation_id = operation_id;
    this.safety_equipment_id = safety_equipment_id;
  }
}

export class RequirementRegistRequest {
  operation_id: number;
  requirement_id: number;

  constructor(operation_id: number, requirement_id: number) {
    this.operation_id = operation_id;
    this.requirement_id = requirement_id;
  }
}

export class SafetyPointRegistRequest {
  operation_id: number;

  constructor(operation_id: number) {
    this.operation_id = operation_id;
  }
}

export class SafetyPointPutRequest {
  safety_point_id: number;
  content: string;

  constructor(safety_point_id: number, content: string) {
    this.safety_point_id = safety_point_id;
    this.content = content;
  }
}

export class PartsRegistRequest {
  operation_id: number;
  name: string;
  count: number;

  constructor(operation_id: number, name: string, count: number) {
    this.operation_id = operation_id;
    this.name = name;
    this.count = count;
  }
}

export class PartsUpdateRequest {
  parts_id: number;
  name: string;
  count: number;

  constructor(parts_id: number, name: string, count: number) {
    this.parts_id = parts_id;
    this.name = name;
    this.count = count;
  }
}

export class HistoryRegistRequest {
  operation_id: number;
  title: string;
  detail: string;

  constructor(operation_id: number, title: string, detail: string) {
    this.operation_id = operation_id;
    this.title = title;
    this.detail = detail;
  }
}

export class HistoryUpdateRequest {
  operation_history_id: number;
  title: string;
  detail: string;

  constructor(operation_history_id: number, title: string, detail: string) {
    this.operation_history_id = operation_history_id;
    this.title = title;
    this.detail = detail;
  }
}

export class OperatorSignatureRequest {
  operation_id: number;

  constructor(operation_id: number) {
    this.operation_id = operation_id;
  }
}

export class OperationCardRegistRequest {
  operation_id: number = 0;
  title: string = "";
  card_order: number = 0;
  detail_illustration_s3_key: string = "";

  constructor(operation_id: number, title: string, card_order: number, detail_illustration_s3_key: string) {
    this.operation_id = operation_id;
    this.title = title;
    this.card_order = card_order;
    this.detail_illustration_s3_key = detail_illustration_s3_key;
  }
}

export class OperationCardUpdateRequest {
  operation_card_id: number;
  title: string;

  constructor(operation_card_id: number, title: string) {
    this.operation_card_id = operation_card_id;
    this.title = title;
  }
}

export class OperationPointRegistRequest {
  operation_card_id: number;
  category: number;
  content: string;

  constructor(operation_card_id: number, category: number, content: string) {
    this.operation_card_id = operation_card_id;
    this.category = category;
    this.content = content;
  }
}

export class OperationEquipmentRegistRequest {
  operation_id: number;
  safety_equipment_id: number;

  constructor(operation_id: number, safety_equipment_id: number) {
    this.operation_id = operation_id;
    this.safety_equipment_id = safety_equipment_id;
  }
}

export class OperationHashItemRegistRequest {
  operation_card_id: number;
  operation_hash_master_id: number;

  constructor(operation_card_id: number, operation_hash_master_id: number) {
    this.operation_card_id = operation_card_id;
    this.operation_hash_master_id = operation_hash_master_id;
  }
}

export class OperationPointUpdateContentRequest {
  operation_point_id: number;
  content: string;

  constructor(operation_point_id: number, content: string) {
    this.operation_point_id = operation_point_id;
    this.content = content;
  }
}

export class OperationPointUpdateLinkRequest {
  operation_point_id: number = 0;
  illustration_page_id: number = 0;

  constructor(operation_point_id: number, illustration_page_id: number) {
    this.operation_point_id = operation_point_id;
    this.illustration_page_id = illustration_page_id;
  }
}

export class IllustrationPageRegistRequest {
  operation_card_id: number = 0;
  page: number = 0;
  illustration_s3_key: string = "";

  constructor(operation_card_id: number, page: number, illustration_s3_key: string) {
    this.operation_card_id = operation_card_id;
    this.page = page;
    this.illustration_s3_key = illustration_s3_key;
  }
}
