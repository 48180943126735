import { useState } from "react";
import useSWR from "swr";
import { get } from "../apiCaller";
import { ApiMethod, ApiName, createEndPoint } from "../createEndPoint";
import { WorkStandardListResponse } from "../domain/response/workstandardlist";

//---WorkStandardList---
export const useWorkStandardList = (): [WorkStandardListResponse, (team_id: string, model_id: string) => void, () => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get);
  const getData = (team_id: string, model_id: string) => {
    setEndPoint(createEndPoint({ name: ApiName.WorkStandardList, method: ApiMethod.Get }, [team_id, model_id]));
  };
  const resetData = () => {
    setEndPoint(null);
  };
  const mutateData = () => {
    mutate(endPoint);
  };

  return [data, getData, resetData, mutateData, error];
};
