import React, { useContext, useEffect, useState } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import { cloneDeep } from "lodash";
import { Message } from "../../context/appContextData";
import { DialogMessages, ErrorMessages, SuccessMessages, WarningMessages } from "../../consts/messages";
import { UserInfoLimits } from "../../consts/inputLimits";
import { isOnlyHalfAlphabetAndNumber } from "../../function/validationCheck";
import ComboBox from "../Common/ComboBox";
import { UserRoleUpdateRequest } from "../../apicaller/domain/request/datamanagement";
import { deleteUserRole, postUserRole, putUserRole } from "../../apicaller/repository/datamanagament";
import { AddCircle } from "@material-ui/icons";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import SaveIcon from "@material-ui/icons/Save";
import { SelectedUserInfo } from "./display/userList";
import { AuthorityLabel, DialogButtonLabel, Role, UserListPageLabel } from "../../consts/label";

const useStyles = makeStyles({
  title: {
    textAlign: "center",
  },
  tableHeader: {
    backgroundColor: "whitesmoke",
  },
  roleTable: {
    overflowY: "auto",
    maxHeight: "400px",
  },
  textField: {
    width: "100%",
  },
  typography: {
    textAlign: "center",
  },
});

export enum DialogAction {
  update,
  delete,
  cancel,
}

export interface Props {
  open: boolean;
  target: SelectedUserInfo;
  editSelectUser: (target: SelectedUserInfo) => void;
  editSelectRoles: any;
  handleDialogAction: (action: DialogAction) => void;
  displayRoleList: any;
  setDisplayRoleList: any;
  usrPass: string;
  setUserPW: any;
  authority: string;
  setAuthority: any;
  putUser: any;
  useUserRoleList: any;
  getUserRoleList: any;
  getSectionList: any;
  sectionList: any;
  mutateUserList: any;
  mutateUserRoleList: () => void;
  mutateSectionList: () => void;
}

export const UserSettingDialog = (props: Props) => {
  const styles = useStyles();
  const theme = useTheme();
  const snackBarMessage = useContext(Message);

  const [selectedModuleName, setSelectedModuleName] = useState("");
  const [selectedModuleRole, setSelectedModuleRole] = useState("");
  const [selectedUnitName, setSelectedUnitName] = useState("");
  const [selectedUnitRole, setSelectedUnitRole] = useState("");
  const [selectedTeamName, setSelectedTeamName] = useState("");
  const [selectedTeamRole, setSelectedTeamRole] = useState("");

  const [headerModuleRole, setHeaderModuleRole] = useState<string[]>([
    UserListPageLabel.MODULE,
    UserListPageLabel.ROLE,
    UserListPageLabel.USER_SETTING_DIALOG_SAVE_DELETE,
  ]);
  const [headerUnitRole, setHeaderUnitRole] = useState<string[]>([
    UserListPageLabel.UNIT,
    UserListPageLabel.ROLE,
    UserListPageLabel.USER_SETTING_DIALOG_SAVE_DELETE,
  ]);
  const [headerTeamRole, setHeaderTeamRole] = useState<string[]>([
    UserListPageLabel.TEAM,
    UserListPageLabel.ROLE,
    UserListPageLabel.USER_SETTING_DIALOG_SAVE_DELETE,
  ]);

  useEffect(() => {
    setSelectedModuleName("");
    setSelectedModuleRole("");
    setSelectedUnitName("");
    setSelectedUnitRole("");
    setSelectedTeamName("");
    setSelectedTeamRole("");
  }, [props.target.id]);

  //#region ---handle user info change---
  // テキストフィールドでログインIDを変更する時
  const handleChangeUserId = (textFieldValue: string) => {
    let target = cloneDeep(props.target);
    target.id = textFieldValue;
    props.editSelectUser(target);
  };
  // テキストフィールドでユーザー名を変更する時
  const handleChangeUserName = (textFieldValue: string) => {
    let target = cloneDeep(props.target);
    target.name = textFieldValue;
    props.editSelectUser(target);
  };
  // 役職追加ボタンを押された時の処理
  const handleAddRole = (section: string) => {
    let temp = cloneDeep(props.displayRoleList);
    if (section === "module") {
      temp.module_role = [...props.displayRoleList.module_role, { section_name: "", section_id: 0, role: "" }];
      props.displayRoleList.module_role = temp.module_role;
    } else if (section === "unit") {
      temp.unit_role = [...props.displayRoleList.unit_role, { section_name: "", section_id: 0, role: "" }];
      props.displayRoleList.unit_role = temp.unit_role;
    } else if (section === "team") {
      temp.team_role = [...props.displayRoleList.team_role, { section_name: "", section_id: 0, role: "" }];
      props.displayRoleList.team_role = temp.team_role;
    }

    props.setDisplayRoleList(temp);
  };

  // コンボボックスで権限を入力された場合
  const handleChangeAuthority = (value: string) => {
    props.setAuthority(value);
    let target = cloneDeep(props.target);
    if (value === AuthorityLabel.GENERAL) {
      target.authority = 0;
    } else if (value === AuthorityLabel.EDITOR) {
      target.authority = 1;
    } else if (value === AuthorityLabel.ADMIN) {
      target.authority = 2;
    }
    props.editSelectUser(target);
  };

  /*************    delete    **************** */

  //指定された役職の削除
  const handleDeleteRole = (select_section: string, section_id: number, index: number) => {
    let result: boolean = false;
    result = window.confirm(DialogMessages.DELETE_SECTION);
    if (result) {
      deleteUserRole(select_section, section_id, props.target.user_id).then(
        (data) => {
          props.displayRoleList.module_role.splice(index, 1);
          props.setDisplayRoleList(props.displayRoleList);
          props.getSectionList(String(props.target.user_id));

          props.mutateUserRoleList();
          props.mutateSectionList();
          snackBarMessage.setMessage(SuccessMessages.ROLE_DELETE, "success");
        },
        (error) => {
          console.log(error);
          snackBarMessage.setMessage(ErrorMessages.ROLE_DELETE, "error");
        }
      );
    }
  };

  /*************    post    *****************/

  //役職の保存ボタンが押された時の処理
  const handlePostModuleRole = () => {
    let module_id = 0;

    props.sectionList?.module_list?.find((e) => {
      if (e.section_name === selectedModuleName) {
        module_id = e.section_id;
      }
    });

    let request = new UserRoleUpdateRequest("MODULE", module_id, props.target.user_id, selectedModuleRole);

    postUserRole(request).then(
      (data) => {
        const addModule = { section_name: selectedModuleName, section_id: request.section_id, role: request.role };
        props.displayRoleList.module_role[props.displayRoleList.module_role.length - 1] = addModule;
        props.setDisplayRoleList(props.displayRoleList);
        props.getSectionList(String(props.target.user_id));

        props.mutateUserRoleList();
        props.mutateSectionList();
        snackBarMessage.setMessage(SuccessMessages.POST_ROLE, "success");
      },
      (error) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.POST_ROLE, "error");
      }
    );
  };

  //役職の保存ボタンが押された時の処理
  const handlePostUnitRole = () => {
    let unit_id = 0;

    props.sectionList?.unit_list?.find((e) => {
      if (e.section_name === selectedUnitName) {
        unit_id = e.section_id;
      }
    });

    let request = new UserRoleUpdateRequest("UNIT", unit_id, props.target.user_id, selectedUnitRole);

    postUserRole(request).then(
      (data) => {
        const addModule = { section_name: selectedUnitName, section_id: request.section_id, role: request.role };
        props.displayRoleList.unit_role[props.displayRoleList.unit_role.length - 1] = addModule;
        props.setDisplayRoleList(props.displayRoleList);
        props.getSectionList(String(props.target.user_id));

        props.mutateUserRoleList();
        props.mutateSectionList();
        snackBarMessage.setMessage(SuccessMessages.POST_ROLE, "success");
      },
      (error) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.POST_ROLE, "error");
      }
    );
  };

  const handlePostTeamRole = () => {
    let team_id = 0;

    props.sectionList?.team_list?.find((e) => {
      if (e.section_name === selectedTeamName) {
        team_id = e.section_id;
      }
    });

    let request = new UserRoleUpdateRequest("TEAM", team_id, props.target.user_id, selectedTeamRole);

    postUserRole(request).then(
      (data) => {
        const addModule = { section_name: selectedTeamName, section_id: request.section_id, role: request.role };
        props.displayRoleList.team_role[props.displayRoleList.team_role.length - 1] = addModule;
        props.setDisplayRoleList(props.displayRoleList);
        props.getSectionList(String(props.target.user_id));

        props.mutateUserRoleList();
        props.mutateSectionList();
        snackBarMessage.setMessage(SuccessMessages.POST_ROLE, "success");
      },
      (error) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.POST_ROLE, "error");
      }
    );
  };

  //#endregion

  /*************    change    *****************/

  const handleChangeRole = (index: number, row: any, section_name: string, section_id: number, role: string) => {
    let request = new UserRoleUpdateRequest(section_name, section_id, props.target.user_id, role);
    putUserRole(request).then(
      (data) => {
        props.mutateUserList();

        props.mutateUserRoleList();
        snackBarMessage.setMessage(SuccessMessages.POST_ROLE, "success");
      },
      (error) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.POST_ROLE, "error");
      }
    );
  };

  /*************    save    *****************/

  const handleSaveSetting = () => {
    props.putUser(props.target);
  };

  useEffect(() => {
    if (props.target.user_id === 0) return;
    props.getSectionList(String(props.target.user_id));
  }, [props.sectionList]);

  /***************************************/
  /************   View *******************/
  /***************************************/

  const viewModule = () => {
    return (
      <Table>
        <TableHead className={styles.tableHeader}>
          {headerModuleRole.map((h) => {
            return (
              <TableCell>
                <div>{h}</div>
              </TableCell>
            );
          })}
        </TableHead>
        <TableBody>
          {props.displayRoleList?.module_role != undefined ? (
            props.displayRoleList.module_role.map((row, index) => {
              return (
                <TableRow>
                  {row.section_id === 0 ? (
                    <>
                      <TableCell>
                        <ComboBox
                          label={UserListPageLabel.MODULE}
                          options={props.sectionList?.module_list !== undefined ? props.sectionList.module_list.map((l) => l.section_name) : []}
                          value={selectedModuleName}
                          onChange={(e) => {
                            setSelectedModuleName(e);
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <ComboBox
                          label={UserListPageLabel.ROLE}
                          disableClearable
                          options={[Role.ASSOCIATE, Role.MODULE_MANAGER]}
                          value={selectedModuleRole}
                          onChange={(e) => {
                            setSelectedModuleRole(e);
                          }}
                        />
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>{row.section_name}</TableCell>
                      <TableCell>
                        <ComboBox
                          label={UserListPageLabel.ROLE}
                          disableClearable
                          value={row.role}
                          options={[Role.ASSOCIATE, Role.MODULE_MANAGER]}
                          onChange={(e) => {
                            handleChangeRole(index, row, "MODULE", row.section_id, e);
                            row.role = e;
                          }}
                        />
                      </TableCell>
                    </>
                  )}

                  <TableCell>
                    {row.section_id === 0 ? (
                      <IconButton
                        size="small"
                        onClick={() => {
                          handlePostModuleRole();
                        }}
                      >
                        <SaveIcon color={"secondary"} />
                      </IconButton>
                    ) : (
                      <IconButton
                        size="small"
                        onClick={() => {
                          handleDeleteRole("Module", row.section_id, index);
                        }}
                      >
                        <RemoveCircleOutlineIcon color="error" />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <></>
          )}
          {props.displayRoleList?.module_role !== undefined ? (
            props.displayRoleList.module_role.length !== 0 ? (
              props.displayRoleList.module_role[props.displayRoleList.module_role.length - 1].section_id !== 0 && (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    <IconButton
                      size="small"
                      onClick={() => {
                        handleAddRole("module");
                      }}
                    >
                      <AddCircle color="primary" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            ) : (
              props.displayRoleList.module_role.length === 0 && (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    <IconButton
                      size="small"
                      onClick={() => {
                        handleAddRole("module");
                      }}
                    >
                      <AddCircle color="primary" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            )
          ) : (
            <></>
          )}
        </TableBody>
      </Table>
    );
  };

  const viewUnit = () => {
    return (
      <Table>
        <TableHead className={styles.tableHeader}>
          {headerUnitRole.map((h) => {
            return (
              <TableCell>
                <div>{h}</div>
              </TableCell>
            );
          })}
        </TableHead>
        <TableBody>
          {props.displayRoleList?.unit_role !== undefined ? (
            props.displayRoleList.unit_role.map((row, index) => {
              return (
                <TableRow>
                  {row.section_id === 0 ? (
                    <>
                      <TableCell>
                        <ComboBox
                          label={UserListPageLabel.UNIT}
                          options={props.sectionList?.unit_list !== undefined ? props.sectionList.unit_list.map((l) => l.section_name) : []}
                          value={selectedUnitName}
                          onChange={(e) => {
                            setSelectedUnitName(e);
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <ComboBox
                          label={UserListPageLabel.ROLE}
                          disableClearable
                          options={[Role.ASSOCIATE, Role.UNIT_LEADER]}
                          value={selectedUnitRole}
                          onChange={(e) => {
                            setSelectedUnitRole(e);
                          }}
                        />
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>{row.section_name}</TableCell>
                      <TableCell>
                        <ComboBox
                          label={UserListPageLabel.ROLE}
                          disableClearable
                          options={[Role.ASSOCIATE, Role.UNIT_LEADER]}
                          value={row.role}
                          onChange={(e) => {
                            handleChangeRole(index, row, "UNIT", row.section_id, e);
                            row.role = e;
                          }}
                        />
                      </TableCell>
                    </>
                  )}
                  <TableCell>
                    {row.section_id === 0 ? (
                      <IconButton
                        size="small"
                        onClick={() => {
                          handlePostUnitRole();
                        }}
                      >
                        <SaveIcon color={"secondary"} />
                      </IconButton>
                    ) : (
                      <IconButton
                        size="small"
                        onClick={() => {
                          handleDeleteRole("Unit", row.section_id, index);
                        }}
                      >
                        <RemoveCircleOutlineIcon color="error" />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <></>
          )}
          {props.displayRoleList?.unit_role !== undefined ? (
            props.displayRoleList.unit_role.length !== 0 ? (
              props.displayRoleList.unit_role[props.displayRoleList.unit_role.length - 1].section_id !== 0 && (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    <IconButton
                      size="small"
                      onClick={() => {
                        handleAddRole("unit");
                      }}
                    >
                      <AddCircle color="primary" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            ) : (
              props.displayRoleList.unit_role.length === 0 && (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    <IconButton
                      size="small"
                      onClick={() => {
                        handleAddRole("unit");
                      }}
                    >
                      <AddCircle color="primary" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            )
          ) : (
            <></>
          )}
        </TableBody>
      </Table>
    );
  };

  const viewTeam = () => {
    return (
      <Table>
        <TableHead className={styles.tableHeader}>
          {headerTeamRole.map((h) => {
            return (
              <TableCell>
                <div>{h}</div>
              </TableCell>
            );
          })}
        </TableHead>
        <TableBody>
          {props.displayRoleList?.team_role !== undefined ? (
            props.displayRoleList.team_role.map((row, index) => {
              return (
                <TableRow>
                  {row.section_id === 0 ? (
                    <>
                      <TableCell>
                        <ComboBox
                          label={UserListPageLabel.TEAM}
                          options={props.sectionList?.team_list !== undefined ? props.sectionList.team_list.map((l) => l.section_name) : []}
                          value={selectedTeamName}
                          onChange={(e) => {
                            setSelectedTeamName(e);
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <ComboBox
                          label={UserListPageLabel.ROLE}
                          disableClearable
                          options={[Role.ASSOCIATE, Role.TEAM_LEADER]}
                          value={selectedTeamRole}
                          onChange={(e) => {
                            setSelectedTeamRole(e);
                          }}
                        />
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>{row.section_name}</TableCell>
                      <TableCell>
                        <ComboBox
                          label={UserListPageLabel.ROLE}
                          disableClearable
                          options={[Role.ASSOCIATE, Role.TEAM_LEADER]}
                          value={row.role}
                          onChange={(e) => {
                            handleChangeRole(index, row, "TEAM", row.section_id, e);
                            row.role = e;
                          }}
                        />
                      </TableCell>
                    </>
                  )}
                  <TableCell>
                    {row.section_id === 0 ? (
                      <IconButton
                        size="small"
                        onClick={() => {
                          handlePostTeamRole();
                        }}
                      >
                        <SaveIcon color={"secondary"} />
                      </IconButton>
                    ) : (
                      <IconButton
                        size="small"
                        onClick={() => {
                          handleDeleteRole("Team", row.section_id, index);
                        }}
                      >
                        <RemoveCircleOutlineIcon color="error" />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <></>
          )}
          {props.displayRoleList?.team_role !== undefined ? (
            props.displayRoleList.team_role.length !== 0 ? (
              props.displayRoleList.team_role[props.displayRoleList.team_role.length - 1].section_id !== 0 && (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    <IconButton
                      size="small"
                      onClick={() => {
                        handleAddRole("team");
                      }}
                    >
                      <AddCircle color="primary" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            ) : (
              props.displayRoleList.team_role.length === 0 && (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    <IconButton
                      size="small"
                      onClick={() => {
                        handleAddRole("team");
                      }}
                    >
                      <AddCircle color="primary" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            )
          ) : (
            <></>
          )}
        </TableBody>
      </Table>
    );
  };

  return (
    <Dialog
      maxWidth={"lg"}
      fullWidth
      aria-labelledby="user-dialog"
      open={props.open}
      onClose={() => {
        props.handleDialogAction(DialogAction.cancel);
      }}
    >
      <DialogTitle className={styles.title} id={"user-dialog"}>
        {UserListPageLabel.USER_SETTING_DIALOG_TITLE}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={4} style={{ borderRight: "1px solid" }}>
            <Typography className={styles.typography} variant={"h6"}>
              {UserListPageLabel.BASIC_INFO_TITLE}
            </Typography>
            <TextField
              className={styles.textField}
              margin="normal"
              label={UserListPageLabel.LOGIN_ID}
              value={props.target.id}
              inputProps={{ maxLength: UserInfoLimits.ID_MAX_LENGTH }}
              onChange={(e) => {
                if (!isOnlyHalfAlphabetAndNumber(e.currentTarget.value)) {
                  snackBarMessage.setMessage(WarningMessages.NOT_ONLY_HALF_ALPHABET_AND_NUMBER, "warning");
                  return;
                }
                handleChangeUserId(e.currentTarget.value);
              }}
            />
            <TextField
              className={styles.textField}
              margin="normal"
              label={UserListPageLabel.USER_NAME}
              value={props.target.name}
              inputProps={{ maxLength: UserInfoLimits.NAME_MAX_LENGTH }}
              onChange={(e) => handleChangeUserName(e.currentTarget.value)}
            />
            <div style={{ width: "100%", marginTop: theme.spacing(4) }}>
              <ComboBox
                disableClearable
                label={UserListPageLabel.AUTHORITY}
                options={[AuthorityLabel.GENERAL, AuthorityLabel.EDITOR, AuthorityLabel.ADMIN]}
                value={props.authority}
                onChange={(e) => {
                  handleChangeAuthority(e);
                }}
              />
            </div>
            <div style={{ marginTop: theme.spacing(2), textAlign: "right" }}>
              <Button
                color="secondary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={() => {
                  handleSaveSetting();
                }}
              >
                {UserListPageLabel.SAVE}
              </Button>
            </div>
          </Grid>
          <Grid item xs={8} style={{ borderLeft: "1px solid" }}>
            <Typography className={styles.typography} variant={"h6"}>
              {UserListPageLabel.AFFILIATION_INFO_TITLE}
            </Typography>
            <div className={styles.roleTable}>
              <TableContainer>{viewModule()}</TableContainer>
              <TableContainer>{viewUnit()}</TableContainer>
              <TableContainer>{viewTeam()}</TableContainer>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => {
            props.handleDialogAction(DialogAction.cancel);
          }}
        >
          {DialogButtonLabel.CLOSE}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
