import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useContext } from "react";
import { Redirect } from "react-router-dom";
import OperationList from "../components/OperationList/OperationList";
import { LoginInfo } from "../context/appContextData";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      // AppBar表示分の余白
      marginTop: 50,
      flexGrow: 1,
    },
    typography: {
      fontSize: 24,
      marginTop: 30,
      marginLeft: 40,
    },
    text: {
      marginLeft: 45,
      width: "40%",
      height: 50,
    },
    button: {
      fontSize: 24,
      marginTop: 20,
      marginLeft: 45,
      width: 100,
      height: 50,
    },
  })
);

export const OPERATION_NAME_MIN_LENGTH = 1;
export const OPERATION_NAME_MAX_LENGTH = 200;

const OperationListPage = () => {
  const styles = useStyles();
  const LoginContext = useContext(LoginInfo);

  //権限
  if (LoginContext.loginUser.authority === 0) {
    return <Redirect to="/login" />;
  }

  return (
    <div className={styles.container}>
      <OperationList />
    </div>
  );
};

export default OperationListPage;
