import React, { useEffect, useState } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HashChipDialog from "./HashChipDialog";
import { OperationHashMasterList } from "../../apicaller/domain/response/operation";
import { OperationHashItemChipGroup } from "./display/home";
import { WorkStandardWorkTabLabel } from "../../consts/label";

export interface Props {
  AddHandle: any;
  HashDialogContents: OperationHashMasterList;
  HashRegisteredList: OperationHashItemChipGroup;
}

export default function DialogHashBoard(props: Props) {
  const [open, setOpen] = useState(false);
  const [operation_hash_master_list, setContent] = useState<OperationHashMasterList>({ operation_hash: [] });
  const [isProcessing, setIsProcessing] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
    setIsProcessing(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setContent(props.HashDialogContents);
  }, []);

  return (
    <div>
      <Tooltip title={WorkStandardWorkTabLabel.ADD_HASH}>
        <IconButton onClick={handleClickOpen}>
          <AddCircleIcon />
        </IconButton>
      </Tooltip>
      {operation_hash_master_list && (
        <HashChipDialog
          open={open}
          onClose={handleClose}
          AddHandle={props.AddHandle}
          isProcessing={isProcessing}
          setIsProcessing={setIsProcessing}
          HashDialogContents={operation_hash_master_list}
          HashRegisteredList={props.HashRegisteredList ?? new OperationHashItemChipGroup()}
        />
      )}
    </div>
  );
}
