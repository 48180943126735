import React, { useEffect } from "react";
import { useContext, useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { TreeItem, TreeView } from "@material-ui/lab";
import { InnerAreaSize, WindowSize } from "../../context/appContextData";
import { marked } from "marked";
import "./markdown/style.css";
import { InstructionsPageLabel } from "../../consts/label";
import i18n from "i18next";

const useStyles = makeStyles({
  treeViewContainer: {
    overflow: "auto",
  },
  root: {
    flexGrow: 1,
    minWidth: "100%",
    color: "royalblue",
  },
  md: {
    paddingLeft: 30,
    paddingTop: 20,
    overflow: "auto",
  },
});

export default function ContentTree() {
  const classes = useStyles();
  const [selectedMarkdown, setSelectedMarkdown] = useState("");

  const [introductionLogin, setIntroductionLogin] = useState("");
  const [introductionLogout, setIntroductionLogout] = useState("");
  const [introductionSystemAuthority, setIntroductionSystemAuthority] = useState("");
  const [introductionWorkStandardStatus, setIntroductionWorkStandardStatus] = useState("");
  const [introductionManagementBookStatus, setIntroductionManagementBookStatus] = useState("");
  const [introductionSearch, setIntroductionSearch] = useState("");
  const [workStandardViewOpen, setWorkStandardViewOpen] = useState("");
  const [workStandardViewWorkStandardSelect, setWorkStandardViewWorkStandardSelect] = useState("");
  const [workStandardViewWorkStandardOperatorSign, setWorkStandardViewWorkStandardOperatorSign] = useState("");
  const [createNewCreateNew, setCreateNewCreateNew] = useState("");
  const [workStandardEditWorkStandardList, setWorkStandardEditWorkStandardList] = useState("");
  const [workStandardEditEditMode, setWorkStandardEditEditMode] = useState("");
  const [workStandardEditEditCard, setWorkStandardEditEditCard] = useState("");
  const [workStandardEditEditDetailIllustration, setWorkStandardEditEditDetailIllustration] = useState("");
  const [workStandardEditEditSafetyPoint, setWorkStandardEditEditSafetyPoint] = useState("");
  const [workStandardEditEditSafetyEquipment, setWorkStandardEditEditSafetyEquipment] = useState("");
  const [workStandardEditEditRequirement, setWorkStandardEditEditRequirement] = useState("");
  const [workStandardEditEditParts, setWorkStandardEditEditParts] = useState("");
  const [workStandardChangeNameWorkStandardChangeName, setWorkStandardChangeNameWorkStandardChangeName] = useState("");
  const [workStandardMoveWorkStandardMove, setWorkStandardMoveWorkStandardMove] = useState("");
  const [workStandardDeleteWorkStandardDelete, setWorkStandardDeleteWorkStandardDelete] = useState("");
  const [workStandardPublishApplication, setWorkStandardPublishApplication] = useState("");
  const [workStandardPublishConfirmer, setWorkStandardPublishConfirmer] = useState("");
  const [workStandardPublishApprover, setWorkStandardPublishApprover] = useState("");
  const [workStandardPublishPublish, setWorkStandardPublishPublish] = useState("");
  const [workStandardPublishCancel, setWorkStandardPublishCancel] = useState("");
  const [workStandardPublishReject, setWorkStandardPublishReject] = useState("");
  const [workStandardPublishModify, setWorkStandardPublishModify] = useState("");
  const [workStandardReviseRevise, setWorkStandardReviseRevise] = useState("");
  const [workStandardReviseHistoryDetail, setWorkStandardReviseHistoryDetail] = useState("");
  const [workStandardPublishUnderRevisionApplication, setWorkStandardPublishUnderRevisionApplication] = useState("");
  const [workStandardPublishUnderRevisionApprover, setWorkStandardPublishUnderRevisionApprover] = useState("");
  const [workStandardPublishUnderRevisionPublish, setWorkStandardPublishUnderRevisionPublish] = useState("");
  const [workStandardPublishUnderRevisionCancel, setWorkStandardPublishUnderRevisionCancel] = useState("");
  const [workStandardPublishUnderRevisionReject, setWorkStandardPublishUnderRevisionReject] = useState("");
  const [workStandardPublishUnderRevisionModify, setWorkStandardPublishUnderRevisionModify] = useState("");
  const [workStandardArchiveArchiveApplication, setWorkStandardArchiveArchiveApplication] = useState("");
  const [workStandardArchiveArchiveApprove, setWorkStandardArchiveArchiveApprove] = useState("");
  const [workStandardArchiveArchiveReject, setWorkStandardArchiveArchiveReject] = useState("");
  const [managementBookOpenManagementBookPage, setManagementBookOpenManagementBookPage] = useState("");
  const [managementBookEditManagementBookCreate, setManagementBookEditManagementBookCreate] = useState("");
  const [managementBookEditEditMode, setManagementBookEditEditMode] = useState("");
  const [managementBookEditWorkStandardInProcess, setManagementBookEditWorkStandardInProcess] = useState("");
  const [managementBookEditProcess, setManagementBookEditProcess] = useState("");
  const [managementBookPublishApplication, setManagementBookPublishApplication] = useState("");
  const [managementBookPublishApprove, setManagementBookPublishApprove] = useState("");
  const [managementBookPublishPublish, setManagementBookPublishPublish] = useState("");
  const [managementBookPublishCancel, setManagementBookPublishCancel] = useState("");
  const [managementBookPublishReject, setManagementBookPublishReject] = useState("");
  const [managementBookPublishModify, setManagementBookPublishModify] = useState("");
  const [managementBookReviseRevise, setManagementBookReviseRevise] = useState("");
  const [dataManagementModel, setDataManagementModel] = useState("");
  const [dataManagementUnit, setDataManagementUnit] = useState("");
  const [dataManagementTeam, setDataManagementTeam] = useState("");
  const [dataManagementProductionCapacity, setDataManagementProductionCapacity] = useState("");
  const [dataManagementProductionCapacityCurrently, setDataManagementProductionCapacityCurrently] = useState("");
  const [userListView, setUserListView] = useState("");
  const [userListRegister, setUserListRegister] = useState("");
  const [userListModify, setUserListModify] = useState("");
  const [userListDelete, setUserListDelete] = useState("");

  const windowSizeContext = useContext(WindowSize);
  const InnerAreaSizeContext = useContext(InnerAreaSize);
  const setMarkdownHandler = (md: any) => {
    var el = document.getElementById("mdDiv");
    el?.scrollTo(0, 0);

    fetch(md)
      .then((res) => res.text())
      .then((text) => setSelectedMarkdown(text));
  };

  const importMarkdown = () => {
    let language = "";

    switch (i18n.language) {
      case "ja":
        language = "japanese";
        break;
      default:
        language = "japanese";
        break;
    }

    import(`./markdown/${language}/introduction/login.md`).then((element) => {
      setIntroductionLogin(element.default);
    });
    import(`./markdown/${language}/introduction/logout.md`).then((element) => {
      setIntroductionLogout(element.default);
    });
    import(`./markdown/${language}/introduction/system_authority.md`).then((element) => {
      setIntroductionSystemAuthority(element.default);
    });
    import(`./markdown/${language}/introduction/work_standard_status.md`).then((element) => {
      setIntroductionWorkStandardStatus(element.default);
    });
    import(`./markdown/${language}/introduction/management_book_status.md`).then((element) => {
      setIntroductionManagementBookStatus(element.default);
    });
    import(`./markdown/${language}/introduction/search.md`).then((element) => {
      setIntroductionSearch(element.default);
    });
    import(`./markdown/${language}/work_standard_view/open.md`).then((element) => {
      setWorkStandardViewOpen(element.default);
    });
    import(`./markdown/${language}/work_standard_view/work_standard_select.md`).then((element) => {
      setWorkStandardViewWorkStandardSelect(element.default);
    });
    import(`./markdown/${language}/work_standard_view/work_standard_operator_sign.md`).then((element) => {
      setWorkStandardViewWorkStandardOperatorSign(element.default);
    });
    import(`./markdown/${language}/create_new/create_new.md`).then((element) => {
      setCreateNewCreateNew(element.default);
    });
    import(`./markdown/${language}/work_standard_edit/work_standard_list.md`).then((element) => {
      setWorkStandardEditWorkStandardList(element.default);
    });
    import(`./markdown/${language}/work_standard_edit/edit_mode.md`).then((element) => {
      setWorkStandardEditEditMode(element.default);
    });
    import(`./markdown/${language}/work_standard_edit/edit_card.md`).then((element) => {
      setWorkStandardEditEditCard(element.default);
    });
    import(`./markdown/${language}/work_standard_edit/edit_detail_illustration.md`).then((element) => {
      setWorkStandardEditEditDetailIllustration(element.default);
    });
    import(`./markdown/${language}/work_standard_edit/edit_safety_point.md`).then((element) => {
      setWorkStandardEditEditSafetyPoint(element.default);
    });
    import(`./markdown/${language}/work_standard_edit/edit_safety_equipment.md`).then((element) => {
      setWorkStandardEditEditSafetyEquipment(element.default);
    });
    import(`./markdown/${language}/work_standard_edit/edit_requirement.md`).then((element) => {
      setWorkStandardEditEditRequirement(element.default);
    });
    import(`./markdown/${language}/work_standard_edit/edit_parts.md`).then((element) => {
      setWorkStandardEditEditParts(element.default);
    });
    import(`./markdown/${language}/work_standard_change_name/work_standard_change_name.md`).then((element) => {
      setWorkStandardChangeNameWorkStandardChangeName(element.default);
    });
    import(`./markdown/${language}/work_standard_move/work_standard_move.md`).then((element) => {
      setWorkStandardMoveWorkStandardMove(element.default);
    });
    import(`./markdown/${language}/work_standard_delete/work_standard_delete.md`).then((element) => {
      setWorkStandardDeleteWorkStandardDelete(element.default);
    });
    import(`./markdown/${language}/work_standard_publish/application.md`).then((element) => {
      setWorkStandardPublishApplication(element.default);
    });
    import(`./markdown/${language}/work_standard_publish/confirmer.md`).then((element) => {
      setWorkStandardPublishConfirmer(element.default);
    });
    import(`./markdown/${language}/work_standard_publish/approver.md`).then((element) => {
      setWorkStandardPublishApprover(element.default);
    });
    import(`./markdown/${language}/work_standard_publish/publish.md`).then((element) => {
      setWorkStandardPublishPublish(element.default);
    });
    import(`./markdown/${language}/work_standard_publish/cancel.md`).then((element) => {
      setWorkStandardPublishCancel(element.default);
    });
    import(`./markdown/${language}/work_standard_publish/reject.md`).then((element) => {
      setWorkStandardPublishReject(element.default);
    });
    import(`./markdown/${language}/work_standard_publish/modify.md`).then((element) => {
      setWorkStandardPublishModify(element.default);
    });
    import(`./markdown/${language}/work_standard_revise/revise.md`).then((element) => {
      setWorkStandardReviseRevise(element.default);
    });
    import(`./markdown/${language}/work_standard_revise/history_detail.md`).then((element) => {
      setWorkStandardReviseHistoryDetail(element.default);
    });
    import(`./markdown/${language}/work_standard_publish_under_revision/application.md`).then((element) => {
      setWorkStandardPublishUnderRevisionApplication(element.default);
    });
    import(`./markdown/${language}/work_standard_publish_under_revision/approver.md`).then((element) => {
      setWorkStandardPublishUnderRevisionApprover(element.default);
    });
    import(`./markdown/${language}/work_standard_publish_under_revision/publish.md`).then((element) => {
      setWorkStandardPublishUnderRevisionPublish(element.default);
    });
    import(`./markdown/${language}/work_standard_publish_under_revision/cancel.md`).then((element) => {
      setWorkStandardPublishUnderRevisionCancel(element.default);
    });
    import(`./markdown/${language}/work_standard_publish_under_revision/reject.md`).then((element) => {
      setWorkStandardPublishUnderRevisionReject(element.default);
    });
    import(`./markdown/${language}/work_standard_publish_under_revision/modify.md`).then((element) => {
      setWorkStandardPublishUnderRevisionModify(element.default);
    });
    import(`./markdown/${language}/work_standard_archive/archive_application.md`).then((element) => {
      setWorkStandardArchiveArchiveApplication(element.default);
    });
    import(`./markdown/${language}/work_standard_archive/archive_approve.md`).then((element) => {
      setWorkStandardArchiveArchiveApprove(element.default);
    });
    import(`./markdown/${language}/work_standard_archive/archive_reject.md`).then((element) => {
      setWorkStandardArchiveArchiveReject(element.default);
    });
    import(`./markdown/${language}/management_book_open/management_book_page.md`).then((element) => {
      setManagementBookOpenManagementBookPage(element.default);
    });
    import(`./markdown/${language}/management_book_edit/management_book_create.md`).then((element) => {
      setManagementBookEditManagementBookCreate(element.default);
    });
    import(`./markdown/${language}/management_book_edit/edit_mode.md`).then((element) => {
      setManagementBookEditEditMode(element.default);
    });
    import(`./markdown/${language}/management_book_edit/work_standard_in_process.md`).then((element) => {
      setManagementBookEditWorkStandardInProcess(element.default);
    });
    import(`./markdown/${language}/management_book_edit/process.md`).then((element) => {
      setManagementBookEditProcess(element.default);
    });
    import(`./markdown/${language}/management_book_publish/application.md`).then((element) => {
      setManagementBookPublishApplication(element.default);
    });
    import(`./markdown/${language}/management_book_publish/approve.md`).then((element) => {
      setManagementBookPublishApprove(element.default);
    });
    import(`./markdown/${language}/management_book_publish/publish.md`).then((element) => {
      setManagementBookPublishPublish(element.default);
    });
    import(`./markdown/${language}/management_book_publish/cancel.md`).then((element) => {
      setManagementBookPublishCancel(element.default);
    });
    import(`./markdown/${language}/management_book_publish/reject.md`).then((element) => {
      setManagementBookPublishReject(element.default);
    });
    import(`./markdown/${language}/management_book_publish/modify.md`).then((element) => {
      setManagementBookPublishModify(element.default);
    });
    import(`./markdown/${language}/management_book_revice/revise.md`).then((element) => {
      setManagementBookReviseRevise(element.default);
    });
    import(`./markdown/${language}/data_management/model.md`).then((element) => {
      setDataManagementModel(element.default);
    });
    import(`./markdown/${language}/data_management/unit.md`).then((element) => {
      setDataManagementUnit(element.default);
    });
    import(`./markdown/${language}/data_management/team.md`).then((element) => {
      setDataManagementTeam(element.default);
    });
    import(`./markdown/${language}/data_management/production_capacity.md`).then((element) => {
      setDataManagementProductionCapacity(element.default);
    });
    import(`./markdown/${language}/data_management/production_capacity_currently.md`).then((element) => {
      setDataManagementProductionCapacityCurrently(element.default);
    });
    import(`./markdown/${language}/user_list/view.md`).then((element) => {
      setUserListView(element.default);
    });
    import(`./markdown/${language}/user_list/register.md`).then((element) => {
      setUserListRegister(element.default);
    });
    import(`./markdown/${language}/user_list/modify.md`).then((element) => {
      setUserListModify(element.default);
    });
    import(`./markdown/${language}/user_list/delete.md`).then((element) => {
      setUserListDelete(element.default);
    });
  };

  useEffect(() => {
    importMarkdown();
  }, []);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={2} className={classes.treeViewContainer} style={{ height: InnerAreaSizeContext.height }}>
          <TreeView className={classes.root} defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
            <TreeItem nodeId="000" label={InstructionsPageLabel.INTRODUCTION}>
              <TreeItem
                nodeId="001"
                label={InstructionsPageLabel.INTRODUCTION_LOGIN}
                onClick={() => {
                  setMarkdownHandler(introductionLogin);
                }}
              />
              <TreeItem
                nodeId="002"
                label={InstructionsPageLabel.INTRODUCTION_LOGOUT}
                onClick={() => {
                  setMarkdownHandler(introductionLogout);
                }}
              />
              <TreeItem
                nodeId="003"
                label={InstructionsPageLabel.INTRODUCTION_SYSTEM_AUTHORITY}
                onClick={() => {
                  setMarkdownHandler(introductionSystemAuthority);
                }}
              />
              <TreeItem
                nodeId="004"
                label={InstructionsPageLabel.INTRODUCTION_WORK_STANDARD_STATUS}
                onClick={() => {
                  setMarkdownHandler(introductionWorkStandardStatus);
                }}
              />
              <TreeItem
                nodeId="005"
                label={InstructionsPageLabel.INTRODUCTION_MANAGEMENT_BOOK_STATUS}
                onClick={() => {
                  setMarkdownHandler(introductionManagementBookStatus);
                }}
              />
              <TreeItem
                nodeId="006"
                label={InstructionsPageLabel.INTRODUCTION_SEARCH}
                onClick={() => {
                  setMarkdownHandler(introductionSearch);
                }}
              />
            </TreeItem>
            <TreeItem nodeId="100" label={InstructionsPageLabel.WORK_STANDARD_VIEW}>
              <TreeItem
                nodeId="101"
                label={InstructionsPageLabel.WORK_STANDARD_VIEW_OPEN}
                onClick={() => {
                  setMarkdownHandler(workStandardViewOpen);
                }}
              />
              <TreeItem
                nodeId="102"
                label={InstructionsPageLabel.WORK_STANDARD_VIEW_WORK_STANDARD_SELECT}
                onClick={() => {
                  setMarkdownHandler(workStandardViewWorkStandardSelect);
                }}
              />
              <TreeItem
                nodeId="103"
                label={InstructionsPageLabel.WORK_STANDARD_VIEW_WORK_STANDARD_OPERATOR_SIGN}
                onClick={() => {
                  setMarkdownHandler(workStandardViewWorkStandardOperatorSign);
                }}
              />
            </TreeItem>
            <TreeItem nodeId="200" label={InstructionsPageLabel.CREATE_NEW}>
              <TreeItem
                nodeId="201"
                label={InstructionsPageLabel.CREATE_NEW_CREATE_NEW}
                onClick={() => {
                  setMarkdownHandler(createNewCreateNew);
                }}
              />
            </TreeItem>
            <TreeItem nodeId="300" label={InstructionsPageLabel.WORK_STANDARD_EDIT}>
              <TreeItem
                nodeId="301"
                label={InstructionsPageLabel.WORK_STANDARD_EDIT_WORK_STANDARD_LIST}
                onClick={() => {
                  setMarkdownHandler(workStandardEditWorkStandardList);
                }}
              />
              <TreeItem
                nodeId="302"
                label={InstructionsPageLabel.WORK_STANDARD_EDIT_EDIT_MODE}
                onClick={() => {
                  setMarkdownHandler(workStandardEditEditMode);
                }}
              />
              <TreeItem
                nodeId="303"
                label={InstructionsPageLabel.WORK_STANDARD_EDIT_EDIT_CARD}
                onClick={() => {
                  setMarkdownHandler(workStandardEditEditCard);
                }}
              />
              <TreeItem
                nodeId="304"
                label={InstructionsPageLabel.WORK_STANDARD_EDIT_EDIT_DETAIL_ILLUSTRATION}
                onClick={() => {
                  setMarkdownHandler(workStandardEditEditDetailIllustration);
                }}
              />
              <TreeItem
                nodeId="305"
                label={InstructionsPageLabel.WORK_STANDARD_EDIT_EDIT_SAFETY_POINT}
                onClick={() => {
                  setMarkdownHandler(workStandardEditEditSafetyPoint);
                }}
              />
              <TreeItem
                nodeId="306"
                label={InstructionsPageLabel.WORK_STANDARD_EDIT_EDIT_SAFETY_EQUIPMENT}
                onClick={() => {
                  setMarkdownHandler(workStandardEditEditSafetyEquipment);
                }}
              />
              <TreeItem
                nodeId="307"
                label={InstructionsPageLabel.WORK_STANDARD_EDIT_EDIT_REQUIREMENT}
                onClick={() => {
                  setMarkdownHandler(workStandardEditEditRequirement);
                }}
              />
              <TreeItem
                nodeId="308"
                label={InstructionsPageLabel.WORK_STANDARD_EDIT_EDIT_PARTS}
                onClick={() => {
                  setMarkdownHandler(workStandardEditEditParts);
                }}
              />
            </TreeItem>
            <TreeItem nodeId="400" label={InstructionsPageLabel.WORK_STANDARD_CHANGE_NAME}>
              <TreeItem
                nodeId="401"
                label={InstructionsPageLabel.WORK_STANDARD_CHANGE_NAME_WORK_STANDARD_CHANGE_NAME}
                onClick={() => {
                  setMarkdownHandler(workStandardChangeNameWorkStandardChangeName);
                }}
              />
            </TreeItem>
            <TreeItem nodeId="500" label={InstructionsPageLabel.WORK_STANDARD_MOVE}>
              <TreeItem
                nodeId="501"
                label={InstructionsPageLabel.WORK_STANDARD_MOVE_WORK_STANDARD_MOVE}
                onClick={() => {
                  setMarkdownHandler(workStandardMoveWorkStandardMove);
                }}
              />
            </TreeItem>
            <TreeItem nodeId="600" label={InstructionsPageLabel.WORK_STANDARD_DELETE}>
              <TreeItem
                nodeId="601"
                label={InstructionsPageLabel.WORK_STANDARD_DELETE_WORK_STANDARD_DELETE}
                onClick={() => {
                  setMarkdownHandler(workStandardDeleteWorkStandardDelete);
                }}
              />
            </TreeItem>
            <TreeItem nodeId="700" label={InstructionsPageLabel.WORK_STANDARD_PUBLISH}>
              <TreeItem
                nodeId="701"
                label={InstructionsPageLabel.WORK_STANDARD_PUBLISH_APPLICATION}
                onClick={() => {
                  setMarkdownHandler(workStandardPublishApplication);
                }}
              />
              <TreeItem
                nodeId="702"
                label={InstructionsPageLabel.WORK_STANDARD_PUBLISH_CONFIRMER}
                onClick={() => {
                  setMarkdownHandler(workStandardPublishConfirmer);
                }}
              />
              <TreeItem
                nodeId="703"
                label={InstructionsPageLabel.WORK_STANDARD_PUBLISH_APPROVER}
                onClick={() => {
                  setMarkdownHandler(workStandardPublishApprover);
                }}
              />
              <TreeItem
                nodeId="704"
                label={InstructionsPageLabel.WORK_STANDARD_PUBLISH_PUBLISH}
                onClick={() => {
                  setMarkdownHandler(workStandardPublishPublish);
                }}
              />
              <TreeItem
                nodeId="705"
                label={InstructionsPageLabel.WORK_STANDARD_PUBLISH_CANCEL}
                onClick={() => {
                  setMarkdownHandler(workStandardPublishCancel);
                }}
              />
              <TreeItem
                nodeId="706"
                label={InstructionsPageLabel.WORK_STANDARD_PUBLISH_REJECT}
                onClick={() => {
                  setMarkdownHandler(workStandardPublishReject);
                }}
              />
              <TreeItem
                nodeId="707"
                label={InstructionsPageLabel.WORK_STANDARD_PUBLISH_MODIFY}
                onClick={() => {
                  setMarkdownHandler(workStandardPublishModify);
                }}
              />
            </TreeItem>
            <TreeItem nodeId="800" label={InstructionsPageLabel.WORK_STANDARD_REVISE}>
              <TreeItem
                nodeId="801"
                label={InstructionsPageLabel.WORK_STANDARD_REVISE_REVISE}
                onClick={() => {
                  setMarkdownHandler(workStandardReviseRevise);
                }}
              />
              <TreeItem
                nodeId="802"
                label={InstructionsPageLabel.WORK_STANDARD_REVISE_HISTORY_DETAIL}
                onClick={() => {
                  setMarkdownHandler(workStandardReviseHistoryDetail);
                }}
              />
            </TreeItem>
            <TreeItem nodeId="900" label={InstructionsPageLabel.WORK_STANDARD_PUBLISH_UNDER_REVISION}>
              <TreeItem
                nodeId="901"
                label={InstructionsPageLabel.WORK_STANDARD_PUBLISH_UNDER_REVISION_APPLICATION}
                onClick={() => {
                  setMarkdownHandler(workStandardPublishUnderRevisionApplication);
                }}
              />
              <TreeItem
                nodeId="902"
                label={InstructionsPageLabel.WORK_STANDARD_PUBLISH_UNDER_REVISION_APPROVER}
                onClick={() => {
                  setMarkdownHandler(workStandardPublishUnderRevisionApprover);
                }}
              />
              <TreeItem
                nodeId="903"
                label={InstructionsPageLabel.WORK_STANDARD_PUBLISH_UNDER_REVISION_PUBLISH}
                onClick={() => {
                  setMarkdownHandler(workStandardPublishUnderRevisionPublish);
                }}
              />
              <TreeItem
                nodeId="904"
                label={InstructionsPageLabel.WORK_STANDARD_PUBLISH_UNDER_REVISION_CANCEL}
                onClick={() => {
                  setMarkdownHandler(workStandardPublishUnderRevisionCancel);
                }}
              />
              <TreeItem
                nodeId="905"
                label={InstructionsPageLabel.WORK_STANDARD_PUBLISH_UNDER_REVISION_REJECT}
                onClick={() => {
                  setMarkdownHandler(workStandardPublishUnderRevisionReject);
                }}
              />
              <TreeItem
                nodeId="906"
                label={InstructionsPageLabel.WORK_STANDARD_PUBLISH_UNDER_REVISION_MODIFY}
                onClick={() => {
                  setMarkdownHandler(workStandardPublishUnderRevisionModify);
                }}
              />
            </TreeItem>
            <TreeItem nodeId="1000" label={InstructionsPageLabel.WORK_STANDARD_ARCHIVE}>
              <TreeItem
                nodeId="1001"
                label={InstructionsPageLabel.WORK_STANDARD_ARCHIVE_ARCHIVE_APPLICATION}
                onClick={() => {
                  setMarkdownHandler(workStandardArchiveArchiveApplication);
                }}
              />
              <TreeItem
                nodeId="1002"
                label={InstructionsPageLabel.WORK_STANDARD_ARCHIVE_ARCHIVE_APPROVE}
                onClick={() => {
                  setMarkdownHandler(workStandardArchiveArchiveApprove);
                }}
              />
              <TreeItem
                nodeId="1003"
                label={InstructionsPageLabel.WORK_STANDARD_ARCHIVE_ARCHIVE_REJECT}
                onClick={() => {
                  setMarkdownHandler(workStandardArchiveArchiveReject);
                }}
              />
            </TreeItem>
            <TreeItem nodeId="1100" label={InstructionsPageLabel.MANAGEMENT_BOOK_OPEN}>
              <TreeItem
                nodeId="1101"
                label={InstructionsPageLabel.MANAGEMENT_BOOK_OPEN_MANAGEMENT_BOOK_PAGE}
                onClick={() => {
                  setMarkdownHandler(managementBookOpenManagementBookPage);
                }}
              />
            </TreeItem>
            <TreeItem nodeId="1200" label={InstructionsPageLabel.MANAGEMENT_BOOK_EDIT}>
              <TreeItem
                nodeId="1201"
                label={InstructionsPageLabel.MANAGEMENT_BOOK_EDIT_MANAGEMENT_BOOK_CREATE}
                onClick={() => {
                  setMarkdownHandler(managementBookEditManagementBookCreate);
                }}
              />
              <TreeItem
                nodeId="1202"
                label={InstructionsPageLabel.MANAGEMENT_BOOK_EDIT_EDIT_MODE}
                onClick={() => {
                  setMarkdownHandler(managementBookEditEditMode);
                }}
              />
              <TreeItem
                nodeId="1203"
                label={InstructionsPageLabel.MANAGEMENT_BOOK_EDIT_WORK_STANDARD_IN_PROCESS}
                onClick={() => {
                  setMarkdownHandler(managementBookEditWorkStandardInProcess);
                }}
              />
              <TreeItem
                nodeId="1204"
                label={InstructionsPageLabel.MANAGEMENT_BOOK_EDIT_PROCESS}
                onClick={() => {
                  setMarkdownHandler(managementBookEditProcess);
                }}
              />
            </TreeItem>
            <TreeItem nodeId="1300" label={InstructionsPageLabel.MANAGEMENT_BOOK_PUBLISH}>
              <TreeItem
                nodeId="1301"
                label={InstructionsPageLabel.MANAGEMENT_BOOK_PUBLISH_APPLICATION}
                onClick={() => {
                  setMarkdownHandler(managementBookPublishApplication);
                }}
              />
              <TreeItem
                nodeId="1302"
                label={InstructionsPageLabel.MANAGEMENT_BOOK_PUBLISH_APPROVE}
                onClick={() => {
                  setMarkdownHandler(managementBookPublishApprove);
                }}
              />
              <TreeItem
                nodeId="1303"
                label={InstructionsPageLabel.MANAGEMENT_BOOK_PUBLISH_PUBLISH}
                onClick={() => {
                  setMarkdownHandler(managementBookPublishPublish);
                }}
              />
              <TreeItem
                nodeId="1304"
                label={InstructionsPageLabel.MANAGEMENT_BOOK_PUBLISH_CANCEL}
                onClick={() => {
                  setMarkdownHandler(managementBookPublishCancel);
                }}
              />
              <TreeItem
                nodeId="1305"
                label={InstructionsPageLabel.MANAGEMENT_BOOK_PUBLISH_REJECT}
                onClick={() => {
                  setMarkdownHandler(managementBookPublishReject);
                }}
              />
              <TreeItem
                nodeId="1306"
                label={InstructionsPageLabel.MANAGEMENT_BOOK_PUBLISH_MODIFY}
                onClick={() => {
                  setMarkdownHandler(managementBookPublishModify);
                }}
              />
            </TreeItem>
            <TreeItem nodeId="1400" label={InstructionsPageLabel.MANAGEMENT_BOOK_REVISE}>
              <TreeItem
                nodeId="1401"
                label={InstructionsPageLabel.MANAGEMENT_BOOK_REVISE_REVISE}
                onClick={() => {
                  setMarkdownHandler(managementBookReviseRevise);
                }}
              />
            </TreeItem>
            <TreeItem nodeId="1500" label={InstructionsPageLabel.DATA_MANAGEMENT}>
              <TreeItem
                nodeId="1501"
                label={InstructionsPageLabel.DATA_MANAGEMENT_MODEL}
                onClick={() => {
                  setMarkdownHandler(dataManagementModel);
                }}
              />
              <TreeItem
                nodeId="1502"
                label={InstructionsPageLabel.DATA_MANAGEMENT_UNIT}
                onClick={() => {
                  setMarkdownHandler(dataManagementUnit);
                }}
              />
              <TreeItem
                nodeId="1503"
                label={InstructionsPageLabel.DATA_MANAGEMENT_TEAM}
                onClick={() => {
                  setMarkdownHandler(dataManagementTeam);
                }}
              />
              <TreeItem
                nodeId="1504"
                label={InstructionsPageLabel.DATA_MANAGEMENT_PRODUCTION_CAPACITY}
                onClick={() => {
                  setMarkdownHandler(dataManagementProductionCapacity);
                }}
              />
              <TreeItem
                nodeId="1505"
                label={InstructionsPageLabel.DATA_MANAGEMENT_PRODUCTION_CAPACITY_CURRENTLY}
                onClick={() => {
                  setMarkdownHandler(dataManagementProductionCapacityCurrently);
                }}
              />
            </TreeItem>
            <TreeItem nodeId="1600" label={InstructionsPageLabel.USER_LIST}>
              <TreeItem
                nodeId="1601"
                label={InstructionsPageLabel.USER_LIST_VIEW}
                onClick={() => {
                  setMarkdownHandler(userListView);
                }}
              />
              <TreeItem
                nodeId="1602"
                label={InstructionsPageLabel.USER_LIST_REGISTER}
                onClick={() => {
                  setMarkdownHandler(userListRegister);
                }}
              />
              <TreeItem
                nodeId="1603"
                label={InstructionsPageLabel.USER_LIST_MODIFY}
                onClick={() => {
                  setMarkdownHandler(userListModify);
                }}
              />
              <TreeItem
                nodeId="1604"
                label={InstructionsPageLabel.USER_LIST_DELETE}
                onClick={() => {
                  setMarkdownHandler(userListDelete);
                }}
              />
            </TreeItem>
          </TreeView>
        </Grid>
        <Grid item xs={10}>
          <div
            id={"mdDiv"}
            className={classes.md}
            style={{ height: windowSizeContext.height - 90 }}
            dangerouslySetInnerHTML={{
              __html: marked(selectedMarkdown),
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
