import { useContext } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { createStyles, makeStyles, TextField, Theme } from "@material-ui/core";
import { LoginInfo } from "../../context/appContextData";
import React from "react";
import { ProcessLimits } from "../../consts/inputLimits";
import { grey } from "@material-ui/core/colors";
import { DialogButtonLabel, ManagementBookProcessListTabLabel } from "../../consts/label";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: theme.palette.secondary.main,
      color: "white",
      textAlign: "center",
      padding: 7,
    },
    cancel: {
      backgroundColor: grey[300],
      textAlign: "center",
      padding: 7,
    },
  })
);

export enum ProcessManagementBookDialogAction {
  create,
  edit,
  cancel,
}

export interface Props {
  open: boolean;
  isAdd: boolean;
  inputText: string;
  handleChangeText: (text: string) => void;
  handleDialogAction: (name: string, action: ProcessManagementBookDialogAction) => void;
}

export default function ProcessManagementBookDialog(props: Props) {
  const styles = useStyles();
  const LoginContext = useContext(LoginInfo);

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={props.open}>
      {/* ダイアログタイトル */}
      <DialogTitle style={{ textAlign: "center" }} id="simple-dialog-title">
        {ManagementBookProcessListTabLabel.PROCESS_DIALOG_TITLE}
      </DialogTitle>

      <List>
        {/* 工程名 */}
        <ListItem>
          <TextField
            size="small"
            label={ManagementBookProcessListTabLabel.PROCESS_NAME}
            value={props.inputText}
            inputProps={{ maxLength: ProcessLimits.NAME_MAX_LENGTH }}
            onChange={(e) => {
              props.handleChangeText(e.currentTarget.value);
            }}
          />
        </ListItem>

        {/* 登録者 */}
        <ListItem>
          <TextField disabled size="small" defaultValue={LoginContext.loginUser.user_name} label={ManagementBookProcessListTabLabel.REGISTER} />
        </ListItem>

        {/* 登録ボタン */}
        <ListItem
          button
          onClick={() => {
            if (props.isAdd) {
              props.handleDialogAction(props.inputText, ProcessManagementBookDialogAction.create);
            } else {
              props.handleDialogAction(props.inputText, ProcessManagementBookDialogAction.edit);
            }
          }}
        >
          <ListItemText className={styles.button} primary={DialogButtonLabel.REGIST} />
        </ListItem>

        {/* キャンセルボタン */}
        <ListItem
          button
          onClick={() => {
            props.handleDialogAction("", ProcessManagementBookDialogAction.cancel);
          }}
        >
          <ListItemText className={styles.cancel} primary={DialogButtonLabel.CANCEL} />
        </ListItem>
      </List>
    </Dialog>
  );
}
