import { useState } from "react";
import useSWR from "swr";
import { putS3, get } from "../apiCaller";
import { ApiMethod, ApiName, createEndPoint } from "../createEndPoint";
import { UploadSignedUrl } from "../domain/response/fileupload";

export const useUploadSignedUrl = (): [UploadSignedUrl, (key: string) => void, () => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get, {
    // ファイルを追加してさらに同じファイルを追加すると2回目の追加時にファイルが2枚アップされるため、
    // 再検証を無効化するオプション項目を追加
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });
  const getData = (key: string) => {
    setEndPoint(createEndPoint({ name: ApiName.UploadSignedUrl, method: ApiMethod.Get }, [key]));
  };
  const resetData = () => {
    setEndPoint(null);
  };
  const mutateData = () => {
    mutate(endPoint);
  };

  return [data, getData, resetData, mutateData, error];
};

export const putAws = (url: string, file: File) => {
  return putS3(url, file);
};
