//英数字のみチェック
export const isOnlyHalfAlphabetAndNumber = (text: string): boolean => {
  const regex = /^([a-zA-Z0-9])+$/;
  return text === "" || regex.test(text);
};

//数字のみチェック
export const isOnlyHalfNumber = (text: string): boolean => {
  const regex = /^([0-9])+$/;
  return text === "" || regex.test(text);
};

//記号チェック
export const isWithoutSign = (text: string): boolean => {
  const regex = /^([^!-/:-@[-`{-~！-／：-＠［-｀｛-～、-〜”’・❘])+$/;
  return text === "" || regex.test(text);
};

//記号チェック(半角のハイフンとアンダーバーはチェックから除外)
export const isWithoutSignExceptHyphenUnderbar = (text: string): boolean => {
  const regex = /^([^!-,./:-@[-^`{-~！-／：-＠［-｀｛-～、-〜”’・❘])+$/;
  return text === "" || regex.test(text);
};

//文字数範囲チェック
export const isLengthCorrect = (text: string, min: number, max: number): boolean => {
  return text.length >= min && text.length <= max;
};
