import { useContext, useEffect, useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { createStyles, makeStyles, TextField, Theme } from "@material-ui/core";
import ComboBox from "../Common/ComboBox";
import { postOperationInProcess } from "../../apicaller/repository/managementbook";
import { PublishedOperationDisplay } from "./display/operationManagementBook";
import { OperationInProcessRequest } from "../../apicaller/domain/request/managementbook";
import { Message } from "../../context/appContextData";
import { ErrorMessages, SuccessMessages, WarningMessages } from "../../consts/messages";
import React from "react";
import { grey } from "@material-ui/core/colors";
import { DialogButtonLabel, ManagementBookWorkStandardListTabLabel } from "../../consts/label";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: theme.palette.secondary.main,
      color: "white",
      textAlign: "center",
      padding: 7,
    },
    cancel: {
      backgroundColor: grey[300],
      textAlign: "center",
      padding: 7,
    },
  })
);

export enum OperationManagementBookDialogAction {
  create,
  cancel,
}

export type OperationManagementBookDialogTarget = {
  process: string;
  team_id: string;
  model_id: string;
  process_order: number;
  process_name: string;
  selectableOperation: PublishedOperationDisplay;
};

export interface Props {
  open: boolean;
  target: OperationManagementBookDialogTarget;
  handleDialogAction: (title: string, action: OperationManagementBookDialogAction) => void;
}

export default function OperationmanagementBookDialog(props: Props) {
  const styles = useStyles();
  const snackBarMessage = useContext(Message);

  const [operationName, setOperationName] = useState("");
  const [managementNum, setManagementNum] = useState("");

  const handleAddData = () => {
    if (managementNum === "") {
      snackBarMessage.setMessage(WarningMessages.OPERATION_BOOK_SELECTED_OPEREATION, "warning");
      return;
    }

    const operation_id =
      props.target.selectableOperation.operation_id[props.target.selectableOperation.managed_number.findIndex((row) => row === managementNum)];
    const tmp = new OperationInProcessRequest(Number(props.target.process), operation_id);
    postOperationInProcess(tmp).then(
      (data) => {
        props.handleDialogAction("", OperationManagementBookDialogAction.create);
        snackBarMessage.setMessage(SuccessMessages.INSERT_OPERATION_IN_PROCESS, "success");
      },
      (error) => {
        console.log(error);
        snackBarMessage.setMessage(ErrorMessages.INSERT_OPERATION_IN_PROCESS, "error");
      }
    );
  };

  useEffect(() => {
    // ダイアログ閉じる際にクリア
    if (!props.open) {
      setOperationName("");
      setManagementNum("");
    }
  }, [props.open]);

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={props.open}>
      {/* ダイアログタイトル */}
      <DialogTitle style={{ textAlign: "center" }} id="simple-dialog-title">
        {ManagementBookWorkStandardListTabLabel.WORK_STANDARD_DIALOG_TITLE}
      </DialogTitle>

      <List>
        {/* 工程名表示 */}
        <ListItem>
          <TextField disabled size="small" defaultValue={props.target.process_name} label={ManagementBookWorkStandardListTabLabel.PROCESS_NAME} />
        </ListItem>

        {/* 管理番号表示 */}
        <ListItem>
          <ComboBox
            label={ManagementBookWorkStandardListTabLabel.MANAGED_NUMBER}
            options={props.target.selectableOperation.managed_number}
            value={managementNum}
            onChange={(value: any) => {
              if (value === null) {
                setOperationName("");
                setManagementNum("");
              } else {
                setOperationName(
                  props.target.selectableOperation.operation_name[props.target.selectableOperation.managed_number.findIndex((row) => row === value)]
                );
                setManagementNum(value);
              }
            }}
          />
        </ListItem>

        {/* 作業標準表示 */}
        <ListItem>
          <ComboBox
            label={ManagementBookWorkStandardListTabLabel.WORK_STANDARD_NAME}
            options={props.target.selectableOperation.operation_name}
            value={operationName}
            onChange={(value: any) => {
              if (value === null) {
                setOperationName("");
                setManagementNum("");
              } else {
                setOperationName(value);
                setManagementNum(
                  props.target.selectableOperation.managed_number[props.target.selectableOperation.operation_name.findIndex((row) => row === value)]
                );
              }
            }}
          />
        </ListItem>

        {/* 登録ボタン */}
        <ListItem
          button
          onClick={() => {
            handleAddData();
          }}
        >
          <ListItemText className={styles.button} primary={DialogButtonLabel.REGIST} />
        </ListItem>

        {/* キャンセルボタン */}
        <ListItem
          button
          onClick={() => {
            props.handleDialogAction("", OperationManagementBookDialogAction.cancel);
          }}
        >
          <ListItemText className={styles.cancel} primary={DialogButtonLabel.CANCEL} />
        </ListItem>
      </List>
    </Dialog>
  );
}
