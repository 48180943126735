import { ApiBody } from "../../utils/schemaUtil";
import { post, put } from "../apiCaller";
import { createEndPoint, ApiName, ApiMethod } from "../createEndPoint";
import { ManagementBookIdRequest } from "../domain/request/workflow";
import { AxiosResponse } from "axios";
import * as $axios from "../../utils/axiosUtils";

// 管理台帳の引き戻し
export const putManagementBookCancelRequest = (body: ManagementBookIdRequest) => {
  const bodyJson = JSON.stringify(body);
  return put(createEndPoint({ name: ApiName.ManagementBookWorkFlowCancel, method: ApiMethod.Put }), bodyJson);
};

// 管理台帳の否認
export const putManagementBookRejectRequest = (body: ApiBody<"/management_book_work_flow/reject", "put">): Promise<AxiosResponse> => {
  return $axios.request({
    url: "/management_book_work_flow/reject",
    method: "put",
    data: body,
  });
};

// 管理台帳の修正
export const putManagementBookModifyRequest = (body: ManagementBookIdRequest) => {
  const bodyJson = JSON.stringify(body);
  return put(createEndPoint({ name: ApiName.ManagementBookWorkFlowModify, method: ApiMethod.Put }), bodyJson);
};

// 管理台帳の改訂
export const postManagementBookReviseRequest = (body: ManagementBookIdRequest) => {
  const bodyJson = JSON.stringify(body);
  return post(createEndPoint({ name: ApiName.ManagementBookWorkFlowRevise, method: ApiMethod.Post }), bodyJson);
};
