import { SelectedUserInfo } from "../../../components/UserList/display/userList";

export interface Authentification {}

export class UserLogin {
  login_id: string = "";
  password: string = "";
}

export class UserSignUp extends UserLogin {
  user_name: string = "";
  authority: number = 0;
  module_role: ModuleRole[];
  unit_role: UnitRole[];
  team_role: TeamRole[];

  constructor(
    login_id: string,
    password: string,
    authority: number,
    user_name: string,
    module_role: ModuleRole[],
    unit_role: UnitRole[],
    team_role: TeamRole[]
  ) {
    super();

    this.login_id = login_id;
    this.password = password;
    this.authority = authority;
    this.user_name = user_name;
    this.module_role = module_role;
    this.unit_role = unit_role;
    this.team_role = team_role;
  }
}

export type ModuleRole = {
  module_id: number;
  role: "ASSCOCIATE" | "MODULE_MANAGER";
};
export type UnitRole = {
  unit_id: number;
  role: "ASSCOCIATE" | "UNIT_LEADER";
};
export type TeamRole = {
  team_id: number;
  role: "ASSCOCIATE" | "TEAM_LEADER";
};

export class UserAuthorityUpdateRequest implements Authentification {
  user_id: number;
  authority: number;

  constructor(user_id: number, authority: number) {
    this.user_id = user_id;
    this.authority = authority;
  }
}

export class UserAttachUpdateRequest {
  user_id: number;
  user_name: string;
  login_id: string;

  constructor(user_id: number, user_name: string, login_id: string) {
    this.user_id = user_id;
    this.user_name = user_name;
    this.login_id = login_id;
  }
}

export class UserAttachMasterUpdateRequest {
  user_id: number;
  user_name: string;
  login_id: string;
  authority: number;
  line_id: number;
  unit_id: number;
  team_id: number;

  constructor(selectedUserInfo: SelectedUserInfo) {
    this.user_id = selectedUserInfo.user_id;
    this.user_name = selectedUserInfo.name;
    this.login_id = selectedUserInfo.id;
    this.authority = selectedUserInfo.authority;
    this.line_id = selectedUserInfo.lineId;
    this.unit_id = selectedUserInfo.unitId;
    this.team_id = selectedUserInfo.teamId;
  }
}
