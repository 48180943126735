import { useContext, useEffect, useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { createStyles, makeStyles, TextField, Theme } from "@material-ui/core";
import { LoginInfo, Message } from "../../context/appContextData";
import React from "react";
import { postHistoryDetail, putHistoryDetail } from "../../apicaller/repository/workstandard";
import { PostHistoryDetailRequest, PutHistoryDetailRequest } from "../../apicaller/domain/request/workstandard";
import ListBox, { ListOption } from "../Common/ListBox";
import { WorkStandardHistoryDetailTarget, WorkStandardHistoryDetailTargetList } from "../../consts/workStandardHistoryDetailTarget";
import { ErrorMessages, SuccessMessages, WarningMessages } from "../../consts/messages";
import { HistoryDetailLimits } from "../../consts/inputLimits";
import { WorkStandardHistoryDetailDisplay } from "./display/home";
import { grey } from "@material-ui/core/colors";
import { DialogButtonLabel, WorkStandardHistoryDetailTabLabel } from "../../consts/label";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    registration: {
      backgroundColor: theme.palette.secondary.main,
      color: "white",
      textAlign: "center",
      padding: 7,
    },
    cancel: {
      backgroundColor: grey[300],
      textAlign: "center",
      padding: 7,
    },
    dialog: {
      width: "500px",
    },
  })
);

export interface Props {
  workStandardId: number;
  workStandardHistoryDetail?: WorkStandardHistoryDetailDisplay;
  open: boolean;
  isEdit: boolean;
  handleDialogClose: () => void;
}

export default function HistoryDetailDialog(props: Props) {
  const styles = useStyles();
  const LoginContext = useContext(LoginInfo);
  const snackBarMessage = useContext(Message);
  const [selectedTarget, setSelectedTarget] = useState<ListOption>(null);
  const [detail, setDetail] = useState("");

  const closeDialogHandler = () => {
    props.handleDialogClose();
  };

  const registrationHandler = () => {
    // 項目の選択チェック
    if (!selectedTarget) {
      snackBarMessage.setMessage(WarningMessages.HISTORY_DETAIL_TARGET, "warning");
      return;
    }

    // 変更箇所の空チェック
    if (detail === "") {
      snackBarMessage.setMessage(WarningMessages.HISTORY_DETAIL_INPUT_LENGTH, "warning");
      return;
    }

    // 選択された項目をDB登録値(英名)に変換
    const target = Object.keys(WorkStandardHistoryDetailTarget).find((key) => {
      return WorkStandardHistoryDetailTarget[key] === selectedTarget.value;
    });
    if (!target) return;

    if (props.isEdit) {
      putHistoryDetail(new PutHistoryDetailRequest(props.workStandardHistoryDetail.id, target, detail)).then(
        () => {
          snackBarMessage.setMessage(SuccessMessages.UPDATE_HISTORY_DETAIL, "success");
          props.handleDialogClose();
        },
        (err) => {
          console.error(err);
          snackBarMessage.setMessage(ErrorMessages.UPDATE_HISTORY_DETAIL, "error");
        }
      );
    } else {
      postHistoryDetail(new PostHistoryDetailRequest(props.workStandardId, target, detail)).then(
        () => {
          snackBarMessage.setMessage(SuccessMessages.INSERT_HISTORY_DETAIL, "success");
          props.handleDialogClose();
        },
        (err) => {
          console.error(err);
          snackBarMessage.setMessage(ErrorMessages.INSERT_HISTORY_DETAIL, "error");
        }
      );
    }
  };

  useEffect(() => {
    if (props.open) {
      // ダイアログを開く際に入力内容をセット
      setSelectedTarget(WorkStandardHistoryDetailTargetList.find((target) => target.value === props.workStandardHistoryDetail.target));
      setDetail(props.workStandardHistoryDetail.detail);
    } else {
      // ダイアログ閉じる際に入力内容クリア
      setSelectedTarget(null);
      setDetail("");
    }
  }, [props.open]);

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={props.open} fullWidth maxWidth={"md"}>
      {/* ダイアログタイトル */}
      <DialogTitle style={{ textAlign: "center" }} id="simple-dialog-title">
        {WorkStandardHistoryDetailTabLabel.HISTORY_DETAIL_DIALOG_TITLE}
      </DialogTitle>
      <List>
        {/* 項目 */}
        <ListItem>
          <ListBox
            label={WorkStandardHistoryDetailTabLabel.TARGET}
            options={WorkStandardHistoryDetailTargetList}
            value={selectedTarget}
            onChange={(e: any) => {
              setSelectedTarget(e);
            }}
          />
        </ListItem>

        {/* 変更箇所 */}
        <ListItem>
          <TextField
            fullWidth
            multiline
            label={WorkStandardHistoryDetailTabLabel.DETAIL}
            value={detail}
            onChange={(e: any) => {
              setDetail(e.currentTarget.value);
            }}
            inputProps={{ maxLength: HistoryDetailLimits.INPUT_MAX_LENGTH }}
          />
        </ListItem>

        {/* 担当者 */}
        <ListItem>
          <TextField disabled fullWidth defaultValue={LoginContext.loginUser.user_name} label={WorkStandardHistoryDetailTabLabel.CHARGE_PERSON} />
        </ListItem>

        {/* 登録ボタン */}
        <ListItem button onClick={registrationHandler}>
          <ListItemText className={styles.registration} primary={DialogButtonLabel.REGIST} />
        </ListItem>

        {/* キャンセルボタン */}
        <ListItem button onClick={closeDialogHandler}>
          <ListItemText className={styles.cancel} primary={DialogButtonLabel.CANCEL} />
        </ListItem>
      </List>
    </Dialog>
  );
}
