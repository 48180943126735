import React from "react";
import { Divider, Tooltip, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { WorkStandardManagementTabLabel } from "../../consts/label";

interface Props {
  userName: string;
  loginId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textDiv: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "calc(100% - 40px)",
    },
    text: {
      textAlign: "center",
      fontSize: 20,
    },
  })
);

export default function CreaterTable(props: Props) {
  const styles = useStyles();

  return (
    <>
      <Typography className={"typography-head"}>{WorkStandardManagementTabLabel.CREATOR}</Typography>
      <Divider />
      <div className={styles.textDiv}>
        <Typography className={styles.text}>
          <Tooltip title={props.userName + " (" + props.loginId + ")"}>
            <div>
              {props.userName}
              {<br />}
              ({props.loginId})
            </div>
          </Tooltip>
        </Typography>
      </div>
    </>
  );
}
