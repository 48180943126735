import React from "react";
import ComboBox from "./ComboBox";
import Grid from "@material-ui/core/Grid";
import { FactoryDisplayComboBox, LineDisplayComboBox, PlantDisplayComboBox, TeamDisplayComboBox, UnitDisplayComboBox } from "./display/display";
import { Label } from "../../consts/label";

interface Props {
  factoryDisplayComboBox: FactoryDisplayComboBox;
  plantDisplayComboBox: PlantDisplayComboBox;
  lineDisplayComboBox: LineDisplayComboBox;
  unitDisplayComboBox: UnitDisplayComboBox;
  teamDisplayComboBox: TeamDisplayComboBox;
  selectedFactoryItem: string;
  selectedPlantItem: string;
  selectedLineItem: string;
  selectedUnitItem: string;
  selectedTeamItem: string;
  handleSelectFactory: (value: string) => void;
  handleSelectPlant: (value: string) => void;
  handleSelectModule: (value: string) => void;
  handleSelectUnit: (value: string) => void;
  handleSelectTeam: (value: string) => void;
}

export default function SelectArea(props: Props) {
  let factoryOptions: string[] = [];
  let plantOptions: string[] = [];

  if (props.factoryDisplayComboBox !== undefined && props.selectedFactoryItem !== undefined) {
    factoryOptions = props.factoryDisplayComboBox.text;
  }

  if (props.plantDisplayComboBox !== undefined && props.selectedPlantItem !== undefined) {
    plantOptions = props.plantDisplayComboBox.text;
  }

  return (
    <Grid container spacing={0}>
      <Grid item sm={2}>
        <ComboBox
          disable
          label={Label.FACTORY}
          options={factoryOptions}
          value={props.selectedFactoryItem}
          onChange={(value: string) => (props.handleSelectFactory ? props.handleSelectFactory(value) : undefined)}
          />
      </Grid>
      <Grid item sm={2}>
        <ComboBox
          disable
          label={Label.PLANT}
          options={plantOptions}
          value={props.selectedPlantItem}
          onChange={(value: string) => (props.handleSelectPlant ? props.handleSelectPlant(value) : undefined)}
        />
      </Grid>
      <Grid item sm={2}>
        <ComboBox
          disable={!(props.lineDisplayComboBox ? props.lineDisplayComboBox.text : []).length}
          label={Label.MODULE}
          options={props.lineDisplayComboBox ? props.lineDisplayComboBox.text : []}
          value={props.selectedLineItem}
          onChange={(value: string) => (props.handleSelectModule ? props.handleSelectModule(value) : undefined)}
        />
      </Grid>
      <Grid item sm={2}>
        <ComboBox
          disable={!(props.unitDisplayComboBox ? props.unitDisplayComboBox.text : []).length}
          label={Label.UNIT}
          options={props.unitDisplayComboBox ? props.unitDisplayComboBox.text : []}
          value={props.selectedUnitItem}
          onChange={(value: string) => (props.handleSelectUnit ? props.handleSelectUnit(value) : undefined)}
        />
      </Grid>
      <Grid item sm={2}>
        <ComboBox
          disable={!(props.teamDisplayComboBox ? props.teamDisplayComboBox.text : []).length}
          label={Label.TEAM}
          options={props.teamDisplayComboBox ? props.teamDisplayComboBox.text : []}
          value={props.selectedTeamItem}
          onChange={(value: string) => (props.handleSelectTeam ? props.handleSelectTeam(value) : undefined)}
        />
      </Grid>
    </Grid>
  );
}
