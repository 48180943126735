import { useState } from "react";
import useSWR from "swr";
import { get, post, put, fetchDelete, patch } from "../apiCaller";
import { ApiMethod, ApiName, createEndPoint } from "../createEndPoint";
import {
  ApproverList,
  ConfirmerList,
  OperationCardList,
  OperationPointList,
  HistoryList,
  OperationSignatureList,
  IllustrationList,
  CanOperatorSign,
  OperationHashItemList,
  OperationHashMasterList,
} from "../domain/response/operation";
import {
  OperationRegistRequest,
  EquipmentRequest,
  SafetyPointRegistRequest,
  SafetyPointPutRequest,
  PartsRegistRequest,
  PartsUpdateRequest,
  OperationCardUpdateRequest,
  OperationPointRegistRequest,
  OperationPointUpdateContentRequest,
  HistoryRegistRequest,
  IllustrationPageRegistRequest,
  OperationPointUpdateLinkRequest,
  OperatorSignatureRequest,
  OperationCardRegistRequest,
  OperationHashItemRegistRequest,
  RequirementRegistRequest,
} from "../domain/request/operation";
import { Operation, SafetyEquipmentList, RequirementList, SafetyPointList, PartsList } from "../domain/response/operation";
import { ApiBody } from "../../utils/schemaUtil";
import { AxiosResponse } from "axios";
import * as $axios from "../../utils/axiosUtils";

// Post Operation
export const postOperation = (body: OperationRegistRequest): Promise<any> => {
  const bodyJson = JSON.stringify(body);
  return post(createEndPoint({ name: ApiName.Operation, method: ApiMethod.Post }), bodyJson);
};

// Get Equipment
export const useEquipment = (): [SafetyEquipmentList, (operationId: string) => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get);
  const getData = (operationId: string) => {
    setEndPoint(createEndPoint({ name: ApiName.Equipment, method: ApiMethod.Get }, [operationId]));
  };
  const mutateData = () => {
    mutate(endPoint);
  };
  return [data, getData, mutateData, error];
};

export const postEquipment = (body: EquipmentRequest): Promise<any> => {
  const bodyJson = JSON.stringify(body);
  return post(createEndPoint({ name: ApiName.Equipment, method: ApiMethod.Post }), bodyJson);
};

export const deleteOperationEquipment = (equpimentNecessityId: string): Promise<any> => {
  return fetchDelete(createEndPoint({ name: ApiName.Equipment, method: ApiMethod.Delete }, [equpimentNecessityId]));
};

// Get Requirement
export const useRequirement = (): [RequirementList, (operation_id: string) => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get);
  const getData = (operation_id: string) => {
    setEndPoint(createEndPoint({ name: ApiName.Requirement, method: ApiMethod.Get }, [operation_id]));
  };
  const mutateData = () => {
    mutate(endPoint);
  };

  return [data, getData, mutateData, error];
};

// Post Requirement
export const postRequirement = (body: RequirementRegistRequest): Promise<any> => {
  const bodyJson = JSON.stringify(body);
  return post(createEndPoint({ name: ApiName.Requirement, method: ApiMethod.Post }), bodyJson);
};

// Delete Requirement
export const deleteRequirement = (requirementNecessityId: string): Promise<any> => {
  return fetchDelete(createEndPoint({ name: ApiName.Requirement, method: ApiMethod.Delete }, [requirementNecessityId]));
};

//Get Operation
export const useOperation = (): [Operation, (operationId: string) => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get, { refreshInterval: 1000 });
  const getData = (operationId: string) => {
    setEndPoint(createEndPoint({ name: ApiName.Operation, method: ApiMethod.Get }, [operationId]));
  };
  const mutateData = () => {
    mutate(endPoint);
  };
  return [data, getData, mutateData, error];
};

//---APPROVER---
export const useApprover = (): [ApproverList, (operationId: string) => void, () => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get);
  const getData = (operationId: string) => {
    setEndPoint(createEndPoint({ name: ApiName.ApproverList, method: ApiMethod.Get }, [operationId]));
  };
  const resetData = () => {
    setEndPoint(null);
  };
  const mutateData = () => {
    mutate(endPoint);
  };
  return [data, getData, resetData, mutateData, error];
};

//---CONFIRMER---
export const useConfirmer = (): [ConfirmerList, (operationId: string) => void, () => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get);
  const getData = (operationId: string) => {
    setEndPoint(createEndPoint({ name: ApiName.Confirmer_list, method: ApiMethod.Get }, [operationId]));
  };
  const resetData = () => {
    setEndPoint(null);
  };
  const mutateData = () => {
    mutate(endPoint);
  };
  return [data, getData, resetData, mutateData, error];
};

// Get SafetyPoint
export const useSafetyPoint = (): [SafetyPointList, (operation_id: string) => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get);
  const getData = (operation_id: string) => {
    setEndPoint(createEndPoint({ name: ApiName.SafetyPointList }, [operation_id]));
  };
  const mutateData = () => {
    mutate(endPoint);
  };

  return [data, getData, mutateData, error];
};

// Post SafetyPoint
export const postSafetyPoint = (body: SafetyPointRegistRequest): Promise<any> => {
  const bodyJson = JSON.stringify(body);
  return post(createEndPoint({ name: ApiName.SafetyPoint, method: ApiMethod.Post }), bodyJson);
};

// Put SafetyPoint
export const putSafetyPoint = (body: SafetyPointPutRequest) => {
  const bodyJson = JSON.stringify(body);
  return put(createEndPoint({ name: ApiName.SafetyPoint, method: ApiMethod.Put }), bodyJson);
};

// Delete Requirement
export const deleteSafetyPoint = (id: number): Promise<any> => {
  return fetchDelete(createEndPoint({ name: ApiName.SafetyPoint, method: ApiMethod.Delete }, [String(id)]));
};

// Get Parts
export const useParts = (): [PartsList, (operation_id: string) => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get);
  const getData = (operation_id: string) => {
    setEndPoint(createEndPoint({ name: ApiName.Parts, method: ApiMethod.Get }, [operation_id]));
  };
  const mutateData = () => {
    mutate(endPoint);
  };

  return [data, getData, mutateData, error];
};

// Post Parts
export const postParts = (body: PartsRegistRequest): Promise<any> => {
  const bodyJson = JSON.stringify(body);
  return post(createEndPoint({ name: ApiName.Parts, method: ApiMethod.Post }), bodyJson);
};

// Put Parts
export const putParts = (body: PartsUpdateRequest): Promise<any> => {
  const bodyJson = JSON.stringify(body);
  return put(createEndPoint({ name: ApiName.Parts, method: ApiMethod.Put }), bodyJson);
};

// Delete Parts
export const deleteParts = (id: string): Promise<any> => {
  return fetchDelete(createEndPoint({ name: ApiName.Parts, method: ApiMethod.Delete }, [id]));
};

// Get History
export const useHistory = (): [HistoryList, (operation_id: string) => void, () => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get);
  const getData = (operation_id: string) => {
    setEndPoint(createEndPoint({ name: ApiName.HistoryList, method: ApiMethod.Get }, [operation_id]));
  };
  const resetData = () => {
    setEndPoint(null);
  };
  const mutateData = () => {
    mutate(endPoint);
  };

  return [data, getData, resetData, mutateData, error];
};

// Post History
export const postHistory = (body: HistoryRegistRequest): Promise<any> => {
  const bodyJson = JSON.stringify(body);
  return post(createEndPoint({ name: ApiName.History, method: ApiMethod.Post }), bodyJson);
};

// Get management book contains work standard
export const useCanOperatorSign = (): [CanOperatorSign, (operationId: string) => void, () => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get);
  const getData = (operationId: string) => {
    setEndPoint(createEndPoint({ name: ApiName.CanOperatorSign, method: ApiMethod.Get }, [operationId]));
  };
  const resetData = () => {
    setEndPoint(null);
  };
  const mutateData = () => {
    mutate(endPoint);
  };
  return [data, getData, resetData, mutateData, error];
};

// Get OperationSignature
export const useOperationSignature = (): [OperationSignatureList, (operationId: string) => void, () => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get);
  const getData = (operationId: string) => {
    setEndPoint(createEndPoint({ name: ApiName.OperatorSignatureList, method: ApiMethod.Get }, [operationId]));
  };
  const resetData = () => {
    setEndPoint(null);
  };
  const mutateData = () => {
    mutate(endPoint);
  };
  return [data, getData, resetData, mutateData, error];
};

// Post Operator Signature
export const postOperatorSignature = (body: OperatorSignatureRequest) => {
  const bodyJson = JSON.stringify(body);
  return post(createEndPoint({ name: ApiName.OperatorSignature, method: ApiMethod.Post }), bodyJson);
};

// Get OperationCard
export const useOperationCard = (): [OperationCardList, (operation_id: string) => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get);
  const getData = (operation_id: string) => {
    setEndPoint(createEndPoint({ name: ApiName.OperationCardList, method: ApiMethod.Get }, [operation_id]));
  };
  const mutateData = () => {
    mutate(endPoint);
  };

  return [data, getData, mutateData, error];
};

// Put OperationCard
export const postOperationCard = (body: OperationCardRegistRequest) => {
  const bodyJson = JSON.stringify(body);
  return post(createEndPoint({ name: ApiName.OperationCard, method: ApiMethod.Post }), bodyJson);
};

export const putOperationCard = (body: OperationCardUpdateRequest): Promise<any> => {
  const bodyJson = JSON.stringify(body);
  return put(createEndPoint({ name: ApiName.OperationCard, method: ApiMethod.Put }), bodyJson);
};

export const deleteOperationCard = (operation_card_id: string) => {
  return fetchDelete(createEndPoint({ name: ApiName.OperationCard, method: ApiMethod.Delete }, [operation_card_id]));
};

// Get OperationPoint
export const useOperationPoint = (): [OperationPointList, (operation_card_id: string) => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get);
  const getData = (operation_card_id: string) => {
    setEndPoint(createEndPoint({ name: ApiName.OperationPointList, method: ApiMethod.Get }, [operation_card_id]));
  };
  const mutateData = () => {
    mutate(endPoint);
  };

  return [data, getData, mutateData, error];
};

export const useOperationHashItem = (): [OperationHashItemList, (operation_card_id: string) => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get);
  const getData = (operation_card_id: string) => {
    setEndPoint(createEndPoint({ name: ApiName.OperationHashItemList, method: ApiMethod.Get }, [operation_card_id]));
  };
  const mutateData = () => {
    mutate(endPoint);
  };

  return [data, getData, mutateData, error];
};

export const useOperationHashMaster = (): [OperationHashMasterList, () => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get);
  const getData = () => {
    setEndPoint(createEndPoint({ name: ApiName.OperationHashMasterList, method: ApiMethod.Get }));
  };
  const mutateData = () => {
    mutate(endPoint);
  };

  return [data, getData, mutateData, error];
};

export const deleteOperationHashItem = (operationHashItemID: string): Promise<any> => {
  return fetchDelete(createEndPoint({ name: ApiName.OperationHashItem, method: ApiMethod.Delete }, [operationHashItemID]));
};

export const postOperationHashItem = (body: OperationHashItemRegistRequest): Promise<any> => {
  const bodyJson = JSON.stringify(body);
  return post(createEndPoint({ name: ApiName.OperationHashItem, method: ApiMethod.Post }), bodyJson);
};

// Post OperationPoint
export const postOperationPoint = (body: OperationPointRegistRequest): Promise<any> => {
  const bodyJson = JSON.stringify(body);
  return post(createEndPoint({ name: ApiName.OperationPoint, method: ApiMethod.Post }), bodyJson);
};

// Put OperationPoint Content
export const putOperationPointContent = (body: OperationPointUpdateContentRequest): Promise<any> => {
  const bodyJson = JSON.stringify(body);
  return put(createEndPoint({ name: ApiName.OperationPoint, method: ApiMethod.Put }), bodyJson);
};

// Put OperationPoint Link
export const putOperationPointLink = (body: OperationPointUpdateLinkRequest): Promise<any> => {
  const bodyJson = JSON.stringify(body);
  return put(createEndPoint({ name: ApiName.OperationPointLink, method: ApiMethod.Put }), bodyJson);
};

// patch OperationPoint Link
export const patchOperationPointLink = (operation_point_id: string): Promise<any> => {
  return patch(createEndPoint({ name: ApiName.OperationPointLink, method: ApiMethod.Patch }, [operation_point_id]), "");
};

// Delete OperationPoint
export const deleteOperationPoint = (operationPointId: string): Promise<any> => {
  return fetchDelete(createEndPoint({ name: ApiName.OperationPoint, method: ApiMethod.Delete }, [operationPointId]));
};

export const postOperationPage = (body: IllustrationPageRegistRequest) => {
  const bodyJson = JSON.stringify(body);
  return post(createEndPoint({ name: ApiName.IllustrationPage, method: ApiMethod.Post }), bodyJson);
};

export const deleteOperationPage = (illustration_page_id: string) => {
  return fetchDelete(createEndPoint({ name: ApiName.IllustrationPage, method: ApiMethod.Delete }, [illustration_page_id]));
};

//---Illustration---
export const useIllustration = (): [IllustrationList, (operation_id: string) => void, () => void, () => void, string] => {
  const [endPoint, setEndPoint] = useState<string | null>(null);
  const { data, mutate, error } = useSWR(endPoint, get);
  const getData = (operation_id: string) => {
    setEndPoint(createEndPoint({ name: ApiName.IllustrationList, method: ApiMethod.Get }, [operation_id]));
  };
  const resetData = () => {
    setEndPoint(null);
  };
  const mutateData = () => {
    mutate(endPoint);
  };

  return [data, getData, resetData, mutateData, error];
};

export const getSignedUrlForPut = async (s3key: string): Promise<string> => {
  const result = await get(createEndPoint({ name: ApiName.UploadSignedUrl, method: ApiMethod.Get }, [s3key]));
  return result.url;
};

export const getSignedUrlForGet = async (s3key: string): Promise<string> => {
  const result = await get(createEndPoint({ name: ApiName.ViewSignedUrl, method: ApiMethod.Get }, [s3key]));
  return result.url;
};

export const putChangeWorkStandardName = (body: ApiBody<"/operation/operation_name", "put">): Promise<AxiosResponse> => {
  return $axios.request({
    url: "/operation/operation_name",
    method: "put",
    data: body,
  });
};
