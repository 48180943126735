import {
  Avatar,
  Button,
  Card,
  Chip,
  createStyles,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import ChipPoint from "../../icons/ChipPoint.png";
import ChipQualityCharacter from "../../icons/ChipQualityCharacteristics.png";
import ChipEventOccurring from "../../icons/ChipEventOccurring.png";
import ChipTools from "../../icons/ChipTools.png";
import ChipTrouble from "../../icons/ChipTrouble.png";
import Divider from "@material-ui/core/Divider";
import { EditInfo, Message } from "../../context/appContextData";
import { useGetElementProperty } from "../../hooks/getElementProperty";
import {
  deleteOperationCard,
  deleteOperationPage,
  getSignedUrlForPut,
  postOperationCard,
  postOperationPage,
  putOperationCard,
  useOperation,
  useOperationCard,
} from "../../apicaller/repository/operation";
import { DialogMessages, ErrorMessages, SuccessMessages, WarningMessages } from "../../consts/messages";
import { OperationCard } from "../../apicaller/domain/response/operation";
import { IllustrationPageRegistRequest, OperationCardRegistRequest, OperationCardUpdateRequest } from "../../apicaller/domain/request/operation";
import { WorkStandardCardLimits } from "../../consts/inputLimits";
import { sortByAsc } from "../../function/sort";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import InputBase from "@material-ui/core/InputBase";
import { v4 } from "uuid";
import illustration_template from "../../context/illustration_template.json";
import { putAws } from "../../apicaller/repository/fileupload";
import { OperationCardDisplay, OperationCardDisplayGroup, OperationPageDisplay } from "../Home/display/home";
import BasicChipGroup from "../Home/BasicChipGroup";
import CompOperationHashItemChipGroup from "../Home/CompOperationHashItemChipGroup";
import { OperationChipGroup } from "./OperationChipGroup";
import PartsTable from "./PartsTable";
import CompSafetyEquipmentChipGroup from "../Home/CompSafetyEquipmentChipGroup";
import RequirementChipGroup from "../Home/RequirementChipGroup";
import { MoveableBoard } from "./MoveableBoard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Navigation, Pagination } from "swiper/modules";
import { purple } from "@material-ui/core/colors";
import { useTheme } from "@material-ui/core/styles";
import { revision } from "../../consts/firstRevision";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../../styles/Swiper.css";
import { putWorkPersonSeconds } from "../../apicaller/repository/workstandard";
import { isLengthCorrect, isOnlyHalfNumber } from "../../function/validationCheck";
import { WorkStandardWorkTabLabel } from "../../consts/label";

interface Props {
  contentHeight: number;
  operationId: string;
  revisionNumber: number;
  cardWidth: number | undefined;
  modelId: number;
  workStandardName: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    //---右部分---
    operation: {
      overflowX: "hidden",
    },
    chip: {
      fontSize: 18,
      borderRadius: 5,
      marginRight: 10,
      marginBottom: 10,
    },
    selectedCardDiv: {
      border: "3px solid " + theme.palette.secondary.dark,
      borderRadius: 5,
    },
    cardHeaderDiv: {
      width: "100%",
      display: "flex",
      backgroundColor: purple[50],
      overflowWrap: "anywhere",
      alignItems: "center",
    },
    titleNo: {
      backgroundColor: theme.palette.secondary.main,
      width: "max-content",
      paddingInline: 10,
      color: "white",
      borderRadius: 5,
      whiteSpace: "nowrap",
      marginLeft: 5,
    },
    //カードタイトル(VIEWモード)
    title: {
      fontSize: 18,
      cursor: "pointer",
      marginLeft: 10,
      marginRight: 10,
      minHeight: "35px",
      display: "flex",
      alignItems: "center",
      flexGrow: 1,
    },
    //カードタイトル(編集モード)
    edittitle: {
      fontSize: 18,
      marginLeft: 10,
      marginRight: 10,
      flexGrow: 1,
    },
    //カード削除ボタン
    deleteBtn: {
      height: "35px",
    },
    personSecondsChip: {
      marginRight: 5,
    },
    //チップ部分
    chipGroup: {
      maxWidth: "100%",
    },
    //カード追加ボタン
    addBtn: {
      textAlign: "center",
    },
    typeChipGroup: {
      flexGrow: 1,
      paddingLeft: 0,
      minHeight: "60px",
    },
  })
);

export const WorkTab = (props: Props) => {
  const styles = useStyles();
  const theme = useTheme();
  const cardContainerRef = useRef(null);
  const EditContext = useContext(EditInfo);
  const snackBarMessage = useContext(Message);
  const [workStandard, getWorkStandard, mutateWorkStandard, workStandardError] = useOperation();
  const [operationCardList, getOperationCardList, mutateOperationCardList, OperationCardListError] = useOperationCard();

  const [operationCardDisplayList, setOperationCardDisplay] = useState<OperationCardDisplayGroup>(new OperationCardDisplayGroup());
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);
  const [displayPageList, setDisplayPageList] = useState<OperationPageDisplay>(new OperationPageDisplay());
  const [selectedPage, setSelectedPage] = useState(0);
  const [showMainIllustration, setShowMainIllustration] = useState(true);

  const swiperRef = useRef(null);
  const labelRef = useRef(null);
  const labelHeight = useGetElementProperty(labelRef).getElementProperty("height");

  const voiceFunc = (readingMsg: string) => {
    var msg = new SpeechSynthesisUtterance();
    msg.text = readingMsg;
    msg.lang = "ja-JP";
    window.speechSynthesis.speak(msg);
  };

  //#region ---CHIP---
  //Chipが押下される時
  const handleShowLinkedPage = (operation_card_id: number, page: number) => {
    const cardToJump = operationCardDisplayList.operationCards.find((e) => e.operation_card_id === operation_card_id);
    if (cardToJump) setDisplayPageList(displayPageList.jumpPage(cardToJump.card_order - 1, page - 1));
  };
  //#endregion

  //#region ---STEPPER---
  //新しいStepperが追加された時
  const handleAddStepper = (operation_card_id: number, page: number) => {
    const illustration_s3_key = `${props.modelId}/detail_illustration/${v4()}_illustration.json`;
    const reqBody: IllustrationPageRegistRequest = new IllustrationPageRegistRequest(operation_card_id, page, illustration_s3_key);
    postOperationPage(reqBody).then(
      (data) => {
        getSignedUrlForPut(illustration_s3_key).then(
          (url: string) => {
            putAws(url, new File([JSON.stringify(illustration_template)], "illastration_template.json")).then(
              (data) => {
                mutateOperationCardList();
                snackBarMessage.setMessage(SuccessMessages.INSERT_OPERATION_PAGE, "success");
              },
              (error) => {
                console.log(error);
              }
            );
          },
          (error) => {
            console.log(error);
            snackBarMessage.setMessage(ErrorMessages.GET_S3_URL_OPERATION, "error");
          }
        );
      },
      (error) => {
        console.log(error);
        setOperationCardDisplayList();
        snackBarMessage.setMessage(ErrorMessages.INSERT_OPERATION_PAGE, "error");
      }
    );
  };

  //stepperが削除された時の処理
  const handleDeleteStepper = (illustration_page_id: number, operationCardOrder: number) => {
    let result: boolean = window.confirm(DialogMessages.DELETE_ILLUSTRATION_PAGE);
    if (result) {
      deleteOperationPage(String(illustration_page_id)).then(
        (data) => {
          setDisplayPageList(displayPageList.deletePage(operationCardOrder));
          setSelectedPageHandler(selectedCardIndex);

          mutateOperationCardList();
          snackBarMessage.setMessage(SuccessMessages.DELETE_OPERATION_PAGE, "success");
        },
        (error) => {
          console.log(error);
          setOperationCardDisplayList();
          snackBarMessage.setMessage(ErrorMessages.DELETE_OPERATION_PAGE, "error");
        }
      );
    }
  };
  //#endregion

  //#region ---TITLE---
  const handleTitleChange = (id: number, e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const index = operationCardDisplayList.operationCards.findIndex((s: OperationCardDisplay) => s.operation_card_id === id);
    setOperationCardDisplay(operationCardDisplayList.changeTitle(index, e.currentTarget.value));
  };

  const handleChangePersonSeconds = (id: number, personSeconds: string) => {
    const index = operationCardDisplayList.operationCards.findIndex((s: OperationCardDisplay) => s.operation_card_id === id);
    setOperationCardDisplay(operationCardDisplayList.changePersonSeconds(index, personSeconds));
  };

  // input key Enter
  const handleTitleOnKeyPress = (e: any) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      e.target.blur();
    }
  };

  // update title
  const handleTitleUpdate = (id: number, title: string) => {
    const reqData = new OperationCardUpdateRequest(id, title);

    putOperationCard(reqData).then(
      (data) => {
        mutateOperationCardList();
        snackBarMessage.setMessage(SuccessMessages.UPDATE_OPERATION_CARD, "success");
      },
      (error) => {
        // Restore operation title to pre-changed state
        const operationCards = operationCardList.operation_card.map((e: OperationCard) => {
          return {
            operation_card_id: e.operation_card_id,
            title: e.title,
            card_order: e.card_order,
          };
        });

        let sorted = new OperationCardDisplayGroup();
        sorted.operationCards = sortByAsc(operationCards, "card_order");
        setOperationCardDisplay(sorted);
        snackBarMessage.setMessage(ErrorMessages.UPDATE_OPERATION_CARD, "error");
      }
    );
  };
  //#endregion

  //#region ---CARD---
  const handleCardClick = (index: number) => {
    setShowMainIllustration(false);
    setSelectedPageHandler(index);
    setSelectedCardIndex(index);
    if (index === selectedCardIndex) return;
    swiperRef.current?.swiper.slideTo(0);
  };

  // card add button clicked
  const handleAddOperationCard = () => {
    const illustration_s3_key = `${props.modelId}/detail_illustration/${v4()}_illustration.json`;
    const reqData = new OperationCardRegistRequest(
      Number(props.operationId),
      "",
      operationCardDisplayList.operationCards.length + 1,
      illustration_s3_key
    );

    postOperationCard(reqData).then(
      (data) => {
        getSignedUrlForPut(illustration_s3_key).then(
          (url: string) => {
            putAws(url, new File([JSON.stringify(illustration_template)], "illastration_template.json")).then(
              (data) => {
                setDisplayPageList(displayPageList.addCard());
                mutateOperationCardList();
                snackBarMessage.setMessage(SuccessMessages.INSERT_OPERATION_CARD, "success");
              },
              (error) => {
                console.log(error);
              }
            );
          },
          (error) => {
            console.log(error);
            snackBarMessage.setMessage(ErrorMessages.GET_S3_URL_OPERATION, "error");
          }
        );
      },
      (error) => {
        console.log(error);
        setOperationCardDisplayList();
        snackBarMessage.setMessage(ErrorMessages.INSERT_OPERATION_CARD, "error");
      }
    );
  };

  // card delete button clicked
  const handleDeleteOperationCard = (operation_card_id: number) => {
    deleteOperationCard(String(operation_card_id)).then(
      (data) => {
        const deletedCardIndex = operationCardDisplayList.operationCards.findIndex(
          (s: OperationCardDisplay) => s.operation_card_id === operation_card_id
        );
        setDisplayPageList(displayPageList.deleteCard(deletedCardIndex));
        // カードの削除が選択されたカードのindexに影響を与える場合に、indexを更新する
        if (selectedCardIndex >= deletedCardIndex) {
          if (!(selectedCardIndex === 0 && deletedCardIndex === 0)) setSelectedCardIndex(selectedCardIndex - 1);
        }
        mutateOperationCardList();
        snackBarMessage.setMessage(SuccessMessages.DELETE_OPERATION_CARD, "success");
      },
      (error) => {
        console.log(error);
        setOperationCardDisplayList();
        snackBarMessage.setMessage(ErrorMessages.DELETE_OPERATION_CARD, "error");
      }
    );
  };
  //#endregion

  // set display data (swr)
  const setOperationCardDisplayList = async () => {
    if (!operationCardList) return;
    if (!operationCardList.operation_card) return;
    //表示するページをセットする
    setDisplayPageList(displayPageList.setData(props.operationId, operationCardList));
    //表示用オブジェクト生成
    setOperationCardDisplay(await operationCardDisplayList.setData(operationCardList));
    setOperationCardDisplay(operationCardDisplayList.sortData());

    setSelectedPageHandler(selectedCardIndex);
  };

  // set display data (state)
  const setSelectedPageHandler = (selectedCardIndex: number) => {
    //check now selected card and page data exist
    if (
      !operationCardDisplayList.operationCards[selectedCardIndex] &&
      displayPageList.displayPageList[operationCardDisplayList.operationCards[selectedCardIndex]?.card_order - 1] === undefined
    ) {
      setSelectedPage(0);
    } else {
      setSelectedPage(displayPageList.displayPageList[operationCardDisplayList.operationCards[selectedCardIndex].card_order - 1]);
    }
  };

  //#region ---useEffect---
  useEffect(() => {
    getWorkStandard(props.operationId);
    getOperationCardList(props.operationId);
    setSelectedCardIndex(0);
  }, [props.operationId]);

  // change in SWR data
  useEffect(() => {
    setOperationCardDisplayList();
  }, [operationCardList]);

  // SWR error
  useEffect(() => {
    if (OperationCardListError) snackBarMessage.setMessage(ErrorMessages.GET_OPERATION_CARD, "error");
  }, [OperationCardListError]);
  //#endregion

  const handleUpdatePersonSeconds = (workId: number, personSeconds: string) => {
    putWorkPersonSeconds({ work_id: workId, person_seconds: Number(personSeconds) })
      .then(() => {
        snackBarMessage.setMessage(SuccessMessages.WORK_PERSON_SECONDS, "success");
      })
      .catch((err) => {
        console.log(err);
        snackBarMessage.setMessage(ErrorMessages.WORK_PERSON_SECONDS, "error");
      });
  };

  const slide = () => {
    if (showMainIllustration) {
      return (
        <SwiperSlide>
          <MoveableBoard
            id={"mainIllustration"}
            modelId={props.modelId}
            illustrationS3Key={workStandard?.illustration_s3_key}
            workStandardName={props.workStandardName}
            revisionNumber={props.revisionNumber}
            showAddButton={false}
            showDeleteButton={false}
          />
        </SwiperSlide>
      );
    }
    if (operationCardDisplayList.operationCards[selectedCardIndex]?.illustration_page_list.length === 0) {
      return (
        <SwiperSlide>
          {EditContext.editMode && (
            <Button
              variant="contained"
              style={{
                float: "right",
              }}
              color="secondary"
              onClick={() => {
                handleAddStepper(
                  operationCardDisplayList.operationCards[selectedCardIndex]?.operation_card_id,
                  operationCardDisplayList.operationCards[selectedCardIndex]?.illustration_page_list.length
                );
              }}
            >
              <AddCircleIcon />
            </Button>
          )}
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant={"h2"}
              align={"center"}
              style={{
                width: "100%",
              }}
            >
              {WorkStandardWorkTabLabel.NO_ILLUSTRATION_LINE1}
              <br />
              {WorkStandardWorkTabLabel.NO_ILLUSTRATION_LINE2}
            </Typography>
          </div>
        </SwiperSlide>
      );
    }
    return operationCardDisplayList.operationCards[selectedCardIndex]?.illustration_page_list.map((page, index) => (
      <SwiperSlide>
        <MoveableBoard
          id={String(index)}
          modelId={props.modelId}
          illustrationS3Key={page.illustration_s3_key}
          workStandardName={props.workStandardName}
          revisionNumber={props.revisionNumber}
          showAddButton={operationCardDisplayList.operationCards[selectedCardIndex]?.illustration_page_list.length === index + 1}
          showDeleteButton={true}
          addIllustration={() => {
            handleAddStepper(
              operationCardDisplayList.operationCards[selectedCardIndex]?.operation_card_id,
              operationCardDisplayList.operationCards[selectedCardIndex]?.illustration_page_list.length
            );
          }}
          deleteIllustration={() => {
            handleDeleteStepper(
              operationCardDisplayList.operationCards[selectedCardIndex]?.illustration_page_list[selectedPage].illustration_page_id,
              selectedPage
            );
          }}
        />
      </SwiperSlide>
    ));
  };

  return (
    <Grid container spacing={1} style={{ padding: theme.spacing(0, 1) }}>
      <Grid item xs={6} style={{ height: props.contentHeight * 0.6 }}>
        <Typography ref={labelRef} className={"typography-label"}>
          {WorkStandardWorkTabLabel.ILLUSTRATION_TABLE_TITLE}
        </Typography>
        <Paper elevation={1}>
          <Swiper
            ref={swiperRef}
            modules={[Navigation, Pagination, Keyboard]}
            slidesPerView={1}
            navigation
            pagination={{
              renderBullet: function (index: number, className: string) {
                return '<span class="' + className + '">' + (index + 1) + "</span>";
              },
            }}
            keyboard={{ enabled: true }}
            style={{ height: props.contentHeight * 0.6 - labelHeight - theme.spacing(1) }}
            onSlideChange={(swiper) => {
              setSelectedPage(swiper.activeIndex);
            }}
          >
            {slide()}
          </Swiper>
        </Paper>
      </Grid>

      <Grid item xs={6} style={{ height: props.contentHeight * 0.6 }}>
        <div className={styles.operation} style={{ height: "100%" }}>
          <span style={{ display: "flex", flexFlow: "row wrap" }}>
            <Chip
              className={styles.chip}
              label={WorkStandardWorkTabLabel.QUALITY_CHARACTERISTIC}
              avatar={<Avatar src={ChipQualityCharacter} style={{ color: "Black" }} />}
            />
            <Chip className={styles.chip} label={WorkStandardWorkTabLabel.POINT} avatar={<Avatar src={ChipPoint} style={{ color: "Black" }} />} />
            <Chip className={styles.chip} label={WorkStandardWorkTabLabel.TOOL} avatar={<Avatar src={ChipTools} style={{ color: "Black" }} />} />
            <Chip
              className={styles.chip}
              label={WorkStandardWorkTabLabel.EVENT_OCCURRING}
              avatar={<Avatar src={ChipEventOccurring} style={{ color: "Black" }} />}
            />
            <Chip className={styles.chip} label={WorkStandardWorkTabLabel.TROUBLE} avatar={<Avatar src={ChipTrouble} style={{ color: "Black" }} />} />
          </span>
          <Card
            className={showMainIllustration ? styles.selectedCardDiv : ""}
            style={{ backgroundColor: purple[50], marginBottom: 10 }}
            onClick={() => {
              setShowMainIllustration(true);
            }}
          >
            <Typography variant={"h4"}>{WorkStandardWorkTabLabel.OVERVIEW_ILLUSTRATION}</Typography>
          </Card>
          {operationCardDisplayList.operationCards.map((operationCardData, index) => {
            return (
              <Card
                key={index}
                style={{ minHeight: "150px", marginBottom: 10 }}
                className={selectedCardIndex === index && !showMainIllustration ? styles.selectedCardDiv : ""}
                onClick={() => handleCardClick(index)}
              >
                {/* タイトル */}
                {EditContext.editMode ? (
                  <div className={styles.cardHeaderDiv}>
                    <Typography className={styles.titleNo}>{index + 1}</Typography>
                    <InputBase
                      className={styles.edittitle}
                      value={operationCardData.title}
                      onChange={(e) => handleTitleChange(operationCardData.operation_card_id, e)}
                      onBlur={(e) => handleTitleUpdate(operationCardData.operation_card_id, e.currentTarget.value)}
                      onKeyPress={(e) => handleTitleOnKeyPress(e)}
                      inputProps={{
                        maxLength: WorkStandardCardLimits.INPUT_MAX_LENGTH,
                      }}
                    />
                    <Chip
                      className={styles.personSecondsChip}
                      size={"small"}
                      label={
                        <TextField
                          size={"small"}
                          type={"text"}
                          value={operationCardData.person_seconds}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{WorkStandardWorkTabLabel.SECOND}</InputAdornment>,
                          }}
                          onChange={(e) => {
                            if (!isOnlyHalfNumber(e.currentTarget.value)) {
                              snackBarMessage.setMessage(WarningMessages.NOT_ONLY_HALF_NUMBER, "warning");
                              return;
                            }
                            if (!isLengthCorrect(e.currentTarget.value, 0, 8)) {
                              snackBarMessage.setMessage(WarningMessages.PERSON_SECONDS_LENGTH, "warning");
                              return;
                            }
                            handleChangePersonSeconds(operationCardData.operation_card_id, e.currentTarget.value);
                          }}
                          onBlur={(e) => handleUpdatePersonSeconds(operationCardData.operation_card_id, e.currentTarget.value)}
                        />
                      }
                    />
                    {props.revisionNumber === revision.firstRevision && (
                      <IconButton
                        className={styles.deleteBtn}
                        disabled={operationCardDisplayList.operationCards.length === 1}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteOperationCard(operationCardData.operation_card_id);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </div>
                ) : (
                  <div className={styles.cardHeaderDiv}>
                    <Typography className={styles.titleNo}>
                      {index + 1} / {operationCardDisplayList.operationCards.length}
                    </Typography>
                    <Typography className={styles.title} onClick={() => voiceFunc(operationCardData.title)}>
                      {operationCardData.title}
                    </Typography>
                    <Chip
                      className={styles.personSecondsChip}
                      color={"secondary"}
                      size={"small"}
                      label={operationCardData.person_seconds + " " + WorkStandardWorkTabLabel.SECOND}
                    />
                  </div>
                )}

                <Divider />

                <CompOperationHashItemChipGroup operationCardId={operationCardData.operation_card_id} />

                {/* チップグループ */}
                <div style={{ margin: 10 }}>
                  <OperationChipGroup
                    stepsCount={operationCardData.illustration_page_list?.length}
                    operationCardId={operationCardData.operation_card_id}
                    displayllustrationPageId={
                      operationCardData.illustration_page_list &&
                      operationCardData.illustration_page_list[displayPageList.displayPageList[operationCardData.card_order - 1]]
                        ?.illustration_page_id
                    }
                    handleShowLinkedPage={handleShowLinkedPage}
                    isCardSelected={selectedCardIndex === index}
                  />
                </div>
              </Card>
            );
          })}
          {!EditContext.editMode && (
            <Typography variant={"h4"} style={{ textAlign: "center", marginBottom: "20px" }}>
              {WorkStandardWorkTabLabel.END_OF_WORK}
            </Typography>
          )}

          {/* 作業カード追加ボタン */}
          {EditContext.editMode && (
            <div className={styles.addBtn}>
              <IconButton color={"secondary"} onClick={handleAddOperationCard}>
                <AddCircleIcon fontSize="large" />
              </IconButton>
            </div>
          )}
        </div>
      </Grid>

      <Grid item xs={3} style={{ height: props.contentHeight * 0.4, display: "flex", flexDirection: "column" }}>
        <Typography className={"typography-label"}>{WorkStandardWorkTabLabel.SAFETY_POINT_TABLE_TITLE}</Typography>
        <Card style={{ flexGrow: 1 }}>
          <BasicChipGroup operationId={props.operationId} />
        </Card>
      </Grid>
      <Grid item xs={2} style={{ height: props.contentHeight * 0.4, display: "flex", flexDirection: "column" }}>
        <Typography className={"typography-label"}>{WorkStandardWorkTabLabel.SAFETY_EQUIPMENT_TABLE_TITLE}</Typography>
        <Card style={{ flexGrow: 1 }}>
          <CompSafetyEquipmentChipGroup operationId={props.operationId} />
        </Card>
      </Grid>
      <Grid item xs={2} style={{ height: props.contentHeight * 0.4, display: "flex", flexDirection: "column" }}>
        <Typography className={"typography-label"}>{WorkStandardWorkTabLabel.REQUIREMENT_TABLE_TITLE}</Typography>
        <Card style={{ flexGrow: 1 }}>
          <RequirementChipGroup operationId={String(props.operationId)} />
        </Card>
      </Grid>
      <Grid item xs={5} style={{ height: props.contentHeight * 0.4, display: "flex", flexDirection: "column" }}>
        <Typography className={"typography-label"}>{WorkStandardWorkTabLabel.PARTS_TABLE_TITLE}</Typography>
        <Card style={{ flexGrow: 1 }}>
          <PartsTable operationId={String(props.operationId)} />
        </Card>
      </Grid>
    </Grid>
  );
};
