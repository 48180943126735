import React from "react";
import { LoginUser } from "../apicaller/domain/response/authentication";
import { SnackMessage } from "../components/Common/SnackbarMessage";

export type loginInfo = {
  loginUser: LoginUser;
  getLoginUser: any;
};

export const LoginInfo = React.createContext<loginInfo>({
  loginUser: {
    user_id: 0,
    login_id: "",
    user_name: "",
    authority: 0,
    module_role: [],
    unit_role: [],
    team_role: [],
  },
  getLoginUser: () => {
  },
});

export type editInfo = {
  editMode: boolean;
  invert: any;
};

export const EditInfo = React.createContext<editInfo>({
  editMode: false,
  invert: () => {
  },
});

export type windowSize = {
  height: number;
  width: number;
};

export const WindowSize = React.createContext<windowSize>({
  height: window.innerHeight,
  width: window.innerWidth,
});

export type innerAreaSize = {
  height: number;
};

export const InnerAreaSize = React.createContext<innerAreaSize>({
  height: 0,
});

export type snackBarMessage = {
  showAlert: boolean;
  message: SnackMessage;
  setMessage: any;
  closeMessage: any;
};

export const Message = React.createContext<snackBarMessage>({
  showAlert: false,
  message: { message: "", type: "info" },
  setMessage: () => {
  },
  closeMessage: () => {
  },
});

export type miniDrawerInfo = {
  open: boolean;
  handleDrawerClose: any;
};

export const MiniDrawerInfo = React.createContext<miniDrawerInfo>({
  open: false,
  handleDrawerClose: () => {
  },
});

export type userFeedbackData = {
  feedbackType: string;
  targetPage: string;
  content: string;
  changeFeedbackTypeHandler: any;
  changeTargetPageHandler: any;
  changeContentHandler: any;
};

export const UserFeedback = React.createContext<userFeedbackData>({
  feedbackType: "",
  targetPage: "",
  content: "",
  changeFeedbackTypeHandler: () => {
  },
  changeTargetPageHandler: () => {
  },
  changeContentHandler: () => {
  },
});
