import { Collapse, message, notification } from "antd";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Component } from "react";
import { v4 } from "uuid";
import { SVGModal } from "../common";
import CommonButton from "../common/CommonButton";
import Scrollbar from "../common/Scrollbar";
import { Flex } from "../flex";
import Icon from "../icon/Icon";
import { getSignedUrlForPut, getSignedUrlForGet } from "../../../apicaller/repository/operation";
import { putAws } from "../../../apicaller/repository/fileupload";
import { hideLoading, showLoading } from "../../../components/Common/Loading2";
import { UploadFile } from "../../../consts/uploadFile";

notification.config({
  top: 80,
  duration: 2,
});

class ImageMapItems extends Component {
  static propTypes = {
    canvasRef: PropTypes.any,
    descriptors: PropTypes.object,
  };

  state = {
    activeKey: [],
    collapse: true,
    textSearch: "",
    descriptors: {},
    filteredDescriptors: [],
    svgModalVisible: false,
  };

  componentDidMount() {
    const { canvasRef } = this.props;
    this.waitForCanvasRender(canvasRef);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (JSON.stringify(this.props.descriptors) !== JSON.stringify(nextProps.descriptors)) {
      const descriptors = Object.keys(nextProps.descriptors).reduce((prev, key) => {
        return prev.concat(nextProps.descriptors[key]);
      }, []);
      this.setState({
        descriptors,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (JSON.stringify(this.state.descriptors) !== JSON.stringify(nextState.descriptors)) {
      return true;
    } else if (JSON.stringify(this.state.filteredDescriptors) !== JSON.stringify(nextState.filteredDescriptors)) {
      return true;
    } else if (this.state.textSearch !== nextState.textSearch) {
      return true;
    } else if (JSON.stringify(this.state.activeKey) !== JSON.stringify(nextState.activeKey)) {
      return true;
    } else if (this.state.collapse !== nextState.collapse) {
      return true;
    } else if (this.state.svgModalVisible !== nextState.svgModalVisible) {
      return true;
    } else if (this.state.uploading !== nextState.uploading) {
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    const { canvasRef } = this.props;
    this.detachEventListener(canvasRef);
  }

  waitForCanvasRender = (canvas) => {
    setTimeout(() => {
      if (canvas) {
        this.attachEventListener(canvas);
        return;
      }
      const { canvasRef } = this.props;
      this.waitForCanvasRender(canvasRef);
    }, 5);
  };

  attachEventListener = (canvas) => {
    canvas.canvas.wrapperEl.addEventListener("dragenter", this.events.onDragEnter, false);
    canvas.canvas.wrapperEl.addEventListener("dragover", this.events.onDragOver, false);
    canvas.canvas.wrapperEl.addEventListener("dragleave", this.events.onDragLeave, false);
    canvas.canvas.wrapperEl.addEventListener("drop", this.events.onDrop, false);
  };

  detachEventListener = (canvas) => {
    canvas.canvas.wrapperEl.removeEventListener("dragenter", this.events.onDragEnter);
    canvas.canvas.wrapperEl.removeEventListener("dragover", this.events.onDragOver);
    canvas.canvas.wrapperEl.removeEventListener("dragleave", this.events.onDragLeave);
    canvas.canvas.wrapperEl.removeEventListener("drop", this.events.onDrop);
  };

  /* eslint-disable react/sort-comp, react/prop-types */
  handlers = {
    onAddItem: (item, centered) => {
      const { canvasRef } = this.props;

      if (canvasRef.handler.interactionMode === "polygon") {
        message.info("Already drawing");
        return;
      }
      const id = v4();
      const option = Object.assign({}, item.option, { id });
      if (item.option.superType === "svg" && item.type === "default") {
        this.handlers.onSVGModalVisible(item.option);
        return;
      }
      if (item.option.type === "image") {
        const imageUploader = document.getElementById("image upload dialog");
        imageUploader.click();
        return;
      }

      if (item.option.type === "video") {
        const videoUploader = document.getElementById("video upload dialog");
        videoUploader.click();
        return;
      }
      canvasRef.handler.add(option, centered);
    },
    onAddSVG: (option, centered) => {
      const { canvasRef } = this.props;
      canvasRef.handler.add(
        {
          ...option,
          type: "svg",
          superType: "svg",
          id: v4(),
          name: "New SVG",
        },
        centered
      );
      this.handlers.onSVGModalVisible();
    },
    onDrawingItem: (item) => {
      const { canvasRef } = this.props;
      if (canvasRef.handler.interactionMode === "polygon") {
        message.info("Already drawing");
        return;
      }
      if (item.option.type === "line") {
        canvasRef.handler.drawingHandler.line.init();
      } else if (item.option.type === "arrow") {
        canvasRef.handler.drawingHandler.arrow.init();
      } else {
        canvasRef.handler.drawingHandler.polygon.init();
      }
    },
    onChangeActiveKey: (activeKey) => {
      this.setState({
        activeKey,
      });
    },
    onCollapse: () => {
      this.setState({
        collapse: !this.state.collapse,
      });
    },
    transformList: () => {
      return Object.values(this.props.descriptors).reduce((prev, curr) => prev.concat(curr), []);
    },
    onSVGModalVisible: () => {
      this.setState((prevState) => {
        return {
          svgModalVisible: !prevState.svgModalVisible,
        };
      });
    },
  };

  events = {
    /**
     * 動画ファイルアップロード
     * @param {*} e
     * @returns void
     */
    onImageFileUpload: (e) => {
      //画像選択ダイアログのDOM取得
      const imageUploader = document.getElementById("image upload dialog");
      if (imageUploader.files === null) return;
      if (imageUploader.files[0] === null) return;

      // 選択ファイルの拡張子チェック
      const allowExtensions = ".(jpg|jpeg|png|JPG|JPEG|PNG)$";
      if (!imageUploader.files[0].name.match(allowExtensions)) {
        alert("拡張子が jpg, jpeg, png 以外の画像ファイルはアップロードできません。");
        return;
      }

      // ファイルサイズチェック
      if (imageUploader.files[0].size > UploadFile.IMAGE_LIMIT_SIZE) {
        alert("500 MBを超える画像ファイルはアップロードできません。");
        return;
      }

      showLoading("uploading");

      const jsonFileName = String(this.props.illustrationS3Key).split("/").reverse()[0];
      const illustrationCategory = String(this.props.illustrationS3Key).split("/")[1];
      const jsonFileUuid = jsonFileName.split("_")[0];
      //編集中用：{model_id}/{main_illustration or detail_illustration}/{JSONファイルのuuid}/tmp/images/{uuid}_{選択したファイル名}.{拡張子}
      const illustrationS3Key = `${this.props.modelId}/${illustrationCategory}/${jsonFileUuid}/prd/images/${v4()}_${imageUploader.files[0].name}`;
      getSignedUrlForPut(illustrationS3Key).then((url) => {
        putAws(url, imageUploader.files[0]).then(() => {
          getSignedUrlForGet(illustrationS3Key).then((url) => {
            const { canvasRef } = this.props;
            // イメージオブジェクト生成(サイズ取るため用)
            var img = new Image();
            img.src = url;
            // 表示する画像のサイズ(高さと横幅)を取得するため、onloadを実施
            img.onload = function() {
              // 画像サイズをW300にするための計算
              const scale = 300 / this.width;

              const option = {
                id: v4(),
                type: "image",
                src: url,
                // 画像をcanvasの中央に配置
                width: this.width,
                height: this.height,
                name: illustrationS3Key,
                // 画像サイズをW300にする
                scaleX: scale,
                scaleY: scale,
              };
              canvasRef.handler.add(option, true);
              hideLoading();
            };
          });

          //input タグで保持してるファイルパスを初期化する
          imageUploader.value = "";
        });
      });
    },
    /**
     * 動画ファイルアップロード
     * @param {*} e
     * @returns void
     */
    onVideoFileUpload: (e) => {
      //画像選択ダイアログのDOM取得
      const videoUploader = document.getElementById("video upload dialog");
      if (videoUploader.files === null) return;
      if (videoUploader.files[0] === null) return;

      // 選択ファイルの拡張子チェック
      const allowExtensions = ".(mp4|mov|MP4|MOV)$";
      if (!videoUploader.files[0].name.match(allowExtensions)) {
        alert("拡張子が mp4, mov 以外の動画ファイルはアップロードできません。");
        return;
      }

      // ファイルサイズチェック
      if (videoUploader.files[0].size > UploadFile.VIDEO_LIMIT_SIZE) {
        alert("500 MBを超える動画ファイルはアップロードできません。");
        return;
      }

      showLoading("uploading");

      const jsonFileName = String(this.props.illustrationS3Key).split("/").reverse()[0];
      const illustrationCategory = String(this.props.illustrationS3Key).split("/")[1];
      const jsonFileUuid = jsonFileName.split("_")[0];
      //MOVファイルの拡張子が小文字になる場合は、エラーが出るため大文字にする
      const videoName = String(videoUploader.files[0].name).toUpperCase();
      //編集中用：{model_id}/{main_illustration or detail_illustration}/{JSONファイルのuuid}/tmp/videos/{uuid}_{選択したファイル名}.{拡張子}
      const illustrationS3Key = `${this.props.modelId}/${illustrationCategory}/${jsonFileUuid}/prd/videos/${v4()}_${videoName}`;
      getSignedUrlForPut(illustrationS3Key).then((url) => {
        putAws(url, videoUploader.files[0]).then(() => {
          getSignedUrlForGet(illustrationS3Key).then((url) => {
            const { canvasRef } = this.props;
            const option = {
              id: v4(),
              superType: "element",
              type: "video",
              src: url,
              width: 480,
              height: 270,
              name: illustrationS3Key,
              autoplay: false,
              muted: false,
              loop: false,
            };
            canvasRef.handler.add(option, true);
            hideLoading();
            //input タグで保持してるファイルパスを初期化する
            videoUploader.value = "";
          });
        });
      });
    },
    onDragStart: (e, item) => {
      this.item = item;
      const { target } = e;
      target.classList.add("dragging");
    },
    onDragOver: (e) => {
      if (e.preventDefault) {
        e.preventDefault();
      }
      e.dataTransfer.dropEffect = "copy";
      return false;
    },
    onDragEnter: (e) => {
      const { target } = e;
      target.classList.add("over");
    },
    onDragLeave: (e) => {
      const { target } = e;
      target.classList.remove("over");
    },
    onDrop: (e) => {
      e = e || window.event;
      if (e.preventDefault) {
        e.preventDefault();
      }
      if (e.stopPropagation) {
        e.stopPropagation();
      }
      const { layerX, layerY } = e;
      const dt = e.dataTransfer;
      if (dt.types.length && dt.types[0] === "Files") {
        const { files } = dt;
        Array.from(files).forEach((file) => {
          file.uid = v4();
          const { type } = file;
          if (type === "image/png" || type === "image/jpeg" || type === "image/jpg") {
            const item = {
              option: {
                type: "image",
                file,
                left: layerX,
                top: layerY,
              },
            };
            this.handlers.onAddItem(item, false);
          } else {
            notification.warn({
              message: "Not supported file type",
            });
          }
        });
        return false;
      }
      const option = Object.assign({}, this.item.option, {
        left: layerX,
        top: layerY,
      });
      const newItem = Object.assign({}, this.item, { option });
      this.handlers.onAddItem(newItem, false);
      return false;
    },
    onDragEnd: (e) => {
      this.item = null;
      e.target.classList.remove("dragging");
    },
  };

  renderItems = (items) => (
    <Flex flexWrap="wrap" flexDirection="column" style={{ width: "100%" }}>
      {items.map((item) => this.renderItem(item))}
    </Flex>
  );

  renderItem = (item, centered) =>
    item.type === "drawing" ? (
      <div
        key={item.name}
        draggable
        onClick={(e) => this.handlers.onDrawingItem(item)}
        className="rde-editor-items-item"
        style={{
          justifyContent: this.state.collapse ? "center" : null,
        }}
      >
        <span className="rde-editor-items-item-icon">
          <Icon name={item.icon.name} prefix={item.icon.prefix} style={item.icon.style} />
        </span>
        {this.state.collapse ? null : <div className="rde-editor-items-item-text">{item.name}</div>}
      </div>
    ) : (
      <div
        key={item.name}
        draggable
        onClick={(e) => this.handlers.onAddItem(item, centered)}
        onDragStart={(e) => this.events.onDragStart(e, item)}
        onDragEnd={(e) => this.events.onDragEnd(e, item)}
        className="rde-editor-items-item"
        style={{
          justifyContent: this.state.collapse ? "center" : null,
        }}
      >
        <span className="rde-editor-items-item-icon">
          <Icon name={item.icon.name} prefix={item.icon.prefix} style={item.icon.style} />
        </span>
        {this.state.collapse ? null : <div className="rde-editor-items-item-text">{item.name}</div>}
      </div>
    );

  render() {
    const { descriptors } = this.props;
    const { collapse, textSearch, activeKey, svgModalVisible, svgOption, uploading } = this.state;
    const className = classnames("rde-editor-items", {
      minimize: collapse,
    });
    let collapsePanels;
    if (collapse) {
      collapsePanels = Object.keys(descriptors).map((key) => <Collapse.Panel key={key}>{this.renderItems(descriptors[key])}</Collapse.Panel>);
    } else {
      collapsePanels = Object.keys(descriptors).map((key) => (
        <Collapse.Panel key={key} header={key}>
          {this.renderItems(descriptors[key])}
        </Collapse.Panel>
      ));
    }

    return (
      <>
        <div className={className} style={{ display: "none" }}>
          <input
            id="image upload dialog"
            style={{ display: "none" }}
            accept=".jpg,.jpeg,.png"
            type="file"
            onChange={(e) => this.events.onImageFileUpload(e)}
          />
          <input
            id="video upload dialog"
            style={{ display: "none" }}
            accept=".mp4,.mov"
            type="file"
            onChange={(e) => this.events.onVideoFileUpload(e)}
          />
          <Flex flex="1" flexDirection="column" style={{ height: "100%" }}>
            <Flex justifyContent="center" alignItems="center" style={{ height: 40 }}>
              <CommonButton
                icon={collapse ? "angle-double-right" : "angle-double-left"}
                shape="circle"
                className="rde-action-btn"
                style={{ margin: "0 4px" }}
                onClick={this.handlers.onCollapse}
              />
            </Flex>
            <Scrollbar>
              <Flex flex="1" style={{ overflowY: "hidden" }}>
                <Collapse
                  style={{ width: "100%" }}
                  bordered={false}
                  activeKey={activeKey.length ? activeKey : Object.keys(descriptors)}
                  onChange={this.handlers.onChangeActiveKey}
                >
                  {collapsePanels}
                </Collapse>
              </Flex>
            </Scrollbar>
          </Flex>
          <SVGModal visible={svgModalVisible} onOk={this.handlers.onAddSVG} onCancel={this.handlers.onSVGModalVisible} option={svgOption} />
        </div>
      </>
    );
  }
}

export default ImageMapItems;
