import React, { useContext, useState } from "react";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { EditInfo } from "../../context/appContextData";
import { OperationPointChip } from "../Home/display/home";
import { WorkStandardPointLimits } from "../../consts/inputLimits";

interface Props {
  parentWidth: number;
  chipContent: OperationPointChip;
  handleDelete: any;
  handleChange: any;
  handleUpdate: any;
  handleLinkRegist: any;
  handleLinkCancel: any;
  handleShowLinkedPage: any;
  isCardSelected: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexdirection: "row",
      padding: 5,
    },
    chip: {
      fontSize: 18,
      borderRadius: 5,
      height: "100%",
      minHeight: 35,
      minWidth: 100,
      "& .MuiChip-label": {
        overflowWrap: "anywhere",
        whiteSpace: "normal",
        textOverflow: "clip",
        width: "100%",
      },
    },
    chiperror: {
      fontSize: 18,
      borderRadius: 5,
      height: "100%",
      minHeight: 35,
      minWidth: 100,
      backgroundColor: "#F75D59",
      "& .MuiChip-label": {
        overflowWrap: "anywhere",
        whiteSpace: "normal",
        textOverflow: "clip",
        width: "100%",
      },
    },
    tooltips: {
      fontSize: 18,
    },
  })
);

export const OperationChip = (props: Props) => {
  const styles = useStyles();
  const [onEdit, setOnEdit] = useState(false);
  const EditContext = useContext(EditInfo);

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    props.handleChange(props.chipContent.operation_point_id, e);
  };

  const handleOnBlur = (e: React.FormEvent<HTMLInputElement>) => {
    props.handleUpdate(props.chipContent.operation_point_id, e);
    setOnEdit(false);
  };

  const handleOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      if (!e.nativeEvent.isComposing) {
        props.handleUpdate(props.chipContent.operation_point_id, e);
        setOnEdit(false);
      }
    }
    props.handleChange(props.chipContent.operation_point_id, e);
  };

  const voiceFunc = (readingMsg: string) => {
    var msg = new SpeechSynthesisUtterance();
    msg.text = readingMsg;
    msg.lang = "ja-JP";
    window.speechSynthesis.speak(msg);
  };

  return (
    <div className={styles.root}>
      {EditContext.editMode ? (
        <Chip
          className={props.chipContent.isError ? styles.chiperror : styles.chip}
          label={
            onEdit ? (
              <input
                style={{ width: props.parentWidth }}
                type="text"
                value={props.chipContent.content}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onKeyPress={handleOnKeyPress}
                autoFocus={true}
                maxLength={WorkStandardPointLimits.INPUT_MAX_LENGTH}
              />
            ) : (
              props.chipContent.content
            )
          }
          onDelete={() => props.handleDelete(props.chipContent.operation_point_id)}
          avatar={<Avatar src={props.chipContent.source} />}
          onClick={(e) => {
            setOnEdit(true);
          }}
        />
      ) : (
        <Chip
          className={styles.chip}
          label={props.chipContent.content}
          onClick={() => voiceFunc(props.chipContent.content)}
          avatar={<Avatar src={props.chipContent.source} />}
        />
      )}
    </div>
  );
};
