import React, { useContext, useState } from "react";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { EditInfo } from "../../context/appContextData";
import { Tooltip, Typography } from "@material-ui/core";
import { OperationPointChip } from "./display/home";
import { SafetyEquipment } from "../../apicaller/domain/response/operation";

interface Props {
  safetyEquipmentData: SafetyEquipment;
  handleDelete: any;
  equipmentItems: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexdirection: "row",
      padding: 5,
      scrollSnapAlign: "center",
    },
    chip: {
      fontSize: 18,
      borderRadius: 5,
      height: "100%",
      minHeight: 35,
      minWidth: 100,
      "& .MuiChip-label": {
        // wordWrap: "break-word",
        overflowWrap: "anywhere",
        whiteSpace: "normal",
        textOverflow: "clip",
        width: "100%",
      },
    },
    chiperror: {
      fontSize: 18,
      borderRadius: 5,
      height: "100%",
      minHeight: 35,
      minWidth: 100,
      backgroundColor: "#F75D59",
      "& .MuiChip-label": {
        // wordWrap: "break-word",
        overflowWrap: "anywhere",
        whiteSpace: "normal",
        textOverflow: "clip",
        width: "100%",
      },
    },
    input: {
      width: 300,
    },
    tooltips: {
      fontSize: 18,
    },
  })
);

export const CompSafetyEquipmentChip = (props: Props) => {
  const styles = useStyles();
  const EditContext = useContext(EditInfo);

  const getEquipmentName = (id: number) => {
    const findItem = props.equipmentItems.find((item) => item.avatarSourceId == id) ?? 0;
    if (findItem === 0) return "";
    return findItem.name;
  };

  const getEquipmentImage = (id: number) => {
    const findItem = props.equipmentItems.find((item) => item.avatarSourceId == id) ?? 0;
    if (findItem === 0) return "";
    return findItem.image;
  };

  return (
    <div className={styles.root}>
      {EditContext.editMode ? (
        <Chip
          label={getEquipmentName(props.safetyEquipmentData.safety_equipment_id)}
          avatar={<Avatar alt="image" src={getEquipmentImage(props.safetyEquipmentData.safety_equipment_id)} />}
          onDelete={() => props.handleDelete(props.safetyEquipmentData.safety_equipment_necessity_id)}
        />
      ) : (
        <Chip
          className={styles.chip}
          label={getEquipmentName(props.safetyEquipmentData.safety_equipment_id)}
          avatar={<Avatar alt="image" src={getEquipmentImage(props.safetyEquipmentData.safety_equipment_id)} />}
        />
      )}
    </div>
  );
};

export default CompSafetyEquipmentChip;
